<template>
  <div class="authen_box">
   <div class="tip_box">
     <div v-if="$route.query.type== 1">
       <img src="../../../assets/img/succsTip.png" alt="" />
       <p >客户认证已提交，审核完成后，即可进行相关操作！</p>
     </div>
     <div v-if="$route.query.type== 2">
       <img src="../../../assets/img/failTip.png" alt="" />
       <p>客户认证提交失败，请稍后重试！</p>
     </div>
     <p class="tip_text">如有其他疑问，可联系商务进行沟通，商务QQ：3007359468/3007359467</p>
   </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
    }
  },
  created() {
    setTimeout(()=> {
      if(this.$route.query.type == 1){
        this.$router.push('/SearchPage')
      }else{
        this.$router.push('/authen')
      }
    },4000)
  },
  methods: {

  },

}
</script>

<style scoped lang="scss">
.authen_box{
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 0 30px;
  box-sizing: border-box;

  .tip_box{
    margin: 0 auto;
    width: 1265px;
    border: 1px solid #CECECE;
    margin-top: 100px;
    text-align: center;
    img{
      width: 160px;
      height: 160px;
      margin: 60px auto 40px auto;
    }
    p{
      font-size: 28px;
    };
    p.tip_text{
      font-size: 20px;
      margin: 40px 0 100px 0;
    }
  }
}
</style>