var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"ave_block",attrs:{"element-loading-text":_vm.loadingText}},[_c('p',{staticClass:"tip"},[_vm._v("以下常用均值可能需要更新：")]),_c('div',{staticClass:"table-box"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.refresh_list,"border":"","max-height":"300px","span-method":_vm.objectSpanMethod}},[_c('el-table-column',{attrs:{"prop":"instrument_id","label":"机号","min-width":"100","header-align":"center","align":"center"}}),_c('el-table-column',{attrs:{"prop":"testing_item","label":"检测项目","min-width":"100","header-align":"center","align":"center"}}),_c('el-table-column',{attrs:{"prop":"qc_batch","label":"质控批号","min-width":"100","header-align":"center","align":"center"}}),_c('el-table-column',{attrs:{"prop":"level","label":"水平","min-width":"90","header-align":"center","align":"center"}}),_c('el-table-column',{attrs:{"prop":"average","label":"平均值","min-width":"90","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [( row.level == 'C1')?_c('span',[_vm._v(_vm._s(row.fixed_target_c1 ? row.fixed_target_c1.toFixed(2) : '-'))]):_vm._e(),( row.level == 'C2')?_c('span',[_vm._v(_vm._s(row.fixed_target_c2 ? row.fixed_target_c2.toFixed(2) : '-'))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"sd","label":"SD","min-width":"90","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [( row.level == 'C1')?_c('span',[_vm._v(_vm._s(row.fixed_sd_c1 ? row.fixed_sd_c1.toFixed(2) : '-'))]):_vm._e(),( row.level == 'C2')?_c('span',[_vm._v(_vm._s(row.fixed_sd_c2 ? row.fixed_sd_c2.toFixed(2) : '-'))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"cv","label":"CV","min-width":"90","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [( row.level == 'C1')?_c('span',[_vm._v(_vm._s(row.fixed_cv_c1 ? (((row.fixed_cv_c1 *100).toFixed(2)) + "%") : '-'))]):_vm._e(),( row.level == 'C2')?_c('span',[_vm._v(_vm._s(row.fixed_cv_c2 ? (((row.fixed_cv_c2 *100).toFixed(2)) + "%") : '-'))]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"opt","label":"操作","min-width":"132","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticClass:"refr_btn",attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleRefresh(row,1)}}},[_vm._v("更新")])]}}])})],1)],1),_c('span',{staticClass:"dialog-footer",staticStyle:{"position":"absolute","bottom":"20px"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"refr_all",attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleRefresh(null,2)}}},[_vm._v("更新所有均值")])],1),_c('el-dialog',{staticClass:"success_tip",attrs:{"title":"","width":"30%","visible":_vm.success_tip_dialog,"show-close":false,"center":true,"modal-append-to-body":false,"append-to-body":true,"before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.success_tip_dialog=$event}}},[_c('p',{staticStyle:{"text-align":"left"}},[_vm._v(" 常用均值已更新！成功"),_c('span',{staticStyle:{"color":"#81B337","font-size":"18px","font-weight":"bold","padding":"0 2px"}},[_vm._v(_vm._s(_vm.update_tip_info.success))]),_vm._v("条，失败 "),_c('span',{staticStyle:{"color":"#BD3124","font-size":"18px","font-weight":"bold","padding":"0 2px"}},[_vm._v(_vm._s(_vm.update_tip_info.error))]),_vm._v("条。")]),(_vm.update_tip_info.error > 0)?_c('p',{staticStyle:{"text-align":"left"}},[_vm._v("失败的有："+_vm._s(_vm.update_tip_info.error_list? _vm.error_list.toString() : '')+"，失败原因:质控数据数量未达到计算常用均值数据数量要求!")]):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.handleClose()}}},[_vm._v("知道了")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }