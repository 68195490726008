<template>
  <div >
    <div >
      <div class="echarts-box content-box">
        <div class="legend-box">
          <p class="legend-btn">
            <span :class="ZorLJ == 'LJ'&&'choiceZorLJ'" @click="ZorLJ = 'LJ'">L-J</span>
            <span :class="ZorLJ == 'Z'&&'choiceZorLJ'" @click="ZorLJ = 'Z'">Z分数</span>
          </p>
          <p class="legend_drop">告警点颜色：<span class="GJ"></span> </p>
          <p class="legend_drop">失控点颜色：<span class="SK"></span> </p>
          <p class="legend_drop">更换试剂：<span class="SJ tip_line"></span> </p>
          <p class="legend_drop">重新定标：<span class="DB tip_line"></span> </p>
        </div>
        <div>
          <div v-if="echartsShow&&ZorLJ=='Z'" >
            <line_Z id="innerEchartLine"  :ehartData="zData" :calibration_data_list="calibration_data_list"></line_Z>
          </div>
          <div v-if="echartsShow&&ZorLJ=='LJ'" >
            <div v-for="(item,index) in ljData" :key="index" >
              <line_LJ :id="item.c1_data.id" :ehartData="item.c1_data" :calibration_data_list="calibration_data_list"></line_LJ>
              <line_LJ :id="item.c2_data.id" :ehartData="item.c2_data" :calibration_data_list="calibration_data_list"></line_LJ>
            </div>
            <div v-if="ljData.length == 0" class="lj-seat-box">
              <p>暂无数据</p>
            </div>
          </div>
        </div>
      </div>
      <div class="accept-confirm-box">
        所选项是否全部接受<el-button class="btn" type="primary" :disabled="disabled_accept" size="small" @click="confirmAccept">确定</el-button>
      </div>
      <div class="table-box content-box self-table">
        <el-table ref="data_table_ref" 
          :data="table_data"  style="width: 100%" header-align="center" :header-cell-style="rowClass"  :cell-class-name="cellClassName" border :row-class-name="tableRowClassName"
          table size="mini">
          <el-table-column label="日期" prop="date_time_order" align="center" :width="flexColumnWidth(150)"></el-table-column>
          <el-table-column v-if="isAll" label="质控批号" prop="qc_batch" align="center" :width="flexColumnWidth(140)"></el-table-column>
          <el-table-column label="试剂批号" align="center" prop="batch" :width="flexColumnWidth(140)"></el-table-column>
          <el-table-column label="C1(mg/L)" align="center" :width="flexColumnWidth(130)" class="borde-right-th">
            <template #header>
              <div class="check-cell">
                <span><el-checkbox v-model="c1_all_accepted" :disabled="table_data.length==0" @change="changeAllAccept(1,$event)"></el-checkbox></span>
                <span> C1(mg/L)</span>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="check-cell">
                <span><el-checkbox v-if="scope.row.c1" v-model="scope.row.c1_accept"  @change="changeAcceptList(scope.row.c1_id, $event);judgeAllAcceptStatus()"></el-checkbox></span>
                <span class="weightFont" style="margin-left: 4px" @click="checkAbsorbance(scope.row,'c1')"> {{ scope.row.c1 ?scope.row.c1.toFixed(2) : '-' }}</span>
                <el-tooltip v-if="scope.row.c1" style="color:#aaaaaa;font-size: 15px" class="item" popper-class="tooltip_instrument" effect="light" content="查看吸光度" placement="top-start">
                  <div slot="content">
                    <i style="color:#aaaaaa;margin-left: 0px" class="el-icon-warning"></i>查看吸光度
                  </div>
                  <i style="color:#aaaaaa;vertical-align: 3px;margin-left: auto" class="el-icon-info"  @click="checkAbsorbance(scope.row,'c1')"></i>
                </el-tooltip>
              </div>
              
            </template>
          </el-table-column>
          <el-table-column label="是否在控" align="center" :width="flexColumnWidth(220)">
            <template slot-scope="scope">
              <div class="report-box">
                <span :class="scope.row.is_c1_lc==true ? 'SK-text' :scope.row.is_c1_lc==false?'GJ-text' : ''">
                  {{ !scope.row.c1 ? '' : scope.row.is_c1_lc ? "失控" : "在控" }}
                 <el-tooltip v-if="scope.row.report_tag_c1" style="color:#BD3124;" class="item" popper-class="tooltip_instrument" effect="light" content="填写报告" placement="top-start">
                  <div slot="content">
                    <span>填写报告</span>
                  </div>
                  <i style="color:#aaaaaa;margin-left: auto" class="el-icon-edit-outline" @click="judgeDoubleLc(scope.row.above_data,1,scope.row,scope.$index)"></i>
                </el-tooltip>
                <el-tooltip  v-if="scope.row.c1_pdf" style="color:#81B337;" class="item" popper-class="tooltip_instrument" effect="light" content="查看报告" placement="top-start">
                  <div slot="content">
                    <span>查看报告</span>
                  </div>
                  <i style="color:#aaaaaa;margin-left: auto" class="el-icon-document-checked" @click="checkControlReport(scope.row.above_data,1,scope.row)"></i>
                </el-tooltip>
                </span>

<!--                <el-button class="report-btn" v-if="scope.row.report_tag_c1" size="mini" @click="judgeDoubleLc(scope.row.above_data,1,scope.row,scope.$index)">填写报告</el-button>-->
<!--                <el-button class="report-btn" v-if="scope.row.c1_pdf" size="mini" @click="checkControlReport(scope.row.above_data,1,scope.row)">查看报告</el-button>-->
              </div>
            </template>
          </el-table-column>
          <el-table-column label="是否接受" align="center" :width="flexColumnWidth(150,110)" >
            <template slot-scope="scope">
              <el-switch class="switch" v-model="scope.row.c1_accept" active-text="是" inactive-text="否" v-if="scope.row.c1"
                @change="changeAcceptFun(scope.row.c1_id, $event)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="违背规则" prop="c1_rule" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column label="C2(mg/L)" align="center" :width="flexColumnWidth(130)">
            <template #header>
              <div class="check-cell">
                <span><el-checkbox v-model="c2_all_accepted" :disabled="table_data.length==0" @change="changeAllAccept(2,$event)"></el-checkbox></span>
                <span> C2(mg/L)</span>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="check-cell">
                <span>
                  <el-checkbox v-if="scope.row.c2" v-model="scope.row.c2_accept" @change="changeAcceptList(scope.row.c2_id, $event);judgeAllAcceptStatus()"></el-checkbox></span>
                <span class="weightFont" style="margin-left: 4px"  @click="checkAbsorbance(scope.row,'c2')">{{ scope.row.c2 ? scope.row.c2.toFixed(2) : '-' }}</span>
                <el-tooltip v-if="scope.row.c2"  style="color:#aaaaaa;font-size: 15px" class="item" popper-class="tooltip_instrument" effect="light" content="查看吸光度" placement="top-start">
                  <div slot="content">
                    <i style="color:#aaaaaa;margin-left: 0px" class="el-icon-warning"></i>查看吸光度
                  </div>
                  <i style="color:#aaaaaa;vertical-align: 3px;margin-left: auto" class="el-icon-info"  @click="checkAbsorbance(scope.row,'c2')"></i>
                </el-tooltip>
              </div>
              <!-- mg/L -->
            </template>
          </el-table-column>
          <el-table-column label="是否在控" align="center" :width="flexColumnWidth(220)">
            <template slot-scope="scope">
              <div class="report-box">
                <span class="report-item" :class="scope.row.is_c2_lc ? 'SK-text' :scope.row.is_c2_lc==false?'GJ-text' : ''">
                  {{ !scope.row.c2 ? '' : scope.row.is_c2_lc ? "失控" : "在控"}}
                   <el-tooltip v-if="scope.row.report_tag_c2" style="color:#BD3124;" class="item" popper-class="tooltip_instrument" effect="light" content="填写报告" placement="top-start">
                  <div slot="content">
                    <span>填写报告</span>
                  </div>
                  <i style="color:#aaaaaa;margin-left: auto" class="el-icon-edit-outline" @click="judgeDoubleLc(scope.row.above_data,2,scope.row,scope.$index)"></i>
                </el-tooltip>
                <el-tooltip  v-if="scope.row.c2_pdf" style="color:#81B337;" class="item" popper-class="tooltip_instrument" effect="light" content="查看报告" placement="top-start">
                  <div slot="content">
                    <span>查看报告</span>
                  </div>
                  <i style="color:#aaaaaa;margin-left: auto" class="el-icon-document-checked" @click="checkControlReport(scope.row.above_data,2,scope.row)"></i>
                </el-tooltip>
                </span>
<!--                <el-button class="report-btn" v-if="scope.row.report_tag_c2" size="mini" @click="judgeDoubleLc(scope.row.above_data,2,scope.row,scope.rowIndex)">{{scope.row.above_data.c2_pdf?'查看':'填写'}}报告</el-button>-->
<!--                <el-button class="report-btn" v-if="scope.row.report_tag_c2" size="mini" @click="judgeDoubleLc(scope.row.above_data,2,scope.row,scope.$index)">填写报告</el-button>-->
<!--                <el-button class="report-btn" v-if="scope.row.c2_pdf" size="mini" @click="checkControlReport(scope.row.above_data,2,scope.row)">查看报告</el-button>-->
              </div>
            </template>
          </el-table-column>
          <el-table-column  label="是否接受" align="center" :width="flexColumnWidth(150,110)">
            <template slot-scope="scope">
              <el-switch class="switch" v-model="scope.row.c2_accept" active-text="是" inactive-text="否" v-if="scope.row.c2"
                @change="changeAcceptFun(scope.row.c2_id, $event)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="违背规则" prop="c2_rule" align="center" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
    </div>
    <div>
      <!-- 弹窗 -->
      <el-dialog
        title=""
        :visible.sync="dialog_double"
        width="35%"
        class="tip-dialog-box"
        :show-close="false">
        <div class="double-box">
          <p>当前时间段C{{temp_index[0]==1?'2':'1'}}也存在失控，是否同时填写C1、C2失控报告？</p>
          <div class="btn-box">
            <el-button type="primary" class="btn" size="small" @click="dialog_double = false;temp_index=[1,2];openControlReport()">是！同时填写</el-button>
          </div>
          <div class="btn-box">
            <el-button class="btn" size="small" @click="dialog_double = false;openControlReport()">不！只填写C{{temp_index[0]}}失控报告</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title=""
        :visible.sync="dialog_data_push"
        width="35%"
        class="tip-dialog-box"
        :show-close="false">
        <div class="double-box">
          <p>数据已刷新,是否同步更新页面</p>
          <div class="btn-box">
            <el-button type="primary" class="btn" size="small" @click="dialog_data_push = false;$emit('change',{})">是</el-button>
          </div>
          <div class="btn-box">
            <el-button class="btn" size="small" @click="dialog_data_push = false;">否</el-button>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        title=""
        :visible.sync="dialog_all_accept"
        width="35%"
        class="tip-dialog-box"
        :show-close="false">
        <div class="double-box">
          <p>
            {{c1_all_not?'C1':''}}
            {{c2_all_not?'C2':''}}
            已全部去除，是否确认</p>
          <div class="btn-box">
            <el-button type="primary" class="btn" size="small" @click="confirmAllNot">是</el-button>
          </div>
          <div class="btn-box">
            <el-button class="btn" size="small" @click="dialog_all_accept = false;c1_all_not = false;c2_all_not = false">否</el-button>
          </div>
        </div>
      </el-dialog>
      <reportDialog :hospital_id="hospital_id" ref="reportDialog" @success="set_report_data" :selectRows="selectRows" :is_under_control_c1="is_under_control_c1" :is_under_control_c2="is_under_control_c2"></reportDialog>
<!--      report_select_dialog-->
      <el-dialog
          title=""
          v-if="report_select_dialog"
          :visible.sync="report_select_dialog"
          width="70%"
          class="tip-dialog-box select_sk"
          >
        <template slot="title">
          <h3>{{formatDateString(this.currentRow.date_time)}}有以下{{this.merge_data_temp.length}}组失控数据：</h3>
        </template>
        <div class="double-box">
          <el-table :data="merge_data_temp" stripe :max-height="450" :min-height="200" border>
            <el-table-column label="日期" prop="date_time_order" header-align="center" align="center" min-width="120">
              <template slot-scope="scope">
                <span :style="{color: (scope.row.c1_disable || scope.row.c2_disable) ? '#81B337' : ''}">{{scope.row.date_time_order}}</span>
              </template>
            </el-table-column>
            <el-table-column label="" prop="report_type" header-align="center" align="center" width="100">
              <template slot-scope="scope">
                <div class="check-cell">
                  <span v-if="!scope.row.is_c1_lc ">-</span>
                  <span v-else>
                    <span v-if="scope.row.is_c1_lc">
                      <el-checkbox v-if="!scope.row.c1_pdf" :key="scope.$index" :disabled="scope.row.c1_disable" v-model="scope.row.c1_select" @change="chooseSk(scope.row.c1_id, 1,scope.$index)"></el-checkbox>
                      <span  v-else >-</span>
                    </span>
                    <span v-else>-</span>
                  </span>

                </div>
              </template>
            </el-table-column>
            <el-table-column label="C1(mg/L)" prop="c1" header-align="center" align="center" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.is_c1_lc && !scope.row.c1_pdf" :style="{color: scope.row.c1_disable ? '#81B337' : ''}">{{scope.row.c1 ? scope.row.c1 : '-'}}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column label="违背规则" prop="c1_rule" header-align="center" align="center" min-width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.is_c1_lc && !scope.row.c1_pdf" :style="{color: scope.row.c1_disable ? '#81B337' : ''}">{{scope.row.c1_rule?scope.row.c1_rule:'-'}}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column label="" prop="report_type" header-align="center" align="center" width="100" >
              <template slot-scope="scope">
                <div class="check-cell">
                  <span v-if="!scope.row.is_c2_lc "> -</span>
                  <span v-else>
                    <span v-if="scope.row.is_c2_lc">
                      <el-checkbox  v-if="!scope.row.c2_pdf" :key="scope.$index" :disabled="scope.row.c2_disable" v-model="scope.row.c2_select" @change="chooseSk(scope.row.c2_id, 2,scope.$index)"></el-checkbox>
                      <span v-else>-</span>
                    </span>
                    <span v-else>-</span>
                  </span>

                </div>
              </template>
            </el-table-column>
            <el-table-column label="C2(mg/L)" prop="c2" header-align="center" align="center" width="100">
              <template slot-scope="scope">
                <span v-if="scope.row.is_c2_lc && !scope.row.c2_pdf" :style="{color: scope.row.c2_disable ? '#81B337' : ''}">{{scope.row.c2 ? scope.row.c2 : '-'}}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
            <el-table-column label="违背规则" prop="c2_rule" header-align="center" align="center" min-width="120">
              <template slot-scope="scope">
                <span v-if="scope.row.is_c2_lc && !scope.row.c2_pdf" :style="{color: scope.row.c2_disable ? '#81B337' : ''}">{{scope.row.c2_rule?scope.row.c2_rule:'-'}}</span>
                <span v-else>-</span>
              </template>
            </el-table-column>
          </el-table>
          <p class="table_tip" >可以选择其中任意一项或多项同时填写失控报告。</p>
        </div>
        <div class="btn-box select_rep">
          <el-button  class="btn confirm" size="small" @click="goReport()">已选好，去填写报告</el-button>
          <el-button class="btn" size="small" @click="handleCleanSelected">取消</el-button>
        </div>
      </el-dialog>

    </div>
  </div>
</template>

<script>
import api from '@/api/quality'

import line_Z from './echarts/line_Z.vue'; 
import line_LJ from './echarts/line_LJ.vue'; 
import {flexColumnWidth} from '@/assets/js/adaption.js'
import reportDialog from './reportDialog.vue';
import indoorMixins from './indoorMixins';


export default {
  mixins: [indoorMixins],
  props: ['hospital_id','instrument_id','target_sd_x'],
  components: {
    reportDialog,
    line_Z,
    line_LJ,
  },
  data() {
    return {
      ZorLJ: 'Z',
      table_data: [], //列表数据
      table_original_data: [], //原始列表数据保存
      zData: {}, //Z分数图数据
      ljData: {}, //Z分数图数据
      isAll: false,
      echartsShow: false,
      rowDay: 0,//每日颜色区分日期记录
      rowType: true, //每日颜色区分当前状态
      temp_above_data: {},
      currentRow: {},
      temp_index: '',
      dialog_double: false,
      is_accepted: [],
      not_accepted: [],
      dialog_data_push: false,
      c1_all_accepted: true,
      c2_all_accepted: true,
      dialog_all_accept: false,
      c1_all_not: false,
      c2_all_not: false,
      merge_data_temp: [], //合并失控报告可供选择的数据
      report_select_dialog: false, //合并失控报告可供选择的数据
      validity_period_info: {},
      calibration_data_list: [],
      selected_data: [], // 只有1条
      selectRows: [],
      is_under_control_c1: false, // 点击填写报告时，C1当天是否有在控
      is_under_control_c2: false, // 点击填写报告时，C2当天是否有在控
    }
  },
  computed: {
    disabled_accept() {
      return this.is_accepted.length==0&&this.not_accepted.length==0
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    formatDateString(dateString) {
      const [year, month, day] = dateString.split(' ')[0].split('-').map(Number);
      return `${year}年${month}月${day}日`;
    },
    checkAbsorbance(row,type){ // 查看吸光度
      if(type === 'c1'){
        if(row.c1){
          const url = this.$router.resolve({ path: '/qcs-detail', query: {type: 'yzk',id: row.c1_qc_id } }).href; // 解析路由为完整 URL
          window.open(url, '_blank'); // 在新标签页中打开 URL
          // this.$router.push({ path: '/qcs-detail', query: {type: 'yzk',id: row.c1_qc_id } })
        }else{
          this.$message.warning('暂无数据')
        }
      }else{
        if(row.c2){
          const url = this.$router.resolve({ path: '/qcs-detail', query: {type: 'yzk',id: row.c2_qc_id } }).href; // 解析路由为完整 URL
          window.open(url, '_blank'); // 在新标签页中打开 URL
          // this.$router.push({ path: '/qcs-detail', query: {type: 'yzk',id: row.c2_qc_id } })
        }else{
          this.$message.warning('暂无数据')
        }
      }
    },
    handleScroll() {
      let heightTop = document.getElementsByClassName('indoor-content')[0].scrollTop //距离顶部高度]
      let sticky_top = document.getElementsByClassName('self-table')[0].offsetTop - document.getElementsByClassName('head')[0].clientHeight + 14
      if (heightTop >= sticky_top) { //表头到达页面顶部固定表头
        // 146 为距离顶部多少时开始吸顶，即吸顶距离
        let top = heightTop - sticky_top
        document.getElementsByClassName('el-table__header-wrapper')[0].style.zIndex = '777'
        document.getElementsByClassName('el-table__header-wrapper')[0].style.top = `${top}px`
        document.getElementsByClassName('el-table__header-wrapper')[0].style.position = `sticky`
      } else {
        document.getElementsByClassName('el-table__header-wrapper')[0].style.top = ''
        document.getElementsByClassName('el-table__header-wrapper')[0].style.zIndex = ''
      }
    },

    /* 表格样式 */
    // 表格列宽
    flexColumnWidth(width,minWidth) {
      return flexColumnWidth(width,minWidth)
    },
    // 表格头部样式 
    rowClass({ row, column, rowIndex, columnIndex }){
      let c1StyleList = this.isAll?[3,4,5,6]:[2,3,4,5],
        c2StyleLits = this.isAll?[7,8,9,10]: [6,7,8,9],
        borderList = this.isAll?[6]: [5];
      let style_text = ""
      if(rowIndex == 0 && c1StyleList.includes(columnIndex)){
        style_text +='color:#166DC9;'
      }
      if(rowIndex == 0 && c2StyleLits.includes(columnIndex)){
        style_text += 'color:#7CCE7A;'
      }
      if(borderList.includes(columnIndex)){
        style_text += 'border-right:4px double #EBEEF5'
      }
      return style_text
    },

    cellClassName({row, column, rowIndex, columnIndex}) {
      let borderList = this.isAll?[6,]: [5];
      if(borderList.includes(columnIndex)){
        return "my-cell"
      }
    },
    // 按日期不同颜色区分
    tableRowClassName({row, rowIndex}) {
      return row.rowClass
    },
    /* 数据变化后刷新 */
    push_tableAndChart_data(tableAndChartData) {
      this.is_accepted = []
      this.not_accepted = []
      this.echartsShow = false
      // 列表数据
      this.table_original_data = JSON.parse(JSON.stringify(tableAndChartData.qc_list))
      this.table_original_data.reverse()
      this.table_data = JSON.parse(JSON.stringify(tableAndChartData.qc_list));
      this.table_data.reverse()
      this.calibration_data_list = JSON.parse(JSON.stringify(tableAndChartData.calibration_data_list)) // 定标点位
      this.judgeAllAcceptStatus();
        //按日期不同颜色区分
      let length = this.table_data.length
      let rowType = false
      if(length>0){
        this.table_data[0].rowClass = '';
      }
      for(let i=1;i<length;i++){
        let nowRwowDay = this.table_data[i].date_time_order.split('.')[0].split('-')[2]
        let oldRowDay = this.table_data[i-1].date_time_order.split('.')[0].split('-')[2]
        if(oldRowDay!=nowRwowDay){
          rowType = !rowType
        }
        this.table_data[i].rowClass = rowType?'dark-row':''
      }

      // 折线图数据
      let dataToEcharts = this.handle_dataToEcharts(tableAndChartData.qc_data,tableAndChartData.qc_list,tableAndChartData.qc_serum_dict)
      this.zData = dataToEcharts.zData
      this.ljData = dataToEcharts.ljData
      this.$nextTick(()=> {
        this.echartsShow = true;
      })
    },
    /* 是否接受 */
    // 是否接受按钮
    changeAcceptFun(qc_point_id,e) {
      let params = {
        hospital_id: this.hospital_id,
      }
      if(e){
        params.is_accepted = [qc_point_id]
        params.not_accepted = []
      }else{
        params.is_accepted = []
        params.not_accepted = [qc_point_id]
      }
      // 初始化数据
      
      api.qc_point_accept(params).then(res => {
        if(res.code == 200) {
          this.$emit('change',{})
        }
      })
    },
    // 是否接受-多选
    changeAcceptList(qc_point_id,e) {
      if(e){
        // 改成了接受
        // 判断在不在不在里面
        if(this.not_accepted.includes(qc_point_id)){
          this.not_accepted = this.not_accepted.filter(e => e !== qc_point_id)
        }else {
          this.is_accepted.push(qc_point_id)
        }
      }else{
        // 改成了不接受
        if(this.is_accepted.includes(qc_point_id)){
          this.is_accepted = this.is_accepted.filter(e => e !== qc_point_id)
        }else {
          this.not_accepted.push(qc_point_id)
        }
      }
    },
    // 判断表头的全选按钮状态
    judgeAllAcceptStatus() {
      let c1_is_accepted = []
      let c2_is_accepted = []
      let c1_not_accepted = []
      let c2_not_accepted = []
      
      this.table_data.forEach(e => {
        if(e.c1){
          e.c1_accept? c1_is_accepted.push(e.c1_id) : c1_not_accepted.push(e.c1_id)
        }
        if(e.c2){
          e.c2_accept? c2_is_accepted.push(e.c2_id) : c2_not_accepted.push(e.c2_id)
        }
      })
      if(c1_is_accepted.length!=0){
        this.c1_all_accepted = c1_not_accepted.length==0? true : false
      }else {
        this.c1_all_accepted = false
      }
      if(c2_is_accepted.length!=0){
        this.c2_all_accepted = c2_not_accepted.length==0? true : false
      }else {
        this.c2_all_accepted = false
      }
    },
    // 全选切换
    changeAllAccept(type,event) {
      this.table_data.forEach(element => {
        if(element['c'+type]){
          if(element['c'+type+'_accept']!=event){
            element['c'+type+'_accept'] = event
            this.changeAcceptList(element['c'+type+'_id'],event)
          }
        }
      })
    },
    // 确认后发送数据
    confirmAccept() {
      // this.confirmAccept = 
      if(!this.c1_all_accepted){
        let c1_temp_list = this.table_data.filter(e => e.c1&&e.c1_accept)
        if(c1_temp_list.length == 0) {
          this.c1_all_not = true
        }
      }
      if(!this.c2_all_accepted){
        let c2_temp_list = this.table_data.filter(e => e.c2&&e.c2_accept)
        if(c2_temp_list.length == 0) {
          this.c2_all_not = true
        }
      }
      if(this.c1_all_not||this.c2_all_not){
        this.dialog_all_accept = true
        return
      }

      let params = {
        hospital_id: this.hospital_id,
        is_accepted: this.is_accepted,
        not_accepted: this.not_accepted,
      }
      let loading = this.$loading({
        lock: true,
        text: '数据更新中，请等待',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      api.qc_point_accept(params).then(res => {
        if(res.code == 200) {
          this.is_accepted = []
          this.not_accepted = []
          this.dialog_data_push = true
        }
      }).finally(msg => {
        loading.close()
      })
    },
    // 全部去除的确认
    confirmAllNot() {
      let params = {
        hospital_id: this.hospital_id,
        is_accepted: this.is_accepted,
        not_accepted: this.not_accepted,
      }
      this.dialog_all_accept = false;
      this.c1_all_not = false;
      this.c2_all_not = false;
      let loading = this.$loading({
        lock: true,
        text: '数据更新中，请等待',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      api.qc_point_accept(params).then(res => {
        if(res.code == 200) {
          this.is_accepted = []
          this.not_accepted = []
          this.dialog_data_push = true
        }
      }).finally(msg => {
        loading.close()
      })
    },
    /* 打开失控报告弹窗 */
    // 是否两个浓度都失控
    judgeDoubleLc(item,type,currentRow,index) { // 点击填写报告第一步
      this.currentRow = currentRow
      this.temp_above_data = item
      // this.temp_index = [type]
      // let c1_pdf = item.c1_pdf
      // let c2_pdf = item.c2_pdf
      // if(item['c'+type+'_pdf']) {
      //   this.openControlReport();
      //   return;
      // }
      // if(item.is_c1_lc&&item.is_c2_lc){
      //   if(type == 1){
      //     if(c2_pdf){
      //       this.openControlReport();
      //     }else {
      //       this.dialog_double = true;
      //     }
      //   }else {
      //     if(c1_pdf){
      //       this.openControlReport();
      //     }else {
      //       this.dialog_double = true;
      //     }
      //   }
      // }else {
      //   this.openControlReport();
      // }
      this.merge_data_temp = []  //循环之前清空上一次的数据（弹窗数据）
      let current_row_date = currentRow.date_time_order.split('.')[0]
      // 过滤当天的数据
      let merge_data_current_temp = this.table_data.filter(e => {
        return  e.date_time_order.split('.')[0] == (current_row_date)})
      this.is_under_control_c1 = merge_data_current_temp.some(item => {return item.report_tag_c1})
      this.is_under_control_c2 = merge_data_current_temp.some(item => {return item.report_tag_c2})
      // 过滤当前失控数据
      this.merge_data_temp = merge_data_current_temp.filter(e => {
        return  (e.is_c1_lc && !e.c1_pdf ) || (e.is_c2_lc && !e.c2_pdf)
      })
      // 初始化，每一个失控数据的可选性和是否选中
      this.merge_data_temp.forEach(item=>{
        item.c1_select = undefined
        item.c2_select = undefined
        item.c1_disable = false
        item.c2_disable = false
      })
      if(type === 1){
        if( this.merge_data_temp.length > 0){
          if(this.merge_data_temp.length == 1){ // 如果只有一条失控数据直接打开失控报告，不需要选择合并弹窗
            this.merge_data_temp[0].c1_select = true
            this.selected_data = this.merge_data_temp
           this.goReport()
          }else{
            this.report_select_dialog = true;
            for(let n=0;n< this.merge_data_temp.length; n++){ // C1默认选中第一个未出报告的
              if(!this.merge_data_temp[n].c1_pdf && this.merge_data_temp[n].is_c1_lc){
                this.merge_data_temp[n].c1_select = true
                this.merge_data_temp[n].c1_disable = true
                return;
              }
            }
          }
        }
      }else{
        if( this.merge_data_temp.length > 0){
          if(this.merge_data_temp.length == 1){ // 如果只有一条失控数据直接打开失控报告，不需要选择合并弹窗
            this.merge_data_temp[0].c2_select = true
            this.selected_data = this.merge_data_temp
            this.goReport()
          }else {
            this.report_select_dialog = true;
            for (let n = 0; n < this.merge_data_temp.length; n++) { // C1默认选中第一个未出报告的
              if (!this.merge_data_temp[n].c2_pdf && this.merge_data_temp[n].is_c2_lc) {
                this.merge_data_temp[n].c2_select = true
                this.merge_data_temp[n].c2_disable = true
                return;
              }
            }
          }
        }
      }
    },
    goReport(){
      this.openControlReport()
      this.report_select_dialog = false
    },
    handleCleanSelected(){ // 清空选中项
      this.report_select_dialog = false;
    },
    // 打开失控报告弹窗
    openControlReport() {
      // this.temp_index.forEach((e,index) => {
      //   if(index == this.temp_index.length-1){
      //     qc_point_ids += this.temp_above_data['c' + e + '_id']
      //   }else {
      //     qc_point_ids += this.temp_above_data['c' + e + '_id'] + ','
      //   }
      // });
      let qc_point_ids
      let selectedIds = [];
      this.merge_data_temp.forEach(obj => { // 获取选中的c1_id、c2_id
        // 如果c1_select为true，则添加c1_id到selectedIds数组
        if (obj.c1_select) {
          selectedIds.push(obj.c1_id);
        }
        // 如果c2_select为true，则添加c2_id到selectedIds数组
        if (obj.c2_select) {
          selectedIds.push(obj.c2_id);
        }
      });
      this.selectRows = this.merge_data_temp.filter(item => item.c1_select || item.c2_select);
      qc_point_ids = selectedIds.toString()
      let param = {
        hospital_id: this.hospital_id,
        // c_rule: item['c'+index+'_rule'],
        date_time_order: this.merge_data_temp[0].date_time_order,
        c1_rule: this.merge_data_temp[0].c1_rule,
        c2_rule: this.merge_data_temp[0].c2_rule,
        qc_instance_id: this.merge_data_temp[0].qc_instance_id,
        qc_serum_id: this.merge_data_temp[0].qc_serum_id,
        qc_point_ids,
        c1: this.currentRow.c1,
        c2: this.currentRow.c2,
        c1_sd:this.target_sd_x.c1_sd,
        c2_sd:this.target_sd_x.c1_sd,
        c1_x:this.target_sd_x.c1_x,
        c2_x:this.target_sd_x.c2_x,
        selectRows:this.selectRows
      }
      this.$refs.reportDialog.open_dialog(param);
    },
    // 查看失控报告弹窗
    checkControlReport(item,type,currentRow) {
      this.temp_above_data = item
      this.currentRow = currentRow
      this.currentRow.c1_select = false
      this.currentRow.c2_select = false
      let qc_point_ids = ''
      // this.temp_index.forEach((e,index) => {
      //   if(index == this.temp_index.length-1){
      //     qc_point_ids += this.temp_above_data['c' + e + '_id']
      //   }else {
      //     qc_point_ids += this.temp_above_data['c' + e + '_id'] + ','
      //   }
      // });
      let selectRows = []
      selectRows.push(this.currentRow)
      if(type ===1){
        qc_point_ids += this.currentRow['c1_id']
        selectRows[0].c1_select = true
      }else{
        qc_point_ids += this.currentRow['c2_id']
        selectRows[0].c2_select = true
      }
      let date_time_order = this.selected_data.date_time_order
      let param = {
        hospital_id: this.hospital_id,
        // c_rule: item['c'+index+'_rule'],
        c1_rule: this.currentRow.c1_rule,
        c2_rule: this.currentRow.c2_rule,
        qc_instance_id: this.currentRow.qc_instance_id,
        qc_serum_id: this.currentRow.qc_serum_id,
        qc_point_ids,
        c1: this.currentRow.c1,
        c2: this.currentRow.c2,
        c1_sd:this.target_sd_x.c1_sd,
        c2_sd:this.target_sd_x.c1_sd,
        c1_x:this.target_sd_x.c1_x,
        c2_x:this.target_sd_x.c2_x,
        selectRows: [currentRow],
        date_time_order,
      }

      this.$refs.reportDialog.open_dialog(param);
    },
    /* 成功后修改列表的报告数据 */
    set_report_data(file_url) {
      this.$emit('change')
      // if(this.temp_index.length == 1){
      //   this.temp_above_data['c'+this.temp_index[0]+'_pdf'] = file_url[0];
      // }else{
      //   this.temp_above_data.c1_pdf = file_url[0];
      //   this.temp_above_data.c2_pdf = file_url[1];
      // }
    },
    chooseSk(val,type,current_index){
    }
  },
}
</script>

<style lang="scss" scoped>

  .GJ {
    background-color: $alarm-color;
  }
  .SK {
    background-color: $incontrollable-color;
  }
  .SK-text  {
    color: $incontrollable-color;
  }
  .SJ  {
    border-top:2px  dashed  red;
  }
  .DB  {
    border-top:2px dashed green;
  }
 .GJ-text {
    color: $alarm-color;
  }
  .content-box {
    margin: $gap;
    background-color: #fff;
    padding: $gap;
    border-radius: $gap;
  }

 
  
  .legend-box{
    display: flex;
    align-items: center;
    gap: 3*$gap;
    .legend-btn {
      &>span {
        display: inline-block;
        cursor: pointer;
        height: px(35);
        line-height: px(35);
        padding: 0 px(30);
        font-size: px(24);
        border-radius: px(4);
        margin-left: px(10);
        color: #ffffff;
        font-weight: bold;
        background: #BEBEBE;
      }
      .choiceZorLJ {
        background: #2D74B6;
        box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, .2);
      }
    }
    .legend_drop {
      display: flex;
      align-items: center;
      &>span {
        width: px(30);
        height: px(18);
        border-radius: 50%/50%;
        display: inline-block;
      }
      .tip_line{
        display: inline-block;
        width: px(40);
        height: px(1);
        border-radius: 0;
      }
      
    }
  }
  .lj-seat-box {
    height: px(420);
    width: 99%;
    position: relative;
    &>p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      size: $text-size;
    }
  }

  //表格内样式

  //间隔颜色
  .table-box {
   
    ::v-deep .el-table .dark-row {
      background:  #fafafa !important;
    }
    
    ::v-deep .el-table__body {
      .has-gutter {
        th{
          .cell {
            font-size: $table-th-size;
          }
        }
      }
      .el-table__row {
        td {
          .cell {
            font-size: $table-td-size;
            line-height: px(25);
          }
        }
      }
    } 
   
    ::v-deep.switch {
      font-size: $table-td-size ;
      .el-switch__label {
        &>span {
          font-size: $table-td-size;
          line-height: px(15);
        }
      }
      .el-switch__label--left {
        margin-right: px(10);
      }
      .el-switch__core {
        width: px(40) !important;
        height: px(20) !important;
       // transform: scale(1,0.9);
      }
      .el-switch__core::after {
        width: px(18);
        height: px(18);
        margin-top: -px(1);
      }
      
      .el-switch__label--right {
        margin-left: px(10);
      }
    }
    ::v-deep.el-switch.is-checked .el-switch__core::after {
      margin-left: -px(20) !important;
    }

    .report-box {
      position: relative;
      width: 100%;
      font-size: $table-td-size;
      .report-btn {
        position: absolute;
        right: 0;
        top:  50%;
        transform: translate(0,-50%);
        padding: px(3);
        font-size: $btn-text-size;
      }
      span.report-item{
        position: relative;
        display:inline-block;
      }
    }
    ::v-deep .el-icon-edit-outline.el-tooltip,::v-deep .el-icon-document-checked.el-tooltip,{
      position: absolute;
      padding-left: 6px;
      //top:4px;
    }
  }
  
  ::v-deep .my-cell {
    border-right: 4px double #EBEEF5;
  }
  ::v-deep .my-cell::before {
    width: 2px;
    height: 3px;
    background-color: red;
    
  }
  .tip-dialog-box {
    ::v-deep.show-close {
      height: 0;
      padding: 0;
    }
    ::v-deep.el-dialog__header {
      padding: 0;
    }
    ::v-deep.el-dialog__body {
      padding: px(20);
    }
    ::v-deep.el-dialog {
      margin-top: 30vh !important;
    }
    .double-box {
      &>p {
        font-size: px(20);
        text-align: center;
      }
      
    }
    .btn-box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .btn {
        width: 50%;
        margin-top: $gap;
      }
    }
  }
  .accept-confirm-box {
    padding-left: $gap;
    display: flex;
    align-items: center;
    margin: $gap $gap -px(5);
    font-size: px(20);
    .btn {
      margin-left: $gap;
      width: px(120);
      font-size: px(18);
    }
  }
  .check-cell {
    display: flex;
    align-items: center;
    &>span:first-child {
      text-align: left;
      ::v-deep .el-checkbox__inner{
        width: px(16);
        height: px(16);
      }
      ::v-deep .el-checkbox__inner::after{
        height: px(10);
        left: px(6);
      }
    }
    &>span:last-child {
      width: calc(100% - px(18));
      text-align: center;
    }
  }
  .question{
    vertical-align: 3px;
    width: 13px;
    height: 13px;
    display: inline-block;

    background: url('../../../../assets/img/quest.png');
    background-size: cover;
  }

  .select_sk{ // 选择合并失控报告弹窗
    display: flex;
    padding: 20px;
    ::v-deep .el-dialog { margin: auto !important; }
    ::v-deep .el-table thead tr th{
      background: #F2F2F2;
      color:#000;
      font-size: 16px;
    }

    ::v-deep .el-table td.el-table__cell div{
      color:#4f4f4f;
      font-size: 14px;
    }
    .table_tip{
      color:#BD3124;
      font-size: 16px!important;
      text-align: left!important;
      margin: 14px 0 30px 0;
    }
    //::v-deep .el-dialog{
    //  margin-top: 15vh !important;
    //}
    ::v-deep .el-dialog__header{
      h3{
        width:calc(100% - 40px);
        margin:0 20px 0 20px;
        padding:30px 0 10px 0;
        display: inline-block;
        box-sizing: border-box;
        border-bottom:1px solid #efefef;
      }
    }
    .double-box{
      max-height: 500px;
    }
    .btn-box.select_rep{
      margin-bottom:20px;
      ::v-deep .el-button{
        display: inline-block;
        width: 220px;
        height: 40px;
        line-height: 40px;
        padding:0 15px;
      }
      .el-button.confirm{
        background: #347CAF;
        color:#fff;
      }
    }
  }
  .dialog-center-box.qc {
    position: relative;
    .top {
      justify-content: center;
      padding-bottom: px(10);
      p{
        text-align: center;
        width: 100%;
      }
    }
    .body{
      p{
        margin-top: 30px;
        margin-left: 38px;
        font-size: 16px;
        color: #4F4F4F;
      }
      .field_value{
        margin-left: 4px;
      }
    }
    .qc_footer{
      text-align: center;
      position: absolute;
      bottom: 10%;
      left: calc(50% - 50px)
    }
  }
  ::v-deep .el-dialog__body{
    height: 100%;
  }
  .dialog-center-box {
    height: 100%;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: $dialog-title-size;
      border-bottom: $border-color solid 1px;
      p {
        font-weight: bold;
        color: #111111;
        font-size: $dialog-title-size;
        padding-bottom: $gap;
        width: 20%;
      }
      .title_text{
        color: #E99D42;
        font-size: 28px;
      }
    }
    .body {
      margin: $gap;
      font-size: $dialog-text-size;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1550px), print {
  ::v-deep .el-icon-edit-outline.el-tooltip,::v-deep .el-icon-document-checked.el-tooltip,{
    top:2px;
  }
}
  @media screen and (min-width: 1551px) , print {
    ::v-deep .el-icon-edit-outline.el-tooltip,::v-deep .el-icon-document-checked.el-tooltip,{
      top:5px;
    }
  }
</style>