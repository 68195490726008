<template>
  <div class="index">
    <div class="print-box">
      <div class="page-box " style="background-color: 1;">
        <div class="title-box"> 
          <p>{{hospital_name}}</p>
          <p>{{dataList[2].value}}室内质控月度报告</p>
          <p>{{date_text}}</p>
        </div>
        <div class="detail-menu" style="margin-top:50px;">
          <p class="tip">基本信息</p>
          <detailMenu :dataList="dataList"></detailMenu>
        </div>
        <div class="detail-menu" style="margin-top:25px;">
          <p class="tip">质控信息</p>
          <detailMenu :dataList="qcp_data_list[0].dataList"></detailMenu>
        </div>
        <div class="table-box" style="margin-top:25px;">
          <p class="tip">L-J图</p>
          <table border="1" class="monthly-table" >
            <tbody class="monthly-tbody">
              <tr>
                <td class="left no-left-border">
                  <span>C1</span>
                  <ul>
                    <li v-if="qcp_data_list[0].dataList[3].value==='设定'"><span>(设定)</span></li>
                    <li><p><span class="xbar">X</span>:</p><p>{{textTarget(qcp_data_list[0].qc_data.target_c1)}}mg/L</p></li>
                    <li><p>SD:</p><p>{{textTarget(qcp_data_list[0].qc_data.sd_c1)}}mg/L</p></li>
                    <li><p>CV:</p><p>{{ textCV(qcp_data_list[0].qc_data.cv_c1)}}%</p></li>
                  </ul>
                </td>
                <td class="no-left-border no-right-border echarts-box">
                  <line_LJ  :legendShow="false" :id="'print_monthly'+qcp_data_list[0].c1_data.id" :ehartData="qcp_data_list[0].c1_data" :calibration_data_list="calibration_data_list"></line_LJ>
                </td>
              </tr>
              <tr>
                <td class="left no-left-border">
                  <span>C2</span>
                  <ul>
                    <li v-if="qcp_data_list[0].dataList[3].value==='设定'"><span>(设定)</span></li>
                    <li><p><span class="xbar">X</span>:</p><p>{{textTarget(qcp_data_list[0].qc_data.target_c2)}}mg/L</p></li>
                    <li><p>SD:</p><p>{{textTarget(qcp_data_list[0].qc_data.sd_c2)}}mg/L</p></li>
                    <li><p>CV:</p><p>{{ textCV(qcp_data_list[0].qc_data.cv_c2)}}%</p></li>
                  </ul>
                </td>
                <td class="no-left-border no-right-border echarts-box">
                  <line_LJ :legendShow="false" :id="'print_monthly'+qcp_data_list[0].c2_data.id" :ehartData="qcp_data_list[0].c2_data" :calibration_data_list="calibration_data_list"></line_LJ>
                </td> 
              </tr>
            </tbody>
          </table>
        </div>
        <div class="page-bottom-box" >
          <p>第1页,共{{page}}页</p>
        </div>
      </div>
      <div v-if="page>3">
        <div class="page-box" style="background-color: 1;" v-for="(item,index) in (page-3)" :key="index">
          <div class="z-box">
            <p class="tip"> Z分数图</p>
            <div class=" echarts-box" >
              <line_Z :id="'print_monthlyEchartLine'+index"  :ehartData="qcp_data_list[item-1].zData" :calibration_data_list="calibration_data_list"></line_Z>
            </div>
          </div>
          <div class="line"></div>
          <div class="detail-menu">
            <p class="tip">质控信息</p>
            <detailMenu :dataList="qcp_data_list[item].dataList"></detailMenu>
          </div>
          <div class="table-box">
            <p class="tip">L-J图</p>
            <table border="1" class="monthly-table" >
              <tbody class="monthly-tbody">
                <tr>
                  <td class="left no-left-border">
                    <span>C1</span>
                    <ul>
                      <li v-if="qcp_data_list[item].dataList[3].value==='设定'"><span>(设定)</span></li>
                      <li><p><span class="xbar">X</span>:</p><p>{{textTarget(qcp_data_list[item].qc_data.target_c1)}}mg/L</p></li>
                      <li><p>SD:</p><p>{{textTarget(qcp_data_list[item].qc_data.sd_c1)}}mg/L</p></li>
                      <li><p>CV:</p><p>{{ textCV(qcp_data_list[item].qc_data.cv_c1)}}%</p></li>
                    </ul>
                  </td>
                  <td class="no-left-border no-right-border echarts-box">
                    <line_LJ  :legendShow="false" :id="'print_monthly'+qcp_data_list[item].c1_data.id" :ehartData="qcp_data_list[item].c1_data" :calibration_data_list="calibration_data_list"></line_LJ>
                  </td>
                </tr>
                <tr>
                  <td class="left no-left-border">
                    <span>C2</span>
                    <ul>
                      <li v-if="qcp_data_list[item].dataList[3].value==='设定'"><span>(设定)</span></li>
                      <li><p><span class="xbar">X</span>:</p><p>{{textTarget(qcp_data_list[item].qc_data.target_c2)}}mg/L</p></li>
                      <li><p>SD:</p><p>{{textTarget(qcp_data_list[item].qc_data.sd_c2)}}mg/L</p></li>
                      <li><p>CV:</p><p>{{ textCV(qcp_data_list[item].qc_data.cv_c2)}}%</p></li>
                    </ul>
                  </td>
                  <td class="no-left-border no-right-border echarts-box">
                    <line_LJ :legendShow="false" :id="'print_monthly'+qcp_data_list[item].c2_data.id" :ehartData="qcp_data_list[item].c2_data" :calibration_data_list="calibration_data_list"></line_LJ>
                  </td> 
                </tr>
              </tbody>
            </table>
          </div>
          <div class="page-bottom-box">
            <p>第{{index + 2}}页,共{{page}}页</p>
          </div>
        </div>
      </div>
      <div class="page-box " style="background-color: 1;">
        <div class="z-box">
          <p class="tip"> Z分数图</p>
          <div class="echarts-box echarts-box" >
            <line_Z :id="'monthlyEchartLine_-1'"  :ehartData="qcp_data_list[qcp_data_list.length-1].zData" :calibration_data_list="calibration_data_list"></line_Z>
          </div>
        </div>
        <div class="line"></div>
        <div class="statistics-box" v-if="statistics_data!={}">
          <p class="tip">统计图</p>
          <table border="1" class="monthly-table" >
            <tbody class="monthly-tbody">
              <tr>
                <td rowspan='5' class="no-left-border tip-c-td">
                  C1
                </td>
                <td rowspan='4' class="no-left-border">
                  质控品数据
                </td>
                <td class="no-left-border"></td>
                <td class="no-left-border no-right-border">
                  <ul class="target">
                    <li><span class="xbar">X</span>(mg/L)</li>
                    <li>SD(mg/L)</li>
                    <li>CV(%)</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="no-left-border">上月</td>
                <td class="no-left-border no-right-border">
                  <ul class="target">
                    <li>{{ textTarget(statistics_data.last_month_target_c1) }}</li>
                    <li>{{ textTarget(statistics_data.last_month_sd_c1) }}</li>
                    <li>{{ textCV(statistics_data.last_month_cv_c1) }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="no-left-border">本月</td>
                <td class="no-left-border no-right-border">
                  <ul class="target">
                    <li>{{ textTarget(statistics_data.now_month_target_c1) }}</li>
                    <li>{{ textTarget(statistics_data.now_month_sd_c1) }}</li>
                    <li>{{ textCV(statistics_data.now_month_cv_c1) }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="no-left-border">累积</td>
                <td class="no-left-border no-right-border">
                  <ul class="target">
                    <li>{{ textTarget(statistics_data.year_stats_target_c1) }}</li>
                    <li>{{ textTarget(statistics_data.year_stats_sd_c1)  }}</li>
                    <li>{{ textCV(statistics_data.year_stats_cv_c1)  }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="3" class="no-left-border no-right-border echarts-box">
                  <monthlyS  id="echartLineS3" :ehartData="statistics_data.ehartData_C1"></monthlyS>
                </td>
              </tr>
              <tr>
                <td rowspan='5' class="no-left-border tip-c-td">
                  C2
                </td>
                <td rowspan='4' class="no-left-border">
                  质控品数据
                </td>
                <td class="no-left-border"></td>
                <td class="no-left-border no-right-border"> 
                  <ul class="target">
                    <li><span class="xbar">X</span>(mg/L)</li>
                    <li>SD(mg/L)</li>
                    <li>CV(%)</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="no-left-border">上月</td>
                <td class="no-left-border no-right-border">
                  <ul class="target">
                    <li>{{ textTarget(statistics_data.last_month_target_c2) }}</li>
                    <li>{{ textTarget(statistics_data.last_month_sd_c2) }}</li>
                    <li>{{ textCV(statistics_data.last_month_cv_c2) }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="no-left-border">本月</td>
                <td class="no-left-border no-right-border">
                  <ul class="target">
                    <li>{{ textTarget(statistics_data.now_month_target_c2) }}</li>
                    <li>{{ textTarget(statistics_data.now_month_sd_c2)  }}</li>
                    <li>{{ textCV(statistics_data.now_month_cv_c2)  }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="no-left-border">累积</td>
                <td class="no-left-border no-right-border">
                  <ul class="target">
                    <li>{{ textTarget(statistics_data.year_stats_target_c2)}}</li>
                    <li>{{ textTarget(statistics_data.year_stats_sd_c2)  }}</li>
                    <li>{{ textCV(statistics_data.year_stats_cv_c2 ) }}</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td colspan="3" class="no-left-border no-right-border echarts-box">
                  <monthlyS  id="echartLineS4" :ehartData="statistics_data.ehartData_C2"> </monthlyS>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="page-bottom-box">
          <p>第{{page-1}}页,共{{page}}页</p>
        </div>
      </div>
      <div class="page-box " style="background-color: 1;">
        <div class="analysis-box">
          <p>综合分析</p>
          <div class="analysis-text-box">
            <p>根据本月质控结果观察:</p>
            <p class="indent">C1质控品统计次数 <u>{{'\u00a0'}}{{analysis_data.c1_cnt}}{{'\u00a0'}}</u> 次，C2质控品统计次数 <u>{{'\u00a0'}}{{analysis_data.c2_cnt}}{{'\u00a0'}}</u> 次</p>
            <p class="indent">失控统计 <u>{{'\u00a0'}}{{analysis_data.lc_cnt}}{{'\u00a0'}}</u> 次:</p>
            <p class="indent2">
              <span v-for="(item,index) in analysis_data.stats_lc_report" :key="index">
                {{item.analysis_type_name}}: <u>{{'\u00a0'}}{{ item.count}}{{'\u00a0'}}</u> 次
              </span>
            </p>
          </div>
<!--          <div class="print-info-box ">-->
<!--            <p><span>分析人:</span>{{user_name}}</p>-->
<!--            <p><span>打印人:</span>{{user_name}}</p>-->
<!--            <p><span>打印时间:</span>{{nowDate}}</p>-->
<!--            <p><span>质量负责人:</span></p>-->
<!--          </div>-->
        </div>
        <div class="analysis-box" style="margin-top: 10px;">
          <p>结论</p>
          <div class="analysis-text-box conclusion">
            <!--情况1，新旧试剂批号切换-->
<!--            <p v-if="analysis_data.month_summary.change_batch"  class="conclusion_item">本月切换试剂批号，定靶过程中，测试的均值和SD与设定的靶值和SD接近，符合预期，处于受控状态。</p>-->
            <!--情况2，没有发生失控的月度分析小结-->
            <p v-if="analysis_data.month_summary.not_lc" class="conclusion_item">本月
              <span v-if="analysis_data.month_summary.change_batch">切换试剂批号，共</span>做了{{analysis_data.month_summary.qc_cnt}}次质控，未发生失控情况，系统状态稳定，没有明显误差，质控曲线形态
              {{analysis_data.status_val}}
             ，
              {{analysis_data.trend_val}}趋势线变化，SD、CV值与上个月做比较， {{analysis_data.compare_val}}，本月处于{{analysis_data.control_val}}状态。</p>
            <!--情况3，发生失控的月度小结-->
            <p v-if="!analysis_data.month_summary.not_lc" >本月
              <span v-if="analysis_data.month_summary.change_batch">切换试剂批号，共</span>做了{{analysis_data.month_summary.qc_cnt}}次质控，失控数据如下:</p>
            <div v-if="!analysis_data.month_summary.not_lc">
              <div v-for="item in analysis_data.month_summary.lc_info_list" class="conclusion_item">
                <span style="vertical-align: top;font-weight: 600">{{item.data_time}},{{item.level}} 失控：</span>
                <p style="display: inline-block">
                  <p>失控原因：{{item.reason}}</p>
                  <p> 处理：{{item.correction_name}}</p>
                </p>
              </div>
              <p class="conclusion_item_last">纠正后数据SD、CV值与上个月做比较：
                {{analysis_data.compare_val}}，本月处于：{{analysis_data.control_val}}状态</p>
            </div>
          </div>
          <div class="print-info-box">
            <p><span>分析人:</span>{{user_name}}</p>
            <p><span>打印人:</span>{{user_name}}</p>
            <p><span>打印时间:</span>{{nowDate}}</p>
            <p><span>质量负责人:</span></p>
          </div>
        </div>
        <div class="page-bottom-box">
          <p>第{{page}}页,共{{page}}页</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import detailMenu from './printDetailMenu.vue'
import line_Z from '../echarts/line_Z.vue'
import line_LJ from '../echarts/line_LJ.vue'
import monthlyS from '../echarts/monthlyS.vue'
import printMonthly from './printMonthly.vue'
import indoorMixins from '../indoorMixins'


export default {
  props: ['dataList1','qcp_data_list','statistics_data','month','user_name','nowDate','analysis_data','calibration_data_list'],
  mixins: [indoorMixins],
  components: {
    printMonthly,
    detailMenu,
    line_Z,
    line_LJ,
    monthlyS,
  },
  data() {
    return {
      dataList: [  //医院基本信息
        {
          name: '仪器名称',
          value: '',
          width: '33%',
        },
        {
          name: '机号',
          value: '',
          width: '33%',
        },
        {
          name: '检测项目',
          value: '',
          width: '33%',
          rightBorder: false,
        },
        {
          name: '失控规则',
          value: '',
          width: '33%',
        },
        {
          name: '警告规则',
          value: '',
          width: '33%',
        },
        {
          name: '方法学',
          value: '',
          width: '33%',
          rightBorder: false,
        },
      ],
      hospital_name: '',
      
      date_text: '',
      page: 0,
    }
  },
  created() {
    this.dataList = JSON.parse(JSON.stringify(this.dataList1));
    this.hospital_name = this.dataList.shift().value
    let month_text_list = this.month.split('-')
    let day = new Date(month_text_list[0], Number(month_text_list[1]), 0).getDate()
    this.date_text = `${month_text_list[0]}-${month_text_list[1]}-01~${month_text_list[0]}-${month_text_list[1]}-${day}`

    this.page = this.qcp_data_list.length + 2;
  }
}
</script>

<style lang="scss" scoped>
$gap: 5px;
//长宽  1430px  1006px
.index {
  width: 1006px;
}
.print-box {
  width: 1006px;
}
.page-box {
  padding: 30px 20px; 
  height: 1430px;
  font-size: 18px;
  //border: 1px solid #000;
  box-sizing: border-box;
  position: relative;
  .page-bottom-box {
    position: absolute;
    bottom: 20px;
    font-size: 16px;
    left: 50%;
    transform: translate(-50%,0);
  }
}
.page-box:first-child {
  padding-top: 10*$gap;


}
.page-box:last-child{
  height: 1420px;
}
.title-box {
  p {
    text-align: center;
  }
  p:nth-child(1) {
    font-size: 22px;
    font-weight: bold;
  }
  p:nth-child(2) {
    font-size: 20px;
    font-weight: bold;
  }
  p:nth-child(3) {
    font-size: 18px;

  }
}
.detail-menu {
  margin-top: 4*$gap;
}
.tip {
  margin-top: 2*$gap;
  font-size: 20px;
  font-weight: bold;
}
.line {
  height: 2px;
  border-bottom: 1px dashed #02A7F0;
  margin: 5px;
}

.monthly-table{
  width:100%;
  height: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border-color:  $border-color;
  border-left: 0;
  border-right: 0;
  .monthly-tbody{
    td {
      font-size: 18px;
      padding: 5px 0;
    }
    tr {
      width: 100%;
      text-align: center;
    }
    .left {
      width: 15%;
      &>span {
        font-weight: bold;
        font-size: 18px;
      }
      &>ul{
        margin-top: 10px;
        &>li {
          margin-top: 3px;
          display: flex;
          white-space: nowrap;
          p:first-child {
            flex: 1;
            text-align: right;
          }
          p:last-child {
            flex: 2;
            text-align: left;
          }
          span {
            flex: 1;
          }
        }
      }
    }
    .target {
      display: flex;
      &>li {
        flex: 1;
      }
    }
    .no-left-border {
      border-left: 0;
    }
    .no-right-border {
      border-right: 0;
    }
  }
}
.statistics-box {
  margin-top: 4*$gap;

}
.analysis-box {
  margin: 0 $gap;
  font-size: 18px;
  &>p {
    margin: $gap 0;
    font-size: 18px;
    font-weight: bold;
    background-color: #409EFF;
    padding: $gap;
    color: #fff;
    border-radius: 3px;
    text-align: center;
    width: 10%;
    box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.4);
  }
  .analysis-text-box {
    &>p {
      margin-top: $gap;
    }
    .indent2 {
      text-indent: 2em;
      display: flex;
      span {
        
      }
    }
    u {
      text-decoration-color: #409EFF;
    }
  }
  .print-info-box {
    display: flex;
    padding-top: 3px;
    margin-top: 5px;
    margin-bottom: 10px;
    border-top: $border-color solid 1px;
    justify-content: space-between;
    p:last-child {
      width: 30%;
    } 
    p>span{
      margin-right: $gap;
    }
  }
}
.echarts-box {
  ::v-deep .innerEchartLine {
    height: 300px;
  }
  ::v-deep .echarts {
    height: 300px;
  }
}
.xbar {
  text-decoration: overline;
}
.tip-c-td {
  width: 10%;
}
.conclusion{
  margin-top: 10px;
  .conclusion_item{
    line-height: 28px;
  }
  .conclusion_item_last{
    margin-top: 10px;
  }

}
</style>