<template>
  <div class="zone">
    <div class="prd_list half_block">
      <trade-left ref="TradeLeft" @updateData="updateData"  @updateCart="updateCart"></trade-left>
    </div>
    <div class="half_block">
      <trade-right ref="TradeRight" @updateCart="updateCart" @updateData="updateData"></trade-right>
    </div>

  </div>

</template>
<script>

import FunctionBox from "@/views/Statistics/components/functionBox.vue";
import common from "@/views/OrderManagement/mixin/common";
import PlatSelect from "@/components/PlatSelect.vue";
import TradeRight from "@/views/OrderManagement/custom/components/tradeRight.vue";
import TradeLeft from "@/views/OrderManagement/custom/components/tradeLeft.vue";

export default {
  components: {TradeRight, PlatSelect, FunctionBox,TradeLeft},
  mixins: [common],
  data() {
    return {
      loading: false,
      searchParams: {
        goods_name: '',
        title: '',
        note_status: '',
        note_no: '',
       start_time: '',
      }
    }
  },
  created() {
  },
  methods: {
    updateData(){
      this.$refs.TradeLeft.getTableList()
    },
    updateCart(row,type){
      // type= 1推荐列表 0商品列表
      this.$refs.TradeRight.loading = true
      if(row){
        this.$refs.TradeRight.getCartList(row.inventory_id,type)
      }else{
        this.$refs.TradeRight.getCartList()
      }
    },
    searchBtn() {
      this.searchBtn = true
    },
    clearSearch() {
      this.searchBtn = false
    },
    handlePlatSelect(val){
      this.searchParams.platform_id = val
    },
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/order";
.zone{
  padding: 6px 14px 20px 6px !important;
  .half_block{
    display: inline-block;
    width: 49.5%;
    height: 100%;
    background: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 5px;
    vertical-align: top;
  }
  .half_block:first-child{
    margin-right: 1%;
  }
  .prd_list{
    background:transparent;
    //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    box-shadow: none;
    .indentBox{
      .below{
        .left{
          width: 50%!important;
        }
      }
    }
  }
}
</style>