<template>
  <div class="zone">
    <TJDetailTitle :titleData="'基本信息'" />
    <TJDetailMenu :dataList="dataList" />

    <TJDetailTitle :titleData="'样本检测详情'" />
    <TJDetailMenu :dataList="dataList2" />
  </div>
</template>

<script>
import apiDetail from '@/api/searchDetail.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
export default {
  name: 'SamplechecksDetail',
  components: { TJDetailTitle, TJDetailMenu },
  data() {
    return {
      dataList: [
      {
          name: '医院',
          value: '',
          width: '25%',
        },
        {
          name: '机号',
          value: '',
          width: '25%',
        },
        {
          name: '检测项目',
          value: '',
          width: '25%',
        },
        {
          name: '结果',
          value: '',
          width: '25%',
          rightBorder: false,
        },
        {
          name: '仪器位置',
          value: '',
          width: '25%',
        },
        {
          name: '平台',
          value: '',
          width: '25%',
        },
        {
          name: '试剂批号',
          value: '',
          width: '25%',
        },
        {
          name: '试剂开瓶日期',
          value: '',
          width: '25%',
          rightBorder: false,
        },
        {
          name: '试剂最新使用时间',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
        {
          name: '试剂开瓶时长',
          value: '',
          width: '25%',
        },
      ],
      dataList2: [
        {
          name: '样品编号',
          value: '',
          width: '25%',
        },
        {
          name: 'ID号',
          value: '',
          width: '25%',
        },
        {
          name: '试管类型',
          value: '',
          width: '25%',
        },
        {
          name: '样本类型',
          value: '',
          width: '25%',
        },
        {
          name: '检测结果',
          value: '',
          width: '25%',
        },
        {
          name: '检测时间',
          value: '',
          width: '25%',
        },
        {
          name: '条码号',
          value: '',
          width: '25%',
        },
        {
          name: '异常标识',
          value: '',
          width: '25%',
        },
        {
          name: 'UDI',
          value: '',
          width: '50%',
        },
        {
          name: '方法学',
          value: '',
          width: '25%',
        },
        {
          name: '年龄',
          value: '',
          width: '25%',
        },
        {
          name: 'HCT',
          value: '',
          width: '25%',
        },
        {
          name: 'WBC',
          value: '',
          width: '25%',
        },
        {
          name: 'SCr',
          value: '',
          width: '50%',
        },
      ],
      detailId: '',
      detailData: {},
      O_RFID_list: ['0401413','0401401','0400501','0400513','0400701','0400601','0400713','0400613'],
      A_RFID_list: ['0402113','0402213']
    }
  },
  created() {
    this.detailId = this.$route.query.id
    this.getsamplecheckDetail()
  },
  methods: {
    getsamplecheckDetail() {
      apiDetail
        .samplecheckDetail({ sample_check_id: this.detailId })
        .then((res) => {
          let data = res.data
          this.dataList[0].value = data.hospital_name
          this.dataList[1].value = data.instrument_id
          this.dataList[2].value = data.testing_unique
          this.dataList[3].value = data.sample_check_result
          this.dataList[4].value = data.hos_pos_name
          this.dataList[5].value = data.platform_unique
          this.dataList[6].value = data.batch
          this.dataList[7].value = data.open_date
          this.dataList[8].value = data.last_used_date
          this.dataList[9].value = data.cumulative_time

          this.dataList2[0].value = data.number_sample
          this.dataList2[1].value = data.number_id
          this.dataList2[2].value = data.tube_type
          this.dataList2[3].value = data.sample_type_name
          this.dataList2[4].value = data.check_result
          this.dataList2[5].value = data.yun_time
          this.dataList2[6].value = data.bar
          this.dataList2[7].value = data.error_flag
          this.dataList2[8].value = data.kit_id
          this.dataList2[9].value = data.method
          this.dataList2[10].value = data.age
          this.dataList2[11].value = data.hct ? `${data.hct}%` :'-'
          this.dataList2[12].value = data.wcb  ? `${data.wcb}×10^9/L` : '-'
          this.dataList2[13].value = data.scr ?  `${data.scr}μmol/L` : '-'

          let temp_list = [];
          switch (data.platform_unique) {
            case 'OTTOMAN-2020':
              this.dataList.push({
                name: '试剂剩余量',
                value: data.s_sample_info.dose_remain,
                width: '25%',
              })
              // let e =  data.kit_id.substring(0, 7)
             let type_text = this.A_RFID_list.includes(data.kit_id.substring(0, 7))?'ottoman2020_A':'ottoman2020_O'


              temp_list = [
                {
                  name: '液位探测剩余脉冲数',
                  value: data.s_sample_info.pulse,
                  width: '100%',
                },
                {
                  name: '吸光度差值1',
                  value: data.abs_diff650,
                  width: '50%',
                },
                {
                  name: '吸光度差值2',
                  value: data.abs_diff2,
                  width: '50%',
                },
                {
                  name: '主波长多点吸光度',
                  type: type_text,
                  value: data.samplecheckabs_abs578,
                  width: '100%',
                },
                {
                  name: '第二主波长多点吸光度',
                  type: type_text,
                  value: data.samplecheckabs_abs650,
                  width: '100%',
                },
              ]
              this.dataList2.splice(this.dataList2.length,0,...temp_list)
              break;
            case 'OTTOMAN-1000':case 'mini+':
              temp_list = [
                {
                  name: '试管架号',
                  value: data.s_sample_info.tube_rack_no,
                  width: '25%',
                },
                {
                  name: '试管号',
                  value: data.s_sample_info.tube_no,
                  width: '25%',
                },
                {
                  name: '使用反应杯号',
                  value: data.s_sample_info.cup_no,
                  width: '50%',
                },
                {
                  name: '样本剩余步数',
                  value: data.s_sample_info.sample_step_remain,
                  width: '25%',
                },
                {
                  name: '试剂1剩余步数',
                  value: data.s_sample_info.reagent_step_r1,
                  width: '25%',
                },
                {
                  name: '试剂2剩余步数',
                  value: data.s_sample_info.reagent_step_r2,
                  width: '50%',
                },
                {
                  name: '吸光度差值1',
                  value: data.abs_diff650,
                  width: '50%',
                },
                {
                  name: '吸光度差值2',
                  value: data.abs_diff2,
                  width: '50%',
                },
                {
                  name: '主波长多点吸光度',
                  type: 'OTTOMAN-1000',
                  value: data.samplecheckabs_abs650,
                  width: '100%',
                },
                {
                  name: '副波长多点吸光度',
                  type: 'OTTOMAN-1000',
                  value: data.samplecheckabs_abs578,
                  width: '100%',
                },
              ]
              this.dataList2.splice(this.dataList2.length,0,...temp_list)
              break;
          
            default:
              break;
          }
        })
    },
    //ottoman
    
    // 新云
    // mini
    // maya
  },
}
</script>

<style lang="scss" scoped>
</style>
