<template>
  <div>
    <div class="dialog_content">
      <p class="relate_text title_top" v-for="item in newProductList" style="color: #000;">
        <span style="color: #BD3124;">「{{item.inventory_full_name}}」</span>
        已添加到采购清单，您可能还需要以下商品：
      </p>
      <div class="relate_table">
        <el-table :data="relateTableData" ref="relateTable" v-if="relateTableData.length > 0" border v-loading="loading" element-loading-text="加载中..."  max-height="450px" :scroll-y="true">
          <el-table-column prop="inventory_id" label="存货编码" min-width="120" align="center" header-align="center">
            <template v-slot="{ row, $index }">
              <span >{{row.inventory_id}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="inventory_name" label="存货简称" min-width="200" align="left" header-align="center">
            <template v-slot="{ row, $index }">
              <span>{{row.inventory_name}}</span>
              </template>
          </el-table-column>
          <el-table-column prop="" label="操作" width="130" align="center" header-align="center" :key="Math.random()">
            <template v-slot="{ row, $index }">
              <el-button class="order_btn font_resize" v-if="!row.isOrder" @click="handleSubmit(row,$index)">订购</el-button>
              <el-button class="order_btn font_resize is_order" v-if="row.isOrder">已购</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="bottom_text">
          <p class="relate_text" style="display:inline-block; margin-top: 30px">您也可以将上述商品一键添加到采购清单，<span style="color: #BD3124;">是否需要全部添加？</span> </p>
        </div>
        <div class="footer_btn">
          <el-button class="close" @click="handleClose">不，我自己去选购</el-button>
          <el-button class="confirm" :disabled="allOrderDisabled" type="primary" @click="handleSubmit">是，全部加入采购清单</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {addCart, getRelateInventory} from "@/api/orderGoods/custom";

export default {
  props: {
    newProductList: {
      type: Array,
      default: []
    },
    inventory_id :{
      type: String,
      default: ''
    },
    relateTableData:{
      type: Array,
      default: []
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      newListTemp : [],
      tableData : [],
      inventory_id: '',
      notOrderData: [],
      allOrderDisabled: false,
    }
  },
  beforeUpdate () {
    this.$nextTick(() => {
      // 防止抖动
      this.$refs.relateTable.doLayout()
    })
  },
  created() {
    if(this.newProductList && this.newProductList.length > 0){
      this.newProductList.forEach(item => {
        if(item.is_first){
          this.newListTemp.push(item)
        }
      })
    }
    this.notOrderData = this.relateTableData
  },
  methods: {
    handleSubmit(row,index){
      this.loading = true
      if(row && row.inventory_id){ // 单个
        let shop_item = {
          inventory_id: row.inventory_id,
          // quantity: 1
        }
        let cart = []
        cart.push(shop_item)
        addCart({cart: cart}).then(res => {
          this.loading = false
          if(res.code === 200){
            this.relateTableData[index].isOrder = true
            this.$forceUpdate()
            this.$emit('updateCart',)
            this.$message.success('订购成功')
            this.notOrderData = this.relateTableData.filter(item => !item.isOrder)
            if(this.notOrderData && this.notOrderData.length < 1){
              this.allOrderDisabled = true
            }

          }else{
            this.$message.error(res.data.msg)
          }
        })

      }else{ // 点击全部
        let cart = []
        this.notOrderData.forEach(item => {
          let shop_item = {
            inventory_id: item.inventory_id,
            // quantity: 1
          }
          cart.push(shop_item)
        })
        addCart({cart: cart}).then(res => {
          if(res.code === 200){
            this.$message.success('订购成功')
            this.$emit('close')
            this.$emit('updateCart',)
          }else{
            this.$message.error(res.data.msg)
          }
        })
      }
      this.$forceUpdate()
    },
    handleClose(done) {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/css/order";
::v-deep .el-table__body{
  width: 100%!important;
}
::v-deep .el-table colgroup col[name='gutter']{
  display: none;
  width: 0;
}
::v-deep .el-table .gutter{
  display: none!important;
  width: 0!important;
}
.title_top{
  margin: 40px;
}
.relate_table{
  margin: 0 40px;
}
.relate_text{
  color: #000;
}
.bottom_text{
  width: 100%;
  text-align: center;
  border-top: 2px dotted #ccc;
  margin-top: 50px;
  padding-top: 20px;
}
::v-deep .order_btn.el-button{
  padding:0 16px;
}
.order_btn{
  width: 100px;
  height: 30px;
  line-height: 30px;
  background: #fff;
  color: #567722;
  border: 1px solid #567722;
}
.order_btn.is_order{
  color: #E99D42;
  border: 1px solid #E99D42;
}
.footer_btn{
  display: flex;
  justify-content: center;
  margin: 50px 0;
  ::v-deep .el-button{
    width: 240px;
    height: 50px;
    font-size: 18px;
    margin: 0 20px;
  }
  .confirm{
    background:  #567722;
    color: #fff;
  }
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  .relate_text{
    font-size: 18px;
  }
  .order_btn{
    font-size: 16px;
  }
  ::v-deep .footer_btn .el-button{
    font-size: 16px;
  }
}
@media screen and (min-width: 1551px) , print {
  .relate_text{
    font-size: 20px;
  }
  .order_btn{
    font-size: 16px;
  }
  ::v-deep .footer_btn .el-button{
    font-size: 18px;
  }
}


</style>