import {getInventoryList, getInventoryPrice} from "@/api/orderGoods/custom";
import {refreshPricing} from "@/api/orderGoods/business";
export default {
  data() {
    return {
      loading: false,
      searchText: '',
      selectDialogVisible: false,
      currentIndex: '',
      goodsList: [],
      goodsListTemp: [],
      selectedCustomIdList: [],
    }
  },
  methods:{
    selectableStatus(row){
      return !row.disabled;
    },
    handleFilterGoods(val){ //弹窗里搜索
      if(!val){
        this.goodsList = this.goodsListTemp
      }
      this.goodsList = this.goodsListTemp.filter(item =>{
        return item.inventory_name.includes(val) || item.inventory_full_name.includes(val);
      })
    },
    selectFocus(index){
      this.currentIndex = index
      this.$nextTick(() => {
        // 获取表格的 DOM 元素
        const tableWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper');
        window.requestAnimationFrame(() => {
          tableWrapper.scrollLeft = 0;
        });
      });
    },
    handleSelectionChange(val){
      if(val.length > 0){
        this.selectedList = val
        this.selectedList.forEach(item => {
        item.inventory_ids = item.value
        item.inventory_id = item.value
        item.isAdd = true
        if(item.price){
            item.unit_price = Number(item.price).toFixed(2)
        }else{
            item.unit_price = 0.00
        }
        if(item.tax_price){
            item.tax_price = Number(item.tax_price).toFixed(2)
        }else{
            item.tax_price = 0.00
        }
        })
        }
    },
    getGoodsList(e) {
      getInventoryList({name: e}).then(res => {
        let originalArray = res.data.data
        this.existIdList = this.tradeTableData.map(item=> item.inventory_id)
        const idArray = this.existIdList
        originalArray.forEach((item1,index) => {
          idArray.forEach(item2 => {
            if(item2 == item1.inventory_id){
              item1.disabled = true
            }
          })
        });
        this.goodsList = originalArray.map(item => {
            return {
                label: item.inventory_full_name,
                value: item.inventory_id,
                inventory_name: item.inventory_name,
                inventory_full_name: item.inventory_full_name,
                standard: item.standard,
                sale_unit: item.sale_unit,
                disabled: item.disabled
            }
        })
        this.goodsListTemp = JSON.parse(JSON.stringify(this.goodsList)) //为了弹窗过滤
        if(this.goodsList.length > 0){
          if(this.goodsList.length === 1){
            this.isOptionShow = true
          }else{
            this.selectDialogVisible = true
            this.isOptionShow = false
            this.tradeTableData[this.currentIndex].inventory = {}
          }
        }else{
          this.$message.error('暂未搜索到匹配产品')
        }
            // let newIds = this.goodsList.map(item => item.value)
            // if(this.goodsListTemp && this.goodsListTemp.length > 0){
            //   if(newIds && !newIds.includes(this.goodsListTemp[0].value)){
            //     this.goodsList = this.goodsList.concat(this.goodsListTemp)
            //   }
            // }
      })
    },
    handleSelected(type){ // 弹窗选商品
      if(this.selectedList && this.selectedList.length > 0){
        if(this.selectedList.length == 1){
          let rowData= this.selectedList[0]
          this.tradeTableData[this.currentIndex].inventory = {
            label: rowData.label,
            value: rowData.value,
            inventory_name: rowData.inventory_name,
            standard: rowData.standard,
            sale_unit: rowData.sale_unit
          }
          this.goodsList = [this.tradeTableData[this.currentIndex].inventory]
          // this.tradeTableData[this.currentIndex].isAdd =false
          this.tradeTableData[this.currentIndex].isEdit =false
          this.tradeTableData[this.currentIndex].isNew =false
          this.cantOpt = this.tradeTableData.some(item => item.isAdd)
          this.tradeTableData[this.currentIndex].inventory_ids = rowData.value
          this.tradeTableData[this.currentIndex].inventory_id = rowData.value
          this.tradeTableData[this.currentIndex].inventory_name = rowData.inventory_name
          this.tradeTableData[this.currentIndex].standard = rowData.standard
          this.tradeTableData[this.currentIndex].inventory_full_name = rowData.label
          this.tradeTableData[this.currentIndex].sale_unit = rowData.sale_unit
          if(this.selectedCustomIdList && this.selectedCustomIdList.length > 0){
            let params = {
              pricing_type: this.pricing_type,
              inventory_ids: rowData.value.toString(),
              customer_ids: this.selectedCustomIdList.toString()
            }
            refreshPricing(params).then(res=>{ // 获取商品价格
              if(type == 1) {
                this.tradeTableData[this.currentIndex].price = Number(res.data[0].price).toFixed(2)
                this.tradeTableData[this.currentIndex].tax_price = Number(res.data[0].tax_price).toFixed(2)
                this.tradeTableData[this.currentIndex].tax_rate = res.data[0].tax_rate
              }else{
                let refreshArray = res.data
                this.cstTableData.forEach(item1 => {
                  refreshArray.forEach(item2 =>{
                    if(item2.customer_id == item1.customer_id){
                      item1.price = Number(item2.price).toFixed(2)
                      item1.tax_price = Number(item2.tax_price).toFixed(2)
                      item1.tax_rate =  item2.tax_rate
                    }
                  })
                })
              }
            })
            this.existIdList = this.tradeTableData.filter(item => !item.isAdd).map(item => item.inventory_id)
            this.selectDialogVisible = false
            this.$forceUpdate()
          }
        }else{ // 多个
          this.tradeTableData.splice(this.currentIndex, 1, ...this.selectedList)
          let inventory_ids = this.selectedList.map(item => item.value)
          let params = {
            pricing_type: this.pricing_type,
            inventory_ids: inventory_ids.toString(),
            customer_ids: this.selectedCustomIdList.toString()
          }
          if(this.selectedCustomIdList && this.selectedCustomIdList.length > 0){
            refreshPricing(params).then(res=>{ // 获取商品价格
              let refreshArray = res.data
              if(refreshArray && refreshArray.length >0){
                if(type ===1 ){
                  this.tradeTableData.forEach(item1 => {
                    refreshArray.forEach(item2 =>{
                      if(item2.inventory_id == item1.inventory_ids){
                        item1.price = Number(item2.price).toFixed(2)
                        item1.tax_price = Number(item2.tax_price).toFixed(2)
                        item1.tax_rate =  item2.tax_rate
                      }
                    })
                  })
                }else{
                  this.cstTableData.forEach(item1 => {
                    refreshArray.forEach(item2 =>{
                      if(item2.customer_id == item1.customer_id){
                        item1.price = Number(item2.price).toFixed(2)
                        item1.tax_price = Number(item2.tax_price).toFixed(2)
                        item1.tax_rate =  item2.tax_rate
                      }
                    })
                  })
                }
                this.$forceUpdate()
              }
            })
          }
        }
        this.selectDialogVisible = false
        this.searchText = ''
        this.selectedList = []
      }else{
        this.selectDialogVisible = true
        this.$message({type: 'error', message: '请选择商品'})
      }
    },
    handleCancelSelectGoods(){
      this.selectDialogVisible = false
      this.searchText = ''
      this.selectedList = []
    },
    handleVisibleChange(visible) {
      // 如果下拉框关闭且没有选项，则不显示下拉框
      if ( this.goodsList.length === 0 || this.goodsList.length > 1) {
          this.tradeTableData[this.currentIndex].inventory = {}
      }
    },
    handleClose(){
      this.selectDialogVisible = false
    }
  }
}