<template>
  <div class="drawer_wrap" >
  <div class="drawer_block">
    <div class="content_top">
      <el-select
          @change="chooseCustomer"
          class="check_select"
          v-model="customer_name"
          placeholder="请输入客户名称"
          loading-text="正在从服务端获取数据"
          size="small"
          filterable
          clearable
          remote
          :remote-method="remoteMethod"
      >
        <el-option
            v-for="item in organizationList"
            :key="item.customer_id"
            :label="item.customer_name"
            :value="{label:item.customer_name, value:item.customer_id}">
        </el-option>
      </el-select>
      <div style="margin:10px" v-if="selectedCustomNameList.length > 0">
        <p v-for="(item,index) in selectedCustomNameList" :key="item" type="info" class="select_custom" size="mini" style="margin-right: 5px;line-height: 28px">
          <span>{{index+1}}、{{item}}</span>
          <span class="el-icon-circle-close" style="color: #BD3124;font-size: 16px;margin-left: 10px;vertical-align: -1px" @click="deleteCustom(index)"></span>
        </p>
      </div>
    </div>
    <div class="goodsList">
      <p class="block_title">商品列表</p>
      <el-table
          :data="tradeTableData"
          max-height="450px"
          ref="goodsTable"
          align="center"
          style="width: 100%">
        <el-table-column label="#" type="index" width="50"></el-table-column>
        <el-table-column prop="inventory_full_name" label="商品全称" min-width="300" align="center" header-align="center">
          <template v-slot="{ row, $index }">
<!--            <select-product class="custom_select" v-if="row.isEdit && row.isNew" :goodsListTemp="goodsOpitonsTemp" :existIdList="existInventoryIdList" :clo_index="$index" @chooseGoods="handleFullNameChange" />-->
            <el-select
                v-if="row.isNew"
                @focus="selectFocus($index)"
                @change="handleFullNameChange"
                class="check_select"
                v-model="row.inventory"
                placeholder="请输入关键字"
                loading-text="正在从服务端获取数据"
                size="small"
                filterable
                clearable
                remote
                :remote-method="getGoodsList"
                @visible-change="handleVisibleChange"
            >
              <el-option
                  :class="isOptionShow ? 'show' : 'unShow'"
                  v-if="isOptionShow"
                  v-for="item in goodsList"
                  :key="item.value"
                  :label="item.label"
                  :disabled="item.disabled"
                  :value="item">
              </el-option>
            </el-select>
            <span  v-else>{{row.inventory_full_name ? row.inventory_full_name : '/'}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="inventory_name" label="商品简称" width="120" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <span>{{row.inventory_name ? row.inventory_name : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="standard" label="规格/型号" width="110" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <span>{{row.standard ? row.standard : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="单价（元）" width="120" align="center" header-align="center" :key="Math.random()">
          <template slot="header" slot-scope="scope">
            <div style="display: flex;justify-content: center;align-items: center;">
              <span>单价（元）</span>
              <i class="el-icon-edit" style="color:#CECECE;"></i>
            </div>
          </template>
          <template v-slot="{ row, $index }">
            <el-input v-if="row.isEdit" @blur="handleChangeEdit($index,false)" @keyup.enter.native="handleChangeEdit($index,false)" @input="handlePriceOrTaxChange(row)" style="width: 130px" v-model="row.price" size="mini"  controls-position="right" :min="1" :max="10" label="描述文字"></el-input>
            <span style="padding:10px" v-else @click="handleChangeEdit($index,true)">{{row.price ? Number(row.price).toFixed(2) : '0.00'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tax_rate" label="税率" width="120" align="center" header-align="center">
          <template slot="header" slot-scope="scope">
            <div style="display: flex;justify-content: center;align-items: center;">
              <span>税率</span>
              <i class="el-icon-edit" style="color:#CECECE;"></i>
            </div>
          </template>
          <template v-slot="{ row, $index }">
            <el-input  v-if="row.isEdit" @blur="handleChangeEdit($index,false)"  v-model="row.tax_rate"  @keyup.enter.native="handleChangeEdit($index,false)" @input="handlePriceOrTaxChange(row)" style="width: 130px" size="mini"  controls-position="right"  :min="1" :max="10" label="描述文字"></el-input>
            <span v-else @click="handleChangeEdit($index,true)">{{row.tax_rate ? row.tax_rate : '13'}} <span v-if="row.tax_rate">%</span></span>
          </template>
        </el-table-column>
        <el-table-column prop="tax_price" label="含税单价（元）"  width="140" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <span>{{row.tax_price ? row.tax_price : '0.00'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="oprate" label="操作" width="220" fixed="right" align="center">
          <template v-slot="{ row, $index }">
            <el-button  size="mini" style="color: #567722;border-color: #567722;padding:8px 18px; " @click="checkHistory(row)">历史定价</el-button>
            <el-button size="mini"  style="color:#BD3124;border-color: #BD3124;padding:8px 18px; " @click="handleDelete($index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="table_bottom" >
        <el-row>
          <el-col :span="14">
            <el-button size="mini" style="background: #fff; color:#4095E5;border:1px solid #4095E5;padding:8px 18px;" class="font_resize"   icon="el-icon-plus" @click="addGoods">添加商品</el-button>
          </el-col>
          <el-col :span="10">
            <div style="width: 100%;text-align: right!important;padding-right: 40px!important;box-sizing: border-box">
              <div style="display: inline-block; vertical-align: top">
                <span class="font_resize">币种：</span>
                <div class="currency" style="display: inline-block;width: 120px;margin-right: 20px">
                  <el-select size="mini"  v-model="currency" placeholder="请选择">
                    <el-option
                        v-for="item in currency_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div style="display: inline-block;">
                <span style="line-height: 28px" class="font_resize">汇率：</span>
                <div class="currency" style="display: inline-block;width: 120px">
                  <el-input size="mini"  v-model="tax_rate"></el-input>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <el-dialog title="历史定价" class="his_dialog" v-if="historyDialogVisible" :visible.sync="historyDialogVisible"  :modal="false">
        <template #title>
          <span class="dialog_title">历史定价</span>
        </template>
        <history-price ref="historyPrice" :customer_ids="form.customer_ids" :inventory_id="selected_inventory_id"></history-price>
        <span slot="footer" class="dialog-footer">
          <el-button size="mini" @click="historyDialogVisible = false">关闭</el-button>
        </span>
      </el-dialog>
      <el-dialog
          title="选择商品"
          :visible.sync="selectDialogVisible"
          v-if="goodsListTemp.length > 1 || searchText != ''"
          width="50%"
          :modal="false"
          :show-close="false"
          class="select_dialog"
          :before-close="handleClose">
        <template #title>
          <span class="dialog_title">选择商品</span>
          <div style="display: inline-block;float: right;width: 280px" class="dialog_search_input">
            <el-input v-model="searchText" placeholder="请输入商品名称"  @keyup.enter.native="handleFilterGoods(searchText)" style="width: 200px;margin-right: 20px" size="mini"></el-input>
            <i slot="append" class="dialog_search search_icon" @click="handleFilterGoods(searchText)"></i>
          </div>
        </template>
        <div class="select_dialog">
          <el-table :data="goodsList" @selection-change="handleSelectionChange" stripe ref="relateTable"  border v-loading="loading" element-loading-text="加载中..."  max-height="450px" :scroll-y="true">
            <el-table-column  type="selection"  :selectable="selectableStatus" width="50" align="center" header-align="center"></el-table-column>
            <el-table-column prop="inventory_full_name" label="全称" min-width="150" align="left" header-align="center">
              <template v-slot="{ row, $index }">
                <span >{{row.label}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="inventory_name" label="简称" min-width="150" align="left" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.inventory_name}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="standard" label="规格/型号" min-width="120" align="left" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.standard}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer"  class="dialog-footer" style="text-align: center">
          <el-button style="margin-right: 14px" @click="handleCancelSelectGoods" size="mini">取 消</el-button>
          <el-button type="primary" @click="handleSelected(1)" size="mini" style="background: #4095E5" >确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
  <div class="footer">
    <el-button size="mini" class="save_btn" @click="save">确定保存</el-button>
  </div>
  </div>
</template>
<script>
import common from "@/views/OrderManagement/mixin/common";
import fixPrice from "@/views/OrderManagement/mixin/fixPrice";
import {organization_list} from "@/api/search";
import HistoryPrice from "@/views/OrderManagement/business/components/historyPrice.vue";
import {fixPricing, historyPricing,search_customer,refreshPricing} from "@/api/orderGoods/business";
import selectProduct from "@/views/OrderManagement/business/components/selectProduct.vue";
import {getInventoryList, getInventoryPrice} from "@/api/orderGoods/custom";
export default {
  components: {HistoryPrice,selectProduct},
  mixins: [common,fixPrice],
  props: {
    selectData: {
      type: Array,
      default: () => {
        return []
      }
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch:{
    selectData(newVal) {
      if (newVal) {
        this.initData()
      }
    }
},
data() {
  return {
    historyDialogVisible: false,
    pricing_type: '1',
    organizationList: [],
    selectedCustomIdList: [],
    selectedCustomNameList: [],
    goodsOpitonsTemp:[], //选择某条商品的临时option
    existInventoryIdList: [], //已选商品id
    currency: 'CNY',
    tax_rate: '1.0000',
    customer_name: '',
    selected_inventory_id: '',
    customer_ids: '',
    currency_options:[
      {value: 'CNY', label: '人民币'},
      // {value: 'USD', label: '美元'},
      // {value: 'EUR', label: '欧元'},
      // {value: 'GBP', label: '英镑'},
      // {value: 'JPY', label: '日元'},
    ],
    goodsList: [],
  }
},
created() {
  this.initData()
},
methods: {
  handleScroll(){
    this.goodsList = []
  },
  initData() {
    if(this.selectData){
      this.tradeTableData = JSON.parse(JSON.stringify(this.selectData))
      this.tradeTableData.forEach(item => {
        if(!item.price){
          item.price = 0
          item.tax_price = 0
        }
        if(!item.tax_rate){
          item.tax_rate = 13
        }
        item.isEdit = false
      })
      this.existInventoryIdList = this.tradeTableData.map(item => item.inventory_id)
    }
    this.$nextTick(() => {
      const tableBodyWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper')
      if (tableBodyWrapper){
        tableBodyWrapper.addEventListener('scroll', this.handleScroll);
      }
    })
    if(this.tradeTableData.length > 0){
      this.tradeTableData.forEach(item => {
        if(item.price && item.tax_rate ){
          item.unit_price = Number(item.price) * (1 + Number(item.tax_rate)/100)
        }
      })
    }
  },
  handleFullNameChange(data){
    this.tradeTableData[this.currentIndex].isEdit = false
    this.tradeTableData[this.currentIndex].isNew = false
    this.tradeTableData[this.currentIndex].inventory_id = data.value
    this.tradeTableData[this.currentIndex].inventory_name = data.inventory_name
    this.tradeTableData[this.currentIndex].standard = data.standard
    this.tradeTableData[this.currentIndex].inventory_full_name = data.label
    this.existIdList = this.tradeTableData.map(item=> item.inventory_id)
    this.goodsList.forEach(item => {
      if(this.existIdList.includes(item.value)){
        item.disabled = true
      }
    })
    this.goodsOpitonsTemp =  [{label: data.label,
      value: data.value,
      inventory_name: data.inventory_name,
      standard: data.standard,
      sale_unit: data.sale_unit,
      disabled: true
    }]
    this.existInventoryIdList = this.tradeTableData.map(item => item.inventory_id.toString())
    // 更新商品列表
    if(this.selectedCustomIdList.length > 0){
      let inventory_ids = this.tradeTableData.map(item => item.inventory_id)
      let params = {
        pricing_type: this.pricing_type,
        inventory_ids: data.value.toString(),
        customer_ids: this.selectedCustomIdList.toString()
      }
      refreshPricing(params).then(res=>{
        let refreshArray = res.data
        if(refreshArray && refreshArray.length > 0){
          this.tradeTableData[this.currentIndex].price = Number(refreshArray[0].price).toFixed(2)
          this.tradeTableData[this.currentIndex].tax_rate = refreshArray[0].tax_rate
          this.tradeTableData[this.currentIndex].tax_price = Number(refreshArray[0].tax_price).toFixed(2)
        }else{
          this.tradeTableData[this.currentIndex].price = 0.00
          this.tradeTableData[this.currentIndex].tax_rate = 13
          this.tradeTableData[this.currentIndex].tax_price = 0.00
        }
      }).catch(err=>{
        this.$message.error(err)
      })
    }
    this.$forceUpdate()
  },
  save(type) {
    let pricing_data = this.tradeTableData.forEach(item => {
      item.inventory_ids = [item.inventory_id]
    })
    if(!this.form.customer_ids){  // 校验是否选择客户
      this.$message.error('请选择客户！')
      return
    }
    let isProductValidate = this.tradeTableData.some(item => !item.inventory_ids[0])
    if(isProductValidate){ // 校验新增商品行是否已选商品
      this.$message.error('新增商品不可为空！')
      return
    }
    let originalArray = this.tradeTableData.map(item => item.inventory_id)
    let uniqueArray =  originalArray.filter((item, index) => originalArray.indexOf(item) === index);
    if(originalArray.length !== uniqueArray.length ){
      this.$message.error('请删除重复商品！')
      return
    }
    let params= {
      pricing_type: this.pricing_type,
      pricing_data: this.tradeTableData,
      customer_ids:  this.form.customer_ids
    }
    fixPricing(params).then(res => {
      if(res.code === 200){
        this.$message.success('定价成功！')
        this.$emit('close')
      }else{
        this.$message.error(res.data.msg)
      }
    }).finally((err)=>{

    })
  },
  addGoods(){
    this.$nextTick(() => {
      // 新增商品时滚动条到最底部
      const tableWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper');
      window.requestAnimationFrame(() => {
        tableWrapper.scrollTop = tableWrapper.scrollHeight;
      });
      const tableBodyWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper')
      if (tableBodyWrapper){
        tableBodyWrapper.addEventListener('scroll', this.handleScroll);
      }
    });
    this.tradeTableData.push({
      inventory_full_name: '',
      inventory_name: '',
      standard: '',
      price: '0.00',
      tax_rate: 13,
      tax_price: '0.00',
      isEdit: true,
      isNew: true
    })
  },
  deleteCustom(index){
    this.$confirm('确定要删除当前客户吗?', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      // center: true
    }).then(() => {
      this.selectedCustomNameList.splice(index, 1)
      this.selectedCustomIdList.splice(index, 1)
      this.form.customer_ids =  this.selectedCustomIdList
      if(this.form.customer_ids && this.form.customer_ids.length > 0){
        // 更新商品列表
        let inventory_ids = this.tradeTableData.map(item => item.inventory_id)
        let params = {
          pricing_type: this.pricing_type,
          inventory_ids: inventory_ids.toString(),
          customer_ids: this.selectedCustomIdList.toString()
        }
        refreshPricing(params).then(res=>{
          let refreshArray = res.data
          if(refreshArray && refreshArray.length > 0){
            this.tradeTableData = this.tradeTableData.map(item1 => {
              const item2 = refreshArray.find(item => item.inventory_id === item1.inventory_id);
              return { ...item1, ...item2 };
            });
            this.tradeTableData.forEach(item => {
              if(item && item.price && item.price > 0 ){
                item.price = Number(item.price).toFixed(2) || 0.00
              }
              if(item && item.tax_price && item.tax_price > 0){
                item.tax_price = Number(item.tax_price).toFixed(2) || 0.00
              }
            })
          }else{
            this.tradeTableData.forEach(item => {
              if(item && item.price && item.price > 0){
                item.price =  0.00
              }
              if(item && item.tax_price && item.tax_price > 0){
                item.tax_price = 0.00
              }
            })
          }
        }).catch(err=>{
          this.$message.error(err)
        })
      }else{
        this.tradeTableData.forEach(item => {
          if(item && item.price && item.price > 0){
            item.price =  0.00
          }
          if(item && item.tax_price && item.tax_price > 0){
            item.tax_price = 0.00
          }
        })
      }
    }).catch(()=>{
    })
  },
  checkHistory(row){
    if(!this.form.customer_ids){
      this.$message.error('请先选择客户！')
      return
    }
    this.selected_inventory_id = row.inventory_id
    this.historyDialogVisible = true
  },
  remoteMethod(e) {
    search_customer({ customer_name: e }).then(res=>{
      this.organizationList = res.data
    })
  },
  chooseCustomer(custom){
    if(custom && custom.label && !this.selectedCustomIdList.includes(custom.value)){
      this.selectedCustomNameList.push(custom.label)
      this.selectedCustomIdList.push(custom.value)
      this.form.customer_ids =  this.selectedCustomIdList
      this.customer_name =  custom.label
      // 更新商品列表
      let inventory_ids = this.tradeTableData.map(item => item.inventory_id)
      let params = {
        pricing_type: this.pricing_type,
        inventory_ids: inventory_ids.toString(),
        customer_ids: this.selectedCustomIdList.toString()
      }
      refreshPricing(params).then(res=>{
        let refreshArray = res.data
        if(refreshArray && refreshArray.length > 0){
          this.tradeTableData = this.tradeTableData.map(item1 => {
            const item2 = refreshArray.find(item => item.inventory_id === item1.inventory_id);
            return { ...item1, ...item2 };
          });
          this.tradeTableData.forEach(item => {
            if(item && item.price && item.price > 0 ){
              item.price = Number(item.price).toFixed(2) || 0.00
            }
            if(item && item.tax_price && item.tax_price > 0){
              item.tax_price = Number(item.tax_price).toFixed(2) || 0.00
            }
          })
        }
      }).catch(err=>{
        this.$message.error(err)
      })
    }else{
      if(custom && custom.label && this.selectedCustomIdList.includes(custom.value)){
        this.$message.error('请勿重复选择！')
      }
    }
  },
  handlePriceOrTaxChange(row){
    if(row.price && row.tax_rate){
      row.tax_price = (Number(row.price) * Number(1  + Number(row.tax_rate)/100)).toFixed(2)
    }else{
      row.tax_price = 0.00
    }
    this.$forceUpdate()
  },
  handleChangeEdit(index,isEdit){
    if(this.tradeTableData[index].inventory_id){
      this.$set(this.tradeTableData[index], 'isEdit', isEdit)
      this.$set(this.tradeTableData[index], 'price', this.tradeTableData[index].price ? Number(this.tradeTableData[index].price).toFixed(2) : '0.00')
      this.$set(this.tradeTableData[index], 'tax_rate', this.tradeTableData[index].tax_rate ? this.tradeTableData[index].tax_rate : '13')
    }else{
      this.$set(this.tradeTableData[index], 'isEdit', isEdit)
    }
    if(isEdit){
      this.tradeTableData[index].price = (this.tradeTableData[index].price / 1).toFixed(2).toString()
    }
    this.$forceUpdate()
  },
  handleDelete(index){
    this.$confirm('确定要删除当前商品吗?', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      // center: true
    }).then(() => {
      this.tradeTableData.splice(index, 1)
      this.existInventoryIdList =  this.tradeTableData.map(item => item.inventory_id)
      this.$message({
        type: 'success',
        message: '删除成功!'
      });
    }).catch(() => {
    });
  },
  },
  beforeDestroy() {
    this.customer_name = ''
    this.selectData = []
    this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper').removeEventListener('scroll', this.handleScroll);
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/order";
::v-deep .el-dialog__footer{
  text-align: center!important;
}
::v-deep .el-table thead.is-group th.el-table__cell{
  background: #EFEFEF;
  color: #000!important;
}
.his_dialog {
  display: flex;
  padding: 20px;
  ::v-deep .el-dialog { margin: auto !important; }
}
::v-deep .el-table thead .el-table__cell{
  padding: 0;
  border-bottom: none;
}
::v-deep .el-input-number.is-controls-right .el-input__inner{
  width: 130px;
}
::v-deep .el-table td.el-table__cell div{
  //text-align: left;
}
::v-deep .el-input--mini .el-input__inner{
  width: 84%;
}
::v-deep .custom_select .el-input--mini .el-input__inner{
  width: 100%;
}

::v-deep .el-table td.el-table__cell div{
  width: 100%!important;
}
::v-deep .el-dialog__header{
  padding:16px 20px;
  border-bottom: 1px solid #eee;
}
::v-deep .el-dialog__body{
  padding: 18px 20px !important;
}
::v-deep .select_dialog .el-dialog__header .el-input{
  width: 100%!important;
}
::v-deep .select_dialog .el-dialog__header  .el-input--mini .el-input__inner{
  width: 100%!important;
  position: relative;
}
.dialog_search.search_icon{
  background-image: url("../../../../assets/img/search.png") ;
  background-size: cover;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 28px;
  top: 22px;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  .select_custom{
    font-size: 14px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 14px;
  }
  ::v-deep .el-drawer__header{
    color: #000;
    font-size: 16px;
  }
  .orderInfo .block p, .orderInfo .block div{
    font-size: 14px;
  }
  .block_title{
    font-size: 16px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 14px;
  }
  .table_bottom p{
    font-size: 14px;
  }

}
@media screen and (min-width: 1551px) , print {
  .select_custom{
    font-size: 16px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 16px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 16px;
  }
  ::v-deep .el-drawer__header{
    color: #000;
    font-size: 18px;
  }
  .orderInfo .block p, .orderInfo .block div{
    font-size: 16px;
  }
  .block_title{
    font-size: 18px;
  }
  .table_bottom p{
    font-size: 16px;
  }
}
.drawer_block{
  .content_top{
    padding: 0 20px;
    padding-bottom: 20px;
    border-bottom: 1px dotted #BBBBBB;
  }
  .goodsList{
    padding: 0px 10px;
    .table_bottom{
      margin-top: 20px;
      margin-bottom: 20px
    }
  }
  .block_title{
    font-size: 16px;
    font-weight: bold;
    margin: 16px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
}
.footer{
  position: absolute;
  height: 80px;
  bottom: 0px;
  width: 100%;
  left:10px;
}
.save_btn{
  background: #347CAF;
  color: #fff;
  border:none;
  padding:10px 38px!important;
  margin-top: 20px;
}
.currency_item{
  display: inline-block;
  vertical-align: top;
}
.currency_item.left{
  margin-right: 20px;
}
::v-deep .el-input .el-input__inner{
  width: 100%;
}
::v-deep .currency  .el-input .el-input__inner{
  width: 100px;
  font-size: 14px!important;
}
::v-deep .currency  .el-input--mini .el-input__inner{
  width: 100px!important;
  font-size: 14px!important;
}

</style>