<template>
  <el-dialog
      title=""
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
      class="success_tip"
      >
    <div class="dialog_content">
      <img src="@/assets/img/sub_success.png">
      <p style="font-size: 30px">提交成功</p>
      <span  class="dialog-footer">
      <el-button @click="returnList" size="mini">返回列表</el-button>
      <el-button @click="checkOrder" size="mini">查看订单</el-button>
      <el-button @click="anotherOrder" size="mini">再来一单</el-button>
    </span>
    </div>

  </el-dialog>
</template>
<script>
import {reOrder} from "@/api/orderGoods/custom";

export default {
  props: {
    orderId: {
      type: Number,
      default:null
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    anotherOrder(){ // 再来一单
      this.dialogVisible = false
      reOrder({order_id: this.orderId}).then(res => {
        if(res.code === 200){
          this.$message.success('订购成功')
          this.$emit('updateData')
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    returnList(){ // 返回列表
      this.dialogVisible = false
      this.$emit('updateData')
    },
    checkOrder(){  // 查看详情
      this.$emit('checkOrder',)
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__footer{
  text-align: center;
  padding-bottom: 80px;
}
::v-deep .el-dialog__header{
  border-bottom: none!important;
}
.dialog_content{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dialog-footer{
  margin-top: 60px;
}
::v-deep .dialog-footer .el-button:focus,::v-deep .dialog-footer .el-button:hover{
  background: #4095E5!important;
  color: #fff!important;
  border-color:#4095E5!important;

}
::v-deep .dialog-footer .el-button{
  padding:12px 30px;
  margin-right: 40px;
  font-size: 16px;
}
::v-deep .dialog-footer .el-button:last-child{
  margin-right: 0;
}

.dialog_content{
  height: auto;
  text-align: center;
  padding: 40px 0 60px 0;
  box-sizing: border-box;
  p{
    line-height: 2;
  }
  span{
    line-height: 2;
  }
}
.success_tip{
  display: flex;
  padding: 20px;
  ::v-deep .el-dialog { margin: auto !important; }
}
</style>