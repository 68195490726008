<template>
  <div class="zone">
    <functionBox  :titleData="{permission_flag:'SparePart',nowID:1}" :isClear="true" :isOrder="true" :isSelected="isSelected" :isOrderExport="isOrderExport"
                  @searchBtn="searchBtn" @addCheck="editCheck('0')" @clearSearch="clearSearch" @exportOrder="handleExportOrder" :searhLoading="loading">
      <template>
        <li  class="keyword_search">
          <el-input  placeholder="请输入经销商/医院/公司名称/订单编号" v-model="searchParams.search" clearable size="small" @keyup.enter.native="getList(searchParams.order_status)" ></el-input>
        </li>
        <li>
          <el-select v-model="searchParams.order_status" placeholder="请选择订单状态" @change="getList(searchParams.order_status)" size="small" filterable clearable>
            <el-option
                v-for="item in orderStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li>
          <el-date-picker
              size="small"
              v-model="dateValue"
              format="yyyy/MM/dd"
              value-format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="marginLeft:10px"
              class="Calendar"
              @change="dateChange"
              @clear="clearSearchTime"
          />
        </li>
      </template>
    </functionBox>
    <div class="check_select">
      <el-checkbox v-model="pendingApproval" label="1" @change="handleChecked" style="color:#951D1D;">仅显示待审核订单</el-checkbox>
      <el-checkbox v-model="isAllChecked"  @change="handleAllCheckedChange">全选</el-checkbox>
    </div>
    <div class="sheet" :class="{'empty' : orderList.length ==0,'no-scroll': loading }" ref="sheet" v-loading="loading"  :element-loading-text="loadingText">
      <div class="empty-container"  v-if="!loading && orderList.length ==0">
        <el-empty description="暂无数据"></el-empty>
      </div>
      <div class="orderList" >
        <ul>
          <el-checkbox-group v-model="checkedList" @change="handleCheckedOrderChange">
          <li class="orderItem" v-for="order in orderList">
            <el-row class="orderItemTop">
              <el-col :span="5">
                <el-checkbox
                             :label="order.order_id"
                             :checked="checkedList.includes(order.order_id)" >

                </el-checkbox>
                <span> 订单号：{{order.order_code}}</span>
              </el-col>
              <el-col :span="5"><span> 下单时间：{{order.create_time}}</span></el-col>
              <el-col :span="7"><span> 客户名称：{{order.customer_name}}</span></el-col>
              <el-col :span="5"><span :style="{'color': order.order_status === 9005 ? '#000' : '#4F4F4F'}"> 订单状态：{{order.order_status_name}}</span></el-col>
            </el-row>
            <el-row class="orderItemContent">
              <el-col :span="19">
                <el-col :span="24">
                  <el-col :span="6"> 订单金额：{{order.total_price}}&nbsp;元</el-col>
                  <el-col :span="18"> 开票信息：{{order.invoice_info.invoice_header}}&nbsp;/&nbsp;{{order.invoice_info.registration_code}}
                    &nbsp;/&nbsp;{{order.invoice_info.contact_person}}&nbsp;/&nbsp;{{order.invoice_info.contact_email}}</el-col>
                </el-col>
                <el-col :span="24"><span> 收货信息：{{order.receive_address.customer_contact}}&nbsp;/&nbsp;
                  {{order.receive_address.customer_phone}}&nbsp;/&nbsp;{{order.receive_address.customer_address}}</span></el-col>
              </el-col>
              <el-col class="contentRight" :span="5"><span @click="openDrawer(order)">订&nbsp;单&nbsp;详&nbsp;情</span></el-col>
            </el-row>
            <el-row class="orderItemBottom" v-if="order.undo_remark && order.order_status === 9003">
              <el-col>
                <span>撤销原因：{{order.undo_remark}}</span>
              </el-col>
            </el-row>
          </li>
          </el-checkbox-group>
        </ul>
      </div>

    </div>
    <el-pagination
        v-if="orderList.length > 0"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="searchParams.size"
        :total="searchParams.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchParams.page"
        layout="total, sizes, prev, pager, next"
        pager-count="3"
        class="page"
        background>
    </el-pagination>
    <el-drawer
        title="订单详情"
        :visible.sync="isDrawerShow"
        :wrapperClosable="false"
        size="70%">
      <template #title>
        <span class="dialog_title component_title">订单详情</span>
      </template>
<!--      <div >-->
        <order-approve ref="orderDrawer" v-if="isApproveShow"  :visible.sync="isApproveShow" @updateList="updateList" :orderId="selectedOrderId"  ></order-approve>
<!--        </div>-->
    </el-drawer>
    <order-dtl ref="orderDetail"  :orderId="selectedOrderId"></order-dtl>
  </div>
</template>
<script>
import FunctionBox from "@/views/Statistics/components/functionBox.vue";
import common from "@/views/OrderManagement/mixin/common";
import PlatSelect from "@/components/PlatSelect.vue";
import OrderApprove from "@/views/OrderManagement/business/orderApprove.vue";
import {checkOrderList, export_order} from "@/api/orderGoods/business";
import orderDtl from "@/views/OrderManagement/business/components/orderDtl.vue";
export default {
  components: {PlatSelect, FunctionBox,OrderApprove,orderDtl},
  mixins: [common],
  data() {
    return {
      detailLoading: false,
      loadingText: '数据加载中',
      isEmpty: false,
      checkedOrders: {},
      isApproveShow: false,
      isOrderDtlShow: false,
      selectedOrderId: '',
      isOrderExport: 0,
      pendingApproval: false,
      loading: false,
      isSelected: false,
      checked: false,
      checkAll : false,
      isDrawerShow: false,
      innerDrawerShow: false,
      isAllChecked: false,
      dateValue: [],
      checkedList: [],
      searchParams: {
        search: '',
        order_status: '',
        start_date: '',
        end_date: '',
        page: 1,
        size: 10,
        total: 0,
      }
    }
  },
  computed: {
    isIndeterminate:{// 当部分选中时，全选框为不确定状态
      get(){},
      set(){
        return this.checkedList.length > 0 && this.checkedList.length < this.orderList.length;
      },
    },
  },
  created() {
    this.orderList.forEach(order => {
      this.$set(this.checkedOrders, order.id, false);
    });
    this.getList()
  },
  methods: {
    updateList(){
      this.getList()
      this.isApproveShow = false
      this.isDrawerShow =false
    },
    handleChecked(val){
      if(val){
        this.getList(9001)
      }else{
        this.searchParams.order_status = ''
        this.getList()
      }
    },
    getList(status){
      this.$nextTick(() => {
        // 获取表格的 DOM 元素
        const blockWrapper = this.$refs.sheet
        window.requestAnimationFrame(() => {
          blockWrapper.scrollTop = 0;
        });
      });
      this.loading = true
      if(status){
        this.searchParams.order_status = status
      }else{
        this.searchParams.order_status = ''
      }
      this.loadingText = '数据加载中'
      checkOrderList(this.searchParams).then(res => {
        this.orderList = res.data.data
        if(this.orderList.length == 0){
          this.isEmpty = true
        }else{
          this.isEmpty = false
        }
        this.searchParams.total = res.data.count
        setTimeout(()=>{
          if(status ==9001){
            this.handleAllCheckedChange()
          }
        },500)

      }).catch(err => {
        if( err.status === 'error' && this.searchParams.page >1 ){
          this.searchParams.page = 1
          this.getList()
        }else{
           this.$message.error(err.msg)
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleSizeChange(val){
      this.searchParams.size = val
      this.getList()
    },
    handleCurrentChange(val){
      this.searchParams.page = val
      this.getList()
    },
    handleAllCheckedChange(value) {
      // 全选/全不选时更新checkedList
      if (value) {
        this.checkedList = this.orderList.map(order => order.order_id);
      } else {
        this.checkedList = [];
      }
      if(this.checkedList.length > 0){
        this.isOrderExport = 1
      }else{
        this.isOrderExport = 0
      }
      this.isIndeterminate = false;
    },
    handleCheckedOrderChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.orderList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.orderList.length;
      if(this.checkedList.length > 0){
        this.isOrderExport = 1
      }else{
        this.isOrderExport = 0
      }
    },
    openDrawer(row){
      this.selectedOrderId = row.order_id ? row.order_id.toString() : ''
      if(row.order_status == 9001){
        this.isDrawerShow = true
        this.isApproveShow = true
        this.isOrderDtlShow = false
        setTimeout(()=>{
          this.$refs.orderDrawer.getDetail()
        },100)

      }else{
        this.isApproveShow = false
        // this.isOrderDtlShow = true
        this.$refs.orderDetail.isDrawerShow = true
        // this.$refs.orderDetail.isLoading = true
        // setTimeout(()=>{
        //   this.$refs.orderDetail.getDetail()
        // },100)
      }

    },
    dateChange(){
      this.searchParams.start_date = this.dateValue[0]
      this.searchParams.end_date = this.dateValue[1]
      this.getList(this.searchParams.order_status)
    },
    clearSearchTime(){},
    searchBtn() {
     this.getList(this.searchParams.order_status)
    },
    clearSearch() {
      this.dateValue = []
      this.searchParams.start_date = null
      this.searchParams.end_date = null
      this.searchParams.search = null
      this.searchParams.order_status = null
      this.searchParams.page = 1
      this.searchParams.size =  10
      this.getList()
    },
    handlePlatSelect(val){
      this.searchParams.platform_id = val
    },
    handlePageChange(val){
      this.searchParams.page = val
      this.getList()
    },
    handleCheckboxChange(orderId, event) {
      const isChecked = event.target.checked;
      if (isChecked) {
        this.checkedList.push(orderId); // 选中时添加到 checkedList
      } else {
        const index = this.checkedList.indexOf(orderId);
        if (index > -1) {
          this.checkedList.splice(index, 1); // 取消选中时从 checkedList 中移除
        }
      }
    },
    handleExportOrder(){
      if(!this.isSelected){
        this.loading = true
        this.loadingText = '正在下载文件，请稍后'
        let params = {
          // order_id:this.checkedList,
          start_date:'',
          end_date:'',
          order_status:'',
          search:'',
        }
        if(this.checkedList.length < 2){
          params.order_id = this.checkedList.toString()
        }else{
          params.order_ids = this.checkedList.toString()
        }
        export_order(params).then(res =>{
          let url= res.data.file_url
          this.downloadFile(url)
        }).finally(()=>{
          this.loading = false
        })
      }
    },
    downloadFile(url) {
      return new Promise((resolve, reject) => {
        const fileName = url.split('/').pop()
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.download = fileName
        // 这个延迟操作的目的是为了确保浏览器有足够的时间来处理下载操作
        // 这样可以避免在循环中快速触发多个下载操作，导致浏览器无法正确处理下载请求
        link.addEventListener('click', () => {
          setTimeout(() => {
            resolve()
          }, 1000)
        })
        link.click()
      }).finally(()=>{this.exporting = false})
    },
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/order";
::v-deep .el-pagination .el-input__suffix{
  line-height: 38px!important;
  height: 38px!important;
}
.zone{
  position: relative;
  .indentBox{
    .below{
      .left{
        width: 50%!important;
      }
    }
  }
  ::v-deep .el-drawer__header {
    border-bottom: 2px dotted #eee;
    padding: 10px;
    margin-bottom: 14px;
  }
  ::v-deep .el-drawer__header span{
    font-weight: bold;
    color: #000;
  }
  .sheet{
    position: relative;
    overflow: auto;
    margin-top: 4px;
    height: calc(100% - 240px);
  }
  .sheet.empty{
    background: #fff;
    height: calc(100% - 186px);
  }
  .sheet.no-scroll { // loading时不允许滚动
    overflow: hidden;
    height: calc(100% - 166px);
  }
  .check_select{
    display: inline-block;
    margin-top: 10px;
  }
  ::v-deep .check_select .el-checkbox__label{
    font-size: 13px;
  }
  .orderList{
    display: inline-block;
    margin-top: 2px;
    margin-bottom: 0px;
    width: 100%;
    .orderItem{
      color: #6C6C6C;
      background: #fff;
      border-radius: 4px;
      padding: 4px 0px;
      margin-bottom: 8px;
      .orderItemTop{
        line-height: 2.4;
        border-bottom: 1px solid #eee;
        color: #000;
        ::v-deep .el-checkbox{
          margin: 0 10px 0 16px;
          .el-checkbox__label{
            display: none;
          }
        }
        span{
          font-size: 14px;
          font-weight: bold;
        }

      }
      .orderItemContent{
        line-height: 2.4;
        margin: 4px 16px;
        .contentRight{
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 48px;
          span{
            margin-top: 18px;
            border:1px solid #4095E5;
            color: #4095E5;
            font-size: 14px;
            line-height: 2;
            padding: 3px 50px;
            cursor: pointer;
          }
        }
      }
      .orderItemBottom{
        line-height: 2;
        padding: 4px 16px;
        box-sizing: border-box;
        border-top:1px solid #eee;
        color: #BD3124;
      }
    }
    .orderItem:last-child{
      margin-bottom: 0!important;
    }
  }
}
.page{
  position: absolute;
  bottom: 0;
  right: 14px;
  //background: #fff;
  width: calc(100% - 28px);
  padding:10px 20px 20px 20px;
}
::v-deep .el-input--small .el-input__inner{
  width: 100%;
}
::v-deep .keyword_search .el-input__inner{
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  .sheet{
    position: relative;
    overflow: auto;
    margin-top: 4px;
    height: calc(100% - 220px)!important;
  }
  .keyword_search{
    width: 264px!important;
    font-size: 14px;
  }
  ::v-deep .keyword_search .el-input__inner{
    font-size: 13px;
  }
  ::v-deep .el-select .el-input .el-input__inner{
    font-size: 13px;
  }
  .orderItem{
    font-size: 14px;
    .orderItemTop{
      font-size: 16px;
    }
  }
  ::v-deep .page .el-input--mini .el-input__inner{
    height: 28.5px!important;
    line-height: 1!important;
  }
}
@media screen and (min-width: 1551px) , print {
  .keyword_search{
    width: 320px;
    ::v-deep .el-input__inner{
      font-size: 16px;
    }
  }
  ::v-deep .el-pagination .el-select .el-input .el-input__inner{
    height: 38.4px!important;
    line-height: 1!important;
  }
  ::v-deep .el-input--small .el-input__inner{
    font-size: 16px;
  }
  .orderItem{
    font-size: 16px;
    .orderItemTop{
      font-size: 18px;
    }
  }
}
.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95%; /* 设置容器高度为视口高度，以便于居中 */
  background: #fff;
}
</style>