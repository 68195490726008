<template>
  <div class="zone">
    <functionBox  :titleData="{permission_flag:'SparePart',nowID:1}" :hideSearchBtn="true"
                  @searchBtn="searchBtn" @addCheck="editCheck('0')" @clearSearch="clearSearch" :searhLoading="loading">
      <template>
        <li class="keyword_search search_line_one">
          <el-input placeholder="请输入存货名称/商品简称" v-model="searchParams.name" @clear="getTabelList" @keyup.enter.native="getTabelList" clearable size="small"></el-input>
        </li>
        <li class="search_line_one">
          <el-select
              v-model="searchParams.platform_unique"
              placeholder="请选择平台"
              size="small"
              filterable
              clearable
              @change="getTabelList"
          >
            <el-option
                v-for="item in platOptions"
                :key="item.platform"
                :label="item.platform_unique"
                :value="item.platform_unique"
            ></el-option>
          </el-select>
        </li>
        <li class="search_line_one">
          <el-select v-model="searchParams.inventory_type" @clear="getTabelList" @change="getTabelList" placeholder="请选择商品类别" size="small" filterable clearable>
            <el-option
                v-for="item in goodsTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </li>
        <li class="search_line_one">
          <el-button type="primary" size="small" @click="searchBtn" :disabled="searhLoading" :loading="searhLoading"><i style="margin-right: 4px" class="el-icon-search"/>搜&nbsp;索</el-button>
          <el-button  size="small"  @click="clearSearch" :disabled="searhLoading" ><i style="margin-right: 4px" class="el-icon-refresh" />重&nbsp;置</el-button>
        </li>
      </template>
    </functionBox>
    <div class="sheet" v-loading="loading" element-loading-text="数据加载中">
      <el-table
          ref="proTable"
          :data="tableData"
          height="100%"
          stripe
          @selection-change="handleSelectionChange"
          style="width: 100%">
        <el-table-column  type="selection" width="50" align="center" header-align="center"></el-table-column>
        <el-table-column label="#" type="index" width="60" align="center" header-align="center"></el-table-column>
        <el-table-column prop="inventory_full_name" label="商品全称" align="left" header-align="center" min-width="410">
          <template  v-slot="{row}">
            <span>{{row.inventory_full_name ? row.inventory_full_name : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="inventory_name" label="商品简称" min-width="180" align="center" header-align="center">
          <template  v-slot="{row}">
            <span>{{row.inventory_name ? row.inventory_name : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="standard" label="规格/型号" min-width="160" align="center" header-align="center">
          <template  v-slot="{row}">
            <span>{{row.standard ? row.standard : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="inventory_type" label="类别" min-width="50" align="center" header-align="center">
          <template  v-slot="{row}">
            <span>{{row.inventory_type ? row.inventory_type : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="commodity" label="批准文号" min-width="170" align="center" header-align="center">
          <template  v-slot="{row}">
            <span>{{row.commodity ? row.commodity : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" width="320" fixed="right" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <el-button class="el-icon-s-promotion special" size="mini" @click="handleAddGoods(row.inventory_id)" style="color:#377F7F;border:1px solid #377F7F;">详情</el-button>
<!--            <el-button class="el-icon-edit-outline" size="mini"  @click="addGoods(1,scope.row.id)" style="color:#E99D42;border:1px solid #E99D42;">编辑</el-button>-->
            <el-button class="el-icon-coin special" size="mini" @click="editPrice(row, '1')" style="color: #567722;border:1px solid #567722;">定价</el-button>
            <el-button class="el-icon-close special" size="mini" @click="handleDelete(row.inventory_id)" style="color: #BD3124;border:1px solid #BD3124;">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        v-if="tableData.length > 0"
        class="page"
        background
        :pager-count="3"
        layout="total, sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="total">
    </el-pagination>
    <div class="footer-btn" v-if="tableData.length > 0">
      <!--        <el-button size="mini" type="primary" class="el-icon-plus" @click="addGoods(1)"  style="background: #4095E5;border:1px solid #4095E5;">新增商品</el-button>-->
      <el-button class="el-icon-coin special new_add"  size="mini" @click="editPrice(selectData,'2')" style="color: #567722;border:1px solid #567722;">新增定价</el-button>
    </div>
    <addGoods ref="addGoods" :key="inventory_id" v-if="dialogVisible" :dialogVisible="dialogVisible" :inventoryId="inventory_id" @close="dialogVisible=false"></addGoods>
    <el-drawer
        title="定价"
        :with-header="false"
        :visible.sync="isDrawerShow"
        destroy-on-close
        :wrapper-closable="false"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :modal-append-to-body=false
        :before-close="closeDrawer"
        size="70%">
      <fixPrice ref="fixPrice"  :selectData="selectData"  @close="closeDrawer"></fixPrice>
    </el-drawer>
    </div>
</template>
<script>
import FunctionBox from "@/views/Statistics/components/functionBox.vue";
import common from "@/views/OrderManagement/mixin/common";
import PlatSelect from "@/components/PlatSelect.vue";
import addGoods from "@/views/OrderManagement/business/components/addGoods.vue"
import HistoryPrice from "@/views/OrderManagement/business/components/historyPrice.vue";
import fixPrice from "@/views/OrderManagement/business/components/fixPrice.vue";
import {getInventoryList,deletInventory} from "@/api/orderGoods/business";
import api from "@/api/tongji";

export default {
  components: {HistoryPrice, PlatSelect, FunctionBox,addGoods,fixPrice},
  mixins: [common],
  data() {
    return {
      searhLoading: false,
      isAddShow: false,
      isFixShow: false,
      loading: false,
      dialogVisible: false,
      isDtl: false,
      isDrawerShow: false,
      inventory_id: '', //查看详情
      tableHeight: 0,
      platOptions: [],
      total: 0,
      selectData: [],
      searchParams: {
        name: '',
        platform_unique: '',
        inventory_type: '',
        page: '1',
        size: '10',
      }
    }
  },
  created() {
    this.getTableHeight()
    this.getTabelList()
    this.platformFun()
  },
  methods: {
    getTableHeight(){
      var viewportHeight = window.innerHeight;
      var viewportWidth =  window.innerWidth
      // var element = document.getElementById('pagination');
      if(viewportWidth > 2400){
        this.tableHeight = viewportHeight - 290
      }else if(viewportWidth < 2400 &&  viewportWidth > 1550){
        this.tableHeight = viewportHeight - 276
      }else{
        this.tableHeight = viewportHeight - 226
      }
    },
    getTabelList(){
      this.$nextTick(() => {
        // 获取表格的 DOM 元素
        const blockWrapper = this.$refs.proTable.$el.querySelector('.el-table__body-wrapper');
        window.requestAnimationFrame(() => {
          blockWrapper.scrollTop = 0;
        });
      });
      this.loading = true
      getInventoryList(this.searchParams).then(res =>{
        if(res.code === 200){
          this.tableData = res.data.data
          this.total = res.data.count
          this.loading = false
        }else{
          this.$message.error(res.data.msg)
        }
      }).catch(err => {
        if( err.status === 'error' && this.searchParams.page >1 ){
          this.searchParams.page = 1
          this.getTabelList()
        }else{
          this.$message.error(err.msg)
        }
      }).finally(()=>{
        this.loading = false
      })
    },
    editPrice(data,type){
      if(type == '1' && data){
        // 基础知识：使用数组的7个内置函数，才能让vue监听到数据的变化，时刻谨记
        this.selectData = []
        this.selectData.push(data)
        this.isDrawerShow = true
      }else if(type==='2'){

        this.isDrawerShow = true
      }else{
        this.selectData = []
        this.isDrawerShow = true
      }
    },
    closeDrawer(){
      this.selectData = []
      this.isDrawerShow = false
    },
    handleSelectionChange(val){
      this.selectData = [...val]
    },
    handleDelete(id){
      this.inventory_id = id
      let param = {inventory_id:id}
      this.$confirm('确定要删除当前商品吗?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        // center: true
      }).then(() => {
        deletInventory(param).then(res =>{
          if(res.code === 200){
            this.getTabelList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            this.$message.error(res.data.msg)
          }
        })
      }).catch(() => {
      });
    },
    handleAddGoods(id){
      // this.isAddShow = true
      // 技巧：如果希望子组件每次重新渲染，需要给子组件添加key属性，key的属性值必须唯一，否则子组件不会重新渲染
      // 此处利用inventory_id作为key，每次点击不同记录，都会重新渲染子组件，子组件的created都会执行，因此才会重新请求数据
      this.inventory_id = id;
      this.dialogVisible = true;
      // this.$refs.addGoods.dialogVisible = true
      // if(type == 0){
      //   this.$refs.addGoods.isCheck = true
      // }else{
      //   this.$refs.addGoods.isCheck = false
      // }
    },
    //平台下拉框
    platformFun() {
      api.platform({}).then((res) => {
        this.platOptions = res.data
      })
    },
    searchBtn() {
      this.getTabelList()
    },
    clearSearch() {
      this.searchParams.name = ''
      this.searchParams.platform_unique =''
      this.searchParams.inventory_type =''
      this.searchParams.page = 1
      this.getTabelList()
    },
    handlePlatSelect(val){
      this.searchParams.platform_id = val
    },
    handleSizeChange(val){
      this.searchParams.size = val
      this.getTabelList()
    },
    handleCurrentChange(val){
      this.searchParams.page = val
      this.getTabelList()
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/order";
::v-deep .search_line_one .el-button{
  padding: 0 16px;
}
.search_line_one{
  margin-top: 0!important;
}
::v-deep .el-button.special:hover{
  background: #fff;
}
.zone{
  .sheet{
    position: relative;
    overflow: auto;
    margin-top: 8px;
    height: calc(100% - 146px);
  }
  .indentBox{
    .below{
      .left{
        width: 50%!important;
      }
    }
  }
}
.page{
  position: absolute;
  bottom: 0;
  width: calc(100% - 28px);
  padding: 10px 20px 6px 20px;
  box-sizing: border-box;
}
.footer-btn{
  position: fixed;
  bottom:28px;
}
::v-deep .el-table thead th.el-table__cell{
  background: #EFEFEF;
  color: #000;
  line-height: 40px;
}
::v-deep .keyword_search .el-input__inner{
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  .zone .sheet{
    position: relative;
    overflow: auto;
    margin-top: 8px;
    height: calc(100% - 136px)!important;
  }
  .page{
    padding-bottom: 0;
  }
  .footer-btn {
    position: fixed;
    bottom: 18px;
  }

  ::v-deep .search_line_one .el-button{
    font-size: 14px;
    height: 32px;
  }
  ::v-deep .keyword_search .el-input__inner{
    font-size: 13px;
  }
  ::v-deep .el-select .el-input .el-input__inner{
    font-size: 13px!important;
  }
  ::v-deep .el-button--mini{
    font-size: 14px!important;
  }
  ::v-deep .el-table__cell .cell .el-button span{
    font-size: 14px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 14px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 14px;
  }
  .table_bottom p{
    font-size: 14px;
  }
  .keyword_search{
    width: 206px;
    font-size: 14px;
  }
  ::v-deep .el-select .el-input .el-input__inner{
    font-size: 14px;
  }
  .new_add{
    font-size: 14px;
  }
}
@media screen and (min-width: 1551px) , print {
  ::v-deep .search_line_one .el-button{
    font-size: 16px;
    height: 32px;
  }
  ::v-deep .el-button--mini{
    font-size: 16px!important;
  }
  .new_add{
    font-size: 16px;
  }
  ::v-deep .el-table__cell .cell .el-button span{
    font-size: 16px;
  }

  ::v-deep .el-select .el-input .el-input__inner{
    font-size: 16px;
  }
  .keyword_search{
    width: 240px;
    ::v-deep .el-input__inner{
      font-size: 16px;
    }
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 16px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 16px;
  }
  .table_bottom p{
    font-size: 16px;
  }
}
::v-deep .el-pagination{
  margin-bottom: 20px;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner{
  height: inherit;
  width: 100px;
}
</style>