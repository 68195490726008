<template>
  <div class="zone">
    <functionBox  :titleData="{permission_flag:'SparePart',nowID:1}" :hideSearchBtn="true"
                  @searchBtn="searchBtn" @addCheck="editCheck('0')" @clearSearch="clearSearch" :searhLoading="loading">
      <template>
        <li class="keyword_search search_line_one">
          <el-input placeholder="请输入客户名称" v-model="searchParams.customer_name" @clear="getTabelList" @keyup.enter.native="getTabelList" clearable size="small"></el-input>
        </li>
        <li class="search_line_one">
          <el-button type="primary" size="small" @click="searchBtn" :disabled="searhLoading" :loading="searhLoading"><i style="margin-right: 4px" class="el-icon-search"/>搜&nbsp;索</el-button>
          <el-button  size="small"  @click="clearSearch" :disabled="searhLoading" ><i style="margin-right: 4px" class="el-icon-refresh" />重&nbsp;置</el-button>
        </li>
      </template>
    </functionBox>
    <div class="sheet" v-loading="loading" element-loading-text="数据加载中">
      <el-table
          ref="proTable"
          :data="tableData"
          height="100%"
          stripe
          @selection-change="handleSelectionChange"
          style="width: 100%">
        <el-table-column  type="selection" :selectable="selectableStatus"  width="50" align="center" header-align="center"></el-table-column>
        <el-table-column label="#" type="index" width="60" align="center" header-align="center"></el-table-column>
        <el-table-column prop="customer_name" label="客户名称" align="left" header-align="center" min-width="180">
          <template  v-slot="{row}">
            <span>{{row.customer_name ? row.customer_name : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="registration_code" label="组织代码" min-width="130" align="center" header-align="center">
          <template  v-slot="{row}">
            <span>{{row.registration_code ? row.registration_code : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="legal_person_name" label="法人姓名" min-width="130" align="center" header-align="center">
          <template  v-slot="{row}">
            <span>{{row.legal_person_name ? row.legal_person_name : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="legal_person_phone" label="手机号" min-width="100" align="center" header-align="center">
          <template  v-slot="{row}">
            <span>{{row.legal_person_phone ? row.legal_person_phone : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="business_license" label="营业执照" min-width="170" align="center" header-align="center">
          <template  v-slot="{row}">
            <el-image
                v-if="row.business_license"
                style="width: 68px; height: 34px"
                :src="row.business_license"
                :preview-src-list="[row.business_license]"
                fit="contain"></el-image>
            <span v-else>/</span>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" width="230" fixed="right" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <el-button v-if="row.cert_status == 5001" @click="handleCert(5002,1,row)"  class="special" size="mini" style="color:#377F7F;border:1px solid #377F7F;">审核通过</el-button>
            <el-button v-if="row.cert_status == 5001" @click="handleCert(5003,1,row)"  class="special" size="mini" style="color:#bd3124;border:1px solid #bd3124;">审核驳回</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        v-if="tableData.length > 0"
        class="page"
        background
        :pager-count="3"
        layout="total, sizes, prev, pager, next"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :total="total">
    </el-pagination>
    <div class="footer-btn" v-if="tableData.length > 0">
      <el-dropdown  @command="handleCertCommand">
        <el-button style="color: #567722;border:1px solid #567722;" >批量审核</el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="5002">审核通过</el-dropdown-item>
          <el-dropdown-item command="5003">审核驳回</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

  </div>
</template>
<script>
import FunctionBox from "@/views/Statistics/components/functionBox.vue";
import common from "@/views/OrderManagement/mixin/common";
import {getCertifiedList, checkCertified} from "@/api/orderGoods/business";

export default {
  components: {FunctionBox},
  mixins: [common],
  data() {
    return {
      searhLoading: false,
      isAddShow: false,
      isFixShow: false,
      loading: false,
      dialogVisible: false,
      isDtl: false,
      isDrawerShow: false,
      inventory_id: '', //查看详情
      tableHeight: 0,
      platOptions: [],
      total: 0,
      tableData: [
        // {
        //   cert_id: 1001,
        //   customer_name: '上海奥普',
        //   registration_code: '123456789',
        //   legal_person_name: '王小明',
        //   legal_person_phone: '123456789',
        //   business_license: '123456789'
        // },
        // {
        //   cert_id: 1002,
        //   customer_name: '上海奥普',
        //   registration_code: '123456789',
        //   legal_person_name: '王小明',
        //   legal_person_phone: '123456789',
        //   business_license: '123456789'
        // },
      ],
      selectData: [],
      searchParams: {
        customer_name: '',
        page: '1',
        size: '10',
      }
    }
  },
  created() {
    // this.getTableHeight()
    this.getTabelList()
  },
  methods: {
    // getTableHeight(){
    //   var viewportHeight = window.innerHeight;
    //   var viewportWidth =  window.innerWidth
    //   // var element = document.getElementById('pagination');
    //   if(viewportWidth > 2400){
    //     this.tableHeight = viewportHeight - 290
    //   }else if(viewportWidth < 2400 &&  viewportWidth > 1550){
    //     this.tableHeight = viewportHeight - 276
    //   }else{
    //     this.tableHeight = viewportHeight - 226
    //   }
    // },
    selectableStatus(row){
      return !row.disabled;
    },
    getTabelList(){
      this.$nextTick(() => {
        // 获取表格的 DOM 元素
        const blockWrapper = this.$refs.proTable.$el.querySelector('.el-table__body-wrapper');
        window.requestAnimationFrame(() => {
          blockWrapper.scrollTop = 0;
        });
      });
      this.loading = true
      getCertifiedList(this.searchParams).then(res =>{
        if(res.code === 200){
          this.tableData = res.data.data
          this.tableData.forEach(item =>{
            if(item.cert_status == 5001){
              item.disabled = false
            }else{
              item.disabled = true
            }
            })
          this.total = res.data.count
          this.loading = false
        }else{
          // this.$message.error('')
        }
      }).catch(err => {
        if( err.status === 'error' && this.searchParams.page >1 ){
          this.searchParams.page = 1
          this.getTabelList()
        }else{
          // this.$message.error(err)
        }
      }).finally(()=>{
        this.loading = false
      })
    },

    handleSelectionChange(val){
      this.selectData = [...val]
    },
    handleCertCommand(command){
      this.handleCert(Number(command),2)
    },
    handleCert(cert_status,type,row){
      let cert_ids = []
      if(type == 1){ // 单条
        cert_ids.push(row.cert_id)
      }else{ // 多条
        cert_ids = this.selectData.map(item => item.cert_id)
      }
      if( cert_ids && cert_ids.length > 0){
        checkCertified({cert_status,cert_ids}).then(res =>{
          if(res.code === 200){
            this.getTabelList()
            this.$message({
              type: 'success',
              message: '操作成功!'
            });

          }
        })
      }else{
        this.$message.error('请选择要审核的数据')
      }


    },
    // handleDelete(id){
    //   this.inventory_id = id
    //   let param = {inventory_id:id}
    //   this.$confirm('确定要删除当前商品吗?', '', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning',
    //     // center: true
    //   }).then(() => {
    //     deletInventory(param).then(res =>{
    //       if(res.code === 200){
    //         this.getTabelList()
    //         this.$message({
    //           type: 'success',
    //           message: '删除成功!'
    //         });
    //       }else{
    //         this.$message.error(res.data.msg)
    //       }
    //     })
    //   }).catch(() => {
    //   });
    // },
    searchBtn() {
      this.getTabelList()
    },
    clearSearch() {
      this.searchParams.customer = ''
      this.searchParams.page = 1
      this.getTabelList()
    },
    handleSizeChange(val){
      this.searchParams.size = val
      this.getTabelList()
    },
    handleCurrentChange(val){
      this.searchParams.page = val
      this.getTabelList()
    }
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/order";
::v-deep .search_line_one .el-button{
  padding: 0 16px;
}
.search_line_one{
  margin-top: 0!important;
}
::v-deep .el-button.special:hover{
  background: #fff;
}
.zone{
  .sheet{
    position: relative;
    overflow: auto;
    margin-top: 8px;
    height: calc(100% - 146px);
  }
  .indentBox{
    .below{
      .left{
        width: 50%!important;
      }
    }
  }
}
.page{
  position: absolute;
  bottom: 0;
  width: calc(100% - 28px);
  padding: 10px 20px 6px 20px;
  box-sizing: border-box;
}
.footer-btn{
  position: fixed;
  bottom:28px;
}
::v-deep .el-table thead th.el-table__cell{
  background: #EFEFEF;
  color: #000;
  line-height: 40px;
}
::v-deep .keyword_search .el-input__inner{
  width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  .zone .sheet{
    position: relative;
    overflow: auto;
    margin-top: 8px;
    height: calc(100% - 136px)!important;
  }
  .page{
    padding-bottom: 0;
  }
  .footer-btn {
    position: fixed;
    bottom: 18px;
  }

  ::v-deep .search_line_one .el-button{
    font-size: 14px;
    height: 32px;
  }
  ::v-deep .keyword_search .el-input__inner{
    font-size: 13px;
  }
  ::v-deep .el-select .el-input .el-input__inner{
    font-size: 13px!important;
  }
  ::v-deep .el-button--mini{
    font-size: 14px!important;
  }
  ::v-deep .el-table__cell .cell .el-button span{
    font-size: 14px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 14px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 14px;
  }
  .table_bottom p{
    font-size: 14px;
  }
  .keyword_search{
    width: 206px;
    font-size: 14px;
  }
  ::v-deep .el-select .el-input .el-input__inner{
    font-size: 14px;
  }
  .new_add{
    font-size: 14px;
  }
}
@media screen and (min-width: 1551px) , print {
  ::v-deep .search_line_one .el-button{
    font-size: 16px;
    height: 32px;
  }
  ::v-deep .el-button--mini{
    font-size: 16px!important;
  }
  .new_add{
    font-size: 16px;
  }
  ::v-deep .el-table__cell .cell .el-button span{
    font-size: 16px;
  }

  ::v-deep .el-select .el-input .el-input__inner{
    font-size: 16px;
  }
  .keyword_search{
    width: 240px;
    ::v-deep .el-input__inner{
      font-size: 16px;
    }
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 16px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 16px;
  }
  .table_bottom p{
    font-size: 16px;
  }
}
::v-deep .el-pagination{
  margin-bottom: 20px;
}
::v-deep .el-pagination .el-select .el-input .el-input__inner{
  height: inherit;
  width: 100px;
}
</style>