var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"dialog_content"},[_vm._l((_vm.newProductList),function(item){return _c('p',{staticClass:"relate_text title_top",staticStyle:{"color":"#000"}},[_c('span',{staticStyle:{"color":"#BD3124"}},[_vm._v("「"+_vm._s(item.inventory_full_name)+"」")]),_vm._v(" 已添加到采购清单，您可能还需要以下商品： ")])}),_c('div',{staticClass:"relate_table"},[(_vm.relateTableData.length > 0)?_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"relateTable",attrs:{"data":_vm.relateTableData,"border":"","element-loading-text":"加载中...","max-height":"450px","scroll-y":true}},[_c('el-table-column',{attrs:{"prop":"inventory_id","label":"存货编码","min-width":"120","align":"center","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('span',[_vm._v(_vm._s(row.inventory_id))])]}}],null,false,3863677066)}),_c('el-table-column',{attrs:{"prop":"inventory_name","label":"存货简称","min-width":"200","align":"left","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('span',[_vm._v(_vm._s(row.inventory_name))])]}}],null,false,1750853248)}),_c('el-table-column',{key:Math.random(),attrs:{"prop":"","label":"操作","width":"130","align":"center","header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(!row.isOrder)?_c('el-button',{staticClass:"order_btn font_resize",on:{"click":function($event){return _vm.handleSubmit(row,$index)}}},[_vm._v("订购")]):_vm._e(),(row.isOrder)?_c('el-button',{staticClass:"order_btn font_resize is_order"},[_vm._v("已购")]):_vm._e()]}}],null,false,1289844543)})],1):_vm._e(),_vm._m(0),_c('div',{staticClass:"footer_btn"},[_c('el-button',{staticClass:"close",on:{"click":_vm.handleClose}},[_vm._v("不，我自己去选购")]),_c('el-button',{staticClass:"confirm",attrs:{"disabled":_vm.allOrderDisabled,"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("是，全部加入采购清单")])],1)],1)],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom_text"},[_c('p',{staticClass:"relate_text",staticStyle:{"display":"inline-block","margin-top":"30px"}},[_vm._v("您也可以将上述商品一键添加到采购清单，"),_c('span',{staticStyle:{"color":"#BD3124"}},[_vm._v("是否需要全部添加？")])])])}]

export { render, staticRenderFns }