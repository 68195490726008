
<template>
  <div>
    <el-drawer
        title="订单详情"
        :visible.sync="isDrawerShow"
        size="70%">
      <template #title>
        <span class="dialog_title component_title">订单详情</span>
      </template>
      <div class="orderDtl" v-loading = "isLoading" element-loading-text="数据加载中">
        <div class="orderInfo">
          <el-row>
            <el-col :span="24">
              <el-row :span="24">
                <el-col :span="6">
                  <div class="block" style="border-right: none">
                    <p>订单号</p>
                    <div style="height: 34px;line-height: 34px;padding-top: 10px">{{form.order_code}}</div>
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="block order_time" style="border-right: none;border-left: 1px solid #eee;">
                    <p>下单时间</p>
                    <div style="height: 34px;line-height: 34px;padding-top: 10px">{{form.create_time}}</div>
                  </div>
                </el-col>
                <el-col :span="12" >
                  <div class="block" style="border-left: 1px solid #eee;border-right: none">
                    <p>开票信息</p>
                    <div>{{form.invoice_info.invoice_header}}&nbsp;/&nbsp;{{form.invoice_info.registration_code}}</div>
                    <div>{{form.invoice_info.contact_person}}&nbsp;/&nbsp;{{form.invoice_info.contact_email}}</div>
                  </div>
                </el-col>
              </el-row>
              <el-row :span="24">
                <el-col :span="6">
                  <div class="block" style="border-right: none">
                    <p>订单状态</p>
                    <div style="font-weight: bold" :style="{'color': form.order_status === 9005 ? '#000' : '#4F4F4F'}">{{form.order_status_name}}</div>
                  </div>
                </el-col>
                <el-col :span="[9003,9006].includes(form.order_status) ? 18 : 6">
                  <div class="block" style="border-left: 1px solid #eee;border-right: none;">
                    <p>{{form.order_status === 9004  ?  '发货' : form.order_status === 9005 ? '收货' : '审核'}}时间</p>
                    <div>{{form.check_date? form.check_date : '/'}}</div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="block" style="border-left: 1px solid #eee;border-right: none" v-if="![9003,9006].includes(form.order_status)">
                    <p>物流方式</p>
                    <div>{{form.logistics_type}}</div>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
          <el-row><el-col :span="24">
            <div class="block" style="border-right: none">
              <p>商务备注
                <i class="el-icon-edit" :style="{color: isBusinessRemarkEdit ? '#409EFF' : '','margin-left':'10px', 'margin-right':'14px'}" @click="isBusinessRemarkEdit = true"></i>
                <i class="el-icon-check" @click="handleUpdateBusiRemark"></i></p>
              <div>
                <el-form  ref="receive_info"  :model="businessForm">
                  <el-form-item style="margin-bottom: 0!important">
                    <el-input size="mini" v-if="isBusinessRemarkEdit" placeholder="请填写商务备注" v-model="businessForm.business_remark"></el-input>
                    <span v-else>{{businessForm.business_remark ? businessForm.business_remark : '/'}}&nbsp;</span>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </el-col>

            <el-col :span="24">
              <div class="block" style="border-right: none">
                <p>{{form.delivery_type== 4001 ? '提货人信息' : '收货人信息' }}</p>
                <div><span style="margin-right: 20px;">{{ form.receive_address.customer_contact }}</span>{{form.receive_address.customer_phone}}</div>
                <div>{{form.receive_address.customer_address}}</div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="goodsList">
          <p class="block_title component_inner_title">商品清单</p>
          <el-table
              :data="tradeTableData"
              max-height="450px"
              style="width: 100%">
            <el-table-column label="#" type="index" width="50" align="center" header-align="center"></el-table-column>
            <el-table-column prop="inventory_full_name" label="商品全称" min-width="280" align="left" header-align="center">
              <template v-slot="{ row, $index }">
                <span :style="{color: row.is_first ? '#BD3124' : ''}">{{row.inventory_full_name}}</span>
                <el-tag size="mini" effect="dark" style="background: #BD3124;border: none" v-if="row.is_first"  class="new_pro_tag">本次新购商品</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="inventory_name" label="商品简称" width="130" align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.inventory_name ? row.inventory_name : '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="standard" label="规格/型号" width="130" align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.standard ? row.standard : '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="unit_price"  label="单价" width="110" align="center" header-align="center">
              <el-table-column align="center"
                               class-name="children_column"
                               prop="unit_price"
                               label="(单位：元)"
                               width="90">
                <template v-slot="{ row, $index }">
                  <span>{{row.unit_price ? row.unit_price : '0'}}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="orders_num" label="数量" width="110" align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.orders_num ? row.orders_num : '0'}}&nbsp;{{row.sale_unit}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="orders_sum" label="总价"  width="160" align="center" header-align="center">
              <el-table-column align="center"
                               class-name="children_column"
                               prop="orders_sum"
                               label="(单位：元)"
                               width="160">a
                <template v-slot="{ row, $index }">
                  <span>{{row.orders_sum ? row.orders_sum : '0'}}</span>
                </template>
              </el-table-column>
            </el-table-column>

          </el-table>
          <div class="table_bottom" >
            <p style="display: inline-block; float: right">应付总额：<span style="color: #BD3124 ">{{form.total_price}}&nbsp;元</span></p>
          </div>
        </div>
        <div class="remark" style="margin-bottom: 20px">
          <p class="block_title component_inner_title" >备注说明</p>
          <p class="remark_text" v-if="form.order_remark">{{form.order_remark}}</p>
          <p class="font_resize" style="text-align: center;color: #8888;" v-else>暂无数据</p>
        </div> <div class="remark" style="margin-bottom: 20px">
          <p class="block_title component_inner_title" >发货备注</p>
          <p class="remark_text" v-if="form.check_remark">{{form.check_remark}}</p>
          <p class="font_resize" style="text-align: center;color: #8888;" v-else>暂无数据</p>
        </div>
        <div style="margin-bottom: 16px;">
          <p class="block_title component_inner_title">付款信息</p>
          <div class="payment_info" >
            <el-row >
<!--              <div class="payment_info" >-->
<!--              <el-col :span="8">-->
<!--                <el-col :span="24">-->
<!--                  <span>收款人名称：上海奥普生物医药股份有限公司</span>-->
<!--                </el-col>-->
<!--                <el-col :span="24">-->
<!--                  <span>收款人账号：124662377383839939933</span>-->
<!--                </el-col>-->
<!--                <el-col :span="24">-->
<!--                  <span>开户行名称：招商银行瑞庆路支行</span>-->
<!--                </el-col>-->
<!--              </el-col>-->
<!--              </div>-->
              <el-col :span="24">
                <el-image
                    v-if="form.payment_voucher"
                    style="width: 100px; height: 100px"
                    :src="form.payment_voucher"
                    :preview-src-list="srcList">
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                </el-image>
                <p v-else class="font_resize" style="text-align: center;color: #8888;">暂无数据</p>
              </el-col>
            </el-row>
          </div>
        </div>
        <div v-if="[9004,9005].includes( form.order_status)">
          <p class="block_title component_inner_title" style="margin-bottom: 0">物流信息</p>
          <div v-if="form.logistics_info && form.logistics_info.length > 0">
          <div class="express" v-for="(exp, index) in form.logistics_info">
            <el-row >
              <el-col :span="24" class="block_express">
                  <i class="el-icon-truck" style="width: 20px;font-size: 16px"></i>
                  <span style="margin-right: 20px">物流单号：{{exp.shipping_code}}</span>
                  <span>发货单号：{{exp.dispatch_code}}</span>
              </el-col>
            </el-row>
            <el-row v-if="exp.express_information && exp.express_information.length > 0">
              <div style="height:350px;overflow-y: scroll;margin-bottom: 14px">
              <el-col v-for="(item,index) in exp.express_information" class="express_list">
                <el-col :span="8">
                  <span>{{item.time}}【{{item.status}}】</span>
                </el-col>
                <el-col :span="16">
                  <span>{{item.context}}</span>
                </el-col>
              </el-col>
              </div>
            </el-row>
            <el-row v-else-if="exp.dev_state_list && exp.dev_state_list.length > 0">
              <div style="height:350px;overflow-y: scroll;margin-bottom: 14px">
              <el-col v-for="(item,index) in exp.dev_state_list" class="express_list">
                <el-col :span="8">
                  <span>{{item.upTime}}【{{item.city}}】</span>
                </el-col>
                <el-col :span="16">
                  <span>{{item.address}}</span>
                </el-col>
              </el-col>
              </div>
            </el-row>
            <div class="lenglian" v-loading="loadingechart" v-if="exp.dev_temp_list">
              <div :id="'chart-' + index"  style="width: 100%;height: 400px;"  :key="index"></div>
            </div>
          </div>
          </div>
          <p v-else class="font_resize" style="text-align: center;color: #8888;margin: 16px 0 30px 0;">暂无物流信息，请稍后查询</p>
        </div>

      </div>
    </el-drawer>
  </div>
</template>
<script >
import {getshippingtype} from "@/api/search";
import echartsMixins from '@/assets/js/echartsMixins.js';
import common from "@/views/OrderManagement/mixin/common";
import {checkOrderDetail, checkOrderDtlList} from "@/api/orderGoods/custom";
import {editNote} from "@/api/orderGoods/business";
export default {
  mixins: [echartsMixins,common],
  props:{
    orderId:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      myChart: '',
      isLoading: false,
      srcList: [ ],
      loadingechart: false,
      revoke: '',
      approveContent: '',
      isDrawerShow: false,
      dialogTableVisible: false,
      desc:'',
      businessForm:{
        business_remark: '',
      },
      isBusinessRemarkEdit: false,
      form:{
        invoice_title:'',
        invoice_no: '',
        invoice_name: '',
        invoice_mail: '',
        check_remark: '',
      },
      isEchartShow: false,
    }
  },
  computed: {
    totalAmount(){
      let total = 0
      if(this.tradeTableData && this.tradeTableData.length > 0){
        this.tradeTableData.forEach(item => {
          total += Number(item.orders_sum)
        })
        return total
      }
    }
  },
  watch: {
    isDrawerShow:{
      handler(val){
        if(val){
          this.getDetail()
        }else{
          this.loadingechart = false
        }
      },
    }
  },
  mounted() {
    if(this.orderId){
      this.isDrawerShow = true
    }
  },
  methods: {
    handleUpdateBusiRemark(){
      let params ={
        order_id: this.orderId,
        business_remark: this.businessForm.business_remark,
      }
      editNote(params).then(res=>{
        this.isBusinessRemarkEdit = false
        this.$message.success('商务备注保存成功！')
      })
    },
    getDetail(){
      this.isLoading = true
      checkOrderDetail({order_id: this.orderId}).then(res=>{
        this.form = res.data
        this.businessForm.business_remark = this.form.business_remark
        if(this.form.payment_voucher){
          this.srcList = [this.form.payment_voucher]
        }
        if(this.form.logistics_info && this.form.logistics_info.length > 0){
          this.form.logistics_info.forEach((item,index) => {
            if(item.dev_temp_list){
              this.loadingechart = true
              setTimeout(() => {
                this.initEcharts(item.dev_temp_list,index)
              },1000)
              this.isLoading = false
            }
          })
        }else{
          this.isLoading = false
        }
      })
      checkOrderDtlList({order_id: this.orderId}).then(res=>{
        this.tradeTableData = res.data
      })
    },
    // 温度折线图
    initEcharts(data,index) {
      if(data){
        var myChart = this.$echarts.init(document.getElementById('chart-' + index))
        // 配置图表
        var option = {
          title: {
            text: '冷链运输温度图',
            left: '0',
            top: '0',
            textStyle: {
              fontSize: 16,
            },
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'line',
            },
          },
          grid: {
            show: true,
          },
          legend: {
            right: '0',
            top: '0',
            data: ['温度'],
          },
          xAxis: {
            type: 'category',
            data: data.time_info,
            splitLine: {
              show: true,
            },
            boundaryGap: true,
            lineStyle: {
              //轴线样式设置
              color: 'rgb(215, 215, 215)',
              width: 1,
              type: 'solid',
            },
            axisLine: {
              show: true, //显示和隐藏轴线
              lineStyle: {
                //轴线样式设置
                color: 'rgba(215, 215, 215, 1)',
                width: 1,
                type: 'solid',
              },
            },
          },
          yAxis: {
            type: 'value',
            axisLabel: {
              formatter: '{value} °C',
            },
            axisLine: {
              show: true, //显示和隐藏轴线
              lineStyle: {
                //轴线样式设置
                color: 'rgba(215, 215, 215, 1)',
                width: 1,
                type: 'solid',
              },
            },
          },
          series: [
            {
              showSymbol: false,
              name: '温度',
              data: data.temp_info,
              type: 'line',
              color: 'rgba(249, 158, 155, 1)',
            },
          ],
        }
      }
      myChart.setOption(option)
      this.loadingechart = false
    },
  }
}

</script>

<style scoped lang="scss">
@import "~@/assets/css/order";

::v-deep .el-table th.el-table__cell > .cell{
  font-weight: 400;
}
::v-deep  .el-input--mini .el-input__inner{
  width: 100%!important;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  .block.order_time{
    padding-bottom: 14px!important;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 14px;
  }
  .payment_info span{
    font-size: 14px;
    margin-bottom: 14px;
  }
  ::v-deep .el-drawer__header{
    color: #000;
    font-size: 16px;
  }
  .orderInfo .block p, .orderInfo .block div{
    font-size: 14px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 14px;
  }
  ccc
  .table_bottom p{
    font-size: 14px;
  }
  .express {
    font-size: 14px;
  }

}
@media screen and (min-width: 1551px) , print {
  .block.order_time{
    padding-bottom: 19px!important;
  }
  .payment_info span{
    font-size: 16px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 16px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 16px;
  }
  ::v-deep .el-drawer__header{
    color: #000;
    font-size: 18px;
  }
  .orderInfo .block p, .orderInfo .block div{
    font-size: 16px;
  }
  .remark_text{
    font-size: 14px;
  }
  .table_bottom p{
    font-size: 16px;
  }
  .express {
    font-size: 16px;
  }
}
::v-deep .el-drawer__header{
  border-bottom: 2px dotted #eee;
  padding: 10px;
  margin-bottom: 14px;
}
.orderDtl{
  padding: 0 14px;
  .orderInfo{
    border-bottom:1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    .block{
      padding:14px;
      border-top:1px solid #eee;
      border-right: 1px solid #eee;
      p{
        color: #9A9A9A;
        line-height: 2;
      }
      div{
        line-height: 1.6;
        span{
          display: inline-block;
        }
      }
    }
  }
  .goodsList{
    .table_bottom{
      margin-top: 20px;
      margin-bottom: 50px
    }
  }
  .block_title{
    font-weight: bold;
    margin: 16px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
}
.drawer-footer{
  text-align: center;
  margin-top: 20px;
  .pass{
    background: #377F7F;
    color: #fff;
    padding-left:40px;
    padding-right: 40px
  }
  .reject{
    border:1px solid #377F7F;
    padding-left:40px;
    padding-right: 40px
  }
  .reject:hover{
    background: #fff;
    color: #377F7F;
  }
}
.el-table .children_column{
  border-bottom: none;

}
::v-deep .el-table thead .children_column .cell{
  font-size: 10px!important;

}
::v-deep .el-table thead.is-group th.el-table__cell{
  background: #EFEFEF;
  color: #000;
}
::v-deep .el-table thead .el-table__cell{
  padding: 0;
  border-bottom: none;
}

::v-deep .el-table td.el-table__cell.is-center div{
  text-align: center!important;
}
::v-deep .el-table td.el-table__cell.is-left div{
  text-align: left!important;
}
::v-deep .el-table thead th.el-table__cell{
  text-align: center!important;
}
::v-deep .el-input-number.is-controls-right .el-input__inner{
  width: 130px;
}
::v-deep .el-table td.el-table__cell div{
  text-align: left;
}
::v-deep .el-input--mini .el-input__inner{
  width: 130px;
}
.dialog_close{
  padding-left:40px;
  padding-right:40px
}
.dialog_close:hover{
  background: #fff;
  color: #347caf;
}
.express{
  padding-left:14px;
  margin-bottom: 10px;
  .express_list{
    border:1px solid #eee;
    line-height: 40px;
    padding: 0 10px;
  }
  .express_list:nth-child(2n+1){
    background: #F2F2F2;
  }
  .block_express span{
    font-weight: bold;
    line-height: 2.4;
    color:#4F4F4F;
  }
}
.lenglian{
  height: 370px;
}
.payment_info span{
  line-height: 2;
}
</style>