<template>
  <div>
    <el-dialog
      :visible.sync="dialog_visible"
      width="70%"
      custom-class="dialog-right"
      :close-on-click-modal="false"
      :show-close="false">
      <div class="report-box" v-loading="loading" element-loading-spinner="el-icon-loading"  element-loading-text="室内质控室间化报告生成中" element-loading-background="rgba(0, 0, 0, 0.7)">
        <div class="body"  >
          <!-- <div class="display-box"> -->
          <div  :class="'display-box'" >
            <div class="title-box">
              <p>腾云管家大数据SaaS服务系统</p>
              <p>室内质控室间化结果</p>
              <p>{{report_time_text}}</p>
            </div>
            <detailMenu :dataList="basicData"></detailMenu>
              <el-table
              class="table-box"
              :data="tableData"
              stripe
              style="width: 100% height:100%"
            >
              <el-table-column label="组内信息" align="center">
                <el-table-column
                  prop="potency"
                  label="浓度"
                  align="center"
                >
                  <template slot="header">
                    <div class="render-header">浓度</div>
                  </template>
                </el-table-column>
                <el-table-column label="仪器结果|mg/L" align="center">
                  <template slot="header">
                    <div class="render-header">
                      仪器结果
                      <span>mg/L</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.qc_result }}</strong>
                  </template>
                </el-table-column>
                <el-table-column label="检测时间" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row.time">
                      <p><strong>{{ scope.row.time.split(' ')[0] }}</strong></p>
                      <p><strong>{{ scope.row.time.split(' ')[1] }}</strong></p>
                    </div>
                    <div v-else>-</div>
                  </template>
                </el-table-column>
                <el-table-column label="组内数|个" align="center">
                  <template slot="header">
                    <div class="render-header">
                      组内数
                      <span>个</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.count }}</strong>
                  </template>
                </el-table-column>
                <el-table-column label="组均值|mg/L" align="center">
                  <template slot="header">
                    <div class="render-header">
                      组均值
                      <span>mg/L</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.mean }}</strong>
                  </template>
                </el-table-column>
                <el-table-column
                  label="数据量(N)|个"
                  align="center"
                >
                  <template slot="header">
                    <div class="render-header">
                      数据量(N)
                      <span>个</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.sum }}</strong>
                  </template>
                </el-table-column>
                <el-table-column label="SD|mg/L" align="center" width="70">
                  <template slot="header">
                    <div class="render-header">
                      SD
                      <span>mg/L</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.sd }}</strong>
                  </template>
                </el-table-column>
                <el-table-column label="CV|%" align="center" width="70">
                  <template slot="header">
                    <div class="render-header">
                      CV
                      <span>%</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.cv }}</strong>
                  </template>
                </el-table-column>
                <el-table-column label="偏倚值" align="center" width="90">
                  <template slot="header">
                    <div class="render-header">
                      偏倚值
                      <span>%</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.offset_value ? (scope.row.offset_value * 100).toFixed(2) : '-' }}</strong>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="评价标准" align="center">
<!--                <el-table-column label="靶值" align="center">-->
<!--                  <template slot="header">-->
<!--                    <div class="render-header">-->
<!--                      靶值-->
<!--                      <span>mg/L</span>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                  <template slot-scope="scope">-->
<!--                    <strong>{{ scope.row.target }}</strong>-->
<!--                  </template>-->
<!--                </el-table-column>-->
                <el-table-column
                  label="允许范围"
                  align="center"
                  min-width="120"
                >
                  <template slot="header">
                    <div class="render-header">
                      允许范围
                      <span>mg/L</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong >{{ scope.row.target_range }}</strong>
                  </template>
                </el-table-column>
                <el-table-column label="评价" align="center" width="80">
                  <template slot="header">
                    <div class="render-header">评价</div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.target_rusult }}</strong>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
            <div class="echarts-box">
                <div
                  class="box"
                  v-for="(item, index) of frequency_chart"
                  :key="index"
                >
                  <BetweenEchart :id="'reportBetweenEchart' + index" :ehartData="item" />
                </div>
                <div>
                  <BetweenLineEchart
                    id="reportBetweenEchartLine"
                    :ehartData="BetweenEchartLineData"
                  />
                </div>
            </div>
            <div class="finally-box">
              <p>{{basicData[3].value}}总体结果报告</p>
              <div>
                <div>
                  <p>项目</p>
                  <p>{{basicData[3].value}}</p>
                </div>
                <div>
                  <p>正确数/评价数</p>
                  <p>{{qualified_num_text}}</p>
                </div>
                <div>
                  <p>评价</p>
                  <p>{{qualified_judge_text}}</p>
                </div>
              </div>
            </div>
            <div class="print-info-box">
              <p><span>分析人:</span>{{user_name}}</p>
              <p><span>打印人:</span>{{user_name}}</p>
              <p><span>打印时间:</span>{{now_date}}</p>
              <p><span>质量负责人:</span></p>
            </div>
          </div>
          <div v-if="print_show" :class="'print-box'" id="pdfDom">
            <div class="title-box">
              <p>腾云管家大数据SaaS服务系统</p>
              <p>室内质控室间化评价</p>
              <p>{{report_time_text}}</p>
            </div>
            <detailMenu :dataList="basicData"></detailMenu>
              <el-table
              class="table-box"
              :data="tableData"
              stripe
              style="width: 100% height:100%"
            >
              <el-table-column label="组内信息" align="center">
                <el-table-column
                  prop="potency"
                  label="浓度"
                  align="center"
                >
                  <template slot="header">
                    <div class="render-header">浓度</div>
                  </template>
                </el-table-column>
                <el-table-column label="仪器结果|mg/L" align="center">
                  <template slot="header">
                    <div class="render-header">
                      仪器结果
                      <span>mg/L</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.qc_result }}</strong>
                  </template>
                </el-table-column>
                <el-table-column label="检测时间" align="center">
                  <template slot-scope="scope">
                    <div v-if="scope.row.time">
                      <p><strong>{{ scope.row.time.split(' ')[0] }}</strong></p>
                      <p><strong>{{ scope.row.time.split(' ')[1] }}</strong></p>
                    </div>
                    <div v-else>-</div>
                  </template>
                </el-table-column>
                <el-table-column label="组内数|个" align="center">
                  <template slot="header">
                    <div class="render-header">
                      组内数
                      <span>个</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.count }}</strong>
                  </template>
                </el-table-column>
                <el-table-column label="组均值|mg/L" align="center">
                  <template slot="header">
                    <div class="render-header">
                      组均值
                      <span>mg/L</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.mean }}</strong>
                  </template>
                </el-table-column>
                <el-table-column
                  label="数据量(N)|个"
                  align="center"
                >
                  <template slot="header">
                    <div class="render-header">
                      数据量(N)
                      <span>个</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.sum }}</strong>
                  </template>
                </el-table-column>
                <el-table-column label="SD|mg/L" align="center" width="80">
                  <template slot="header">
                    <div class="render-header">
                      SD
                      <span>mg/L</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.sd }}</strong>
                  </template>
                </el-table-column>
                <el-table-column label="CV|%" align="center" width="80">
                  <template slot="header">
                    <div class="render-header">
                      CV
                      <span>%</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.cv }}</strong>
                  </template>
                </el-table-column>
                <el-table-column label="偏倚值" align="center" width="90">
                  <template slot="header">
                    <div class="render-header">
                      偏倚值
                      <span>%</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.offset_value ? (scope.row.offset_value * 100).toFixed(2) : '-' }}</strong>
                  </template>
                </el-table-column>
              </el-table-column>
              <el-table-column label="评价标准" align="center">
                <el-table-column
                  label="允许范围"
                  align="center"
                  width="120"
                >
                  <template slot="header">
                    <div class="render-header">
                      允许范围
                      <span>mg/L</span>
                    </div>
                  </template>
                  <template slot-scope="scope">
                    <strong >{{ scope.row.target_range }}</strong>
                  </template>
                </el-table-column>
                <el-table-column label="评价" align="center" width="80">
                  <template slot="header">
                    <div class="render-header">评价</div>
                  </template>
                  <template slot-scope="scope">
                    <strong>{{ scope.row.target_rusult }}</strong>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table>
            <div class="echarts-box">
                <div
                  class="box"
                  v-for="(item, index) of frequency_chart"
                  :key="index"
                >
                  <BetweenEchart :id="'reportBetweenEchartPrint' + index" :ehartData="item" />
                </div>
                <div>
                  <BetweenLineEchart
                    id="reportBetweenEchartLinePrint"
                    :ehartData="BetweenEchartLineData"
                  />
                </div>
            </div>
            <div class="finally-box">
              <p>{{basicData[3].value}}总体结果报告</p>
              <div>
                <div>
                  <p>项目</p>
                  <p>{{basicData[3].value}}</p>
                </div>
                <div>
                  <p>正确数/评价数</p>
                  <p>{{qualified_num_text}}</p>
                </div>
                <div>
                  <p>评价</p>
                  <p>{{qualified_judge_text}}</p>
                </div>
              </div>
            </div>
            <div class="print-info-box">
              <p><span>分析人:</span>{{user_name}}</p>
              <p><span>打印人:</span>{{user_name}}</p>
              <p><span>打印时间:</span>{{now_date}}</p>
              <p><span>质量负责人:</span></p>
            </div>
          </div>
        </div>
        <div class="footed">
          <el-button type="primary" class="btn"  @click="download">下载报告</el-button>
          <el-button  class="btn" @click="closeDialog">关闭页面</el-button>
        </div>
      
        <!-- <div class="print-box">
          123
        </div> -->
       
      </div>
    </el-dialog>
  </div>
</template>

<script>
import detailMenu from '../../Indoor/components/detailMenu.vue'
import BetweenEchart from './BetweenEchart'
import BetweenLineEchart from './BetweenLineEchart'
export default {
  components: {
    detailMenu,
    BetweenEchart,
    BetweenLineEchart,
  },
  props: ['tableData','basicData','frequency_chart','BetweenEchartLineData','report_time_text'],
  data(){
    const now_date = new Date().toLocaleDateString('az', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return {
      dialog_visible: false,
      qualified_num_text: '',
      qualified_judge_text: '',
      basic_info_list: [],
      user_name: JSON.parse(localStorage.getItem('userInfo')).user_name,//个人名称
      now_date,
      print_show: false,
      loading: false,
    }
  },
  methods: {
    // 下载报告
    download() {
      this.loading = true
      setTimeout(()=> {
        this.print_show = true;
        this.$nextTick(()=> {
        let title = this.basicData[0].value + `-室内质控室间化评价报告`
        setTimeout(() => {
          this.getPdf(title)
          // this.title_show = false;
        }, 2000);
        setTimeout(()=> {
          this.print_show = false;

          this.loading = false

        }, 4000)
      })
      },1000)
      
    },
    /* 打开弹窗和关闭弹窗 */
    openDialog() {
      let qualified_num = this.tableData.filter(e => e.target_rusult == '合格').length
      let all_num = this.tableData.length
      this.qualified_num_text = `${qualified_num}/${all_num}`
      this.qualified_judge_text = qualified_num === all_num ? '合格' : '不合格'
      
      this.print_show =false;
      this.basic_info_list = this.basicData
      this.dialog_visible = true;
    },
    closeDialog() {
      this.dialog_visible = false;
      
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .dialog-right {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  height: 100vh;
  .el-dialog__header {
    height: 0;
    padding: 1px;
  }
  .el-dialog__body {
    margin: $gap;
    padding: $gap;
    height: 95vh;
  }
}
.report-box {
  .body {
    height: 85vh;
    overflow: auto;
  }
  .footed {
    text-align: center;
    margin-top: $gap;
  }
}

.display-box {
  padding: px(30);
  height: px(1200);
  .title-box {
    p {
      text-align: center;
    }
    p:nth-child(1) {
      font-size: px(22);
      font-weight: bold;
    }
    p:nth-child(2) {
      font-size: px(20);
      font-weight: bold;
    }
    p:nth-child(3) {
      font-size: px(18);
    }
  }
  .table-box {
    margin-top: px(20);
    .render-header {
      position: relative;
      height: px(45);
      font-size: px(18);

      & > span:last-child {
        font-size: px(16);
        position: absolute;
        white-space: nowrap;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    ::v-deep .cell {
      line-height: px(24);
    }
  }
  .echarts-box {
    margin-top: px(20);
    display: flex;
    &>div {
      flex: 1;
    }
  }
  .finally-box {
    &>p {
      font-size: px(20);
      color: #000;
      font-weight: bold;
      text-align: center;
      padding-bottom: px(10);
      border-bottom: px(2) solid #000;
    }
    &>div {
      display: flex;
      &>div {
        flex: 1;
        &>p:first-child {
          text-align: center;
          color: #000;
          font-weight: bold;
        }
        &>p:last-child {
          margin-top: px(10);
          text-align: center;
          
        }
      }
      &>div:first-child {
        flex: 2;
        &>p:last-child {
          margin-top: px(10);
          text-align: left;
          
        }
      }
    }
  }
  .print-info-box {
    display: flex;
    padding-top: $gap/2;
    margin-top: px(50);
    margin-bottom: 2*$gap;
    border-top: $border-color solid 1px;
    justify-content: space-between;
    p:last-child {
      width: 30%;
    } 
    p>span{
      margin-right: $gap;
    }
  }
}
.print-box {
  width: 1006px;
  height: 1430px;
  padding: 30px;
  position: relative;
  .title-box {
    p {
      text-align: center;
    }
    p:nth-child(1) {
      font-size: 22px;
      font-weight: bold;
    }
    p:nth-child(2) {
      font-size: 20px;
      font-weight: bold;
    }
    p:nth-child(3) {
      font-size: 18px;
    }
  }
  .table-box {
    margin-top: 20px;
    .render-header {
      position: relative;
      height: 40px;
      font-size: 14px;
      
      & > span:last-child {
        font-size: 12px;
        position: absolute;
        white-space: nowrap;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
    ::v-deep .cell {
      line-height: 18px;
      font-size: 14px;
    }
  }
  .echarts-box {
    margin-top: px(20);
    display: flex;
    &>div {
      flex: 1;
    }
  }
  .finally-box {
    &>p {
      font-size: 18px;
      color: #000;
      font-weight: bold;
      text-align: center;
      padding-bottom: 10px;
      border-bottom: 2px solid #000;
    }
    &>div {
      display: flex;
      &>div {
        flex: 1;
        &>p:first-child {
          text-align: center;
          color: #000;
          font-weight: bold;
        }
        &>p:last-child {
          margin-top: 10px;
          text-align: center;
          
        }
      }
      &>div:first-child {
        flex: 2;
        &>p:last-child {
          text-align: left;
          
        }
      }
    }
  }
  .print-info-box {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    border-top: $border-color solid 1px;
    justify-content: space-between;
    p:last-child {
      width: 30%;
    } 
    p>span{
      margin-right: $gap;
    }
  }
}

</style>