<template>
  <div class="innerEchartLine">
    <div :id="id" class="echarts" >  
    </div>
    <div class="tip-noData" v-show="ehartData.dataX.length === 0">
      暂无数据
    </div>
  </div>
</template>
<script>
import echartsMixins from "@/assets/js/echartsMixins.js"

export default {
  // props: ['id', 'ehartData','calibration_data_list'],
  props:{
    id: {
      type: String,
      required: true,
    },
    ehartData: {
      type: Object,
      required: true,
    },
    calibration_data_list: {
      type: Array,
      required: false,
      default: function () {
        return []
      }
    }
  },
  mixins: [echartsMixins],

  data() {
    return {
      myChart: '',
      tipTop: 0,
      is_tipDel: false,
      tipLeft: 0,
      delData: {
        realValue: '',
        cl: '',
        batch: '',
      },
      color: [
        '#999999',
        '#F75151',
        '#FFC200',
        '#19CB63',
        '#0073CD',
        '#19CB63',
        '#FFC200',
        '#F75151',
        '#999999',
      ],
      dataY2: [
        '0SD',
      ],
      widthEchart: '100%',
      minNum: 0,
      maxNum: 0,
      markLineData: [],
    }
  },
  watch: {
    ehartData: {
      handler(newVal, oldVal) {
        this.initEcharts()
      },
      deep: true,
    },
  },
  created() {
  },
  mounted() {
    this.handle_data();
    this.myChart = this.$echarts.init(document.getElementById(this.id))
    this.initEcharts()
  },

  methods: {
     // 刷新组件
     handlerResize(){
      this.myChart.resize()
    },
    /* 数据量过少时处理 */
    handle_data() {
      // let length = this.ehartData?.dataX.length
      // if(length>0){
      //   this.ehartData.dataX = ['',...this.ehartData.dataX,'']
      //   this.ehartData.series.forEach(element => {
      //     element.data = [{value: null},...element.data,{value: null}]
      //   });
      // }
     
      // let need_length = 3

      // let length = this.ehartData?.dataX.length
      // if(length>1&&length<need_length){
      //   let length2 = need_length - length
      //   let arr1 = new Array(length2).fill('')
      //   let arr2 = new Array(length2).fill({value: null})
      //   this.ehartData.dataX = [...arr1,...this.ehartData.dataX,...arr1]
      //   this.ehartData.series.forEach(element => {
      //     element.data = [...arr2,...element.data,...arr2]
      //   });
      // }
      // if(length>=need_length){
      //   this.ehartData.dataX = ['',...this.ehartData.dataX,'']
      //   this.ehartData.series.forEach(element => {
      //     element.data = [{value: null},...element.data,{value: null}]
      //   });
      // }
      // 更换试剂或重新定标时，质控图增加标记
      let origArr = []
      if(this.ehartData.series.length > 0){
        origArr = this.ehartData.series[0].data ? this.ehartData.series[0].data : []
      }
      if (origArr.length === 0) {
        // 空数组，没有变化点
        return [];
      }
      const changeIndexes = [];
      let previousBatch = origArr[0].batch; // 初始化前一个batch值为数组第一个元素的batch值
      for (let i = 1; i < origArr.length; i++) {
        if (origArr[i].batch && previousBatch && (origArr[i].batch !== previousBatch)) {
          // 找到batch值变化的index
          changeIndexes.push(i);
          previousBatch = origArr[i].batch; // 更新前一个batch值
        }
      }
      // 返回变化的索引的数组 changeIndexes
      const dataXTemp =this.ehartData.dataX  // 原始横坐标数组

      let calibration_data_listTemp = []
      if(this.calibration_data_list
        &&this.calibration_data_list.length>0){
        this.calibration_data_list.forEach(item =>{
          calibration_data_listTemp.push((item.replace(/-0(\d)-/g, '-$1-').replace(/-0(\d)$/, '-$1')) + '.1')
        })
      }
      // 用于存储变化的结果的数组
      const result = [];
      // 遍历索引数组，并根据索引从原始数组中取变化的横坐标值
      changeIndexes.forEach(index => {
        // 使用数组的索引访问方法取出对应的值，并添加到结果数组中
        result.push(dataXTemp[index]);
      });
      // 遍历 xAxisValues 数组，为 markLineData 创建对象并赋值
      result.forEach(value => {
        const defaultTooltip = {
          formatter: function (param) {
            let htmlTemplate = `<div style='background:#fff;width:156px;padding:5px 15px;'>

              <p style='color:#666666;font-size: 24upx;'>时间：<strong style='color:#000000;font-size: 24upx;'>${
                param.value ? param.value : '-'
            }</strong></p>
              <p style='color:#666666;font-size: 24upx;'>动作：<strong style='color:#000000;font-size: 24upx;'>更换试剂批号</strong></p>
          </div>
              `
            return htmlTemplate
          },
        };
        this.markLineData.push({
          xAxis: value,
          tooltip: defaultTooltip,
          lineStyle: {
            color: 'red' // 第一条标记线的颜色
          }
        });
      });
      calibration_data_listTemp.forEach(value => {
        const calibrateTooltip = {
          formatter: function (param) {
            let htmlTemplate = `<div style='background:#fff;width:156px;padding:5px 15px;'>

              <p style='color:#666666;font-size: 24upx;'>时间：<strong style='color:#000000;font-size: 24upx;'>${
                param.value ? param.value : '-'
            }</strong></p>
              <p style='color:#666666;font-size: 24upx;'>动作：<strong style='color:#000000;font-size: 24upx;'>重新定标</strong></p>
          </div>
              `
            return htmlTemplate
          },
        };
        this.markLineData.push({
          xAxis: value,
          tooltip: calibrateTooltip,
          lineStyle: {
            color: 'green' // 第一条标记线的颜色
          }
        });
      });
      // 输出修改后的 markLineData 数组
    },
    /* 配置文件 */
    initEcharts() {
      var _this = this
      let markLineData = [];
      var option = {
        legend: {
          show: true,
          type: 'scroll',
          top: '1%',
          right: '1%',
          itemWidth:50,
          itemGap: 20, // 间隔距离
          data: this.ehartData.name,
        },
        // dataZoom : [
				// 		{
				// 			type: 'inside',
				// 			show: true,
				// 			start: 0,
				// 			end: 100,
				// 			xAxisIndex: [0],
				// 		},
				// 	],
        tooltip: {
          trigger: 'item',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'line', // 默认为直线，可选为：'line' | 'shadow'
          },
          padding: [0, 0, 0, 0],
          backgroundColor: 'rgba(255,255,255)',
          // borderRadius: 0,
          extraCssText: 'box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);',
          formatter: function (param) {
            let htmlTemplate = `<div style='background:#fff;width:156px;padding:5px 15px;'>
            
                <p style='color:#666666;font-size: 24upx;'>时间：<strong style='color:#000000;font-size: 24upx;'>${
                  param.name ? param.name : '-'
                }</strong></p>
                <p style='color:#666666;font-size: 24upx;'>试剂批号：<strong style='color:#000000;font-size: 24upx;'>${
                  param.data.batch ? param.data.batch : '-'
                }</strong></p>
                <p style='color:#666666;font-size: 24upx;'>结果：<strong style='color:#000000;font-size: 24upx;'>${
                  param.data.realValue ? param.data.realValue : '-'
                }</strong> </p>
                <span style='color:#666666;font-size: 24upx;'>评估：<strong style='color:#000000;font-size: 24upx;'>${
                  param.data.cl == true
                  ? '失控'
                  :  param.data.cl == false
                  ? '告警'
                  : '在控'
                }</strong></span><br/>
                <span style='color:#666666;font-size: 24upx;'>违背规则：<strong style='color:#000000;font-size: 24upx;white-space:normal'>${
                  param.data.rule ? param.data.rule : '-'
                }</strong></span>
              </div>
              `
            return htmlTemplate
          },
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '2%',
          top: '10%',
          containLabel: true,
        },
        xAxis: [
          {

            data: this.ehartData.dataX,
            boundaryGap: false,
            // 控制网格线是否显示
            splitLine: {
              show: false,
            },
            //  改变x轴颜色
            axisLine: {
              show: false,
              lineStyle: {
                width: 2,
                color: '#D7D7D7',
              },
            },
            axisTick: { show: false },
            //  改变x轴字体颜色和大小
            axisLabel: {
              margin: 15,
              textStyle: {
                color: '#9AA1A9',
                fontSize: '14',
              },
            },
          },
        ],
        yAxis: [
          // 左边轴
          {
            min: -4,
            max: 4,
            interval: 1,
            type: 'value',
            axisTick: { show: false },
            axisLine: { show: false },
            axisLabel: {
              textStyle: {
                color: function (param, index) {
                  return _this.color[index]
                },
              },
              formatter: function (value, index) {
                return value>0?
                  `+${value}SD`:value==0?
                  0:`${value}SD`
              },
            },
            splitLine: {
              show: false,
              
              lineStyle: {
                type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
                color: this.color,
              },
            },
          },
          // 右边轴
          {
            scale: 'true',
            axisLine: { show: false },
            axisTick: { show: false },
            // 控制网格线是否显示
            splitLine: {
              show: false,
              // lineStyle: {
              //   type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
              //   color: this.color,
              // },
            },
            //  改变y轴字体颜色和大小
            axisLabel: {
              textStyle: {
                color: '#9AA1A9FF',
                fontSize: '14',
              },
              formatter: '{value}',
            },
          },
        ],
        series: [
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
               width: 0.5,
              type: 'solid',
              color: '#999999',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(-4),
            markLine: {
              data: this.markLineData,
              symbol: ['none', 'none'], // 去掉标记的箭头
              label: {
                show: false // 不显示标签
              },
              lineStyle: {
                type: 'dashed', // 设置线的样式为虚线
                color: 'red' // 线的颜色
              }
            },
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
               width: 0.5,
              type: [8,8],
              color: '#F75151',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(-3),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
               width: 0.5,
              type: [5,5,2,5],
              color: '#FFC200',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(-2),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
               width: 0.5,
              type: 'dashed',
              color: '#19CB63',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(-1),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
               width: 0.5,
              type: 'solid',
              color: '#0073CD',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(0),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
               width: 0.5,
              type: 'dashed',
              color: '#19CB63',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(1),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
               width: 0.5,
              type: [5,5,2,5],
              color: '#FFC200',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(2),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
               width: 0.5,
              type: [8,8],
              color: '#F75151',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(3),
          },
          {
            type: 'line',
            symbolSize: 0,
            lineStyle: {
               width: 0.5,
              type: 'solid',
              color: '#999999',
            },
            connectNulls: true,
            data: new Array(this.ehartData.dataX.length).fill(4),
          },
          ...this.ehartData.series,
        ],
      }
      this.myChart.setOption(option)
    },
  },
}
</script>
<style lang="scss" scoped>
.echarts {
  width: 99%;
  height: px(420);
  //height: 300px;

}
.innerEchartLine {
  position: relative;
}
.tip-noData {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  size: $text-size;
} 
</style>