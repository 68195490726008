
<template>
  <div  ref="orderDtl" @scroll="handleScroll"  style="height:100%;overflow: auto">
    <!--  商务端审批可修改订单信息-->
      <div class="orderDtl" v-loading="loading" element-loading-text="数据加载中">
        <div class="orderInfo">
          <el-row>
            <el-row :span="24">
              <el-col :span="5">
                <div class="block">
                  <p>订单号</p>
                  <div>{{form.order_code}}</div>
                </div>
              </el-col>
              <el-col :span="5">
                <div class="block">
                <p>下单时间</p>
                <div>{{form.create_time}}</div>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="block">
                <p>客户名称</p>
                <div>{{form.customer_name}}</div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="block">
                <p>订单状态</p>
                  <div style="font-weight: bold" :style="{'color': form.order_status === 9005 ? '#000' : '#4F4F4F'}">{{form.order_status_name}}</div>
                </div>
              </el-col>
            </el-row>

          </el-row>
          <el-row>
            <el-col :span="24" >
              <div class="block" style="border-right: none">
                <p>商务备注
                  <i class="el-icon-edit" :style="{color: isBusinessRemarkEdit ? '#409EFF' : '','margin-left':'10px', 'margin-right':'14px'}" @click="isBusinessRemarkEdit = true"></i>
                  <i class="el-icon-check" @click="handleUpdateBusiRemark"></i>
                </p>
                <div style="display: inline-block;width: 100%">
                  <el-form  ref="receive_info"  :model="businessForm">
                      <el-form-item style="margin-bottom: 0!important;">
                        <el-input size="mini" v-if="isBusinessRemarkEdit" placeholder="请填写商务备注" v-model="businessForm.business_remark"></el-input>
                        <span v-else>{{businessForm.business_remark ? businessForm.business_remark : '/'}}&nbsp;</span>
                      </el-form-item>
                  </el-form>
                </div>
              </div>
            </el-col>
            <el-col :span="24" >
              <div class="block" style="border-right: none">
                <p>开票信息</p>
                <div style="display: inline-block;width: 100%">
                  <span>{{form.invoice_info.invoice_header}}&nbsp;/&nbsp;{{form.invoice_info.registration_code}}
                    &nbsp;/&nbsp;{{form.invoice_info.contact_person}}&nbsp;/&nbsp;{{form.invoice_info.contact_email}}</span>
                </div>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="block" style="border-right: none">
              <p>{{form.delivery_type== 4001 ? '提货人信息' : '收货人信息' }}</p>
                <div><span style="margin-right: 20px;">{{ form.receive_address.customer_contact }}</span>{{form.receive_address.customer_phone}}</div>
                <div>{{form.receive_address.customer_address}}</div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="goodsList">
          <p class="block_title component_inner_title">商品清单</p>
          <el-table
              ref="goodsTable"
              :data="tradeTableData"
              max-height="450px"
              :scroll-y="true"
              style="width: 100%">
            <el-table-column label="#" type="index" width="50"  align="center" header-align="center"></el-table-column>
            <el-table-column prop="full_name" label="商品全称" min-width="280"  align="left" header-align="center">
              <template v-slot="{ row, $index }">
                <span :style="{color: row.is_first ? '#BD3124' : ''}" v-if="!row.isEdit">{{row.inventory_full_name ? row.inventory_full_name : '/'}}</span>
<!--                <select-product v-if="row.isEdit" :clo_index="$index" @chooseGoods="handleFullNameChange" />-->
<!--                <select-goods v-if="row.isEdit" :clo_index="$index" @selectFocus="handleSelectFocus" :existIdList="existIdList" :goodsListTemp="goodsListTemp" @chooseGoods="handleFullNameChange" />-->
                <el-select
                    v-if="row.isEdit"
                    ref="selects"
                    @change="handleFullNameChange"
                    class="check_select"
                    @focus="handleSelectFocus($index)"
                    v-model="row.inventory"
                    placeholder="请输入关键字"
                    loading-text="正在从服务端获取数据"
                    size="small"
                    filterable
                    clearable
                    remote
                    :popper-append-to-body='false'
                    :remote-method="getGoodsList"
                    @visible-change="handleVisibleChange($index, $event)"
                >
                  <el-option
                      v-if="isOptionShow"
                      v-for="item in goodsList"
                      :key="item.value"
                      :label="item.label"
                      :disabled="item.disabled"
                      :value="item">
                  </el-option>
                </el-select>
                <el-tag size="mini" effect="dark" style="background: #BD3124;border: none" v-if="row.is_first" class="new_pro_tag">本次新购商品</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="inventory_name" label="商品简称" width="130"  align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.inventory_name ? row.inventory_name : '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="standard" label="规格/型号" width="130"  align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.standard ? row.standard : '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="unit_price"  label="单价" width="90"  align="center" header-align="center">
              <el-table-column  align="center" header-align="center"
                  class-name="children_column"
                  prop="unit_price"
                  label="(单位：元)"
                  width="90">
                <template v-slot="{ row, $index }">
                  <span>{{row.unit_price ? row.unit_price : '0.00'}}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="orders_num" label="数量" width="200"  align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <el-input-number style="width: 130px" v-model="row.orders_num" size="mini"  controls-position="right" @change="handleCountChange(row)" :min="1"  label="描述文字"></el-input-number>
                <span style="margin-left: 8px">{{row.sale_unit}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="orders_sum" label="总价"  width="160"  align="center" header-align="center">
              <el-table-column align="center"
                               class-name="children_column"
                               prop="orders_sum"
                               label="(单位：元)"
                               width="160">
                <template  v-slot="{ row, $index }">
                  <el-input class="total"  v-model="row.orders_sum" size="mini" style="width: 60px"  @blur="validateInput(row,$index)"  @change="handleTotalChange(row)"></el-input>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="oprate" label="操作" width="120" align="center" fixed="right">
              <template v-slot="{ row, $index }">
                <span  v-if="tradeTableData.length == 1 && !row.isEdit">-</span>
                <el-button v-if="row.isEdit" type="text" size="mini" class="el-icon-circle-check" style="color:#81B337;font-size: 22px" @click="handleSave(row)"></el-button>
                <el-button v-if="tradeTableData.length > 1" type="text" size="mini"  class="el-icon-circle-close delete" style="color: #bebebe96; font-size: 22px" @click="handleDelete($index)"></el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="table_bottom" >
            <el-button size="mini" style="background: #fff; color:#E99D42;border:1px solid #E99D42;"   icon="el-icon-plus" @click="addGoods">添加商品</el-button>
            <p style="display: inline-block; float: right">应付总额：<span style="color: #BD3124 ">{{totalAmount}}元</span></p>
          </div>
        </div>
        <div class="remark" style="margin-bottom: 20px">
          <p class="block_title component_inner_title" style="color: #377F7F">备注说明</p>
          <p v-if="form.order_remark" class="remark_text">{{form.order_remark}}</p>
          <p v-else class="font_resize" style="text-align: center;display: inline-block;width: 100%;margin-top: 10px;color: #8888;">暂无数据</p>
        </div>
        <div v-if="form.payment_voucher">
          <p class="block_title component_inner_title">付款信息</p>
          <div>
            <el-image
                style="width: 100px; height: 100px"
                :src="form.payment_voucher"
                :preview-src-list="srcList">
              <div slot="placeholder" class="image-slot">
                加载中<span class="dot">...</span>
              </div>
            </el-image>
          </div>
        </div>
        <div class="shipping_remarks">
          <p class="block_title" >发货备注</p>
          <div>
            <el-input type="textarea"  rows="4" v-model="check_remark" show-word-limit   maxlength="200" placeholder="发货备注，非必填"></el-input>
          </div>
        </div>
        <div slot="footer" class="drawer-footer">
          <el-button type="primary" :disabled="isApproveDisable" class="pass" @click="handleApproved(9002)">审核通过</el-button>
          <el-button  class="reject" :disabled="isApproveDisable" @click="handleApproved(9006)">驳回订单</el-button>
          <el-button  class="reject" :disabled="isApproveDisable" @click="handleApproved(1)" >撤销订单</el-button>
        </div>
      </div>
    <el-dialog title="" :visible.sync="revokeDialogVisible"  :append-to-body="true"  center>
      <el-input type="textarea" rows="5"  maxlength="200"
                show-word-limit v-model="undo_remark" placeholder="请输入撤销备注"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button @click="revokeDialogVisible = false" size="mini" class="dialog_close" >关闭</el-button>
        <el-button  type="primary" size="mini" style="background: #347CAF;padding-left:40px;padding-right:40px" @click="handleRevokeSave">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog
        title="选择商品"
        :visible.sync="selectDialogVisible"
        v-if="goodsListTemp.length > 1 || this.searchText != ''"
        width="50%"
        :modal="false"
        :show-close="false"
        :before-close="handleClose">
      <template #title>
        <span class="dialog_title">选择商品</span>
        <div style="display: inline-block;float: right;width: 280px" class="dialog_search_input">
          <el-input v-model="searchText" placeholder="请输入商品名称"   style="width: 200px;margin-right: 20px" size="mini"></el-input>
          <i slot="append" class="dialog_search search_icon" @click="handleFilterGoods(searchText)"></i>
        </div>
      </template>
      <div class="select_dialog">
        <el-table :data="goodsList" @selection-change="handleSelectionChange" stripe ref="relateTable"  border v-loading="loading" element-loading-text="加载中..."  max-height="450px" :scroll-y="true">
          <el-table-column  type="selection"  :selectable="selectableStatus"  width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="inventory_full_name" label="全称" min-width="150" align="left" header-align="center">
            <template v-slot="{ row, $index }">
              <span >{{row.label}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="inventory_name" label="简称" min-width="150" align="left" header-align="center">
            <template v-slot="{ row, $index }">
              <span>{{row.inventory_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="standard" label="规格/型号" min-width="120" align="left" header-align="center">
            <template v-slot="{ row, $index }">
              <span>{{row.standard}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer"  class="dialog-footer" style="text-align: center">
          <el-button style="margin-right: 14px" @click="handleCancelSelectGoods" size="mini">取 消</el-button>
          <el-button type="primary" @click="handleSelected" size="mini" style="background: #4095E5" >确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import common from "@/views/OrderManagement/mixin/common";
import {
  checkOrderDetail,
  checkOrderDtlList,
  checkOrderList, deleteOrderDtlList, getInventoryList,
  getInventoryPrice,
  saveOrderDtlList, updateCartNum
} from "@/api/orderGoods/custom";
import {checkOrder, editNote} from "@/api/orderGoods/business";
import selectProduct from "@/views/OrderManagement/business/components/selectProduct.vue";
import SelectGoods from "@/views/OrderManagement/business/components/selectGoods.vue";
export default {
  components: {SelectGoods, selectProduct},
  mixins: [common],
  props:{
    orderId:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      totalAmount: 0,
      isApproveDisable: false,
      loading: false,
      srcList: [],
      payment_voucher: '',
      check_remark: null, // 审核信息
      undo_remark: null,  // 撤销备注
      approveContent: '',
      isDrawerShow: false,
      dialogTableVisible: false,
      revokeDialogVisible: false,
      existIdList: [],
      goodsListTemp: [],
      goodsList: [],
      selectDialogVisible: false,
      searchText:'',
      form:{
        invoice_title:'',
        invoice_no: '',
        invoice_name: '',
        invoice_mail: '',
      },
      businessForm:{
        business_remark: '',
      },

      isBusinessRemarkEdit: false,
    }
  },
  computed: {
    // totalAmount(){
    //   let total = 0
    //   if(this.tradeTableData && this.tradeTableData.length > 0){
    //     this.tradeTableData.forEach(item => {
    //       total += Number(item.orders_sum)
    //     })
    //   }
    //   return total.toFixed(2)
    // }
  },
  watch:{
    orderId(newVal,oldVal){
      // this.getDetail()
    }
  },
  created() {
    this.getDetail()

  },
  mounted() {
    this.$refs.orderDtl.addEventListener('scroll', this.handleScroll);
    // this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper').addEventListener('scroll', this.handleScroll2);

  },
  beforeDestroy() {
    this.$refs.orderDtl.removeEventListener('scroll', this.handleScroll);
    this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper').removeEventListener('scroll', this.handleScroll2);
  },
  methods: {
    handleScroll(){
      this.isOptionShow = false
      if(this.currentIndex){
        this.goodsList = []
      }
    },
    handleScroll2(){
      this.isOptionShow = false
      if(this.currentIndex){
        this.goodsList = []
        this.tradeTableData[this.currentIndex].inventory = {}
      }
    },
    handleUpdateBusiRemark(){
      let params ={
        order_id: this.orderId,
        business_remark: this.businessForm.business_remark,
      }
      editNote(params).then(res=>{
        this.isBusinessRemarkEdit = false
        this.$message.success('商务备注保存成功！')
      })
    },
    validateInput(row,index) { //校验数量 输入框
      const intValue = Math.floor(row.orders_sum);
      if (isNaN(intValue) || intValue <= 0) {
        this.$set(this.tradeTableData[index], 'orders_sum', 0.00)
      } else {
        this.$set(this.tradeTableData[index], 'orders_sum', Number(intValue).toFixed(2))
      }
      this.$forceUpdate()
    },
    selectableStatus(row){
      return !row.disabled;
    },
    handleFilterGoods(val){ //弹窗里搜索
      if(!val){
        this.goodsList = this.goodsListTemp
      }
      this.goodsList = this.goodsListTemp.filter(item =>{
        return item.inventory_name.includes(val) || item.inventory_full_name.includes(val);
      })
    },
    handleFullNameChange(data){
      this.tradeTableData[this.currentIndex].inventory_ids = data.value
      this.tradeTableData[this.currentIndex].inventory_id = data.value
      this.tradeTableData[this.currentIndex].inventory_name = data.inventory_name
      this.tradeTableData[this.currentIndex].standard = data.standard
      this.tradeTableData[this.currentIndex].inventory_full_name = data.label
      this.tradeTableData[this.currentIndex].sale_unit = data.sale_unit
      this.existIdList = this.tradeTableData.map(item=> item.inventory_id)
      this.goodsList.forEach(item => {
        if(this.existIdList.includes(item.value)){
          item.disabled = true
        }
      })
      let params = {
        inventory_ids: data.value.toString(),
        order_id: this.orderId.toString(),
        organization_id: this.form.receive_address.customer_id
      }
      getInventoryPrice(params).then(res=>{ // 获取商品价格
        let resData = res.data[0]
        this.tradeTableData[this.currentIndex].isEdit = false
        this.tradeTableData[this.currentIndex].unit_price = Number(resData.price).toFixed(2) || 0.00
        this.tradeTableData[this.currentIndex].is_first = resData.is_first
        this.tradeTableData[this.currentIndex].orders_num = resData.orders_num
        this.tradeTableData[this.currentIndex].orders_sum = (Number(resData.price) *  Number(this.tradeTableData[this.currentIndex].orders_num)).toFixed(2)
        let totalAmountTemp = this.tradeTableData.map(item => item.orders_sum)
        let totalAmount = totalAmountTemp.reduce((sum, num) => {
          const value = (!isNaN(num) && num !== undefined && num !== null && num.trim() !== '') ? parseFloat(num) : 0;
          return sum + Number(value);
        }, 0);
        this.totalAmount = totalAmount.toFixed(2)
      })
      this.existIdList = this.tradeTableData.filter(item => !item.isAdd).map(item => item.inventory_id)
      this.$forceUpdate()
    },
    handleSelectFocus(index){
      this.currentIndex = index
      this.$emit('selectFocus')
      this.$nextTick(() => {
        // 获取表格的 DOM 元素
        const tableWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper');
        window.requestAnimationFrame(() => {
          tableWrapper.scrollLeft = 0;
        });
      });
    },
    handleVisibleChange(rowIndex,isVisible) {
      // 如果下拉框关闭且没有选项，则不显示下拉框
      if ( this.goodsList.length === 0 || this.goodsList.length > 1) {
        this.tradeTableData[this.currentIndex].inventory = {}
      }
    },
    handleSelectionChange(val){
      if(val.length > 0){
        this.selectedList = val
        this.selectedList.forEach(item => {
          item.inventory_ids = item.value
          item.inventory_id = item.value
          item.isAdd = true
          if(item.price){
            item.unit_price = Number(item.price).toFixed(2)
          }else{
            item.unit_price = 0.00
          }
          if(item.tax_price){
            item.tax_price = Number(item.tax_price).toFixed(2)
          }else{
            item.tax_price = 0.00
          }
        })
      }
    },
    getGoodsList(e) {
      getInventoryList({name: e}).then(res => {
        let originalArray = res.data.data
        this.existIdList = this.tradeTableData.map(item=> item.inventory_id)
        const idArray = this.existIdList
        let updatedArray = originalArray.map((item,index) => {
          if (idArray.includes(item.inventory_id)) {
            return  { ...item, disabled: true };
          }
          return item;
        });
        this.goodsList = updatedArray.map(item => {
          return {
            label: item.inventory_full_name,
            value: item.inventory_id,
            inventory_name: item.inventory_name,
            inventory_full_name: item.inventory_full_name,
            standard: item.standard,
            sale_unit: item.sale_unit,
            disabled: item.disabled,
            hideDropdown: true
          }
        })
        this.goodsListTemp = JSON.parse(JSON.stringify(this.goodsList))
        if(this.goodsList.length > 0){
          if(this.goodsList.length === 1){
            this.isOptionShow = true
          }else{
            this.selectDialogVisible = true
            this.isOptionShow = false
            this.tradeTableData[this.currentIndex].inventory = {}
          }
        }else{
          this.$message.error('暂未搜索到匹配产品')
        }
        // let newIds = this.goodsList.map(item => item.value)
        // if(this.goodsListTemp && this.goodsListTemp.length > 0){
        //   if(newIds && !newIds.includes(this.goodsListTemp[0].value)){
        //     this.goodsList = this.goodsList.concat(this.goodsListTemp)
        //   }
        // }
      })
    },
    getDetail(){
      this.loading = true
      checkOrderDetail({order_id: this.orderId}).then(res=>{
        this.form = res.data
        this.expressList = res.data.express_information
        this.srcList = [res.data.payment_voucher]
        this.businessForm.business_remark = this.form.business_remark
      })
      checkOrderDtlList({order_id: this.orderId}).then(res=>{
        this.tradeTableData = res.data
        if(this.tradeTableData && this.tradeTableData.length > 0){
          let totalAmountTemp = this.tradeTableData.map(item => item.orders_sum)
          let totalAmount = totalAmountTemp.reduce((sum, num) => {
            const value = (!isNaN(num) && num !== undefined && num !== null && num.trim() !== '') ? parseFloat(num) : 0;
            return sum + Number(value);
          }, 0);
          this.totalAmount = totalAmount.toFixed(2)
        }
        this.existIdList = this.tradeTableData.filter(item => !item.isAdd).map(item => item.inventory_id)
        this.$nextTick(() => {
          const tableBodyWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper')
          if (tableBodyWrapper){
            tableBodyWrapper.addEventListener('scroll', this.handleScroll2);
          }
        })
        this.loading = false
      })
    },
    handleSelected(){
      let rowData = this.selectedList
      if(! this.selectedList || this.selectedList.length == 0){
        this.selectDialogVisible = true
        this.$message.error('请选择商品')
        return
      }
      if(rowData.length ==1){
        this.tradeTableData[this.currentIndex].inventory_id = rowData[0].value
        this.tradeTableData[this.currentIndex].inventory_name = rowData[0].inventory_name
        this.tradeTableData[this.currentIndex].standard = rowData[0].standard
        this.tradeTableData[this.currentIndex].inventory_full_name = rowData[0].label
        this.tradeTableData[this.currentIndex].sale_unit = rowData[0].sale_unit
        this.tradeTableData[this.currentIndex].isEdit = false
        let params = {
          inventory_ids: rowData[0].value,
          order_id: this.orderId.toString(),
          organization_id: this.form.receive_address.customer_id
        }
        getInventoryPrice(params).then(res=>{
          let refreshArray = res.data
          if(refreshArray && refreshArray.length >0){
            this.tradeTableData[this.currentIndex].unit_price = Number(res.data[0].price).toFixed(2)
            this.tradeTableData[this.currentIndex].isEdit = false
            this.tradeTableData[this.currentIndex].is_first = res.data[0].is_first
            this.tradeTableData[this.currentIndex].orders_num = res.data[0].orders_num
            this.tradeTableData[this.currentIndex].orders_sum = (Number(res.data[0].price) *  Number(this.tradeTableData[this.currentIndex].orders_num)).toFixed(2)
          }
          let totalAmountTemp = this.tradeTableData.map(item => item.orders_sum)
          let totalAmount = totalAmountTemp.reduce((sum, num) => {
            const value = (!isNaN(num) && num !== undefined && num !== null && num.trim() !== '') ? parseFloat(num) : 0;
            return sum + Number(value);
          }, 0);
          this.totalAmount = totalAmount.toFixed(2)
        })
      }else{
        this.tradeTableData.splice(this.currentIndex, 1)
        this.tradeTableData = this.tradeTableData.concat(rowData)
        let inventory_ids = rowData.map(item => item.value)
        let params = {
          inventory_ids: inventory_ids.toString(),
          order_id: this.orderId.toString(),
          organization_id: this.form.receive_address.customer_id
        }
        getInventoryPrice(params).then(res=>{ // 获取商品价格
          let refreshArray = res.data
          if(refreshArray && refreshArray.length >0){
            this.tradeTableData.forEach(item1 => {
              refreshArray.forEach(item2 =>{
                if(item2.inventory_id === item1.inventory_id){
                  item1.unit_price = item2.price
                  item1.is_first = item2.is_first
                  item1.orders_num = item2.orders_num
                  item1.orders_sum = item2.orders_sum
                  item1.tax_price = item2.tax_price
                  item1.tax_rate = item2.tax_rate
                  item1.orders_sum = (Number(item1.orders_num )* Number(item1.unit_price)).toFixed(2)
                }
              })

            })
            let totalAmountTemp = this.tradeTableData.map(item => item.orders_sum)
            let totalAmount = totalAmountTemp.reduce((sum, num) => {
              const value = (!isNaN(num) && num !== undefined && num !== null && num.trim() !== '') ? parseFloat(num) : 0;
              return sum + Number(value);
            }, 0);
            this.totalAmount = totalAmount.toFixed(2)
          }
        })

      }

      this.selectDialogVisible = false
      this.selectedList = []
      this.searchText = ''
    },
    handleCancelSelectGoods(){
      this.selectDialogVisible = false
      this.searchText = ''
      this.selectedList = []
    },
    addGoods(){
      this.$nextTick(() => { //滚动到表格底部，看到新增行
        const tableWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper');
        window.requestAnimationFrame(() => {
          tableWrapper.scrollTop = tableWrapper.scrollHeight;
        });
      });
      this.tradeTableData.push({
        inventory_full_name: '',
        inventory_name: '',
        standard: '',
        unit_price: 0.00,
        orders_num: 0,
        orders_sum: '0.00',
        isEdit: true,
        isAdd: true
      })
    },
    handleCountChange(row){
      row.orders_sum = (Number( row.unit_price) * row.orders_num).toFixed(2)
      let totalAmountTemp = this.tradeTableData.map(item => item.orders_sum)
      let totalAmount = totalAmountTemp.reduce((sum, num) => {
        const value = (!isNaN(num) && num !== undefined && num !== null && num.trim() !== '') ? parseFloat(num) : 0;
        return sum + Number(value);
      }, 0);
      this.totalAmount = totalAmount.toFixed(2)
    },
    handleTotalChange(row){
      if(Number( row.orders_sum) > 0){
        row.unit_price= (Number( row.orders_sum) / row.orders_num).toFixed(2)
      }else{
        row.unit_price= 0.00
      }

      row.orders_sum= (Number( row.orders_sum)).toFixed(2)
    },
    handleSave(row){
      if(!row.inventory_id){
        return this.$message.error('商品全称不能为空！')
      }else{
        if(this.existIdList.includes(row.inventory_ids || row.inventory_id)){
          this.$message.error('保存失败，请勿重复添加商品！')
        }else{
          row.isEdit = false
          row.isAdd = false
          this.$message.success('保存成功！')
        }
      }
      this.existIdList = this.tradeTableData.filter(item => !item.isAdd).map(item => item.inventory_id)
    },
    handleApproved(type){
      this.isApproveDisable = true
      let originalArray = this.tradeTableData.map(item => item.inventory_id)
      let uniqueArray =  originalArray.filter((item, index) => originalArray.indexOf(item) === index);
      if(originalArray.length !== uniqueArray.length ){
        this.$message.error('请删除重复商品！')
        this.isApproveDisable = false
        return
      }
      let isProductValidate = this.tradeTableData.some(item => !item.inventory_id)
      if(isProductValidate){ // 校验新增商品行是否已选商品
        this.$message.error('新增商品不可为空！')
        this.isApproveDisable = false
        return
      }
      if(type === 1){ //撤销
        this.revokeDialogVisible = true
        this.isApproveDisable = false
      }else{
        let orders_data = []
        this.tradeTableData.forEach(item=>{
          orders_data.push({
            inventory_id: Number(item.inventory_id),
            orders_num: item.orders_num,
            orders_sum: item.orders_sum
          })
        })
        let params = {
          order_id: Number(this.orderId) ,
          order_status: type,
          check_remark: this.check_remark,
          orders_data: orders_data,
          customer_id: this.form.invoice_info.customer_id
        }
        checkOrder(params).then(res=>{
          if(res.code === 200){
            this.isApproveDisable = false
            this.$message({message: type == 9002 ? '审核成功！' : '驳回成功！', type: 'success'})
            this.$emit('updateList')
          }
        }).finally(()=>{
          this.isApproveDisable = false
        })
      }
    },
    handleRevokeSave(){
      if(this.undo_remark){
        let orders_data = []
        this.tradeTableData.forEach(item=>{
          orders_data.push({
            inventory_id: Number(item.inventory_id),
            orders_num: item.orders_num,
            orders_sum: item.orders_sum
          })
        })
        let params = {
          order_id: this.orderId,
          order_status: 9003,
          undo_remark: this.undo_remark,
          check_remark: this.undo_remark,
          orders_data: orders_data,
          customer_id: this.form.invoice_info.customer_id
        }
        checkOrder(params).then(res=>{
          if(res.code === 200){
            this.$message({message: '撤销成功！', type: 'success'})
            this.isDrawerShow = false
            this.revokeDialogVisible = false
            this.$emit('updateList')
          }
        }).finally(()=>{

        })
      }else{
        this.$message.error('请输入撤销备注')
      }
    },
    handleDelete(index){
      this.$confirm('确定要删除当前商品吗?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        // center: true
      }).then(() => {
        this.tradeTableData.splice(index, 1)
        this.existIdList = this.tradeTableData.filter(item => !item.isAdd).map(item => item.inventory_id)
        let totalAmountTemp = this.tradeTableData.map(item => item.orders_sum)
        let totalAmount = totalAmountTemp.reduce((sum, num) => {
          const value = (!isNaN(num) && num !== undefined && num !== null && num.trim() !== '') ? parseFloat(num) : 0;
          return sum + Number(value);
        }, 0);
        this.totalAmount = totalAmount.toFixed(2)
        this.$message.success('删除成功！')
      }).catch(() => {
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/order";
::v-deep .el-table .delete.el-button:hover{
  color: #bd3124a8!important;
}
::v-deep .el-table th.el-table__cell > .cell{
  font-weight: 400;
}
::v-deep .el-table .cell{
  text-align: center!important;
}
::v-deep  .el-dialog__header{
  border-bottom: 1px solid #eee;
  padding-bottom: 18px;
}
::v-deep .el-dialog__body{
  padding:20px;
}
::v-deep .select_dialog .el-table th.el-table__cell  {
  background: #eee;
}
::v-deep .select_dialog .el-table th.el-table__cell > .cell {
  font-weight: 600;
  background: #eee;
}
::v-deep .el-table .el-button{
  padding: 10px 8px!important;
}
::v-deep .el-table td.el-table__cell.is-center div{
  text-align: center!important;
}
::v-deep .el-table td.el-table__cell.is-left div{
  text-align: left!important;
}
::v-deep .el-table thead th.el-table__cell{
  text-align: center!important;
}
//total
::v-deep .el-table td.el-table__cell .el-input__inner{
  text-align: center;
}
::v-deep .el-dialog__footer{
  text-align: center;
}
::v-deep .el-input--mini .el-input__inner{
  width: 100%!important;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  .shipping_remarks .block_title{
    margin-bottom: 18px;
    color:#377f7f;
  }
  ::v-deep .shipping_remarks .el-textarea__inner{
    font-size: 16px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 14px;
  }
  ::v-deep .el-drawer__header{
    color: #000;
    font-size: 16px;
  }
  .orderInfo .block p, .orderInfo .block div{
    font-size: 14px;
  }
  .block_title{
    //font-size: 16px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 14px;
  }
  .table_bottom p{
    font-size: 14px;
    margin-bottom: 16px;
  }
  .remark_text{
    font-size: 13px;
  }
}
::v-deep .el-table .el-table__cell .el-input.el-input--mini{
  width: 100%!important;
}
@media screen and (min-width: 1551px) , print {
  .shipping_remarks .block_title{
    font-size: 20px;
    color:#377f7f;
  }
  ::v-deep .shipping_remarks .el-textarea__inner{
    font-size: 18px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 16px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 16px;
  }
  ::v-deep .el-drawer__header{
    color: #000;
    font-size: 18px;
  }
  .orderInfo .block p, .orderInfo .block div{
    font-size: 16px;
  }
  .block_title{
    //font-size: 18px;
  }
  .table_bottom p{
    font-size: 16px;
  }
  .remark_text{
    font-size: 14px;
  }
}
::v-deep .el-drawer__header{
  border-bottom: 2px dotted #eee;
  padding: 10px;
  margin-bottom: 14px;
}
.orderDtl{
  padding: 0 14px;
  .orderInfo{
    border-bottom:1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    .block{
      padding:14px;
      border-top:1px solid #eee;
      border-right: 1px solid #eee;
      p{
        color: #9A9A9A;
        line-height: 1.8;
      }
      div{
        line-height: 1.6;
        span{
          display: inline-block;
        }
      }
    }
  }
  .goodsList{
    .table_bottom{
      margin-top: 20px;
      margin-bottom: 20px
    }
  }
  .block_title{
    //font-size: 16px;
    font-weight: bold;
    margin: 16px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
}
.drawer-footer{
  text-align: center;
  margin: 20px 0;
  .pass{
    background: #377F7F;
    color: #fff;
    padding-left:40px;
    padding-right: 40px
  }
  .reject{
    border:1px solid #377F7F;
    padding-left:40px;
    padding-right: 40px
  }
  .reject:hover{
    background: #fff;
    color: #377F7F;
  }
}
.el-table .children_column{
  border-bottom: none;

}
::v-deep .el-table thead .children_column .cell{
  font-size: 10px!important;
}
::v-deep .el-table thead.is-group th.el-table__cell{
  background: #EFEFEF;
  color: #000;
}
::v-deep .el-table thead .el-table__cell{
  padding: 0;
  border-bottom: none;
}
::v-deep .el-input-number.is-controls-right .el-input__inner{
  width: 130px;
}
::v-deep .el-table td.el-table__cell div{
  text-align: left;
}
::v-deep .el-input--mini .el-input__inner{
  width: 130px;
}
.dialog_close{
  padding-left:40px;
  padding-right:40px
}
.dialog_close:hover{
  background: #fff;
  color:#bd3124b0;
}
::v-deep .select_dialog .el-table thead .el-table__cell{
  padding: 12px 0;
  border-bottom: none;
}
::v-deep .dialog_search_input{
  position: relative;
}
::v-deep .dialog_search_input .el-input{
  width: 100%!important;
}
.dialog_search.search_icon{
  background-image: url("../../../assets/img/search.png") ;
  background-size: cover;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 8px;
  top: 6px;
}
</style>