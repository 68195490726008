import request from '@/utils/request'

export function getInventoryList(params) { // 商品列表
    return request({
        url: '/api/admin/inventory_list/',
        method: 'get',
        params
    })
}
export function getInventoryDetail(params) { // 商品详情
    return request({
        url: '/api/admin/inventory/',
        method: 'get',
        params
    })
}
export function deletInventory(params) {  // 盘点信息提交
    return request({
        url: '/api/admin/inventory/',
        method: 'delete',
        params
    })
}

export function fixPricing(data) {  // 定价
    return request({
        url: '/api/admin/pricing/',
        method: 'post',
        data
    })
}
export function refreshPricing(params) {  // 新增商品或者客户刷新定价
    return request({
        url: '/api/admin/pricing/',
        method: 'get',
        params
    })
}

export function historyPricing(params) {  // 获取历史定价
    return request({
        url: '/api/admin/history_pricing/',
        method: 'get',
        params
    })
}
export function checkOrderList(params) { // 查看订单列表
    return request({
        url: '/api/order_list/',
        method: 'get',
        params
    })
}
export function checkOrder(data) {  // 订单审核
    return request({
        url: '/api/admin/check_order/',
        method: 'post',
        data
    })
}
export function export_order(params) { // 查看订单列表
    return request({
        url: '/api/download_order/',
        method: 'get',
        params
    })
}

export function search_customer(params) { // 查看订单列表
    return request({
        url: '/api/search_customer/',
        method: 'get',
        params
    })
}
export function getCustomerType(params) { // 查看订单列表
    return request({
        url: '/api/admin/customer_type/',
        method: 'get',
        params
    })
}
export function getCertifiedList(params) { // 查看订单详情-商品列表
    return request({
        url: '/api/admin/certified_list/',
        method: 'get',
        params
    })
}
export function checkCertified(data) {  //认证审核
    return request({
        url: '/api/admin/check_certified/',
        method: 'post',
        data
    })
}
export function editNote(data) {  //认证审核
    return request({
        url: '/api/admin/edit_note/',
        method: 'post',
        data
    })
}



