<template>
  <div class="drawer_content">
    <div class="goodsList">
      <el-table
          :data="tradeTableData"
          min-height="250"
          max-height="450"
          ref="goodsTable"
          align="center"
          style="width: 100%">
        <el-table-column label="#" type="index" width="50" align="center" header-align="center"></el-table-column>
        <el-table-column prop="full_name" label="商品全称" min-width="300" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <span v-if="!row.isEdit">{{row.inventory_full_name ? row.inventory_full_name : '/'}} <el-tag size="mini" effect="dark" style="background: #BD3124;border: none" class="new_pro_tag" v-if="row.isFirst" >本次新购商品</el-tag></span>
<!--            <select-product v-if="row.isEdit" :clo_index="$index" :goodsListTemp="goodsOpitonsTemp" :existIdList="existInventoryIdList" @chooseGoods="handleFullNameChange" />-->
            <el-select
                v-if="row.isEdit"
                @focus="selectFocus($index)"
                @change="handleFullNameChange"
                class="check_select"
                v-model="row.inventory"
                placeholder="请输入关键字"
                loading-text="正在从服务端获取数据"
                size="small"
                filterable
                clearable
                remote
                :remote-method="getGoodsList"
                @visible-change="handleVisibleChange"
            >
              <el-option
                  :class="isOptionShow ? 'show' : 'unShow'"
                  v-if="isOptionShow"
                  v-for="item in goodsList"
                  :key="item.value"
                  :label="item.label"
                  :disabled="item.disabled"
                  :value="item">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="inventory_name" label="商品简称" width="120" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <span>{{row.inventory_name ? row.inventory_name : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="standard" label="规格/型号" width="110" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <span>{{row.standard ? row.standard : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="oprate" label="操作" width="170" align="center" header-align="center" fixed="right">
          <template v-slot="{ row, $index }">
            <span v-if="tradeTableData.length <= 1">-</span>
            <div v-else>
              <el-button type="text" size="mini"  v-if='row.isEdit' class="el-icon-circle-check" style="color:#81B337;font-size: 22px" @click="handleTrdSave(row,$index)"></el-button>
              <el-button type="text" size="mini"  class="el-icon-circle-close" style="color:#BD3124; font-size: 22px" @click="handleGoodsDelete($index)"></el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="table_bottom" >
        <el-row>
          <el-col :span="14">
            <el-button size="mini" style="background: #fff; color:#567722;border:1px solid #567722;"   icon="el-icon-plus" @click="addTrade">增加商品</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="goodsList">
      <p class="block_title">选择客户</p>
      <el-table
          :data="cstTableData"
          min-height="250"
          max-height="450"
          ref="customTable"
          align="center"
          style="width: 100%">
        <el-table-column label="#" type="index" width="50"></el-table-column>
        <el-table-column prop="full_name" label="客户名称" min-width="300" align="center" header-align="center" key="custom_name">
          <template v-slot="{ row, $index }">
            <span v-if="!row.isEdit">{{row.custom_name ? row.custom_name : '/'}}</span>
            <el-select
                v-else
                class="custom_select"
                size="mini"
                v-model="row.custom"
                @change="handleCustomerChange(row,$index)"
                filterable
                remote
                reserve-keyword
                placeholder="请输入关键字"
                :remote-method="remoteMethod"
                :loading="loading">
              <el-option
                  v-for="item in customOptions"
                  :key="item.customer"
                  :label="item.customer_name"
                  :value="{label:item.customer_name, value:item.customer_id}"
                  :disabled="item.disabled">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="customer_type" label="客户类别" width="110" align="center" header-align="center" :key="Math.random()">
          <template v-slot="{ row, $index }">
            <span>{{row.customer_type ? row.customer_type : '/'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="单价（元）" width="120" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <el-input v-if="row.isEdit" @blur="changeEdit(row)"  placeholder="请输入单价" style="width: 130px" v-model="row.price" size="mini"  controls-position="right"  @change="handlePriceChange(row)" :min="1" :max="10" label="描述文字"></el-input>
            <span v-else @click="row.isEdit=true">{{row.price ? row.price : '0.00'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="tax_rate" label="税率（%）" width="120" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <el-input v-if="row.isEdit"  @blur="changeEdit(row)"  placeholder="请输入税率" style="width: 130px" v-model="row.tax_rate" size="mini"  controls-position="right"  @change="handleTaxChange(row)" :min="1" :max="10" label="描述文字"></el-input>
            <span v-else @click="row.isEdit = true">{{row.tax_rate}} <span v-if="row.tax_rate">%</span>  </span>
          </template>
        </el-table-column>
        <el-table-column prop="tax_price" label="含税单价（元）"  width="140" align="center" header-align="center">
          <template v-slot="{ row, $index }">
            <span>{{row.tax_price ? row.tax_price : '0.00'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="oprate" label="操作" width="170" align="center" header-align="center" fixed="right">
          <template v-slot="{ row, $index }">
            <el-button v-if="row.isEdit" type="text" size="mini" class="el-icon-circle-check" style="color:#81B337;font-size: 22px" @click="handleCstSave(row, $index)"></el-button>
            <el-button type="text" size="mini"  class="el-icon-circle-close" style="color:#BD3124; font-size: 22px" @click="handleDelete($index,row.customer_id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="table_bottom">
        <el-row>
          <el-col :span="14">
            <el-button size="mini" style="background: #fff; color:#567722;border:1px solid #567722;"   icon="el-icon-plus" @click="addCustom">增加客户</el-button>
          </el-col>
          <el-col :span="10">
            <div style="width: 100%;text-align: right!important;padding-right: 40px!important;box-sizing: border-box">
             <div style="display: inline-block; vertical-align: top">
                <span class="font_resize">币种：</span>
                <div class="currency" style="display: inline-block;width: 120px;margin-right: 30px">
                  <el-select size="mini"  v-model="currency" placeholder="请选择">
                    <el-option
                        v-for="item in currency_options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                  </el-select>
                </div>
             </div>
             <div style="display: inline-block;">
                  <span style="line-height: 28px" class="font_resize">汇率：</span>
                  <div class="currency" style="display: inline-block;width: 120px">
                   <el-input size="mini"  v-model="tax_rate"></el-input>
                  </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div class="footer">
          <el-button size="mini" class="save_btn" style="" @click="save">确定保存</el-button>
        </div>
      </div>
    </div>
    <el-dialog
        title="选择商品"
        :visible.sync="selectDialogVisible"
        v-if="goodsListTemp.length > 1 || searchText != ''"
        width="50%"
        :modal="false"
        class="select_dialog"
        :show-close="false"
        :before-close="handleClose">
      <template #title>
        <span class="dialog_title">选择商品</span>
        <div style="display: inline-block;float: right;width: 280px" class="dialog_search_input">
          <el-input v-model="searchText" placeholder="请输入商品名称"  @keyup.enter.native="handleFilterGoods(searchText)" style="width: 200px;margin-right: 20px" size="mini"></el-input>
          <i slot="append" class="dialog_search search_icon" @click="handleFilterGoods(searchText)"></i>
        </div>
      </template>
      <div class="select_dialog_table">
        <el-table :data="goodsList" @selection-change="handleSelectionChange" stripe ref="relateTable"  border v-loading="loading" element-loading-text="加载中..."  max-height="450px" :scroll-y="true">
          <el-table-column  type="selection" :selectable="selectableStatus"  width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="inventory_full_name" label="全称" min-width="150" align="left" header-align="center">
            <template v-slot="{ row, $index }">
              <span >{{row.label}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="inventory_name" label="简称" min-width="150" align="left" header-align="center">
            <template v-slot="{ row, $index }">
              <span>{{row.inventory_name}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="standard" label="规格/型号" min-width="120" align="left" header-align="center">
            <template v-slot="{ row, $index }">
              <span>{{row.standard}}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer"  class="dialog-footer" style="text-align: center">
          <el-button style="margin-right: 14px" @click="selectDialogVisible = false" size="mini">取 消</el-button>
          <el-button type="primary" @click="handleSelected(2)" size="mini" style="background: #4095E5" >确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import {organization_list} from "@/api/search";
import fixPrice from "@/views/OrderManagement/mixin/fixPrice";
import HistoryPrice from "@/views/OrderManagement/business/components/historyPrice.vue";
import common from "@/views/OrderManagement/mixin/common";
import {fixPricing,refreshPricing, search_customer,getCustomerType} from "@/api/orderGoods/business";
import selectProduct from "@/views/OrderManagement/business/components/selectProduct.vue";
export default {
  components: {selectProduct, HistoryPrice},
  mixins: [common,fixPrice],
  props: {
    selectData: {
      type: Array,
      default: () => {
        return []
      }
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      loading: false,
      pricing_type: '2',
      inventory_ids: [],
      customOptions: [],
      cstTableData: [],
      selectedCustomIdList: [],
      selectedCustomNameList: [],
      goodsOpitonsTemp:[],
      existInventoryIdList:[],
      currency: 'CNY',
      tax_rate: '1.0000',
      currency_options:[
        {value: 'CNY', label: '人民币'},
        // {value: 'USD', label: '美元'},
        // {value: 'EUR', label: '欧元'},
        // {value: 'GBP', label: '英镑'},
        // {value: 'JPY', label: '日元'},
      ],
    }
  },
  created() {
    this.initData()
  },
  methods: {
    handleScroll(){
      this.goodsList = []
    },
    initData() {
      this.cstTableData = []
      if(this.selectData){
        this.tradeTableData = JSON.parse(JSON.stringify(this.selectData))
        this.tradeTableData.forEach(item => {
          if(!item.price){
            item.price = 0
          }
          if(!item.tax_rate){
            item.tax_rate = 0
          }
          item.isEdit = false
        })
        this.tradeTableData = this.tradeTableData
        this.existInventoryIdList = this.tradeTableData.map(item => item.inventory_id)
      }
      if(this.tradeTableData.length > 0){
        this.tradeTableData.forEach(item => {
          if(item.price && item.tax_rate ){
            item.tax_price = Number(item.price) * (1 + Number(item.tax_rate)/100)
          }
        })
        this.tradeTableData.forEach(item=>{
          let tempItem = {label: item.inventory_name,
            value: item.inventory_id,
            inventory_name: item.inventory_name,
            standard: item.standard,
            sale_unit: item.sale_unit,
            disabled: true
          }
          this.goodsOpitonsTemp = this.goodsOpitonsTemp.push(tempItem)
        })
      }
      this.$nextTick(() => {
        const tableBodyWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper')
        if (tableBodyWrapper){
          tableBodyWrapper.addEventListener('scroll', this.handleScroll);
        }
      })
    },
    handleCustomerChange(val,index){ // 选择客户
      this.cstTableData[index].customer_id = val.custom.value
      this.cstTableData[index].custom_name = val.custom.label
      let customOptionsTemp = [{customer_name: val.custom.label, customer_id: val.custom.value,disabled: true}]
      if(!this.customOptions.some(item => item.customer_id === val.custom.value)){
        this.customOptions = this.customOptions.concat(customOptionsTemp)
      }else{
        const idArray = this.cstTableData.map(item => item.customer_id)
        let updatedArray = this.customOptions .map((item,index) => {
          if (idArray.includes(item.customer_id)) {
            return  { ...item, disabled: true };
          }
          return item;
        });
        this.customOptions = updatedArray
      }
      this.selectedCustomIdList = this.cstTableData.map(item => item.customer_id.toString())
      getCustomerType({customer_name: val.custom.label}).then(res=>{ // 获取客户类型
        this.cstTableData[index].customer_type = res.data.customer_type
        // this.$forceUpdate()
      })
      // 更新商品列表
      let inventory_ids = this.tradeTableData.map(item => item.inventory_id)
      let customer_ids = this.cstTableData.map(item => item.customer_id.toString())
      let params = {
        pricing_type: this.pricing_type,
        inventory_ids: inventory_ids.toString(),
        customer_ids: val.customer_id.toString()
      }
      refreshPricing(params).then(res=>{
        let refreshArray = res.data
        if(refreshArray && refreshArray.length > 0) {
          this.cstTableData[index].price = refreshArray[0].price
          this.cstTableData[index].tax_rate = refreshArray[0].tax_rate
          this.cstTableData[index].tax_price = refreshArray[0].tax_price
        }else{
          this.cstTableData[index].price = 0.00
          this.cstTableData[index].tax_rate = 13
          this.cstTableData[index].tax_price = 0.00
        }
      }).catch(err=>{
        this.$message.error(err)
      })
    },
    handleGoodsDelete(index){
      this.$confirm('确定要删除当前商品吗?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        // center: true
      }).then(() => {
        this.tradeTableData.splice(index, 1)
        let customer_ids = this.cstTableData.map(item =>  Number(item.customer_id))
        this.existInventoryIdList = this.tradeTableData.map(item =>  item.inventory_id)
        let filtered_customer_ids = customer_ids.filter(function(item) {
          return !Number.isNaN(item);
        });
        if(filtered_customer_ids.length > 0){
          // 更新商品列表
          let params = {
            pricing_type: this.pricing_type,
            inventory_ids: this.existInventoryIdList.toString(),
            customer_ids: filtered_customer_ids.toString()
          }
          refreshPricing(params).then(res=>{
            let refreshArray = res.data
            if(refreshArray && refreshArray.length > 0){
              this.cstTableData = this.cstTableData.map(item1 => {
                const item2 = refreshArray.find(item => item.customer_id.toString() == item1.customer_id);
                return { ...item1, ...item2 };
              });
              this.cstTableData.forEach(item => {
                if(item && item.price && item.price > 0){
                  item.price = item.price.toFixed(2) || 0.00
                }
                if(item && item.tax_price && item.tax_price > 0){
                  item.tax_price = item.tax_price.toFixed(2) || 0.00
                }
              })
            }
          }).catch(err=>{
            this.$message.error(err)
          })
        }
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
      });
    },
    handleDelete(index,customerId){
      this.$confirm('确定要删除当前客户吗?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        // center: true
      }).then(() => {
        this.cstTableData.splice(index, 1)
        this.customOptions.forEach(item=>{
          if(item.customer_id === customerId){
            item.disabled = false
          }
        })
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
      });
    },
    addCustom(){ // 添加客户
      this.$nextTick(() => {
        // 新增行时滚动条滑到最底部
        const tableWrapper = this.$refs.customTable.$el.querySelector('.el-table__body-wrapper');
        window.requestAnimationFrame(() => {
          tableWrapper.scrollTop = tableWrapper.scrollHeight;
        });
        const tableBodyWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper')
        if (tableBodyWrapper){
          tableBodyWrapper.addEventListener('scroll', this.handleScroll);
        }
      });
      this.cstTableData.push({
        full_name: '',
        type: '',
        tax_rate: 13,
        price: '0.00',
        tax_price: 0.00,
        isEdit: true,
        isNew: true
      })
    },
    addTrade(){
      this.$nextTick(() => {
        // 新增商品滚动条滑动到最底部
        const tableWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper');
        window.requestAnimationFrame(() => {
          tableWrapper.scrollTop = tableWrapper.scrollHeight;
        });
      });
      this.tradeTableData.push({
        inventory_full_name: '',
        inventory_name: '',
        standard: '',
        inventory_ids: '',
        isEdit: true,
        isNew: true
      })
    },
    handleFullNameChange(data){ // 选择商品
      this.tradeTableData[this.currentIndex].inventory_id = data.value
      this.tradeTableData[this.currentIndex].inventory_name = data.inventory_name
      this.tradeTableData[this.currentIndex].standard = data.standard
      this.tradeTableData[this.currentIndex].inventory_full_name = data.label
      let customer_ids = this.cstTableData.map(item =>  Number(item.customer_id))
      this.existIdList = this.tradeTableData.map(item=> item.inventory_id)
      this.goodsList.forEach(item => {
        if(this.existIdList.includes(item.value)){
          item.disabled = true
        }
      })
      this.goodsOpitonsTemp = [{label: data.label,
        value: data.value,
        inventory_name: data.inventory_name,
        standard: data.standard,
        sale_unit: data.sale_unit,
        disabled: true
      }]
      this.existInventoryIdList = this.tradeTableData.map(item => item.inventory_id.toString())
      //去掉空的客户行
      let filtered_customer_ids = customer_ids.filter(function(item) {
        return !Number.isNaN(item);
      });
      if(filtered_customer_ids.length > 0){
        // 更新商品列表
        let params = {
          pricing_type: this.pricing_type,
          inventory_ids: this.existInventoryIdList.toString(),
          customer_ids: filtered_customer_ids.toString()
        }
        refreshPricing(params).then(res=>{
          let refreshArray = res.data
          if(refreshArray && refreshArray.length > 0){
            this.cstTableData = this.cstTableData.map(item1 => {
              const item2 = refreshArray.find(item => item.customer_id.toString() == item1.customer_id);
              return { ...item1, ...item2 };
            });
            this.cstTableData.forEach(item => {
              if(item && item.price && item.price > 0){
                item.price = item.price ? (Number(item.price)).toFixed(2) : 0.00
              }
              if(item && item.tax_price && item.tax_price > 0){
                item.tax_price =  item.tax_price ? item.tax_price.toFixed(2) : 0.00
              }
            })
          }
        }).catch(err=>{
          this.$message.error(err.msg)
        })
      }
      this.$forceUpdate()
    },
    save(){
      let inventory_ids = this.tradeTableData.map(item =>  Number(item.inventory_id))
      if(!inventory_ids){
        this.$message.error('请选择商品')
        return
      }
     if(this.cstTableData && this.cstTableData.length > 0){
       this.cstTableData.forEach(item => {
         item.customer_id = item.customer_id ?  item.customer_id.toString() : null
         item.price = Number(item.price)
         item.tax_rate = Number(item.tax_rate)
         item.tax_price = Number(item.tax_price)
         delete item.full_name
         delete item.isNew
         delete item.isEdit
         delete item.type
       })
     }else{
       this.$message.error('请添加客户！')
       return;
     }
      let isCstValidate = this.cstTableData.some(item => !item.customer_id)
      if(isCstValidate){ // 校验新增客户行是否已选客户
        this.cstTableData.forEach(item => {
          if(!item.customer_id){
            item.isEdit = true
          }
        })
        this.$message.error('请为新增行添加数据！')
        return
      }
      let isPriceValidate = this.tradeTableData.some(item => !item.inventory_id)
      if(isPriceValidate){ // 校验新增商品行是否已选商品
        this.$message.error('请为新增行添加数据!')
        return
      }
      let originalArray = this.tradeTableData.map(item => item.inventory_id)
      let uniqueArray =  originalArray.filter((item, index) => originalArray.indexOf(item) === index);
      if(originalArray.length !== uniqueArray.length ){
        this.$message.error('请删除重复商品！')
        return
      }
      let originalCtsArray = this.cstTableData.map(item => item.customer_id)
      let uniqueCtsArray =  originalCtsArray.filter((item, index) => originalCtsArray.indexOf(item) === index);
      if(originalCtsArray.length !== uniqueCtsArray.length ){
        this.$message.error('请删除重复客户！')
        return
      }
      let params= {
        pricing_type: this.pricing_type,
        pricing_data: this.cstTableData,
        inventory_ids:  inventory_ids
      }
      fixPricing(params).then(res => {
        if(res.code === 200){
          this.$message.success('定价成功！')
          this.$emit('close')
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    handleTrdSave(row, clo_index){
      if(row.inventory_id){
        this.tradeTableData[clo_index].isEdit = false
      }else{
        this.$message.error('请选择商品')
      }
    },
    handleCstSave(row, clo_index){
      if(row.customer_id){
        this.cstTableData[clo_index].isEdit = false
        row.price = Number(row.price).toFixed(2) || 0.00
      }else{
        this.$message.error('请选择客户')
      }
    },
    remoteMethod(e) {
      search_customer({ customer_name: e }).then(res=>{
        let originalArray = res.data
        const idArray = this.cstTableData.map(item => Number(item.customer_id)|| undefined )
        if(idArray && idArray.length > 0 && idArray[0]){
         let updatedArray = originalArray.map(item => {
          if (idArray.includes(item.customer_id)) {
            return { ...item, disabled: true };
          }
          return item;
        });
        let newIds = updatedArray.map(item => item.customer_id)
        if(!newIds.includes(this.customOptions[0].customer_id)){
          this.customOptions = updatedArray.concat(this.customOptions)
        }else{
          this.customOptions = updatedArray
        }
        }else{
          this.customOptions = originalArray
        }
      })
    },
    handlePriceChange(row){
      row.tax_price = (Number(row.price) * Number(1  + Number(row.tax_rate)/100)).toFixed(2)
    },
    handleTaxChange(row){
      row.tax_price = (Number(row.price) * Number( 1 + Number(row.tax_rate)/100)).toFixed(2)
    },
    changeEdit(row){
      row.price = (Number(row.price)).toFixed(2) || 0.00
      if(row.custom_name){
        row.isEdit = false
      }else{
        row.isEdit = true
      }
    },
  },
  beforeDestroy() {
    this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper').removeEventListener('scroll', this.handleScroll);
  }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/order";
//.el-table .children_column{
//  border-bottom: none;
//
//}
//::v-deep .el-table thead .children_column .cell{
//  font-size: 10px!important;
//
//}
::v-deep .el-table td.el-table__cell div{
  width: 100%!important;
}
::v-deep .el-table .el-button {
  padding: 10px 3px!important;
}
::v-deep .el-table td.el-table__cell div{
  width: 100%;
}
::v-deep .el-table thead.is-group th.el-table__cell{
  background: #EFEFEF;
  color: #000!important;
}
::v-deep .el-table thead .el-table__cell{
  padding: 0;
  border-bottom: none;
}
::v-deep .el-input-number.is-controls-right .el-input__inner{
  width: 100%;
  //width: 130px;
}
::v-deep .el-table td.el-table__cell div{
  //text-align: left;
}
::v-deep .el-input--mini .el-input__inner{
  width: 84%;
}
::v-deep .custom_select .el-input--mini .el-input__inner{
  width: 100%;
}

::v-deep .el-input__inner::placeholder {
  color: #888888;
}
::v-deep .el-input__inner::-moz-placeholder { /* Firefox 19+ */
  color:#888888;
}
::v-deep .select_dialog .el-dialog__header .el-input{
  width: 100%!important;
}
::v-deep .select_dialog .el-dialog__header  .el-input--mini .el-input__inner{
  width: 100%!important;
  position: relative;
}
.dialog_search.search_icon{
  background-image: url("../../../../assets/img/search.png") ;
  background-size: cover;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 28px;
  top: 26px;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  ::v-deep .el-table td.el-table__cell div{
    font-size: 14px;
  }
  ::v-deep .el-drawer__header{
    color: #000;
    font-size: 16px;
  }
  .orderInfo .block p, .orderInfo .block div{
    font-size: 14px;
  }
  .block_title{
    font-size: 16px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 14px;
  }
  .table_bottom p{
    font-size: 14px;
  }

}
@media screen and (min-width: 1551px) , print {
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 16px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 16px;
  }
  ::v-deep .el-drawer__header{
    color: #000;
    font-size: 18px;
  }
  .orderInfo .block p, .orderInfo .block div{
    font-size: 16px;
  }
  .block_title{
    font-size: 18px;
  }
  .table_bottom p{
    font-size: 16px;
  }
}
.drawer_content{
  .content_top{
    padding: 0 20px;
    padding-bottom: 20px;
    border-bottom: 1px dotted #BBBBBB;
  }
  .goodsList{
    padding: 0px 10px;
    .table_bottom{
      margin-top: 20px;
      margin-bottom: 20px
    }
  }
  .block_title{
    font-size: 16px;
    font-weight: bold;
    margin: 16px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
}
.footer{
  position: absolute;
  height: 80px;
  bottom: 0px;
  width: 100%;
}
.save_btn{
  background: #347CAF;
  color: #fff;
  border:none;
  padding:10px 38px!important;
  margin-top: 20px;
}
.currency{
  width: 40%;
}
::v-deep .currency .el-select .el-input .el-input__inner{
  width: 100%;
}
::v-deep .goodsList .el-select .el-input .el-input__inner{
  width: 100%;
}

</style>