<template>
  <div class="ave_block" v-loading="loading" :element-loading-text="loadingText">
    <p class="tip">以下常用均值可能需要更新：</p>
    <div class="table-box">
      <el-table :data="refresh_list" border max-height="300px"  :span-method="objectSpanMethod" style="width: 100%">
        <el-table-column prop="instrument_id" label="机号" min-width="100" header-align="center" align="center"></el-table-column>
        <el-table-column prop="testing_item" label="检测项目" min-width="100" header-align="center" align="center"></el-table-column>
        <el-table-column prop="qc_batch" label="质控批号" min-width="100" header-align="center" align="center"></el-table-column>
        <el-table-column prop="level" label="水平" min-width="90" header-align="center" align="center"></el-table-column>
        <el-table-column prop="average" label="平均值" min-width="90" header-align="center" align="center">
          <template  v-slot="{row}">
            <span v-if=" row.level == 'C1'">{{ row.fixed_target_c1 ? row.fixed_target_c1.toFixed(2) : '-' }}</span>
            <span v-if=" row.level == 'C2'">{{row.fixed_target_c2 ? row.fixed_target_c2.toFixed(2) :  '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="sd" label="SD" min-width="90" header-align="center" align="center">
          <template  v-slot="{row}">
            <span v-if=" row.level == 'C1'">{{ row.fixed_sd_c1 ? row.fixed_sd_c1.toFixed(2) : '-' }}</span>
            <span v-if=" row.level == 'C2'">{{row.fixed_sd_c2 ? row.fixed_sd_c2.toFixed(2) :  '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="cv" label="CV" min-width="90" header-align="center" align="center">
          <template  v-slot="{row}">
            <span v-if=" row.level == 'C1'">{{row.fixed_cv_c1 ? `${(row.fixed_cv_c1 *100).toFixed(2)}%` : '-' }}</span>
            <span v-if=" row.level == 'C2'">{{row.fixed_cv_c2 ? `${(row.fixed_cv_c2 *100).toFixed(2)}%` : '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="opt" label="操作" min-width="132" header-align="center" align="center">
          <template  v-slot="{row}">
            <el-button size="mini" class="refr_btn" @click="handleRefresh(row,1)">更新</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <span slot="footer" class="dialog-footer" style="position: absolute;bottom: 20px;">
    <el-button class="refr_all" type="primary" @click="handleRefresh(null,2)">更新所有均值</el-button>
  </span>
<!--    <div class="page_box">-->
<!--      <el-button class="refr_all" @click="handleRefresh(null,2)">更新所有均值</el-button>-->
<!--    </div>-->
    <el-dialog
        title=""
        width="30%"
        :visible.sync="success_tip_dialog"
        :show-close="false"
        :center="true"
        class="success_tip"
        :modal-append-to-body="false"
        :append-to-body="true"
        :before-close="handleClose">
      <p style="text-align: left"> 常用均值已更新！成功<span style="color:#81B337;font-size: 18px;font-weight: bold;padding: 0 2px">{{update_tip_info.success}}</span>条，失败
        <span style="color:#BD3124;font-size: 18px;font-weight: bold;padding: 0 2px">{{update_tip_info.error}}</span>条。</p>
      <p v-if="update_tip_info.error > 0" style="text-align: left">失败的有：{{update_tip_info.error_list? error_list.toString() : ''}}，失败原因:质控数据数量未达到计算常用均值数据数量要求!</p>
      <span slot="footer" class="dialog-footer">
    <el-button @click="handleClose()">知道了</el-button>
  </span>
    </el-dialog>
  </div>
</template>
<script>
import api from '@/api/quality'
  export default {
    computed: {
    },
    props: {
      qc_info_data:{
        type: Array,
        default: []
      }
    },
    data() {
      return {
        loading: false,
        loadingText: '数据加载中',
        success_tip_dialog: false,
        closeType : 1, // 1 刷新单个关闭，2刷新所有关闭
        refresh_list1: [
          // {
          //   instrument_id: "10N01001",
          //   testing_item: "hs-CRP",
          //   qc_batch: "20240101",
          //   level: "C1",
          //   average: "10.01",
          //   sd: "0.56",
          //   cv: "5.65",
          // },

        ],
        refresh_list: [],
        resultOriginData: [],
        update_tip_info: {},
        error_list: []
      }
    },
    created() {
      // 1、整合qc_serum_info 接口获取的数据为一维数组
      let originData = []
      if(this.qc_info_data  && this.qc_info_data.length >0){
        this.qc_info_data.forEach(m =>{
          m.qs_list.forEach(n =>{
            originData = originData.concat(n.qc_serum_info)
          })
        })
        originData.forEach(item => {
          item.instrument_id = item.qc_serum_id.split('-')[0]
          item.testing_item =  (item.qc_serum_id.split('-')[1]) == '4' ? 'hs-CRP' :  'SAA'
        })

        originData.sort((a, b) => {
          if (a.instrument_id < b.instrument_id) {
            return -1;
          }
          if (a.instrument_id > b.instrument_id) {
            return 1;
          }
          return 0;
        });
        // 2、为了构造合并表格的原始数据，把数组中C1 、C2 的数据分开到每一条数据中
        originData.forEach(item => {
          // 创建包含 c1 字段的对象
          const obj1 = {
            ...item, // 复制原始对象的所有字段
            fixed_cv_c2: undefined, // 明确设置 c2 字段为 undefined（或可以删除）
            fixed_sd_c2: undefined,
            fixed_target_c2: undefined,
            level: 'C1'
          };
          delete obj1.fixed_cv_c2; // 或者使用 delete 删除 c2 字段
          delete obj1.fixed_sd_c2;
          delete obj1.fixed_target_c2;

          // 创建包含 c2 字段的对象
          const obj2 = {
            ...item, // 复制原始对象的所有字段
            fixed_cv_c1: undefined, // 明确设置 c1 字段为 undefined（或可以删除）
            fixed_sd_c1: undefined,
            fixed_target_c1: undefined,
            level: 'C2'
          };
          delete obj2.fixed_cv_c1; // 或者使用 delete 删除 c1 字段
          delete obj2.fixed_sd_c1;
          delete obj2.fixed_target_c1;

          // 将两个对象添加到结果数组中
          this.resultOriginData.push(obj1, obj2);
        });
      }
      if(this.resultOriginData.length < 1){
        return
      }
      // 3、合并表格的逻辑
      let processMergeCells =(list)=> {
        let result = [];
        let currentInstrumentId = this.resultOriginData[0].instrument_id;
        let currentTestingItem = null;
        let currentQualityBatch = null;
        let rowIndex = 0;
        this.resultOriginData.forEach((item,index) => {
          const newItem = { ...item,  merge_cell: {} };
          let rowCount = this.resultOriginData.filter(i => i.instrument_id === item.instrument_id).length;
          // 设置 merge_cell 属性
          if(currentInstrumentId === item.instrument_id){
            newItem.merge_cell.instrument_id = {
              rowspan: rowIndex === 0 ? rowCount : 0,
              colspan: rowIndex === 0 ? 1 : 0,
            }
          }else{
            rowIndex = 0
            newItem.merge_cell.instrument_id = {
              rowspan: rowIndex === 0 ? rowCount : 0,
              colspan: rowIndex === 0 ? 1 : 0,
            };
          }
          newItem.merge_cell.testing_item = {
            rowspan: index%2 ===0 ? 2 : 0,
            colspan:  index%2 ===0 ? 1 : 0,
          };
          newItem.merge_cell.qc_batch = {
            rowspan:index%2 === 0 ? 2 : 0,
            colspan: index%2 === 0 ? 1 : 0,
          };
          newItem.merge_cell.opt = {
            rowspan: index%2 === 0 ? 2 : 0,
            colspan:  index%2 === 0 ? 1 : 0,
          };
          // 更新当前状态
          currentInstrumentId = item.instrument_id;
          currentTestingItem = item.testing_item;
          currentQualityBatch = item.qc_batch;
          // 将新项添加到结果数组中
          result.push(newItem);
          rowIndex++;
        });
        return result;
      }
      let new_list = processMergeCells(this.refresh_list1)
      this.refresh_list.push(...new_list )
    },
    mounted() {
    },
    methods: {
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (row.merge_cell) {
         return row.merge_cell[column.property]
        }else{
          return { rowspan: 0, colspan: 0 }
        }
      },
      handleRefresh(row,type){
        this.loadingText = '正在更新中'
        this.loading = true
        if(type == 1){
          let qc_serum_id_list = []
          qc_serum_id_list.push(row.qc_serum_id)
          api.update_serum_target({qc_serum_id_list}).then(res => {
            if(res.status == 'success'){
              this.loading = false
              this.success_tip_dialog = true
              this.update_tip_info = res.data
              if(this.update_tip_info.error_list &&this.update_tip_info.error_list.length > 0){
                this.update_tip_info.error_list.forEach(item => {
                  this.error_list.push(this.replaceSecondValue(item))
                })
              }
            }
          })
        }else{
          this.closeType = 2
          let qc_serum_id_list = []
          qc_serum_id_list = this.refresh_list.map(item => item.qc_serum_id)
          api.update_serum_target({qc_serum_id_list}).then(res => {
            if(res.status === 'success'){
              this.loading = false
              this.success_tip_dialog = true
              this.update_tip_info = res.data
              if(this.update_tip_info.error_list &&this.update_tip_info.error_list.length > 0){
                this.update_tip_info.error_list.forEach(item => {
                  this.error_list.push(this.replaceSecondValue(item))

                })
              }
            }
          })
        }
      },
      replaceSecondValue(str) {
        const parts = str.split('-');
        if (parts.length < 2) {
          return str;
        }
        const secondValue = parts[1];
        // 根据第二个部分的值进行替换
        let newSecondValue;
        switch (secondValue) {
          case '4':
            newSecondValue = 'hs-CRP';
            break;
          case '11':
            newSecondValue = 'SAA';
            break;
          default:
            // 如果第二个部分的值不是4或11，则保持原样（或根据需要处理）
            newSecondValue = secondValue;
        }
        // 如果原字符串包含三个部分，则保留第三部分；否则，第三部分为空
        const thirdValue = parts.length > 2 ? parts[2] : '';
        // 重新组合字符串并返回结果
        return [parts[0], newSecondValue, thirdValue].join('-');
      },
      handleClose(){
        this.success_tip_dialog = false
        this.$emit('handleRefreshClose')
      },
    },

  }
</script>

<style scoped lang="scss">
.ave_block{
  position: relative;
  min-height: 390px;
  ::v-deep .el-table th.el-table__cell>.cell,::v-deep .el-table .el-table__cell>.cell{
    color: #000;
  }
  ::v-deep .el-button.refr_btn{
      border-color: #347CAF;
      color: #347CAF;
      padding: 8px 26px;
      font-size: 14px;
  }
  ::v-deep .el-button.refr_all{

    background: #347CAF;
    color: #fff;
    padding: 8px 26px;
    font-size: 14px;
  }
  p.tip{
    margin:10px 0;
    font-size: 16px;
    color: #4f4f4f;
  }
  .page_box{
    display: inline-block;
    height: 50px;
    width: 100%;
    position: absolute;
    bottom: 0px;
  }
}
.success_tip{
  display: flex;
  //padding: 20px;
  ::v-deep .el-dialog__header{
    padding: 0;
  }
  ::v-deep .el-dialog {
    margin: auto !important;
    //width: 280px!important;
    //height: 140px;

    border-radius: 10px;
    padding: 0px 0 10px 0;
    background-color: rgba(255,255,255,1);
    border: 1px solid #EFEFEF;
  }
  ::v-deep .el-dialog--center .el-dialog__body{
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    //padding:35px 0 22px 0;
  }
  .dialog-footer{
    box-sizing: border-box;
    ::v-deep .el-button{
      border-color: #215476;
      color: #215476;
      height: 30px;
      line-height: 30px;
      padding:0 20px;
    }
  }
}

</style>