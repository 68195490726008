<template>
  <div class="allList"  v-loading="loading" element-loading-text="数据加载中">
    <div v-if="allList.length > 0">
      <div v-for="item in allList"  class="his_item">
        <p v-if="allList.length > 1" class="his_custom_name">{{item.customer_name}}</p>
        <el-table border :data="item.pricing_history" min-height="400px">
          <el-table-column type="index" label="#" width="50" align="center" header-align="center"></el-table-column>
          <el-table-column prop="price" label="价格(元)" width="90" align="center" header-align="center"></el-table-column>
          <el-table-column prop="tax_rate" label="税率(%)" width="90" align="center" header-align="center"></el-table-column>
          <el-table-column prop="tax_price" label="含税定价(元)" width="120" align="center" header-align="center"></el-table-column>
          <el-table-column prop="opt_user_name" label="操作人" width="90" align="center" header-align="center"></el-table-column>
          <el-table-column prop="create_time" label="操作日期" min-width="160" align="center" header-align="center"></el-table-column>
        </el-table>
      </div>
    </div>
    <div v-if="allList.length == 0 &&!loading">
      <el-empty description="暂无数据"></el-empty>
    </div>
  </div>
</template>
<script>
import {historyPricing} from "@/api/orderGoods/business";

export default {
  props: {
    inventory_id: {
      type: String,
      default: ''
    },
    customer_ids:{
      type: Array,
      default: []
    }

  },
  watch: {
    inventory_id(newVal) {
      if(newVal){
        this.getTableList()
      }
    },
  },
  data() {
    return {
      loading: false,
      dialogTableVisible: false,
      allList: []
    }
  },
  created() {
    if(this.customer_ids && this.inventory_id){
      this.getTableList()
    }
  },
  methods: {
    getTableList(){
      this.loading= true
      let params = {
        inventory_id: this.inventory_id,
        customer_ids: this.customer_ids.toString()
      }
      historyPricing(params).then(res => {
        this.allList = res.data
        this.loading= false
      }).finally(()=>{

      })
    },
    close(){
      this.dialogTableVisible = false
    }
  }
}

</script>

<style scoped lang="scss">
@import "../../../../assets/css/order";
.allList {
  max-height: 75vh;
  min-height: 50vh;
  overflow-y: scroll;
}
::v-deep .el-table thead th.el-table__cell{
  background: none!important;
  border-bottom:1px solid #EFEFEF!important;
}
.his_custom_name{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.his_item{
  margin-bottom: 26px;
}
</style>