export default {
    data() {
        return {
            goodsTypeList: [
                {value: 0, label: '其他'},
                {value: 1, label: '试剂'},
                {value: 2, label: '仪器'},
                {value: 3, label: '耗材'}
            ],
            orderStatusList:[
                {value: 9001, label: '待审核'},
                {value: 9002, label: '已审核'},
                {value: 9003, label: '已撤销'},
                {value: 9004, label: '已发货'},
                {value: 9005, label: '已收货'},
                {value: 9006, label: '已驳回'}
            ],
            orderList:[],
            tableData:[],
            tradeTableData: [],
            trdTableData: [],
            cstTableData: [],
            expressList:[],
            tradeList:[],
            purchaseList2:[],
            purchaseList:[]
        }
    },
    methods: {
        statusColor(status){
            if(status == '已审核'){
                return 'color:#567722'
            }else if(status == '待审核'){
                return 'color:#BD3124'
            } else if(status == '已发货'){
                return 'color:#FFBF6B'
            }else if(status == '已驳回'){
                return 'color: #fe7300 '
            }
        },
    }
}