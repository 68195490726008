<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      custom-class="dialog_right"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="report-box">
        <div class="top">
          <p>{{is_complete?'查看':'填写'}}失控报告</p>
          <i class="el-icon-close" @click="close_dialog"></i>
        </div>
        <div class="body" ref="body">
          <div>
            <p class="title">基本信息</p>
            <detailMenu :dataList="dataList" />
          </div>
          <div class="card">
            <p class="title">失控信息</p>
            <div class="menu">
<!--              <div :style="'width:10%'" class="box msgBox" v-if="c1_show">-->
<!--                &lt;!&ndash; <p>{{ qc_level ? 'C' + qc_level : '' }}</p> &ndash;&gt;-->
<!--                <p>C1</p> -->
<!--              </div>-->
<!--              <div-->
<!--                :class="[-->
<!--                  {-->
<!--                    rightBorder: item.rightBorder == false,-->
<!--                    bottomBorder: item.bottomBorder == false,-->
<!--                    absorbance: item.type != null,-->
<!--                  },-->
<!--                  'box',-->
<!--                ]"-->
<!--                v-for="(item, index) of data_c1_list_msg"-->
<!--                :key="'msg_c1_' + index"-->
<!--                :style="'width:' + item.width"-->
<!--              >-->
<!--                <p>{{ item.name }}:</p>-->
<!--                <p>{{ item.value == null ? '/' : item.value }}</p>-->
<!--              </div>-->
<!--              <div :style="'width:10%'" class="box msgBox" v-if="c2_show">-->
<!--                <p>C2</p> -->
<!--              </div>-->
<!--              <div-->
<!--                :class="[-->
<!--                  {-->
<!--                    rightBorder: item.rightBorder == false,-->
<!--                    bottomBorder: item.bottomBorder == false,-->
<!--                    absorbance: item.type != null,-->
<!--                  },-->
<!--                  'box',-->
<!--                ]"-->
<!--                v-for="(item, index) of data_c2_list_msg"-->
<!--                :key="'msg_c2_' + index"-->
<!--                :style="'width:' + item.width"-->
<!--              >-->
<!--                <p>{{ item.name }}:</p>-->
<!--                <p>{{ item.value == null ? '/' : item.value }}</p>-->
<!--              </div>-->
<!--              <el-divider></el-divider>-->
              <div class="error_info_box"  v-for="(item,index ) in error_info_temp"
                    :key="index" style="width: 100%">

                <div class="error_info_item" style="width: 14%">
                  <p class="">日期</p>
                  <p class="">{{item.date_time_order}}</p>
                </div>
               <div  class="error_info_item" style="width: 6%">
                 <p class="important">C{{ item.level }}</p>
               </div>
                <div  class="error_info_item" style="width: 13%">
                  <p>结果:(mg/L)</p>
                  <p>{{ item.qc_result }}</p>
                </div>
                <div  class="error_info_item" style="width: 12%">
                  <p>SD:(mg/L)</p>
                  <p>{{item.level == 1 ? item.sd_c1 : item.sd_c2 }}</p>
                </div>
                <div  class="error_info_item" style="width: 9%">
                  <p>CV:(%)</p>
                  <p v-if="item.level == 1 ">{{item.cv_c1 && item.cv_c1 !== '-' ? `${((item.cv_c1)*100).toFixed(2)}`  : '-'}}</p>
                  <p v-if="item.level == 2 ">{{item.cv_c2 && item.cv_c2 !== '-'  ? `${((item.cv_c2)*100).toFixed(2)}`  : '-' }}</p>
                </div>
                <div  class="error_info_item" style="width: 14%">
                  <p>偏倚值:(%)</p>
                  <p>{{ item.offset_value ? (item.offset_value * 100).toFixed(2) : '-' }}</p>
                </div>
                <div  class="error_info_item" style="width: 17%">
                  <p>质控范围(mg/L):</p>
                  <p>{{item.level == 1 ?  item.c1_range : item.c2_range }}</p>
                </div>
                <div  class="error_info_item line_last" style="width: 15%">
                  <p>违背质控规则:</p>
                  <p>{{item.level == 1 ?  item.c1_rule : item.c2_rule }}</p>
                </div>
                </div>
<!--              <el-divider></el-divider>-->
            </div>
            </div>
          <div class="card" v-if="showAnalysis">
            <p class="title">失控原因分析及纠正措施</p>
            <ul 
              v-for="(item, index) of analysisList"
              :key="'analysis_' + index"
            >
              <li style="width: 100%">
                <span class="analysisText">
                  {{ index + 1 + '、' + item.analysis_type_display }}
                </span>
              </li>
              <li class="dividerCla">
                <p>
                  <el-divider content-position="left">原因分析</el-divider>
                </p>
                <el-divider direction="vertical"></el-divider>
                <p>
                  <el-divider content-position="left">纠正措施</el-divider>
                </p>
              </li>
              <li
                class="analysisItem"
                v-for="(temp, indexS) of item.analysis"
                :key="'a_' + indexS"
                :class="
                  temp.is_additional || temp.analysis_name.length >= 24
                    ? 'widthbig'
                    : 'widthSmall'
                "
              >
                <p>
                  <el-checkbox
                    v-model="temp.value"
                    class="checkbox"
                    :disabled="is_complete"
                    @change="change_analysis(temp)"
                  ></el-checkbox>
                  <span>
                    {{ temp.analysis_name.split('(')[0] }}
                    <br v-if="temp.analysis_name.split('(')[1]" />
                    {{
                      temp.analysis_name.split('(')[1]
                        ? '(' + temp.analysis_name.split('(')[1]
                        : ''
                    }}
                    <br v-if="temp.is_additional" />
                    <el-input
                      v-if="temp.is_additional"
                      placeholder=""
                      :disabled="is_complete || !temp.value"
                      v-model="temp.content"
                      clearable
                      size="mini"
                      class="inpuStyle"
                    ></el-input>
                  </span>
                </p>
                <el-divider direction="vertical"></el-divider>
                <p>
                  <el-checkbox
                    class="checkbox"
                    v-model="temp.correction.value"
                    :disabled="true || is_complete"
                  ></el-checkbox>
                  <span>
                    {{ temp.correction.correction_name.split('(')[0] }}
                    <br v-if="temp.correction.correction_name.split('(')[1]" />
                    {{
                      temp.correction.correction_name.split('(')[1]
                        ? '(' + temp.correction.correction_name.split('(')[1]
                        : ''
                    }}
                    <br v-if="temp.correction.is_additional" />
                    <el-input
                      v-if="temp.correction.is_additional"
                      placeholder=""
                      :disabled="is_complete || !temp.value"
                      v-model="temp.correction.content"
                      clearable
                      size="mini"
                      class="inpuStyle"
                    ></el-input>
                  </span>
                </p>
              </li>

            </ul>
            <ul>
              <li>
                <li style="width: 100%">
                  <span class="analysisText" style="margin: 10px 0 14px 0">
                    {{7 + '、' + '其他' }}
                  </span>
                </li>
                <li>
                  <el-input type="textarea" :disabled="is_complete" v-model="otherReason" :placeholder="is_complete ? '' : '请输入其他原因'"  @blur="change_analysis({value:true,analysis_name:otherReason,correction:{correction_name:otherReason,value:true}},7)"></el-input>
                </li>
              </li>
            </ul>
          </div>
          <div class="card">
            <p class="title">纠正结果</p>
            <el-input
              placeholder="请输入纠正结果"
              :disabled="is_complete"
              type="textarea"
              v-model="correction_text"
              clearable
              size="small"
              maxlength="50"
              show-word-limit
              class="textareaStyle"
            ></el-input>
            <p class="title">结论</p>
            <el-input
              placeholder="请输入结论"
              :disabled="is_complete"
              type="textarea"
              v-model="conclusion_text"
              show-word-limit
              clearable
              size="small"
              maxlength="150"
              class="textareaStyle"
            ></el-input>
            <p class="title">效果评价：</p>
            <el-input
              placeholder="请输入效果评价"
              :disabled="is_complete"
              type="textarea"
              v-model="impact_text"
              clearable
              size="small"
              maxlength="150"
              show-word-limit
              class="textareaStyle"
            ></el-input>
          </div>
        </div>
        <div class="footed">
          <el-popconfirm
            v-show="!is_complete"
            title="失控报告保存后不能修改，请确认？"
            @confirm="save_report"
          >
            <el-button type="primary" size="medium" class="btn"  slot="reference" >保存报告</el-button>
          </el-popconfirm>
          <el-button v-show="is_complete" size="medium" type="primary" class="btn" @click="open_report">查看报告</el-button>
         
          <el-button size="medium" class="btn" @click="close_dialog">
            关闭页面
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import detailMenu from './detailMenu.vue'
import api from '@/api/quality'
import indoorMixins from './indoorMixins'

export default {
  components: {
    detailMenu,
  },
  mixins: [indoorMixins],
  props:{
    is_under_control_c1:{
      type: Boolean,
      default: false
    },
    is_under_control_c2:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      otherReason: '',
      dialogVisible: false,
      is_complete: false, //当前属于查看还是填写 true为查看
      file_url: [], //查看时的地址
      correction_text: '', //纠正结果
      conclusion_text: '', //结论
      impact_text: '', //效果评价
      param_original: {}, //前页面传过来的原始数据
      c1_show: false,
      c2_show: false,
      error_info:{}, // 失控信息
      error_info_temp:[], // 失控信息
      dataList: [
        {
          name: '科室名称',
          value: '',
          width: '25%',
          topBorder: true,
        },
        {
          name: '失控项目',
          value: '',
          width: '25%',
          topBorder: true,
        },
        {
          name: '失控日期',
          value: '',
          width: '25%',
          topBorder: true,
        },
        {
          name: '质控品名称',
          value: '',
          width: '25%',
          topBorder: true,
        },
        {
          name: '仪器名称',
          value: '',
          width: '25%',
        },
        {
          name: '机号',
          value: '',
          width: '25%',
        },
        {
          name: '质控品来源',
          value: '',
          width: '25%',
        },
        {
          name: '质控品批号',
          value: '',
          width: '25%',
        },
        {
          name: '质控品开瓶时间',
          value: '',
          width: '25%',
        },
        {
          name: '试剂批号',
          value: '',
          width: '25%',
        },
        {
          name: '试剂开瓶时间',
          value: '',
          width: '50%',
        },
        {
          name: '试剂唯一码',
          value: '',
          width: '100%',
        },
      ],
      qc_level: 1,
      data_c1_list_msg: [],
      data_c2_list_msg: [],
      analysisList_cause: [], //原因分析及纠正措施原始数据
      report_details_cause: {}, //点的详情数据原始数据
      analysisList: [],
      showAnalysis: false, //失控原因及分析显示
      otherReasonObjt: {},
    }
  },
  mounted() {
    // this.analysisList_cause = JSON.parse(localStorage.getItem('analysisList_cause')).a
    this.get_analysis_data()
  },
  methods: {
    /* 打开和关闭弹窗，其他页面也会调用 */
    open_dialog(param) {
      this.param_original = param
      this.showAnalysis = false;
      this.analysisList = JSON.parse(
        JSON.stringify({ arr: this.analysisList_cause })
      ).arr

      // 清空数据
      this.report_details_cause = {},
      this.dataList.forEach(e => {e.value = ''})

      this.conclusion_text = '',
      this.impact_text = '',

      this.loadingShow();
      let param_temp = JSON.parse(JSON.stringify(param))
      delete param_temp.selectRows
      // 根据接口获取数据并调整页面
      api.report_details(param_temp).then((res) => {
        this.report_details_cause = res.data
        if(this.report_details_cause.content && this.report_details_cause.content !== '/' && this.report_details_cause.content.analysis.length > 0){
          this.otherReason = this.report_details_cause.content.analysis.filter(item => item.analysis_type == '其他').length > 0 ? this.report_details_cause.content.analysis.filter(item => item.analysis_type == '其他')[0].analysis[0].analysis_name : ''
        }
        let data = res.data
        this.file_url = '';
        if(data.file_url.c1){
          this.file_url = [data.file_url.c1]
        }
        if(data.file_url.c2){
          this.file_url = [data.file_url.c2]
        }
        if(data.file_url && data.file_url !=='/' && data.file_url !=={}){
          this.file_url = [data.file_url]
        }
        this.is_complete = (this.file_url.length !=0 && Object.keys(this.file_url[0]).length > 0) ? true : false
        //   暂时注释
        // this.c1_show = data.qc_level.c1?true:false
        // this.c2_show = data.qc_level.c2?true:false
        //
        if (this.is_complete) {
          let content_temp = data.content
          // let content_temp = this.c1_show? data.content: data.content.c2
          //有原数据时填充进列表
          this.impact_text = content_temp.effect_evaluation
          this.conclusion_text = content_temp.conclusions
          this.correction_text = content_temp.corrected_results
          this.analysisList.forEach((item) => {
            let analysisItem = content_temp.analysis.find(
              (e) => e.analysis_type_id == item.analysis_type,
            )
            if (analysisItem) {
              item.analysis.forEach((temp) => {
                let analysisItem_temp = analysisItem.analysis.find(
                  (e) => e.analysis_id == temp.analysis_id,
                )
                if (analysisItem_temp) {
                  temp.content = analysisItem_temp.additional_reasons
                  temp.value = true
                  temp.correction.value = true
                  let correctionItem = content_temp.correction.find(
                    (e) => e.correction_type_id == item.analysis_type,
                  )
                  let correctionItem_temp = correctionItem.correction.find(
                    (e) => e.correction_id == temp.correction.correction_id,
                  )
                  temp.correction.content =
                    correctionItem_temp.additional_measures
                }
              })
            }
          })
        }
        // 给基本信息赋值
        if(data && data.base_info){
          this.dataList[0].value = data.base_info.hos_pos_name ? data.base_info.hos_pos_name : '/' //科室名称
          this.dataList[1].value = data.base_info.testing_unique //失控项目
          this.dataList[2].value = data.base_info.expire_date ? data.base_info.expire_date : '/' //失控日期
          this.dataList[3].value = data.base_info.qc_serum_name ? data.base_info.qc_serum_name : '/' //质控品名称
          this.dataList[4].value = data.base_info.platform_unique //仪器名称
          this.dataList[5].value = data.base_info.instrument_id //机号
          this.dataList[6].value = data.base_info.serum_source ? data.base_info.serum_source : '/' //质控品来源
          this.dataList[7].value = data.base_info.qc_batch //质控品批号
          this.dataList[8].value = data.base_info.serum_open_time ? data.base_info.serum_open_time : '/' //质控品开瓶时间
          this.dataList[9].value = data.base_info.kit_batch ? data.base_info.kit_batch : '/' //试剂批号
          this.dataList[10].value = data.base_info.kit_open_time ? data.base_info.kit_open_time : '/' //试剂开瓶时间
          this.dataList[11].value = data.base_info.kit_id ? data.base_info.kit_id : '/' //UDI
        }
        // if(this.c1_show) {
        //   this.data_c1_list_msg = [
        //     {
        //       name: '结果(mg/L)',
        //       value: data.qc_result.c1,
        //       width: '18%',
        //     },
        //     {
        //       name: 'SD(mg/L)',
        //       value: data.qc_info.sd_c1?data.qc_info.sd_c1.toFixed(2):'',
        //       width: '12%',
        //     },
        //     {
        //       name: 'CV(%)',
        //       value: data.qc_info.cv_c1?(data.qc_info.cv_c1 * 100).toFixed(2):'',
        //       width: '12%',
        //     },
        //     {
        //       name: '偏倚值',
        //       value: data.offset_value.c1,
        //       width: '12%',
        //     },
        //     {
        //       name: '质控范围(mg/L)',
        //       value: data.qc_range.c1,
        //       width: '18%',
        //     },
        //     {
        //       name: '违背质控规则',
        //       value: param.c1_rule,
        //       width: '18%',
        //     },
        //   ]
        // }else {
        //   this.data_c1_list_msg = []
        // }
        // if(this.c2_show) {
        //   this.data_c2_list_msg = [
        //     {
        //       name: '结果(mg/L)',
        //       value: data.qc_result.c2,
        //       width: '13%',
        //     },
        //     {
        //       name: 'SD(mg/L)',
        //       value: data.qc_info.sd_c2?data.qc_info.sd_c2.toFixed(2):'',
        //       width: '13%',
        //     },
        //     {
        //       name: 'CV(%)',
        //       value: data.qc_info.cv_c2?(data.qc_info.cv_c2 * 100).toFixed(2):'',
        //       width: '10%',
        //     },
        //     {
        //       name: '偏倚值',
        //       value:data.offset_value.c2,
        //       width: '13%',
        //     },
        //     {
        //       name: '质控范围(mg/L)',
        //       value: data.qc_range.c2,
        //       width: '18%',
        //     },
        //     {
        //       name: '违背质控规则',
        //       value: param.c2_rule,
        //       width: '18%',
        //     },
        //
        //   ]
        // }else {
        //   this.data_c2_list_msg = []
        // }
        this.error_info = res.data.error_info
        if(this.param_original.selectRows){
        this.param_original.selectRows.forEach(item => {
          if(item.c1_select){
            this.error_info[item.c1_id].date_time_order = item.date_time_order
            this.error_info[item.c1_id].c1_rule = item.c1_rule
          }
          if(item.c2_select){
            this.error_info[item.c2_id].date_time_order = item.date_time_order
            this.error_info[item.c2_id].c2_rule = item.c2_rule
          }
        })
          // 将对象的属性转换为数组的元素
          let arr = Object.entries(this.error_info).map(([key, value]) => ({ ...value, key: parseInt(key, 10) }));
          // 根据 date_time_order 对数组进行倒序排列
          arr.sort((a, b) => {
            let timeA = parseFloat(a.date_time_order.split('.')[1]);
            let timeB = parseFloat(b.date_time_order.split('.')[1]);
            return timeB - timeA; // 倒序排列
          });
          this.error_info_temp = arr

        }else{ // 单条
          this.error_info_temp = this.error_info
          for(let i= 0;i<this.error_info_temp.length;i++){
            this.error_info_temp[i].date_time_order = this.param_original.date_time_order
          }
        }
        let level_list = []
        level_list = this.error_info_temp.map(item => {
          return item.level
        })
        if( level_list.includes(1)&& level_list.includes(2)){
          if(this.param_original.c1 && this.param_original.c2 ){ // 如果c1和c2都有值
            if(this.is_under_control_c1 && this.is_under_control_c2){ // 如果c1和c2都在控
              this.correction_text = `c1:${this.param_original.c1},c2:${this.param_original.c2}`;
            }else if(this.is_under_control_c1 && !this.is_under_control_c2){ //如果c1在控，c2不在控
              this.correction_text = `c1:${this.param_original.c1}`
            }else if(!this.is_under_control_c1 && this.is_under_control_c2){ //如果c2在控，c1不在控
              this.correction_text = `c2:${this.param_original.c2}`;
            }
          }else if(!this.param_original.c1 && this.param_original.c2 && this.is_under_control_c2){
            this.correction_text = `c2:${this.param_original.c2}`;
          }else if(this.param_original.c1 && !this.param_original.c2 && this.is_under_control_c1){
            this.correction_text = `c1:${this.param_original.c1}`;
          }
        }else if( level_list.includes(1) && this.param_original.c1 && this.is_under_control_c1){
          this.correction_text = `c1:${this.param_original.c1}`;
        }else if (level_list.includes(2) && this.param_original.c2 && this.is_under_control_c2){
          this.correction_text = `c2:${this.param_original.c2}`;
        }
        this.controlReportData = res.data
        this.showAnalysis = true
        this.dialogVisible = true
        this.$nextTick(() => {
          this.$refs.body?.scrollTo({ top: 0,});
        });
      }).finally(()=> {
        this.loading.close();
      })
    },
    close_dialog() {
      this.otherReason = ''
      this.dialogVisible = false
    },
    input_analysis(){
      this.change_analysis(obj)
    },
    /* 选择失控原因 */
    change_analysis(temp,type) {
      if(type !== 7){
        if(temp.value){
          temp.correction.value = true;
        }else{
          temp.correction.value = false;
          temp.correction.content = '';
        }
      }
      // if(temp.value){
      //   temp.correction.value = true;
      // }else{
      //   temp.correction.value = false;
      //   temp.correction.content = '';
      // }
      let text = ''
      this.analysisList.forEach((temp,index) => {
        temp.analysis.forEach(e=> {
          if(e.value){
            text+= text?`,${e.analysis_name}`: e.analysis_name
          }
        })
      })
      if(this.otherReason){
        if(text){
          text = text+`，${this.otherReason}`
        }else{
          text = text+`${this.otherReason}`
        }

      }
      if(text == '') {
        this.impact_text = ""
      }else {
        this.impact_text = `此次失控是由于${text}造成，现已纠正且符合质控判断规则，验证通过`
      }
    },
    /* 保存失控报告 */
    save_report() {
      var analysis_list = [];
      this.analysisList.forEach(item=> {
        item.analysis.forEach(temp => {
          if(temp.value){
            analysis_list.push({
              analysis_id: temp.analysis_id,
              additional_reasons: temp.content?temp.content:null,
              correction_id: temp.correction.correction_id,
              additional_measures: temp.correction.content?temp.correction.content:null,
            })
          }
        })
      })
      if(this.otherReason){
        let otherReasonObjt = {
          analysis_id: null,
          additional_reasons: this.otherReason,
          correction_id: null,
          additional_measures: null,
        }
        analysis_list.push(otherReasonObjt)
      }
      // if(this.c1_show){

      // }
      // if(this.c2_show){

      // }
      let rules = {}
      let targets = {}
      let sds = {}
      let qc_point_ids = this.param_original.qc_point_ids.split(',')
      let qc_point_ids_temp = this.param_original.qc_point_ids
      // if(qc_point_ids.length==1) {
      //   rules[qc_point_ids[0]] = this.c1_show?this.param_original.c1_rule:this.param_original.c2_rule
      //   targets[qc_point_ids[0]] = this.param_original.c1_x
      //   sds[qc_point_ids[0]] =this.param_original.c1_sd
      // }else {
      //   rules[qc_point_ids[0]] = this.param_original.c1_rule
      //   rules[qc_point_ids[1]] = this.param_original.c2_rule
      //   targets[qc_point_ids[0]] = this.param_original.c1_x
      //   targets[qc_point_ids[1]] = this.param_original.c2_x
      //   sds[qc_point_ids[0]] = this.param_original.c1_sd
      //   sds[qc_point_ids[1]] = this.param_original.c2_sd
      // }

      qc_point_ids.forEach(item => {
        if(this.error_info[item].level == 1){
          rules[item] = this.error_info[item].c1_rule
          targets[item] = this.param_original.c1_x
          sds[item] = this.error_info[item].sd_c1

        }else if(this.error_info[item].level ==2){
          rules[item] = this.error_info[item].c2_rule
          targets[item]= this.param_original.c2_x
          sds[item] = this.error_info[item].sd_c2
        }
      })
      let param = {
        qc_serum_id: this.param_original.qc_serum_id,
        qc_point_ids,
        // qc_point_ids: qc_point_ids_temp,
        rules,
        hospital_id: this.param_original.hospital_id,
        serum_open_time: this.report_details_cause.base_info.serum_open_time,
        kit_open_time: this.report_details_cause.base_info.kit_open_time,
        corrected_results: this.correction_text,
        conclusions: this.conclusion_text,
        effect_evaluation: this.impact_text,
        analysis_list,
        targets,
        sds,
      }
      this.loadingShow('数据正在提交，请稍后')
      api.create_qc_report(param).then((res) => {
        if (res.code == 200) {
          this.is_complete = true;
          this.$message.success('操作成功!')
          this.file_url = res.data.pdf_url;
          this.$emit('success',this.file_url)
          this.open_report();
        } else {
          this.$message.error('操作失败!')
        }
      }).finally(msg => {
        this.loading.close();
      })
    },
    /* 查看失控报告 */
    open_report() {
      window.open(this.file_url[0], "_blank");
     
    },
    /* 获取失控原因以及纠正措施 */
    get_analysis_data() {
      api.analysis_data().then((res) => {
        if (res.code == 200) {
          this.analysisList_cause = res.data
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .dialog_right {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  height: 100vh;
  .el-dialog__header {
    height: 0;
    padding: 1px;
  }
  .el-dialog__body {
    margin: $gap;
    padding: $gap;
    height: 95vh;
  }
}
.report-box {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $dialog-title-size;
    border-bottom: $border-color solid 1px;
    p {
      font-weight: bold;
      color: #111111;
      font-size: $dialog-title-size;
      padding-bottom: $gap;
      width: 20%;
    }
  }
  .body {
    margin: $gap;
    height: 85vh;
    
    overflow: auto;
    .title {
      font-size: $tip-size;
      font-weight: bold;
      padding: $gap;
    }

    ul {
      padding: $gap;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: $dialog-text-size;
      flex-wrap: wrap;
      li {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        P {
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          span {
            display: inline-block;
            ::v-deep .inpuStyle {
              margin: 0 !important;
              margin-bottom: px(4) !important;
            }
          }
        }
      }
      .analysisText {
        font-size: $dialog-text-size;
        background-color: #d0e17d;
        padding: $gap;
        width: 15%;
        box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.2);
      }
      .analysisItem {
        border-radius: px(4);
        min-height: px(40);
        margin-bottom: $gap;
        border: 1px solid $border-color;
        padding: $gap;
        .el-checkbox {
          margin-right: $gap;
        }
        ::v-deep .el-divider {
          height: 2em;
        }
        .inpuStyle {
          margin-left: px(10);
          width: px(300);
        }
      }
      .analysisItem:nth-child(odd) {
        background-color: #f8f8f8;
      }
      .analysisItem:nth-child(even) {
        background-color: #fff;
      }
      .dividerCla {
        p {
          ::v-deep .el-divider {
            overflow: initial;
            .el-divider__text {
              font-size: $dialog-text-size;
            }
          }
        }
        ::v-deep & > .el-divider {
          height: 2em;
        }
      }
      .textareaStyle {
        font-size: $table-th-size;
      }
      
    }
    .textareaStyle:last-child {
      margin-bottom: $gap;
    }
  }
  .footed {
    text-align: center;
    margin-top: $gap;
    
  }
}

.menu {
  min-height: px(90);
  background-color: #fff;
  border-radius: 5px;
  padding: 0 px(8);
  display: flex;
  margin-bottom: px(20);
  font-size: px(20);
  flex-wrap: wrap;
  box-sizing: border-box;

  .box {
    border-right: 1px solid $border-color;
    box-sizing: border-box;
    padding: px(10) 0 0 px(10);
    height: px(80);
    border-bottom: 1px solid $border-color;
    border-top: 1px solid $border-color;
    p:nth-child(1) {
      color: #999999;
      font-size: $table-td-size;
      height: $table-td-size+$gap;
    }
    p:nth-child(2) {
      margin-top: 1/2*$gap;
      font-size: $table-td-size;
      font-weight: bold;
      color: #333333;
    }
  }
  .msgBox {
    p {
      line-height: px(60);
      font-size: $title-size !important;
      color: #333333 !important;
      font-weight: bold;
    }
  }
  .box:nth-last-child(1) {
    border-right: none;
  }

  .bottomBorder {
    border-bottom: none !important;
  }
  .rightBorder {
    border-right: none;
  }
  .absorbance {
    height: px(100);
  }
}

.menu{
  border-top:1px solid #d7d7d7;
  border-radius: 0;
}
.error_info_item{
  display: inline-block;
  vertical-align: top;
  //border-top:1px solid #d7d7d7;
  border-right:1px solid #d7d7d7;
  border-bottom: 1px solid #d7d7d7;
  padding:4px;
  box-sizing: border-box;
  height: 4.16vw;
  p:nth-child(1) {
    line-height: 1.3;
    color: #999999;
    font-size: 0.9375vw;
    margin-bottom: 0.3125vw;
  }
  p:nth-child(2),p.second {
    margin-top: 0.5208333333vw;
    font-size: 0.9375vw;
    color: #333333;
  }
  p.important{
    font-size: 1.25vw !important;
    color: #333 !important;
    font-weight: 700;
    line-height: 3.6vw;
  }
}
.line_last.error_info_item{
  border-right: none;
}

</style>