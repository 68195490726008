<template>
  <div class="left_content">
    <div class="page_title">
      <div class="tab_content">
        <div class="tab_item" @click="changeTab('1')"> <span   :class="isRecommend === '1' ? 'active' : ''">推荐商品</span></div>
        <div class="tab_item" @click="changeTab('0')"><span    :class="isRecommend === '0' ? 'active':''">商品列表</span></div>
      </div>
      <div class="right_btn" >
        <el-button size="mini" class="order_button" @click="allOrder" v-if="isRecommend === '1'">
          <span>一键订购</span>
          <el-tooltip class="item" popper-class="custom-tooltip" effect="light" content="仅支持选择推荐商品" placement="top">
            <i class="el-icon-question"  style="position: absolute;top: 2px;right: 2px;color:#BD3124"></i>
          </el-tooltip>
        </el-button>
      </div>
    </div>
    <div class="search_block font-resize" v-if="isRecommend == '0'">
      <el-input clearable v-model="searchParams.name" size="mini" style="display:inline-block;width :350px;margin:10px 0 0 0" class="search_input"  @clear="search" @blur="search" @keyup.enter.native="search"
                placeholder="请输入存货名称/商品简称">
      </el-input>
      <i slot="append" class="search_icon" @click="search"></i>
    </div>
    <div class="list_block" :class="getClass()">
<!--      :style="'height:' + tableHeight+ 'px'"-->
      <div class="trade_list"  ref="sheet" :class="getClass()"  v-loading="loading" element-loading-text="数据加载中" >
        <div class="empty-container"  v-if="!loading && tradeList.length == 0">
          <el-empty description="暂无数据"></el-empty>
        </div>
        <div class="item_block"  v-for="(item,index) in tradeList" :key="index" v-if="tradeList.length > 0">
          <div>
            <el-row  class="trade_item"  :class="item.is_in_cart ? 'is_order' : ''">
              <el-row :span="24" >
                <el-col :span="24">
                  <span class="inventory_name">{{ item.inventory_full_name ? item.inventory_full_name : '/'}}</span>
                  <el-tag v-if="isRecommend=='1' && item.order_total" effect="plain" type='warning' size="small" style="margin-left:8px">已购次数：&nbsp;{{item.order_total ? item.order_total : ''}}次</el-tag>
                </el-col>
              </el-row>
              <el-col :span="20">
                <el-row :span="24">
                  <el-col :span="24">
                    <span>简称：{{item.inventory_name ? item.inventory_name : '/'}}</span>
                  </el-col>
                  <el-col :span="12">
                    <span>规格/型号：{{item.standard ? item.standard : '/'}}</span>
                  </el-col>
                  <el-col :span="12">
                    <span>使用平台：{{item.platform_name ? item.platform_name : '/'}}</span>
                  </el-col>
                  <el-col :span="12">
                    <span>方法学：{{item.method ? item.method : '/'}}</span>
                  </el-col>
                  <el-col :span='12'>
                    <span>计量单位：{{item.sale_unit ? item.sale_unit : '/'}}</span>
                  </el-col>
                  <el-col :span="12">
                    <span>注册证号：{{item.commodity ? item.commodity : '/'}}</span>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :span="4">
                <el-button size="mini" v-if="item.is_in_cart" class="order_btn el-icon-check">已购</el-button>
                <el-button size="mini" v-else class="order_btn" @click="orderGoods(item)">订购</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isRecommend == '0' && tradeList.length > 0" class="page_block" >
      <el-pagination
          v-if="isPaginationShow && isRecommend == '0' && tradeList.length > 0 && searchParams.total > 0"
          :key="paginationKey"
          background
          style="width: 100%"
          :pager-count="3"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="searchParams.size"
          :total="searchParams.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="searchParams.page"
          layout="total, sizes, prev, pager, next"
      >
      </el-pagination>
    </div>

  </div>
</template>
<script>
import common from "@/views/OrderManagement/mixin/common";
import {addCart, getInventoryList, getRecommendList} from "@/api/orderGoods/custom";
export default {
  mixins: [common],
  data() {
    return {
      activeName: '1',
      loading: false,
      isRecommend: '1', // 1推荐列表 0商品列表
      tableHeight: 0,
      paginationKey: 0,
      isPaginationShow: false,
      number_entries: 0, //进入页面的次数
      searchParams:{
        name: '',
        page: 1,
        size: 10,
        total: 0,
      }
    }
  },
  created() {
    this.loading = true
    this.getTableList()
  },
  watch:{
    isRecommend(newVal,oldVal){
      if (newVal) {
        this.paginationKey++; // 增加key值，强制更新
      }
      this.getTableList()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    allOrder(){
      if(this.tradeList.length < 1){
        this.$message.warning('无推荐商品')
        return
      }else{
        let cart = []
        this.tradeList.forEach(item => {
          if(item.inventory_id ){
            let shop_item = {
              inventory_id: item.inventory_id,
              // quantity: 1
            }
            cart.push(shop_item)
          }
        })
        this.$emit('updateCart',)
        addCart({cart: cart,one_click: true}).then(res => {
          if(res.code == 200){
            this.$message.success('订购成功')
            this.getTableList()
            // item.isOrder = true
          }else{
            this.$message.error(res.data.msg)
          }
        })
      }
    },
    getClass(){
      let classes = [];
      if(this.tradeList.length == 0 ){
        classes.push('trade_background');
      }
      if(this.isRecommend=='0'){
        classes.push('shop');
        if(this.tradeList.length === 0){
          classes.push('empty');
        }
      }
      if(this.loading){
        classes.push('no-scroll');
      }else{
        // classes.push('scroll');
      }
      return classes.join(' ');

    },
    fetchData() {
      setTimeout(() => {
        // this.searchParams.total = 10; // 异步请求后更新 total
      }, 1000);
    },
    changeTab(isRecommend){
      this.isRecommend = isRecommend
      this.getTableList()
    },
    getTableList() {
      this.$nextTick(() => {
        // 刷新表格滚动到顶部
        const blockWrapper = this.$refs.sheet
        window.requestAnimationFrame(() => {
          blockWrapper.scrollTop = 0;
        });
      });
      this.loading = true
      if(this.isRecommend === '1'){ // 推荐商品
        getRecommendList().then(res => {
          if (res.code === 200) {
            if(res.data.length > 0){
              this.tradeList = res.data
              this.loading = false
            }else{
              this.tradeList = []
              if(this.number_entries === 0 && res.data.length === 0){ //首次进来如果推荐列表没数据，切换到商品列表
                this.changeTab('0')
              }
              // if(this.number_entries > 0){
                this.loading = false
              // }
              this.number_entries ++
            }
          }
        }).finally(()=>{

        })
      }else{ // 商品列表
        let params ={
          name: this.searchParams.name,
          page: this.searchParams.page,
          size: this.searchParams.size
        }
        getInventoryList(params).then(res => {
          if (res.code === 200) {
            this.loading = false
            this.tradeList = res.data.data
            this.searchParams.total = res.data.count || 0
            this.isPaginationShow = true

          }
        }).catch(err => {
          if( err.status === 'error' && this.searchParams.page > 1 ){
            this.searchParams.page = 1
            this.getTabelList()
          }else{
            // this.$message.error(err.msg)
          }
        }).finally(()=>{
          this.loading = false
        })
      }
    },
    orderGoods(item){
      let shop_item = {
        inventory_id: item.inventory_id,
        // quantity: 1
      }
      let cart = []
      cart.push(shop_item)
      this.$emit('updateCart', item,this.isRecommend )
      addCart({cart: cart}).then(res => {
        if(res.code == 200){
          this.$message.success('订购成功')
          item.isOrder = true
          this.getTableList()
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    handleSizeChange(val){
      this.searchParams.size = val
      this.getTableList()
    },
    handleCurrentChange(val){
      this.searchParams.page = val
      this.getTableList()
    },
    search() {
      this.getTableList()
    },
  }
}
</script>
<style scoped lang="scss">
@import "~@/assets/css/order.scss";
::-webkit-scrollbar { // 隐藏滚动条背景
  width: 0;
  height: 0;
  background: transparent;
}
::v-deep .el-button:focus, .el-button:hover{
  background: #fff;
}
::v-deep .el-input--mini .el-input__icon {
  line-height: 28px;
  margin-right: 10px;
}
.left_content{
  height: 100%;
  position: relative;
  .page_title{
    background: #fff;
    height: 44px;
    vertical-align: top;
    line-height: 44px;
    border-radius: 4px;
    margin-left: 10px;
    .tab_content{
      display: inline-block;
      width: 80%;
      line-height: 44px;
      .tab_item{
        display: inline-block;
        cursor: pointer;
      }
      span{
        padding: 13px 20px;
        vertical-align: top;
        font-size: 16px;
      }
      .tab_item:first-child span{
        border-radius: 4px  0 0 4px;
      }
      span.active{
        background: #4095E5;
        color: #fff;
      }
    }
    .right_btn{
      display: inline-block;
      float: right;
      width: 19%;
      line-height: 44px;
      text-align: right;
      padding-right: 46px;
      box-sizing: border-box;
      button{
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        padding: 0 20px;
        vertical-align: top;
        margin-top: 7px;
      }
    }
  }
  .list_block {
    display: inline-block;
    padding-top: 6px;
    width:  100%;
    height: calc(100% - 50px);
    .trade_list {
      overflow-y: scroll;
      width: 100%;
      margin-top: 10px;
      height:  calc(100% - 10px);
      .trade_item {
        background: #fff;
        border-radius: 4px;
        //margin-bottom: 10px;
        padding: 10px 14px;
        margin: 0 6px 10px 10px;
        .inventory_name{
          font-weight: 600;
          line-height: 2.4;
          color: #000000b3;
        }
        span {
          //font-weight: 300;
          line-height: 2;
        }

        .order_btn {
          //padding: 6px 30px;
          color: #567722;
          border-color: #567722;
          box-sizing: border-box;
          vertical-align: top;
          padding: 0;
        }
        .el-tag {
          line-height: 22px;
        }
      }
      .trade_item.is_order {
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2),
        -5px 5px 5px rgba(0, 0, 0, 0.2),
        0px 5px 5px rgba(0, 0, 0, 0.1);
      }
    }
  }
  .trade_list.no-scroll { // loading时不允许滚动
    overflow: hidden;
    height: calc(100% - 10px);
  }
  .trade_list.shop .trade_item.is_order{
    border: 1px solid #E99D42;
  }
  .trade_list .trade_item.is_order{
    border: 1px solid #567722;
  }
  .list_block.shop{
    display: inline-block;
    padding-top: 0px;
    width:  100%;
    height: calc(100% - 98px);
    .trade_list.shop{
      width:  100%;
      box-sizing: border-box;
      height: calc(100% - 56px);
      .trade_item.is_order{
        .order_btn{
          color: #E99D42;
          border-color: #E99D42;
        }
      }
    }
    .trade_list.shop.empty{
      height: 100%;
    }
  }

  .trade_list.trade_background{
    background: #fff;
  }
}
::v-deep .search_block .el-input input{
  width: 100%;
  margin-left: 10px;
}
::v-deep .el-tag--plain.el-tag--warning {
  background-color: #fff;
  border-color: #f48807;
  color: #f48807;
}
.page_block{
  position: absolute;
  width: calc(100% - 21px);
  padding-right: 20px;
  //background: #fff;
  height: 60px;
  line-height: 60px;
  bottom: 0
}
::v-deep .el-pagination{
  margin-top: 17px;
  margin-right: 20px;
  display: inline-block;
  text-align: right;
  line-height: 60px;
}
.empty-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* 设置容器高度为视口高度，以便于居中 */
}
.search_block{
  position: relative;
}
.search_block .search_icon{
  background-image: url("../../../../assets/img/search.png") ;
  background-size: cover;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 332px;
  top:16px;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  ::v-deep .el-icon-check:before {
    font-size: 15px;
    margin-right: 4px;
  }
  .right_btn {
    padding-right: 38px!important;
  }
  ::v-deep .search_block .el-input input{
    font-size: 13px;
  }

  ::v-deep .search_block .el-input__suffix {
    line-height: 32px!important;
    height: 32px!important;
  }
  .trade_list{
    .trade_item{
      span.el-tag{
        font-size: 12px;
      }
      .inventory_name{
        font-size: 15px;
      }
      span{
        font-size: 13px;
      }
      .order_btn{
        font-size: 14px;
        margin-top: 22px;
        width: 90px;
        height: 30px;
        line-height: 30px;
      }
    }
  }
}
.left_content .list_block .trade_list .item_block:last-child .trade_item {
  margin-bottom: 0!important;
}
@media screen and (min-width: 1551px) , print {
  ::v-deep .el-icon-check:before {
    font-size: 18px;
    margin-right: 5px;
  }
  ::v-deep .search_block .el-input input{
    font-size: 16px;
  }
  .trade_list{
    .trade_item{
      span.el-tag{
        font-size: 14px;
      }
      .inventory_name{
        font-size: 17px;
      }
      span{
        font-size: 16px;
      }
      .order_btn{
        font-size: 16px;
        margin-top: 26px;
        width: 120px;
        height: 40px;
        line-height: 40px;
      }
    }
  }
}


::v-deep .custom-tooltip.el-tooltip__popper .popper__arrow{
  //border-color: #BD3124!important;
}
.order_button{
  position: relative;
  color: #567722;
  border-color: #5c9602;

}
.order_button:hover{
  background: #fff;
}
::v-deep  .el-input__inner.el-input__suffix {
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 32px!important;
}

::v-deep .el-input__inner .el-input__suffix-inner {
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 32px!important;
}
::v-deep .el-input--suffix .el-input__inner{
  padding-right: 48px;
}
::v-deep .el-input__inner .el-input__suffix .el-input__clear {
  line-height: 32px;
}
::v-deep .el-input__suffix .el-input__clear {
  vertical-align: middle;
  line-height: 32px;
  font-size: 16px;
  color: #9D9D9D;
}
</style>
<style>
.custom-tooltip{
  border-color: #BD3124!important;
  color:#BD3124!important;
  .popper__arrow {
    border-top-color: #BD3124!important; /* 替换为你想要的颜色 */
  }
}
</style>