
<template>
<!--  客户端审批前可修改订单信息-->
  <div>
    <el-drawer
        title="订单详情"
        :visible.sync="isDrawerShow"
        :wrapperClosable="false"
        :before-close="closeDrawer"

        size="70%">
      <template #title>
        <span class="component_title">订单详情</span>
      </template>
      <div class="orderDtl"  style="height:100%;overflow: auto" v-loading="loading" :element-loading-text="loadingText" ref="orderDtl" @scroll="handleScroll">
        <div class="orderInfo">
          <el-row :span="24">
                <el-col :span="8">
                  <div class="block">
                    <p>订单号</p>
                    <div>{{form.order_code}}</div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="block">
                    <p>下单时间</p>
                    <div>{{form.create_time}}</div>
                  </div>
                </el-col><el-col :span="8">
                <div class="block">
                  <p>订单状态</p>
                  <div style="font-weight: bold" :style="statusColor(form.order_status_name)">{{form.order_status_name}}</div>
                </div>
              </el-col>

              </el-row>
          <el-row :span="24">
            <el-col :span="24">
              <div class="block" style="border-right: none">
                <p>开票信息
                  <i class="el-icon-edit" :style="{color: isInvoiceEdit ? '#409EFF' : '','margin-left':'10px', 'margin-right':'14px'}" @click="isInvoiceEdit = true"></i>
                  <i class="el-icon-check" @click="handleUpdateInfo('invoice')"></i>
                </p>
                <div v-if="!isInvoiceEdit">
                  <span style="margin-right: 30px;">{{form.invoice_info.invoice_header}}</span>
                  <span style="margin-right: 30px;">{{form.invoice_info.registration_code}}</span>
                  <span style="color: #BD3124;margin-right: 16px;">{{form.invoice_info.contact_person}}</span>
                  <span style="color: #BD3124;">{{form.invoice_info.contact_email}}</span>
                </div>
                <div v-if="isInvoiceEdit" style="display: inline-block;width: 100%; ">
                  <el-form :rules="formRules" ref="invoice_info"  :model="form.invoice_info">
                  <el-col :span="6">
                    <el-form-item prop="invoice_header" >
                      <el-input size="mini" placeholder="请填写发票抬头" v-model="form.invoice_info.invoice_header"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="5">
                    <el-form-item prop="registration_code" >
                      <el-input size="mini" placeholder="请填写公司税号" v-model="form.invoice_info.registration_code"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item prop="contact_person">
                      <el-input size="mini" placeholder="请填写收票人" v-model="form.invoice_info.contact_person"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item prop="contact_email">
                      <el-input size="mini" placeholder="请填写接收邮箱" v-model="form.invoice_info.contact_email"></el-input>
                    </el-form-item>
                  </el-col>
                  </el-form>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <div class="block" style="border-right: none">
                <p>{{form.delivery_type==4002 ? '收货人信息' : '提货人信息' }}
                  <i class="el-icon-edit" :style="{color: isReceiveEdit ? '#409EFF' : '','margin-left':'10px', 'margin-right':'14px'}" @click="isReceiveEdit = true"></i>
                  <i class="el-icon-check" @click="handleUpdateInfo('address')"></i>
                </p>

                 <div v-if="!isReceiveEdit" class="check1">
                   <div><span style="margin-right: 20px;">{{ form.receive_address.customer_contact }}</span>{{form.receive_address.customer_phone}}</div>
                   <div>{{form.receive_address.customer_address}}</div>
                 </div>
                <div v-if="isReceiveEdit">
                  <el-row>
                    <el-col :span="24">
                      <el-form :rules="formRules" ref="receive_info"  :model="form.receive_address">
                      <el-col :span="4">
                        <el-form-item prop="customer_contact">
                        <el-input size="mini" :placeholder="form.delivery_type== 4001 ? '请填写提货人' : '请填写收货人' " v-model="form.receive_address.customer_contact"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8">
                        <el-form-item prop="customer_phone">
                        <el-input size="mini" placeholder="请填写电话" v-model="form.receive_address.customer_phone"></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="24">
                        <el-form-item prop="customer_address">
                          <el-input style="margin-top: 10px" placeholder="请填写地址" size="mini" v-model="form.receive_address.customer_address"></el-input>
                        </el-form-item>
                      </el-col>
                      </el-form>
                    </el-col>
                    <el-col :span="24">
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="goodsList">
          <p class="block_title component_inner_title" style="color: #377F7F">商品清单</p>
          <el-table
              v-loading="tableLoading"
              :element-loading-text="loadingText"
              ref="goodsTable"
              :data="tradeTableData"
              max-height="450px"
              :scroll-y="true"
              @scroll.native="handleTableScroll"
              style="width: 100%">
            <el-table-column label="#" type="index" width="50" align="center" header-align="center">
            </el-table-column>
            <el-table-column prop="full_name" label="商品全称" min-width="280" align="left" header-align="center">
              <template v-slot="{ row, $index }">
                <span :style="{color: row.is_first ? '#BD3124' : ''}" v-if="!row.isEdit">{{row.inventory_full_name ? row.inventory_full_name : '/'}}</span>
<!--                <select-product v-if="row.isAdd" :clo_index="$index"  @chooseGoods="handleFullNameChange" />-->
<!--                @change="chooseGoods(row,$index)"-->
                <el-select
                    v-if="row.isEdit"
                    :ref="'mySelect'+$index"
                    :style="{ top: selectTop + 'px' }"
                    popper-class="fixed-select-popper"
                    @focus="selectFocus($index)"
                    @change="handleFullNameChange"
                    v-model="row.inventory"
                    placeholder="请输入关键字"
                    loading-text="正在从服务端获取数据"
                    size="small"
                    filterable
                    clearable
                    remote
                    :popper-append-to-body="false"
                    :remote-method="getGoodsList"
                    @visible-change="handleVisibleChange"
                >
                  <el-option
                      :class="isOptionShow ? 'show' : 'unShow'"
                      v-if="isOptionShow"
                      v-for="item in goodsList"
                      :key="item.value"
                      :label="item.label"
                      :disabled="item.disabled"
                      :value="item">
                  </el-option>
                </el-select>
                <el-tag size="mini" effect="dark" style="background: #BD3124;border: none" v-if="row.is_first && !row.isEdit" class="new_pro_tag">本次新购商品</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="inventory_name" label="商品简称" width="130" align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span >{{row.inventory_name ? row.inventory_name : '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="standard" label="规格/型号" width="130" align="center" text-align="center">
              <template v-slot="{ row, $index }">
                <span >{{row.standard ? row.standard : '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="unit_price"  label="单价" width="90" align="center" text-align="center">
              <el-table-column align="center"
                               class-name="children_column"
                               prop="unit_price"
                               label="(单位：元)"
                               width="90">
                <template v-slot="{ row, $index }">
                  <span >{{row.unit_price ? row.unit_price : '0.00'}}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="orders_num" label="数量" width="200" align="center" text-align="center">
              <template v-slot="{ row, $index }">
                <el-input-number v-model="row.orders_num" :disabled="cantOpt && !row.isAdd" @change="handleCountChange(row)" :min="1" style="width: 130px" size="mini" label="请输入正整数"></el-input-number>
              <span style="padding-left:8px;">{{row.sale_unit}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="orders_sum" label="总价"  width="160" align="center" text-align="center">
              <el-table-column align="center"
                               class-name="children_column"
                               prop="orders_sum"
                               label="(单位：元)"
                               width="160">
                <template v-slot="{ row, $index }">
                  <span >{{row.orders_sum ? row.orders_sum : '0.00'}}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column prop="oprate" class-name="oprate" label="操作" width="100" align="center" header-align="center" fixed="right" :key="Math.random()">
              <template v-slot="{ row, $index }">
                <div :class="row.isNoSave ? 'no_save':''">
                <span  v-if="tradeTableData.length == 1 && !row.isEdit">-</span>
                <el-button v-if="row.isAdd"  type="text" size="mini" class="el-icon-circle-check" style="color:#81B337;font-size: 20px" @click="handleSave(row,$index)"></el-button>
                <el-button v-if="tradeTableData.length > 1"  type="text" size="mini"  class="el-icon-circle-close trade_delete" @click="handleDelete($index,row)"></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="table_bottom" >
            <el-button size="mini" class="drawer_button" style="background: #fff; color:#BD3124;border:1px solid #BBBBBB;" @click="addGoods">继续添加商品</el-button>
            <p style="display: inline-block; float: right">应付总额：<span style="color: #BD3124 ">{{totalAmount}}&nbsp;元</span></p>
          </div>

        </div>
        <div class="remark" style="margin-bottom: 20px">
          <p class="block_title component_inner_title" style="color: #377F7F">备注说明</p>
          <p v-if="form.order_remark" class="remark_text">{{form.order_remark}}</p>
          <p v-else class="font_resize" style="text-align: center;display: inline-block;width: 100%;margin-top: 10px;color: #8888;">暂无数据</p>
        </div>
        <div>
          <p class="block_title fk component_inner_title" style="color:#377F7F">付款凭证上传</p>
          <div class="payment">
            <el-row >
<!--              <el-col :span="24" class="block_invoice">-->
<!--                <el-col :span="24">-->
<!--                  <span>收款人名称：上海奥普生物医药股份有限公司</span>-->
<!--                </el-col>-->
<!--                <el-col :span="24">-->
<!--                  <span>收款人账号：124662377383839939933</span>-->
<!--                </el-col>-->
<!--                <el-col :span="24">-->
<!--                  <span>开户行名称：招商银行瑞庆路支行</span>-->
<!--                </el-col>-->
<!--              </el-col>-->
              <el-col :span="24" class="block_upload">
                <el-upload
                    action="action"
                    list-type="picture-card"
                    :show-file-list="true"
                    :limit="1"
                    :multiple="false"
                    accept=".jpeg,.jpg,.png,.gif,.pdf,"
                    :file-list="srcList"
                    :headers="uploadHeaders"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :http-request="handleUpload"
                    :on-success="handleUploadSuccess"
                >
                                <i class="el-icon-plus"></i>
                  <div slot="tip" class="el-upload__tip" style="color:#00000073;font-size: 13px;margin-top:-0px;">只支持.jpg格式</div>
                </el-upload>
                <el-dialog class="picture" :visible.sync="fileDialogVisible"  :modal="false">
                  <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
              </el-col>
              <el-col :span="24">
                <el-button  class="save_btn" size="mini" style="color:#567722;margin: 14px 0" @click="savePayment">保存付款凭证</el-button>
              </el-col>
            </el-row>
          </div>
        </div>
        <div slot="footer" class="drawer-footer">
          <el-button type="primary" v-if="form.order_status == 9006" class="pass" @click="handleSubmit">提交</el-button>
        </div>
      </div>
      <el-dialog
          title="提示"
          :visible.sync="dialogVisible"
          width="30%"
          :modal="false"
          :before-close="handleClose">
        <span>确定将发票抬头改为：<span style="color:#BD3124">{{this.form.invoice_info.invoice_header}}</span></span>
        <span slot="footer" class="dialog-footer">
          <el-button style="margin-right: 14px" @click="handleCancle()" size="mini">取 消</el-button>
          <el-button type="primary" @click="handleConfirm" size="mini" style="background: #4095E5" >确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
          title="选择商品"
          :visible.sync="selectDialogVisible"
          v-if="goodsListTemp.length > 1 || this.searchText != ''"
          width="50%"
          :modal="false"
          :show-close="false"
          :before-close="handleClose">
        <template #title>
          <span class="dialog_title">选择商品</span>
          <div style="display: inline-block;float: right;width: 280px" class="dialog_search_input">
            <el-input v-model="searchText" placeholder="请输入商品名称"  style="width: 200px;margin-right: 20px" size="mini"></el-input>
            <i slot="append" class="dialog_search search_icon" @click="handleFilterGoods(searchText)"></i>
          </div>
        </template>
        <div class="select_dialog">
          <el-table :data="goodsList" @selection-change="handleSelectionChange" stripe ref="relateTable"  border v-loading="loading" element-loading-text="加载中..."  max-height="450px" :scroll-y="true">
            <el-table-column  type="selection"  :selectable="selectableStatus"  width="50" align="center" header-align="center"></el-table-column>
            <el-table-column prop="inventory_full_name" label="全称" min-width="150" align="left" header-align="center">
              <template v-slot="{ row, $index }">
                <span >{{row.label}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="inventory_name" label="简称" min-width="150" align="left" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.inventory_name}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="standard" label="规格/型号" min-width="120" align="left" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.standard}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer"  class="dialog-footer" style="text-align: center">
          <el-button style="margin-right: 14px" @click="handleCancelSelectGoods" size="mini">取 消</el-button>
          <el-button type="primary" @click="handleSelected" size="mini" style="background: #4095E5" >确 定</el-button>
        </span>
      </el-dialog>
    </el-drawer>

  </div>
</template>
<script>
import axios from "axios";
import common from "@/views/OrderManagement/mixin/common";
import {
  checkOrderDetail,
  checkOrderDtlList, createOrder,
  getInventoryPrice,
  saveVoucher, saveOrderDtlList, update_customer_info, updateOrderDtlList,
  updateCustomerInfo, updateOrderStatus, deleteCart, deleteOrderDtlList, getInventoryList
} from "@/api/orderGoods/custom";
import selectProduct from "@/views/OrderManagement/business/components/selectProduct.vue";
import {deleteCheck} from "@/api/assetCheck";

export default {
  components: {selectProduct},
  mixins: [common],
  props:{
    orderId:{
      type:String,
      default:''
    }
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      // let reg = /^1[34578]\d{9}$/
      let reg = /^1\d{10}$|^(0\d{2,3}-?|\(0\d{2,3}\))?[1-9]\d{4,7}(-\d{1,8})?$/
      if (value === '') {
        callback(new Error('请输入电话'));
      } else {
        if (!reg.test(value)) {
          callback(new Error('请输入正确的电话'));
        }
        callback();
      }
    };
    var checkEmail = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value === '') {
        callback(new Error('请输入邮箱'));
      } else {
        if (!reg.test(value)) {
          callback(new Error('请输入正确的邮箱'));
        }
        callback();
      }
    };
    var checkRegcode = (rule, value, callback) => {
      let reg1 = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/
      if (value === '') {
        callback(new Error('请输入税号'));
      } else {
        if (reg1.test(value) ) {
          callback();
        }else{
          callback(new Error('请输入正确的税号'));
        }
        callback();
      }
    };
    return {
      totalAmount: 0,
      selectTop: 0,
      loading: false,
      isOptionShow: false,
      selectDialogVisible: false,
      dialogVisible:false,
      tableLoading: false,
      loadingText: '正在加载数据',
      cantOpt: false,
      action: `${process.env.VUE_APP_BASE_API}/api/upload_ins_file/`,
      token: localStorage.getItem('token') ||localStorage.getItem('vue_token'),
      uploadHeaders: {
        Authorization: `Bearer ${this.token}` // 假设你的后端需要这样的 Authorization 头部格式
      },
      existIdList: [],//列表中存在的商品id
      srcList: [],
      goodsList: [],
      goodsListTemp: [],
      dialogImageUrl: '',
      fileDialogVisible: false,
      isInvoiceEdit: false,
      isReceiveEdit: false,
      revoke: '',
      approveContent: '',
      isDrawerShow: false,
      dialogTableVisible: false,
      voucher_url: '',
      invoice_header_temp: '', // 发票原始信息为了提交时对比是否更改
      form:{
        invoice_info:{
          invoice_header: '',
        },
        receive_address:{
          customer_contact: '',
        }
      },
      submitParams: {},
      searchText: '',
      formRules: {
        invoice_header: [
          { required: true, message: '请输入发票抬头', trigger: 'blur' },
        ],
        registration_code: [
          { required: true, message: '请输入公司税号', trigger: 'blur' },
          {validator: checkRegcode,trigger: 'blur', }
        ],
        contact_person: [
          { required: true, message: '请输入收票人', trigger: 'blur' },
        ],
        contact_email: [
          { required: true, message: '请输入接收邮箱', trigger: 'blur' },
          {validator: checkEmail,trigger: 'blur', }
        ],
        customer_contact: [
          { required: true, message: '请输入收货人名称', trigger: 'blur' },
        ],
        customer_address: [
          { required: true, message: '请输入收货人地址', trigger: 'blur' },
        ],
        customer_phone: [
          { required: true, message: '请输入收货人电话', trigger: 'blur' },
          {validator: checkPhone,trigger: 'blur', }
        ],
      },
    }
  },
  computed: {
    // totalAmount(){
    //   let total = 0
    //   this.tradeTableData.forEach(item => {
    //     total += Number(item.orders_sum)
    //   })
    //   return total.toFixed(2)
    // }
  },
  watch: {
    isDrawerShow:{
      handler(val){
        if(val){
          this.getDetail()
          // this.$refs.orderDtl.addEventListener('scroll', this.handleScroll);
          this.$nextTick(() => {
            const tableBodyWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper')
            if (tableBodyWrapper){
              tableBodyWrapper.addEventListener('scroll', this.handleScroll2);
            }
          })
        }
      },
    },
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if(userInfo){
      this.invoice_header_temp = userInfo.organization_name
    }else{
      this.invoice_data.invoice_header = ''
    }
    if(this.tradeTableData.length > 0){
      this.tradeTableData.forEach(item => {
        if(item.unit_price && item.orders_num ){
          item.total = (Number(item.price) * Number(item.count)).toFixed(2)
        }
      })
    }
  },
  mounted() {
    this.$refs.orderDtl.addEventListener('scroll', this.handleScroll);
    // this.$nextTick(() => {
    //   const tableBodyWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper')
    //   if (tableBodyWrapper){
    //     tableBodyWrapper.addEventListener('scroll', this.handleScroll2);
    //   }
    // })
  },
  beforeDestroy() {
    this.$refs.orderDtl.removeEventListener('scroll', this.handleScroll);
    this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper').removeEventListener('scroll', this.handleScroll);

  },
  methods: {
    handleScroll(){
      this.isOptionShow = false
      if(this.currentIndex){
        this.tradeTableData[this.currentIndex].inventory = {}
      }
    },
    handleScroll2(){
      this.isOptionShow = false
      if(this.currentIndex){
        this.tradeTableData[this.currentIndex].inventory = {}
      }
    },

    selectableStatus(row){
      return !row.disabled;
    },
    handleVisibleChange(visible) {
      // 如果下拉框关闭且没有选项，则不显示下拉框
      if ( this.goodsList.length === 0 || this.goodsList.length > 1) {
        this.tradeTableData[this.currentIndex].inventory = {}
      }
    },
    getGoodsList(e) {
        getInventoryList({name: e}).then(res => {
          let originalArray = res.data.data
          this.existIdList = this.tradeTableData.map(item=> item.inventory_id)
          const idArray = this.existIdList
          let updatedArray = originalArray.map((item,index) => {
            if (idArray.includes(item.inventory_id)) {
              return  { ...item, disabled: true };
            }
            return item;
          });
          this.goodsList = updatedArray.map(item => {
            return {
              label: item.inventory_full_name,
              value: item.inventory_id,
              inventory_name: item.inventory_name,
              inventory_full_name: item.inventory_full_name,
              standard: item.standard,
              sale_unit: item.sale_unit,
              disabled: item.disabled,
              hideDropdown: true
            }
          })
          this.goodsListTemp = JSON.parse(JSON.stringify(this.goodsList))
          if(this.goodsList.length > 0){
            if(this.goodsList.length === 1){
              this.isOptionShow = true
            }else{
              this.selectDialogVisible = true
              this.tradeTableData[this.currentIndex].inventory = {}
              this.isOptionShow = false
            }
          }else{
            // this.$message.error('暂未搜索到匹配产品')
          }
          // let newIds = this.goodsList.map(item => item.value)
          // if(this.goodsListTemp && this.goodsListTemp.length > 0){
          //   if(newIds && !newIds.includes(this.goodsListTemp[0].value)){
          //     this.goodsList = this.goodsList.concat(this.goodsListTemp)
          //   }
          // }
        })
    },
    chooseGoods(row,index){

    },
    selectFocus(index){
      this.currentIndex = index
      this.$nextTick(() => {
        // 获取表格的 DOM 元素
        const tableWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper');
        window.requestAnimationFrame(() => {
          tableWrapper.scrollLeft = 0;
        });
      });
    },
    handleFilterGoods(val){
      if(!val){
        this.goodsList = this.goodsListTemp
      }
      this.goodsList = this.goodsListTemp.filter(item =>{
        return item.inventory_name.includes(val) || item.inventory_full_name.includes(val);
      })
    },
    handleSelected(){ // 弹窗选商品
      if(this.selectedList && this.selectedList.length > 0){
        if(this.selectedList.length == 1){
          let rowData= this.selectedList[0]
          this.tradeTableData[this.currentIndex].inventory = {
            label: rowData.label,
            value: rowData.value,
            inventory_name: rowData.inventory_name,
            standard: rowData.standard,
            sale_unit: rowData.sale_unit
          }
          this.goodsList = [this.tradeTableData[this.currentIndex].inventory]
          // this.tradeTableData[this.currentIndex].isAdd =false
          this.tradeTableData[this.currentIndex].isEdit =false
          this.cantOpt = this.tradeTableData.some(item => item.isAdd)
          this.tradeTableData[this.currentIndex].inventory_ids = rowData.value
          this.tradeTableData[this.currentIndex].inventory_id = rowData.value
          this.tradeTableData[this.currentIndex].inventory_name = rowData.inventory_name
          this.tradeTableData[this.currentIndex].standard = rowData.standard
          this.tradeTableData[this.currentIndex].inventory_full_name = rowData.label
          this.tradeTableData[this.currentIndex].sale_unit = rowData.sale_unit
          let params = {
            inventory_ids: rowData.value.toString(),
            order_id: this.orderId.toString(),
            organization_id: this.form.receive_address.customer_id
          }
          getInventoryPrice(params).then(res=>{ // 获取商品价格
            this.tradeTableData[this.currentIndex].unit_price = Number(res.data[0].price).toFixed(2)
            this.tradeTableData[this.currentIndex].is_first = res.data[0].is_first
            this.tradeTableData[this.currentIndex].orders_num = res.data[0].orders_num
            this.tradeTableData[this.currentIndex].orders_sum = (Number(res.data[0].price) *  Number(this.tradeTableData[this.currentIndex].orders_num)).toFixed(2)
            let totalAmountTemp = this.tradeTableData.map(item => item.orders_sum)
            let totalAmount = totalAmountTemp.reduce((sum, num) => {
              const value = (!isNaN(num) && num !== undefined && num !== null && num.trim() !== '') ? parseFloat(num) : 0;
              return sum + Number(value);
            }, 0);
            this.totalAmount = totalAmount.toFixed(2)
          })
          this.existIdList = this.tradeTableData.filter(item => !item.isAdd).map(item => item.inventory_id)
          this.selectDialogVisible = false
          this.$forceUpdate()
        }else{
          this.tradeTableData.splice(this.currentIndex, 1, ...this.selectedList)
            let inventory_ids = this.selectedList.map(item => item.value)
            let params = {
              inventory_ids: inventory_ids.toString(),
              order_id: this.orderId.toString(),
              organization_id: this.form.receive_address.customer_id
            }
            getInventoryPrice(params).then(res=>{ // 获取商品价格
              let refreshArray = res.data
              if(refreshArray && refreshArray.length >0){
                this.tradeTableData.forEach(item1 => {
                  refreshArray.forEach(item2 =>{
                    if(item2.inventory_id === item1.inventory_id){
                     item1.unit_price = item2.price
                     item1.is_first = item2.is_first
                     item1.orders_num = item2.orders_num
                     item1.orders_sum = item2.orders_sum
                     item1.tax_price = item2.tax_price
                     item1.tax_rate = item2.tax_rate
                     item1.orders_sum = (Number(item1.orders_num )* Number(item1.unit_price)).toFixed(2) || 0.00
                   }
                  })
                })
                let totalAmountTemp = this.tradeTableData.map(item => item.orders_sum)
                let totalAmount = totalAmountTemp.reduce((sum, num) => {
                  const value = (!isNaN(num) && num !== undefined && num !== null && num.trim() !== '') ? parseFloat(num) : 0;
                  return sum + Number(value);
                }, 0);
                this.totalAmount = totalAmount.toFixed(2)
              }
            })

          this.selectDialogVisible = false
        }
        this.selectedList = []
      }else{
        this.selectDialogVisible = true
        this.$message.error('请选择商品!')
      }
    },
    handleCancelSelectGoods(){
      this.selectDialogVisible = false
      this.searchText = ''
      this.selectedList = []
    },
    handleSelectionChange(val){
      if(val.length > 0){
        this.selectedList = val
        this.selectedList.forEach(item => {
          item.inventory_ids = item.value
          item.inventory_id = item.value
          item.isAdd = true
          if(item.price){
            item.unit_price = Number(item.price).toFixed(2)
          }else{
            item.unit_price = 0.00
          }
          if(item.tax_price){
            item.tax_price = Number(item.tax_price).toFixed(2)
          }else{
            item.tax_price = 0.00
          }
        })
      }
    },
    closeDrawer(){
      let tradeTableDataTemp = this.tradeTableData.filter(item => item.inventory_id) // 去掉空数据行
      let noSave = tradeTableDataTemp.some(item => !item.orders_id)
      if(noSave){ // 如果有没保存的不能关闭
        // this.$message.error('请先保存商品！')
        this.$confirm('提示', {
          title: '提示',
          message: '当前页面有未保存数据，确定要关闭当前页面吗？',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.isDrawerShow = false
        }).catch(() => {
          this.isDrawerShow = true
          this.tradeTableData.forEach(item => {
            if(!item.orders_id){
              item.isNoSave = true
            }
          })
          this.$forceUpdate()
        })

      }else{
        this.isReceiveEdit = false
        this.isInvoiceEdit = false
        this.isDrawerShow = false
        this.$emit('updateData')
        localStorage.removeItem('checkOrderId')
        this.srcList = []
      }
    },
    getDetail(){
      this.loading = true
      checkOrderDetail({order_id: this.orderId}).then(res=>{
        this.form = res.data
        this.expressList = res.data.express_information
        if(res.data.payment_voucher){
          this.srcList = [{ url: res.data.payment_voucher}]
        }else{
          this.srcList = []
        }
      })
      checkOrderDtlList({order_id: this.orderId}).then(res=>{
        this.loading = false
        this.tradeTableData = res.data
        let totalAmountTemp = this.tradeTableData.map(item => item.orders_sum)
        let totalAmount = totalAmountTemp.reduce((sum, num) => {
          const value = (!isNaN(num) && num !== undefined && num !== null && num.trim() !== '') ? parseFloat(num) : 0;
          return sum + Number(value);
        }, 0);
        this.totalAmount = totalAmount.toFixed(2)
        this.cantOpt = this.tradeTableData.some(item => item.isAdd)
        this.existIdList = this.tradeTableData.filter(item => !item.isAdd).map(item => item.inventory_id)
      })
    },
    addGoods(){
      this.$nextTick(() => {
        // 获取表格的 DOM 元素
        const tableWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper');
        window.requestAnimationFrame(() => {
          tableWrapper.scrollTop = tableWrapper.scrollHeight;
        });
      });
      this.tradeTableData.push({
        inventory_full_name: '',
        inventory_name: '',
        standard: '',
        unit_price: 0.00,
        orders_num: 0,
        orders_sum: 0.00,
        isEdit: true,
        isAdd: true, // 新增商品
      })
      this.cantOpt = this.tradeTableData.some(item => item.isAdd)
    },
    handleFullNameChange(data){ //下拉单个
      this.tradeTableData[this.currentIndex].inventory_ids = data.value
      this.tradeTableData[this.currentIndex].inventory_id = data.value
      this.tradeTableData[this.currentIndex].inventory_name = data.inventory_name
      this.tradeTableData[this.currentIndex].standard = data.standard
      this.tradeTableData[this.currentIndex].inventory_full_name = data.label
      this.tradeTableData[this.currentIndex].sale_unit = data.sale_unit
      this.existIdList = this.tradeTableData.map(item=> item.inventory_id)
      this.goodsList.forEach(item => {
        if(this.existIdList.includes(item.value)){
          item.disabled = true
        }
      })
      let params = {
        inventory_ids: data.value.toString(),
        order_id: this.orderId.toString(),
        organization_id: this.form.receive_address.customer_id
      }
      getInventoryPrice(params).then(res=>{ // 获取商品价格
        let resData = res.data[0]
        this.tradeTableData[this.currentIndex].isEdit = false
        this.tradeTableData[this.currentIndex].unit_price = Number(resData.price).toFixed(2) || 0.00
        this.tradeTableData[this.currentIndex].is_first = resData.is_first
        this.tradeTableData[this.currentIndex].orders_num = resData.orders_num
        this.tradeTableData[this.currentIndex].orders_sum = (Number(resData.price) *  Number(this.tradeTableData[this.currentIndex].orders_num)).toFixed(2)
        let totalAmountTemp = this.tradeTableData.map(item => item.orders_sum)
        let totalAmount = totalAmountTemp.reduce((sum, num) => {
          const value = (!isNaN(num) && num !== undefined && num !== null && num.trim() !== '') ? parseFloat(num) : 0;
          return sum + Number(value);
        }, 0);
        this.totalAmount = totalAmount.toFixed(2)
      })
      this.existIdList = this.tradeTableData.map(item => item.inventory_id)
      this.$forceUpdate()
    },
    handleCountChange(row){
      if(row.unit_price && row.orders_num){
        row.orders_sum= (Number( row.unit_price) * row.orders_num).toFixed(2)
      }else{
        row.orders_sum= 0.00
      }
      let totalAmountTemp = this.tradeTableData.map(item => item.orders_sum)
      let totalAmount = totalAmountTemp.reduce((sum, num) => {
        const value = (!isNaN(num) && num !== undefined && num !== null && num.trim() !== '') ? parseFloat(num) : 0;
        return sum + Number(value);
      }, 0);
      this.totalAmount = totalAmount.toFixed(2)
      if(row.inventory_id){
        this.handleUpdate(row)
      }else{
        row.isEdit = true
      }

    },
    handleSave(row,index){ // 新增一条商品的保存
      this.currentIndex = index
      if(!row.inventory_ids){
        this.$message.error('商品全称不能为空！')
        return
      }else{
        if(this.existIdList.includes(row.inventory_ids || row.inventory_id)){
          return this.$message.error('保存失败，请勿重复添加商品！')
        }else{
          row.isEdit = false
          let params = {
            order_id: this.orderId,
            inventory_id: row.inventory_ids || row.inventory_id,
            quantity: row.orders_num,
            inventory_price: Number(row.unit_price)
          }
          saveOrderDtlList(params).then(res =>{
            this.tradeTableData[this.currentIndex].isAdd = false
            this.tradeTableData[this.currentIndex].isNoSave = false
            this.cantOpt = this.tradeTableData.some(item => item.isAdd)
            this.existIdList = this.tradeTableData.filter(item => !item.isAdd).map(item => item.inventory_id)
            this.tradeTableData[index].orders_id = res.data.orders_id
            this.$message.success('保存成功！')
            this.$forceUpdate()
            // setTimeout(() => {
            //   checkOrderDtlList({order_id: this.orderId}).then(res=>{
            //     this.tradeTableData = res.data
            //   },100)
            // })
          }).catch(err=>{
            row.isAdd = true
          })
        }
      }

    },
    handleUpdate(row){ // 更新一条商品的保存
      row.isEdit = false
      if(!row.isAdd){
        let isDontOpt = this.tradeTableData.some(item => item.isAdd)
        if(isDontOpt){
          this.$message.error('请先保存新增商品，再进行操作！')
        }else{
        this.tableLoading = true
          let params = {
            orders_id: row.orders_id,
            inventory_id: row.inventory_ids || row.inventory_id,
            quantity: row.orders_num,
            orders_sum: Number(row.orders_sum)
          }
          updateOrderDtlList(params).then(res=>{
            this.$message.success('保存成功！')
            this.tableLoading = false
          }).catch(err=>{
            this.tableLoading = false
          })
        }
      }
    },
    handleSubmit(){ //驳回后重新提交
      let isValidate = this.tradeTableData.some(item => !item.inventory_id);
      if(isValidate){
        this.$message.error('新增商品不能为空！')
      }else{
        let params1 = {
          order_id: this.orderId,
          order_status: 9001, //待审核
        }
        let params2 = {
          order_id: this.orderId,
          voucher_url: this.voucher_url,
        }
        saveVoucher(params2).then(res=>{
          updateOrderStatus(params1).then(res => {
            if(res.code === 200){
              this.$message.success('提交成功！')
              this.$emit('updateData')
              this.isDrawerShow = false
            }else{
              this.$message.error(res.data.msg)
            }
          })
        }).finally(()=>{
          this.loading = false
        })
      }
    },
    savePayment(){ //保存凭证
      this.loading = true
      this.loadingText = '正在保存付款凭证'
      let params ={
        order_id: this.orderId,
        voucher_url: this.voucher_url,
      }
      saveVoucher(params).then(res=>{
        this.$message.success(res.data)
        this.isDrawerShow = false
        this.loading = false
      }).finally(()=>{
        this.loading = false
      })
    },
    handleDelete(index,row){
      if(row.isAdd){
        this.$confirm('确定要删除当前商品吗?', '', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          // center: true
        }).then(() => {
          this.tradeTableData.splice(index, 1)
          this.existIdList = this.tradeTableData.filter(item => !item.isAdd).map(item => item.inventory_id)
          this.cantOpt = this.tradeTableData.some(item => item.isAdd)
          let totalAmountTemp = this.tradeTableData.map(item => item.orders_sum)
          let totalAmount = totalAmountTemp.reduce((sum, num) => {
            const value = (!isNaN(num) && num !== undefined && num !== null && num.trim() !== '') ? parseFloat(num) : 0;
            return sum + Number(value);
          }, 0);
          this.totalAmount = totalAmount.toFixed(2)
          this.$message.success('删除成功！')
        })
      }else{
        let isDontDelete = this.tradeTableData.some(item => item.isAdd)
        if(isDontDelete){
          this.$message.error('请先保存新增商品，再进行删除操作！')
        }else{
          this.$confirm('确定要删除当前商品吗?', '', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
            // center: true
          }).then(() => {
            if(!row.inventory_id){
              this.tradeTableData.splice(index, 1)
              this.$message({type: 'success', message: '删除成功!'});
            }else{
              this.tableLoading = true
              let params = {
                orders_id: row.orders_id,
                inventory_id: row.inventory_id,
                quantity: row.orders_num,
                inventory_price: row.unit_price
              }
              deleteOrderDtlList(params).then(res=>{
                this.$message({type: 'success', message: '删除成功!'});
                checkOrderDtlList({order_id: this.orderId}).then(res=>{
                  this.tradeTableData = res.data
                  this.existIdList = this.tradeTableData.filter(item => !item.isAdd).map(item => item.inventory_id)
                  this.tableLoading = false
                })
              }).finally(()=>{
                this.tableLoading = false
              })
            }
          }).catch(() => {
          });
        }
      }

    },
    handleUpload( file) {
      this.loading = true
      this.loadingText = '正在上传付款凭证'
      const formData = new FormData();
      formData.append('files', file.file); // 注意：在某些 Element UI 版本中，可能需要使用 file.file 而不是 file.raw
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}` // 假设 token 已经在 data 或 computed 中定义
        }
      };
      // ${baseUrl}/api/upload_asset_file/   上传图片
      let baseUrl= process.env.VUE_APP_BASE_API
      axios.post(`${baseUrl}/api/upload_voucher/`, formData, config)
          .then(data => {
            // 处理响应
            this.loading = false
            this.voucher_url =  data.data.data.urls[0]
            this.srcList.push({url: voucher_url, name: file.file.name, uid: voucher_url})
          })
          .catch(error => {
            // 处理错误
          });
    },
    handleUpdateInfo(type){  // 修改保存发票和收货信息
      let params = {}
      if(type == 'invoice'){ //更新发票信息
        this.$refs['invoice_info'].validate(valid => {
          if(valid){
            this.isInvoiceEdit = false
            this.submitParams = {
              order_id: this.orderId,
              type: type,
              info:{
                customer_id: this.form.receive_address.customer_id,  // 地址ID
                invoice_id: this.form.invoice_info.invoice_id,  // 开票ID
                invoice_header: this.form.invoice_info.invoice_header, // 开票抬头
                registration_code: this.form.invoice_info.registration_code, // 税号
                contact_person: this.form.invoice_info.contact_person, // 联系人
                contact_email: this.form.invoice_info.contact_email, // 邮箱
              }
            }
            if(this.invoice_header_temp !== this.form.invoice_info.invoice_header){
              this.dialogVisible = true
            }else{
              this.handleSubmitInfo(type)
            }
          }else{
            this.$message.error('请检查必填字段！')
            return
          }
        })
      }else{ // 更新收货信息
        this.$refs['receive_info'].validate(valid => {
          if(valid){
            this.isReceiveEdit = false
            this.submitParams = {
              order_id: this.orderId,
              type: type,
              delivery_type: this.form.delivery_type,
              info:{
                customer_id: this.form.receive_address.customer_id,  // 经销商id
                customer_title: this.form.receive_address.customer_title,  // 经销商名称
                address_id: this.form.receive_address.address_id,  // 地址ID
                customer_contact: this.form.receive_address.customer_contact, // 收货人
                customer_phone: this.form.receive_address.customer_phone, // 收货人电话
                customer_address: this.form.receive_address.customer_address, // 经销商地址
              }
            }
            this.handleSubmitInfo(type)
          }else{
            this.$message.error('请检查必填字段！')
            return
          }
        })
      }
    },
    handleSubmitInfo(type){
      updateCustomerInfo(this.submitParams).then(res=>{
        if(res.code === 200){
          this.$message.success('保存成功')
          if(type == 'invoice'){
            this.isInvoiceEdit= false
          }else{
            this.isReceiveEdit = false
          }
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    handleConfirm(){
      this.dialogVisible =false
      this.handleSubmitInfo('invoice')
    },
    handleCancle(){
      this.dialogVisible =false
      this.isInvoiceEdit = true
    },
    handleUploadSuccess(){},
    handleRemove(file, fileList) {
      this.fileList = fileList
      this.voucher_url = null
    },
    handlePictureCardPreview(file) {
      if(file && file.url){
        this.dialogImageUrl = file.url
        this.fileDialogVisible = true
      }
    },
    handleClose(){
      this.dialogVisible = false
    }
  },
}

</script>
<style scoped lang="scss">
@import "@/assets/css/order";
::v-deep .oprate.el-table__cell .cell{
  padding: 0!important;
}
/* 确保在表格滚动时更新下拉位置 */
.el-table__body-wrapper::-webkit-scrollbar {
  width: 12px;
}

.el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.el-table__body-wrapper:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

.el-table__body-wrapper:hover .fixed-select .el-select-dropdown {
  display: block;
}
::v-deep .el-main{
  overflow: visible;
}
::v-deep .el-dialog__footer{
  text-align: center;
}
::v-deep .el-input .el-input__inner{
  width: 100%!important;
}
::v-deep .el-table th.el-table__cell > .cell{
  font-weight: 400;
}
::v-deep .select_dialog .el-table th.el-table__cell > .cell{
  font-weight: 600;
}
::v-deep .el-dialog__header{
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
}
::v-deep .el-table .cell{
  text-align: center!important;
}
::v-deep .el-table .el-button{
  padding: 10px 4px!important;
}
::v-deep .el-button+.el-button {
  margin-left: 0;
}
::v-deep .el-input__inner::placeholder {
  color: #888888;
}
::v-deep .el-input__inner::-moz-placeholder { /* Firefox 19+ */
  color:#888888;
}
::v-deep .el-table td.el-table__cell div{
  text-align: center!important;
}
::v-deep .el-form-item{
  margin-bottom: 2px!important;
}
::v-deep .el-form-item__error{
  padding-top: 0px!important;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  ::v-deep .el-table td.el-table__cell div{
    font-size: 14px;
  }
  ::v-deep .el-drawer__header{
    color: #000;
    font-size: 16px;
    font-weight: bold;
  }
  .orderInfo .block p, .orderInfo .block div{
    font-size: 14px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 14px;
  }
  .table_bottom p{
    font-size: 14px;
  }
  .block_invoice{
     font-size: 14px;
   }
  .payment span{
    color: #6C6C6C;
  }
  .remark_text{
    font-size: 13px;
  }
  .table_bottom .drawer_button,.save_btn span{
    font-size: 14px;
  }
  ::v-deep .save_btn.el-button span{
    font-size: 14px!important;
    padding:8px 12px;
  }
}
::v-deep .el-table td.el-table__cell.is-center div{
  text-align: center!important;
}
::v-deep .el-table td.el-table__cell.is-left div{
  text-align: left!important;
}
@media screen and (min-width: 1551px) , print {
  .remark_text{
    font-size: 14px;
  }
  .table_bottom .drawer_button{
    font-size: 16px!important;
    padding:10px 14px!important;
  }
  ::v-deep .save_btn.el-button{
    padding:10px 14px!important;
  }
  ::v-deep .save_btn.el-button span{
    font-size: 16px;

  }
  .block_invoice{
    font-size: 16px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 16px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 16px;
  }
  ::v-deep .el-drawer__header{
    color: #000;
    font-size: 18px;
    font-weight: bold;
  }
  .orderInfo .block p, .orderInfo .block div{
    font-size: 16px;
  }
  .table_bottom p{
    font-size: 16px;
  }
}
::v-deep .el-drawer__header{
  border-bottom: 2px dotted #eee;
  padding: 10px;
  margin-bottom: 14px;
}
.orderDtl{
  padding: 0 14px;
  .orderInfo{
    border-bottom:1px solid #eee;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    .block{
      padding:14px 14px  10px 14px;
      border-top:1px solid #eee;
      border-right: 1px solid #eee;
      p{
        color: #9A9A9A;
        line-height: 1.8;
      }
      div{
        line-height: 1.6;
        span{
          display: inline-block;
        }
      }
    }
  }
  .goodsList{
    .table_bottom{
      margin-top: 20px;
      margin-bottom: 20px
    }
  }
  .block_title{
    font-weight: bold;
    margin: 16px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }
  .block_title.fk{
    margin: 12px 0;
  }
}
.drawer-footer{
  text-align: center;
  margin-bottom: 20px;
  .pass{
    background: #347CAF;
    color: #fff;
    padding-left:40px;
    padding-right: 40px
  }
  .reject{
    border:1px solid #377F7F;
    padding-left:40px;
    padding-right: 40px
  }
  .reject:hover{
    background: #fff;
    color: #377F7F;
  }
}
.el-table .children_column{
  border-bottom: none;
}
::v-deep .el-table thead .children_column .cell{
  font-size: 10px!important;
}
::v-deep .el-table thead th.el-table__cell{
  background: #EFEFEF;
  color: #000;
}
::v-deep .el-table thead .el-table__cell{
  padding: 0;
  border-bottom: none;
}
::v-deep .select_dialog .el-table thead .el-table__cell{
  padding: 12px 0;
  border-bottom: none;
}
::v-deep .el-input-number.is-controls-right .el-input__inner{
  width: 130px;
}
::v-deep .el-table td.el-table__cell div{
  text-align: left;
}
::v-deep .el-input--mini .el-input__inner{
  width: 130px;
}
.dialog_close{
  padding-left:40px;
  padding-right:40px
}
.dialog_close:hover{
  background: #fff;
  color: #347caf;
}
::v-deep .el-upload--picture-card{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.block_upload{
  margin-top: 14px;
}

.block_invoice span{
  line-height: 2;
  margin: 10px;
  color: #6C6C6C;
}
::v-deep .orderDtl .orderInfo .el-input--mini .el-input__inner{
  width: 90%;
}
.trade_delete{
  color:#BEBEBE;
  font-size: 20px;
}
.trade_delete:hover{
  color:#bd3124b0;
}
.dialog_search_input{
  position: relative;
}
::v-deep .dialog_search_input .el-input{
   width: 100%!important;

 }
.dialog_search.search_icon{
  background-image: url("../../../../assets/img/search.png") ;
  background-size: cover;
  display: inline-block;
  position: absolute;
  width: 20px;
  height: 20px;
  right: 8px;
  top: 6px;
}
.no_save{
  border: 2px solid #bd3124;
}
</style>