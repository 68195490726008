<template>
  <el-dialog class="add_goods" v-if="dialogVisible" :visible.sync="dialogVisible" title="新增商品"  width="60%"  :lockScroll="false"
             :close-on-click-modal="false" :close-on-press-escape="false" :destroy-on-close="true" :show-close="false">
    <template #title>
      <span class="dialog_title">商品详情</span>
    </template>
    <div v-loading="loading" :element-loading-text="loadingText">
      <el-form ref="form" :model="form" label-width="120px" :inline="true" :rules="rules">
        <el-col :span="12">
          <el-form-item label="存货编码" prop="inventory_code">
            <el-input size="mini" :disabled="isCheck" v-model="form.inventory_id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商品名称"  prop="trade_name">
            <el-input size="mini" :disabled="isCheck" v-model="form.inventory_full_name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商品简称" prop="short_name">
            <el-input size="mini" :disabled="isCheck" v-model="form.inventory_name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商品规格" prop="product_specifications">
            <el-input size="mini" :disabled="isCheck" v-model="form.standard"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="商品型号">
            <el-input size="mini" :disabled="isCheck" v-model="form.standard"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="单位">
            <el-input size="mini" :disabled="isCheck" v-model="form.shop_unit"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="注册证号" prop="registration_number">
            <el-input size="mini" :disabled="isCheck" v-model="form.commodity"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="商品类别" >
            <el-radio-group size="mini" :disabled="isCheck" v-model="form.inventory_type">
              <el-radio :label="0" >其他</el-radio>
              <el-radio :label="1" >试剂</el-radio>
              <el-radio :label="2" >仪器</el-radio>
              <el-radio :label="3" >耗材</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="试剂平台" prop="reagent_platform" v-if="form.inventory_type == 1">
            <el-input size="mini" :disabled="isCheck" v-model="form.reagent_info.reagent_platform"></el-input>
<!--            <el-select size="mini" :disabled="isCheck" v-model="form.reagent_platform" placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in reagent_options"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--              ></el-option>-->
<!--            </el-select>-->
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="方法学" prop="methodology"  v-if="form.inventory_type == 1">
            <el-input size="mini" :disabled="isCheck" v-model="form.reagent_info.reagent_method"></el-input>
<!--            <el-select size="mini" :disabled="isCheck" v-model="form.reagent_method" placeholder="请选择">-->
<!--              <el-option-->
<!--                  v-for="item in methodology_options"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--              ></el-option>-->
<!--            </el-select>-->
          </el-form-item>
        </el-col>
        <el-col :span= "24">
          <el-col :span="12">
            <el-form-item label="试剂大类" prop="reagent_categories"  v-if="form.inventory_type == 1">
              <el-input size="mini" :disabled="isCheck" v-model="form.reagent_info.reagent_class"></el-input>
            </el-form-item>
          </el-col>
        </el-col>
        <el-col :span="12">
            <el-form-item label="商品图片">
              <el-image
                  v-if="form.inventory_image"
                  style="width: 100px; height: 100px"
                  :src="form.inventory_image"
                  :preview-src-list="inventory_srcList">
              </el-image>
            </el-form-item>
          </el-col>
        <el-col :span="12">
        <el-form-item label="注册图片">
          <el-image
              v-if="form.commodity_image"
              style="width: 100px; height: 100px"
              :src="form.commodity_image"
              :preview-src-list="commodity_srcList">
          </el-image>
<!--          <el-upload-->
<!--              action="/api/upload_application_word/"-->
<!--              list-type="picture-card"-->
<!--              accept=".jpeg,.jpg,.png,.gif,.pdf,"-->
<!--              :headers="uploadHeaders"-->
<!--              :show-file-list="true"-->
<!--              :limit="1"-->
<!--              :multiple="false"-->
<!--              :file-list="form.commodity_image"-->
<!--              :on-preview="handlePictureCardPreview"-->
<!--              :on-remove="handleRemove"-->
<!--              :http-request="handleUpload"-->
<!--              :on-success="handleUploadSuccess"-->
<!--          >-->
<!--                          <i class="el-icon-plus"></i>-->
<!--            <div slot="tip" class="el-upload__tip" style="color:#00000073;font-size: 13px;margin-top:-0px;">只支持图片格式，如jpg.jpeg、png等</div>-->
<!--          </el-upload>-->
<!--          <el-dialog class="picture" :visible.sync="fileDialogVisible"  :modal="false">-->
<!--            <img width="100%" :src="dialogImageUrl" alt="">-->
<!--          </el-dialog>-->
        </el-form-item>
        </el-col>
        <div class="dialog_footer" >
          <el-button type="primary" class="save" size="mini" @click="onSubmit" v-if="!isCheck">保存</el-button>
          <el-button size="mini" class="close_btn" @click="closeGoodsDialog">关闭</el-button>
        </div>
      </el-form>
    </div>
  </el-dialog>
</template>
<script>
import orderOptions from '../../mixin/orderOptions'
import axios from "axios";
import {getInventoryDetail} from "@/api/orderGoods/business";
export default {
  mixins: [orderOptions],
  props:{
    inventoryId:{
      type: String,
      default:''
    },
    dialogVisible:{
      type: Boolean,
      default:false
    },
    // isCheck:{
    //   type: Boolean,
    //   default:false
    // },
  },
  data() {
    return {
      loading: false,
      loadingText: '正在加载数据',
      isCheck: true, // 是否查看
      // dialogVisible: false,
      // inventory_id: '',
      fileDialogVisible: false,
      dialogImageUrl: '',
      commodity_srcList: [],
      inventory_srcList:[],
      form: {
        inventory_full_name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        standard: '',
        fileList: [],
        resource: '',
        desc: '',
      },
      uploadHeaders: {
        Authorization: `Bearer ${this.token}` // 假设你的后端需要这样的 Authorization 头部格式
      },
      rules: {
        inventory_code: [
          {required: true, message: '请输入存货编码', trigger: 'blur'}
        ],
        trade_name: [
          {required: true, message: '请输入商品名称', trigger: 'blur'}
        ],
        short_name: [
          {required: true, message: '请输入商品简称', trigger: 'blur'}
        ],
        product_specifications: [
          {required: true, message: '请输入商品规格', trigger: 'blur'}
        ],
        registration_number: [
          {required: true, message: '请输入注册证号', trigger: 'blur'}
        ],
        reagent_platform: [
          {required: true, message: '请选择试剂平台', trigger: 'change'}
        ],
        methodology: [
          {required: true, message: '请选择方法学', trigger: 'change'}
        ],
        reagent_categories: [
          {required: true, message: '请输入试剂大类', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.getDtl(this.inventoryId)
  },
  methods: {
    getDtl(id){
      this.loading = true
      getInventoryDetail({inventory_id: id}).then(res=>{
        if(res.code === 200){
          this.form = res.data
          if(this.form.inventory_image){
            this.inventory_srcList = [this.form.inventory_image]
          }
          if(this.form.commodity_image){
            this.inventory_srcList = [this.form.commodity_image]
          }
          this.loading = false
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    onSubmit() {
      this.dialogVisible = false
      this.$message('submit!')
    },
    handleUpload( file) {
      const formData = new FormData();
      formData.append('files', file.file); // 注意：在某些 Element UI 版本中，可能需要使用 file.file 而不是 file.raw
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}` // 假设 token 已经在 data 或 computed 中定义
        }
      };
      // ${baseUrl}/api/upload_asset_file/   上传图片
      let baseUrl= process.env.VUE_APP_BASE_API
      axios.post(`${baseUrl}/api/upload_application_word/`, formData, config)
          .then(data => {
            // 处理响应
            let resUrl =  data.data.data.urls[0]
            this.form.fileList.push({url: resUrl, name: file.file.name, uid: file.file.uid})
          })
          .catch(error => {
            // 处理错误
          });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.fileDialogVisible = true
    },
    handleUploadSuccess(){},
    handleRemove(){},
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    closeGoodsDialog(){
      // 发射一个事件，通知父组件关闭弹窗
      this.$emit('close')
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  font-size: 20px;
  vertical-align: -5px;
}
::v-deep .el-form-item .el-form-item__label {
  padding-right: 10px !important;
}
::v-deep .el-form-item{
  width: 100%;
  .el-form-item__content{
    width: calc(100% - 130px);
    .el-input .el-input__inner{
      width: 100%;
    }
  }
}
::v-deep .el-dialog__header{
  border-bottom: 1px solid #BBBBBB;
}
::v-deep  .picture .el-dialog__header{
  border-bottom: none;
}
::v-deep .el-upload--picture-card{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item{
  width: 80px;
  height: 80px;
  line-height: 80px;
}
::v-deep .el-button.save:hover{
  background: #1989FA;
}
//.add_goods {
//  top: 50% !important; /* 使用 !important 来确保样式优先级 */
//  transform: translateY(-50%) !important; /* 使用 CSS3 transform 来实现垂直居中 */
//  height: 100%;
//}
.add_goods {
  display: flex;
  padding: 20px;
  ::v-deep .el-dialog { margin: auto !important; }
}
.dialog_footer{
  display: inline-block;
  width: 100%;
  text-align: center;
  .close_btn {
    padding:8px 40px;
  }
}

</style>