import request from '@/utils/request'

export function getRecommendList(params) { // 商品列表-推荐
    return request({
        url: '/api/recommend_inventorys/',
        method: 'get',
        params
    })
}
export function getInventoryList(params) { // 商品列表
    return request({
        url: '/api/inventorys_list/',
        method: 'get',
        params
    })
}
export function getCustomInfo(params) { // 获取客户信息，发票信息和收货地址信息
    return request({
        url: '/api/customer_information/',
        method: 'get',
        params
    })
}
export function addCart(data) {  // 添加到购物车
    return request({
        url: '/api/cart/',
        method: 'post',
        data
    })
}
export function updateCartNum(data) {  // 更新购物车商品数量
    return request({
        url: '/api/cart/',
        method: 'put',
        data
    })
}
export function deleteCart(data) {  // 删除购物车商品
    return request({
        url: '/api/cart/',
        method: 'delete',
        data
    })
}
export function checkCard(params) { // 查看购物车
    return request({
        url: '/api/cart/',
        method: 'get',
        params
    })
}
export function checkOrderList(params) { // 查看订单列表
    return request({
        url: '/api/order_list/',
        method: 'get',
        params
    })
}
export function checkOrderDetail(params) { // 查看订单详情
    return request({
        url: '/api/order_detail/',
        method: 'get',
        params
    })
}
export function checkOrderDtlList(params) { // 查看订单详情-商品列表
    return request({
        url: '/api/orders_list/',
        method: 'get',
        params
    })
}
export function saveOrderDtlList(data) {  // 购物车确认提交
    return request({
        url: '/api/orders_list/',
        method: 'post',
        data
    })
}
export function updateOrderDtlList(data) {  // 更新订单
    return request({
        url: '/api/orders_list/',
        method: 'put',
        data
    })
}
export function deleteOrderDtlList(data) {  // 删除订单
    return request({
        url: '/api/orders_list/',
        method: 'delete',
        data
    })
}
export function createOrder(data) {  // 购物车确认提交
    return request({
        url: '/api/create_order/',
        method: 'post',
        data
    })
}
export function updateOrderStatus(data) {  // 用户更新订单状态
    return request({
        url: '/api/update_order_status/',
        method: 'post',
        data
    })
}
export function updateCustomerInfo(data) {  // 更新客户信息 updateCustomerInfo
    return request({
        url: '/api/update_customer_info/',
        method: 'put',
        data
    })
}
export function saveVoucher(data) {  // 保存用户凭证
    return request({
        url: '/api/save_voucher/',
        method: 'post',
        data
    })
}
export function getInventoryPrice(params) { // 查看订单详情-商品列表
    return request({
        url: '/api/inventory_price/',
        method: 'get',
        params
    })
}

export function reOrder(data) {  // 保存用户凭证
    return request({
        url: '/api/re_order/',
        method: 'post',
        data
    })
}
export function getRelateInventory(params) { // 查看订单详情-商品列表
    return request({
        url: '/api/relate_inventory/',
        method: 'get',
        params
    })
}
// 认证
export function certified(data) {  // 保存用户凭证
    return request({
        url: '/api/certified/',
        method: 'post',
        data
    })
}
export function getCertifiedInfo(params) { // 查看订单详情-商品列表
    return request({
        url: '/api/certified/',
        method: 'get',
        params
    })
}
export function sendSms(data) {  // 客户认证验证码
    return request({
        url: '/api/send_sms/',
        method: 'post',
        data
    })
}




