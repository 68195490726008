<template>
  <div class="tableList">
    <div class="btn" v-if="mode_export_List.includes(this.mode) || (this.mode == 'samplechecks' && (user_id== 31 || org_post== 110))">
      <el-button type="success" size="small" @click="dialogVisible = true">导出表格</el-button>
    </div>
    <el-table
      :data="tableData"
      header-align="center"
      v-loading="loading"
      stripe
      size="small"
      :empty-text="loading?' ':'暂无数据'"
    >
      <el-table-column label="序号" align="center" width="80">
        <template slot-scope="scope">
          {{ serialtext(scope.$index+1)  }}
        </template>
      </el-table-column>

      <el-table-column
        v-for="(item, index) in tableHeader"
        :key="index"
        :label="item.label"
        align='center'
        :width="flexColumnWidth(item.width)"
      >
        <template slot-scope="scope">
          <!-- 样本检测-阴阳性 -->
          <div v-if="mode == 'samplechecks' && item.value == 'is_positive'">
            <span
              :style="scope.row[item.value] == true ? 'color:#FF3370;' : ''"
            >
              {{ scope.row[item.value] == true ? '阳性' : '阴性' }}
            </span>
          </div>
          <!-- 仪器-机号 -->
          <div v-else-if="mode == 'instruments' && item.value == 'instrument_id'" >
            <span :style="{color: (!scope.row.has_recent_data && scope.row.has_recent_data !== null) ? '#BD3124' : ' '}"> {{ scope.row.instrument_id ? scope.row.instrument_id: scope.row.instrument_id}}</span>
            <el-tooltip v-if="!scope.row.has_recent_data && scope.row.has_recent_data !== null " style="color:#BD3124;" class="item" popper-class="tooltip_instrument" effect="light" content="连续或大于七天无数据提交" placement="top-start">
              <div slot="content">
                <i style="color:#BD3124;" class="el-icon-warning"></i> 当前仪器已超7天未提交数据
              </div>
              <i style="color:#BD3124;vertical-align: 3px" class="question"></i>
<!--              <i style="color:#BD3124;vertical-align: 3px" class="el-icon-question"></i>-->
            </el-tooltip>
          </div>
          <!-- 仪器-仪器位置 -->
          <div v-else-if="mode == 'instruments' && item.value == 'label'">
            {{ scope.row.label ? scope.row.label: scope.row.hos_pos_name}}
          </div>
          <!-- 订单-成交价 -->
          <div v-else-if="mode == 'orders' && item.value == 'order_sum'">
            {{ $tool.moneyFormatter(Number(scope.row[item.value]), 2) }}
          </div>
          <!-- 产品-是否在售 -->
          <div v-else-if="mode == 'products' && item.value == 'is_sell'">
            {{ scope.row[item.value] == true ? '是' : '否' }}
          </div>
          <!-- 发货单-发货状态 , 物品申请-申请状态 -->
          <div
            v-else-if="
              (mode == 'dispatchs' && item.value == 'shipping_status') ||
              (mode == 'articleapplis' && item.value == 'apply_status')
            "
          >
            <span v-if="!/^[0-9]+.?[0-9]*$/.test(scope.row[item.value])">{{scope.row[item.value]}}</span>
            <span v-if="scope.row[item.value] == 0">已驳回</span>
            <span v-if="scope.row[item.value] == 1">待审批</span>
            <span v-if="scope.row[item.value] == 2">待发货</span>
            <span v-if="scope.row[item.value] == 3">已发货</span>
            <span v-if="scope.row[item.value] == 4">已完成</span>
          </div>
          <!-- 发货单-物流类型 -->
          <div v-else-if="mode == 'dispatchs' && item.value == 'shipping_type'">
            <span v-if="!/^[0-9]+.?[0-9]*$/.test(scope.row[item.value])">{{scope.row[item.value]}}</span>
            <span v-if="scope.row[item.value] == 1">美乐维</span>
            <span v-if="scope.row[item.value] == 2">顺丰速运</span>
            <span v-if="scope.row[item.value] == 3">中铁快运</span>
            <span v-if="scope.row[item.value] == 4">EMS</span>
            <span v-if="scope.row[item.value] == 5">华空物流</span> 
          </div>
          <!-- 订单-发货单号 -->
          <div v-else-if="mode == 'orders' && item.value == 'dispatch_code'">
            <div v-for="(itemX,index) in scope.row[item.value]" :key="index" @click="goDispatchs(itemX)">{{itemX}}</div>
          </div>
          <!-- 发货单-订单编号 -->
          <div v-else-if="mode == 'dispatchs' && item.value == 'order_code'">
            <span @click="goOrders(scope.row[item.value])">{{scope.row[item.value]}}</span>
          </div>
          <!-- 日志类型类型 -->
          <div v-else-if="mode == 'diarys' && item.value == 'diary_type'">
            <span v-for="(item,index) in scope.row[item.value]" :key="index">{{item}}</span>
          </div>
          <!-- 失控-试剂批号 -->
          <div v-else-if="mode == 'cycle_qc_data_list' && item.value == 'qc_batch'">
            <span v-for="(item,index) in scope.row[item.value]" :key="index">{{item}}</span>
          </div>
          <!-- 失控-违背规则 -->
          <div v-else-if="mode == 'cycle_qc_data_list' && item.value == 'qc_info'">
            {{scope.row[item.value].c1_rule?'C1:'+scope.row[item.value].c1_rule:''}}
            {{scope.row[item.value].c2_rule?'C2:'+scope.row[item.value].c2_rule:''}}
            {{scope.row[item.value].c3_rule?'C3:'+scope.row[item.value].c3_rule:''}}
            <!-- <span v-for="(item,index) in scope.row[item.value]" :key="index">
              {{item.c1_rule?'C1:'+item.c1_rule:''}}
              {{item.c2_rule?'C2:'+item.c2_rule:''}}
              {{item.c3_rule?'C3:'+item.c3_rule:''}}
            </span> -->
          </div>
          <!-- 失控-是否有失控报告 -->
          <div v-else-if="mode == 'cycle_qc_data_list' && item.value == 'pdf_url'">
            <span>{{scope.row.pdf_url.length != 0?'有':'无'}}</span>
          </div>
          <!-- 时间 正确匹配空格，转换为换行标签-->
          <div v-else-if="['last_use_time','last_used_date','yun_time','time_extra','creat_time','create_time','implement_date','implement_date','corrected_date','create_date'].includes(item.value)">
            <span v-if="scope.row[item.value]" v-html="scope.row[item.value].replace(/\s+/gm, '<br>')"></span>
          </div>
          <div v-else>{{ scope.row[item.value] }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="140">
        <template slot-scope="scope">
          <div class="caozuo">
            <el-tooltip class="item" effect="light" :content="'查看' + tooltipContent" placement="left" popper-class="custom-tooltip-border">
              <div class="box" @click="toDetail(scope.row)">
                <img
                  src="@/assets/img/fhd.png"
                  alt=""
                  @mouseenter="hoverBtn('fhd2', $event)"
                  @mouseleave="hoverBtn('fhd', $event)"
                  @click="hoverBtn('fhd', $event)"
                />
              </div>
            </el-tooltip>
            <!-- 质检单-下载 -->
            <el-tooltip class="item" effect="light" :content="'下载' + tooltipContent " placement="left" popper-class="custom-tooltip-border">
              <div
                v-if="mode == 'reports'"
                class="cursorpointer"
                @click="downImgBtn(scope.row)"
              >
                <i class="el-icon-download"></i>
              </div>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="el_pagination_style">
      
      <el-pagination
        :hide-on-single-page="total <= 0"
        background
        :disabled="$store.state.searchBtnLoading"
        layout="total,prev, pager, next,jumper"
        :total="total"
        :current-page.sync="pageNum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
    <!-- 表格导出对话框 -->
    <el-dialog
      title="导出EXCLE"
      :visible.sync="dialogVisible"
      width="30%"
      >
      <div class="dialog" >
        <p class="title">请确认信息</p>
        <p class="tips">导出EXCLE时间可能较长，请耐心等待</p>
        <div v-if="this.mode == 'instruments'">
          <ul>
            <li v-if="this.searchArr.instrument_id">机号:{{ this.searchArr.instrument_id }}</li>
            <li v-if="this.searchArr.platform_id">平台:{{ platformText(this.searchArr.platform_id) }}</li>
            <li v-if="this.searchArr.province_id">省份:{{ provinceText(this.searchArr.province_id) }}</li>
            <li v-if="this.searchArr.city_id">市区:{{ cityText(this.searchArr.city_id) }}</li>
            <li v-if="this.searchArr.is_stab">穿刺机:{{this.searchArr.is_stab=='True'?'是':'否'}}</li>
            <li v-if="this.searchArr.hospital_name">医院:{{this.searchArr.hospital_name}}</li>
            <li v-if="this.searchArr.is_factory">是否厂内:{{this.searchArr.is_factory=='1'?'是':'否'}}</li>
            <li v-if="this.searchArr.salesman">销售:{{this.searchArr.salesman}}</li>
            <li v-if="this.searchArr.engineer">工程师:{{this.searchArr.engineer}}</li>
            <li v-if="this.searchArr.customer_name">经销商:{{this.searchArr.customer_name}}</li>
            <li v-if="this.searchArr.gprs_status">仪器状态:{{ this.searchArr.gprs_status ? this.statusOptions.filter(item => item.value == this.searchArr.gprs_status)[0].label : ''}}</li>
            <li v-if="this.searchArr.has_recent_data">七天内是否有数据传输:{{this.searchArr.has_recent_data=='1'?'是':'否'}}</li>
          </ul>
        </div>
        <div v-if="this.mode == 'samplechecks'">
         <ul>
            <li v-if="this.searchArr.platform_id">平台:{{ platformText(this.searchArr.platform_id) }}</li>
            <li v-if="this.searchArr.testing_id">检测项目:{{ testingText(this.searchArr.testing_id) }}</li>
            <li v-if="this.searchArr.is_positive">阴阳性:{{ this.searchArr.is_positive=='True'?'阳性':'阴性' }}</li>
            <li v-if="this.searchArr.sample_type_id">样本类型:{{ sampleText(this.searchArr.sample_type_id) }}</li>
            <li v-if="this.searchArr.instrument_id">机号:{{ this.searchArr.instrument_id }}</li>
            <li v-if="this.searchArr.bottle">UDI:{{ this.searchArr.bottle }}</li>
            <li v-if="this.searchArr.hospital_name">医院:{{ this.searchArr.hospital_name }}</li>
            <li v-if="this.searchArr.batch_id">试剂批号:{{ this.searchArr.batch_id }}</li>
            <li v-if="this.searchArr.start_time">日期范围:{{ this.searchArr.start_time }}~{{ this.searchArr.end_time }}</li>
          </ul>
        </div>
        <div v-if="this.mode == 'diarys'">
         <ul>
            <li v-if="this.searchArr.diary_type">日志类型:{{ diaryTypeText(this.searchArr.diary_type) }}</li>
            <li v-if="this.searchArr.recorder_name">日志记录人:{{ this.searchArr.recorder_name }}</li>
            <li v-if="this.searchArr.start_time">日期范围:{{ this.searchArr.start_time }}~{{ this.searchArr.end_time }}</li>
          </ul>
        </div>
        <div v-if="this.mode == 'qcs'">
         <ul>
            <li v-if="this.searchArr.province_id">省份:{{ provinceText(this.searchArr.province_id) }}</li>
            <li v-if="this.searchArr.testing_id">检测项目:{{ testingText(this.searchArr.testing_id) }}</li>
            <li v-if="this.searchArr.instrument_id">机号:{{ this.searchArr.instrument_id }}</li>
            <li v-if="this.searchArr.qc_batch">质控批号:{{ this.searchArr.qc_batch }}</li>
            <li v-if="this.searchArr.batch_id">试剂批号:{{ this.searchArr.batch_id }}</li>
            <li v-if="this.searchArr.hospital_name">医院:{{ this.searchArr.hospital_name }}</li>
            <li v-if="this.searchArr.start_time">日期范围:{{ this.searchArr.start_time }}~{{ this.searchArr.end_time }}</li>
          </ul>
        </div>
        <div v-if="this.mode == 'recordwarnings'">
         <ul>
            <li v-if="this.searchArr.platform_id">平台:{{ platformText(this.searchArr.platform_id) }}</li>
            <li v-if="this.searchArr.province_id">省份:{{ provinceText(this.searchArr.province_id) }}</li>
            <li v-if="this.searchArr.city_id">市区:{{ cityText(this.searchArr.city_id) }}</li>
            <li v-if="this.searchArr.warning_id">告警代码:{{ this.searchArr.warning_id }}</li>
            <li v-if="this.searchArr.instrument_id">机号:{{ this.searchArr.instrument_id }}</li>
            <li v-if="this.searchArr.hospital_name">医院:{{ this.searchArr.hospital_name }}</li>
            <li v-if="this.searchArr.engineer">工程师:{{this.searchArr.engineer}}</li>
            <li v-if="this.searchArr.start_time">日期范围:{{ this.searchArr.start_time }}~{{ this.searchArr.end_time }}</li>
          </ul>
        </div>
        <div v-if="this.mode == 'repairtask'||this.mode == 'installtask'||this.mode == 'maintaintask'">
          <ul>
            <li v-if="this.searchArr.province_id">省份:{{ provinceText(this.searchArr.province_id) }}</li>
            <li v-if="this.searchArr.platform_id">平台:{{ platformText(this.searchArr.platform_id) }}</li>
            <li v-if="this.searchArr.instrument_id">机号:{{ this.searchArr.instrument_id }}</li>
            <li v-if="this.searchArr.implement_engineer_name">工程师名称:{{ this.searchArr.implement_engineer_name }}</li>
            <li v-if="this.searchArr.hospital_name">医院:{{ this.searchArr.hospital_name }}</li>
            <li v-if="this.searchArr.start_time">日期范围:{{ this.searchArr.start_time }}~{{ this.searchArr.end_time }}</li>
          </ul>
        </div>
        
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="export_instrumentFun">导出</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { searchTable } from '@/assets/js/clickSearch.js'
import { getPlatform,searchData,export_instrument,export_diary,getSampletype,export_sample_check,export_qc,export_task,export_record_warning } from '@/api/search.js'
import apiDetail from '@/api/searchDetail.js'
import { eventBus } from '@/main' 
import { Loading } from 'element-ui';
import { flexColumnWidth } from '@/assets/js/adaption.js'

export default {
  props: ['mode', 'searchList'],
  data() {
    return {
      user_id:  JSON.parse(localStorage.getItem('userInfo')).user_id ,
      org_post:  JSON.parse(localStorage.getItem('userInfo')).org_post ,
      loading: false,
      tableHeader: [], //列表头
      tableData: [], //列表数据
      searchArr: [],
      platformList: [],//平台列表
      sampletypeList: [],//样本类型列表
      statusOptions:[
        {label: '正常', value: 2001},
        {label: '停用', value: 2002},
        {label: '备用机', value: 2003},
        {label: '未启用', value: 2004},
        {label: '传输失败', value: 2005}
      ],
      diarysOption: [
        { value: '1', label: '装机' },
        { value: '2', label: '维修' },
        { value: '3', label: '保养' },
        { value: '4', label: '校准' },
        { value: '5', label: '性能验证' },
        { value: '6', label: '展会' },
        { value: '7', label: '拜访' },
        { value: '8', label: '代理商' },
        { value: '9', label: '远程' },
      ],
      // 'samplechecks'
      mode_export_List: ['diarys','instruments','qcs','repairtask','installtask','maintaintask','recordwarnings'],
      dialogVisible: false,
      tooltipContent: '', //操作提示
      pageNum: 1,
      pageSize: 10,
      total: 0,
    }
  },
  created() {
    eventBus.$on('pageNumReset',(pageNum) => {
      this.pageNum = pageNum;
    })
  },
  computed: {
    serialtext() {
      return (index)=>{
        if(index == 0){
          return 0
        }else{
          return (this.pageNum-1)*10+index
        }
      }
    },
    platformText() { //平台
      return (platform_id) => {
        return this.platformList.filter(e => e.value == platform_id)[0].label;
      }
    },
    provinceText() { //省份
      return (province_id) => {
        let provinceList = JSON.parse(localStorage.getItem('province'))
        return provinceList.filter(e => e.value == province_id)[0].label;
      }
    },
    cityText() { //城市
      return (city_id) => {
        let provinceList = JSON.parse(localStorage.getItem('province'))
        let cityList = provinceList.filter(e => e.value == this.searchArr.province_id)[0].city_list;
        return cityList.filter(e => e.value == city_id)[0].label
      }
    },
    testingText() { //检测项目
      return (testing_id) => {
        let testingList = JSON.parse(localStorage.getItem('testing'))
        return testingList.filter(e => e.value == testing_id)[0].label;
      }
    },
    sampleText() { //样本类型
      return (sample_type_id) => {
        return this.sampletypeList.filter(e => e.value == sample_type_id)[0].label;
      }
    },
    diaryTypeText() { //日志类型
      return (diary_type)=>{
        switch (diary_type) {
          case '1': return '装机';
          case '2': return '维修';
          case '3': return '保养';
          case '4': return '校准';
          case '5': return '性能验证';
          case '6': return '展会';
          case '7': return '拜访';
          case '8': return '代理商';
          case '9': return '远程';
          default:
            break;
        }
      }
    }
  },
  watch: {
    mode(value) {
      this.searchArr = {};
      this.searchArr.instrument_id = '';
      this.searchArr.dispatch_code = '';
      this.searchArr.order_code = '';
      if(value=='recordwarnings'){
        // 告警
        let recordwarningsVal = localStorage.getItem('recordwarnings');
        localStorage.setItem('recordwarnings',  '')
        this.searchArr.instrument_id = recordwarningsVal?recordwarningsVal : ''
      }else if(value=='dispatchs'){
        // 发货单
        let dispatchsVal = localStorage.getItem('dispatchs');
        localStorage.setItem('dispatchs',  '')
        this.searchArr.dispatch_code = dispatchsVal?dispatchsVal : ''
      }
      else if(value=='orders'){
        // 订单
        let ordersVal = localStorage.getItem('orders');
        localStorage.setItem('orders',  '')
        this.searchArr.order_code = ordersVal?ordersVal : ''
      }
      this.tooltipContent = searchTable[value].tooltipContent
      this.tableHeader = searchTable[value].tableHeader
      this.pageNum = 1
      this.total = 0
      this.getListFun()
    },
    searchList(value) {
      this.searchArr = value
      this.getListFun()
    },

  },
  mounted() {
    this.platformList = getPlatform();
    this.sampletypeList = getSampletype();
  },
  methods: {
    // 宽度
    flexColumnWidth(width,minWidth) {
      if(width){
        return flexColumnWidth(width,minWidth)
      }else {
        return null
      }
    },
    // 获取列表数据
    getListFun() {
      this.tableData = []
      this.loading = true
      let tempMode = this.mode
			this.$store.commit("dosearchBtnLoading", true);
      if(this.mode){
        searchData(this.mode, {
          page: this.pageNum,
          size: this.pageSize,
          ...this.searchArr,
        })
            .then((res) => {
              if(this.mode != tempMode){return} //当前界面展示的搜索如果和展示不同时不进行下一步操作。
              if (res.code === 200) {
                this.tableData = res.data.data
                this.total = res.data.count
                this.loading = false
              }
            })
            .catch((err) => {
              this.loading = false
            }).finally(msg => {
          this.$store.commit("dosearchBtnLoading", false);
        })
      }

    },

    // 导出excle
    export_instrumentFun() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      var a = document.createElement('a')
      var event = new MouseEvent('click')
      a.download = 'imgName'
      
      switch (this.mode) {
        case 'instruments':
          export_instrument({
          ...this.searchArr,
          }).then(res => {
            if(res.code == 200) {
              loading.close();
              this.dialogVisible = false;
              
              a.href = res.data.file_url
              a.dispatchEvent(event)
            }
          }).catch(err=>{
              loading.close();
          })
          break;
        case 'samplechecks':
          export_sample_check({
          ...this.searchArr,
          }).then(res => {
            if(res.code == 200) {
              loading.close();
              this.dialogVisible = false;

              a.href = res.data.file_url
              a.dispatchEvent(event)
            }
          }).catch(err=>{
              loading.close();
          })
          break;
        case 'diarys':
          this.searchArr['is_type'+this.searchArr.diary_type]=true;
          export_diary({
            ...this.searchArr,
          }).then(res => {
            if(res.code == 200) {
              loading.close();
              this.dialogVisible = false;

              a.href = res.data.file_url
              a.dispatchEvent(event)
            }
          }).catch(err=>{
              loading.close();
          })
          break;
        case 'qcs':
          export_qc({
            ...this.searchArr,
          }).then(res => {
            if(res.code == 200) {
              loading.close();
              this.dialogVisible = false;

              a.href = res.data.file_url
              a.dispatchEvent(event)
            }
          }).catch(err=>{
              loading.close();
          })
          break;
        case 'recordwarnings': 
          export_record_warning({
            ...this.searchArr,
            }).then(res => {
              if(res.code == 200) {
                loading.close();
                this.dialogVisible = false;

                a.href = res.data.file_url
                a.dispatchEvent(event)
              }
            }).catch(err=>{
                loading.close();
            })
          break;
        case 'repairtask':case 'installtask':case 'maintaintask':
          let task_type = this.mode=='repairtask'?2:
                        this.mode=='installtask'?1:3

          export_task({
            task_type,
            ...this.searchArr,
          }).then(res => {
            if(res.code == 200) {
              loading.close();
              this.dialogVisible = false;

              a.href = res.data.file_url
              a.dispatchEvent(event)
            }
          }).catch(err=>{
              loading.close();
          })
          break;
        default:
          break;
      }
      
    },
    handleSizeChange(val) {
      this.pageNum = val
      this.getListFun()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getListFun()
    },

    // 鼠标放在操作图标时
    hoverBtn(a, e) {
      e.target.src = require('@/assets/img/' + a + '.png')
    },
    //打开发货单页面并带参数
    goDispatchs(value) {

      // localStorage.setItem('mode', 'dispatchs')
      sessionStorage.setItem('mode', 'dispatchs')
      localStorage.setItem('dispatchs',  value)
      this.$emit('showTableList');
    },
    // 打开订单页面并带参数
    goOrders(value) {
      // localStorage.setItem('mode', 'orders')
      sessionStorage.setItem('mode', 'orders')

      localStorage.setItem('orders',  value)
      this.$emit('showTableList');
    },
    // 跳转详情页面
    toDetail(value) {
      let id = '',
          newPage = false
      switch (this.mode) {
        case 'orders':
          id = value.order_id
          break
        case 'products':
          id = value.inventory_id
          break
        case 'instruments':
          id = value.instrument_id
          break
        case 'kits':
          id = value.kit_id
          break
        case 'reports':
          id = value.report_id
          newPage = true
          break
        case 'dispatchs':
          id = value.dispatch_id
          break
        case 'qcs':
          id = value.qc_id
          break
        case 'calibrations':
          id = value.calibration_id
          break
        case 'cycle_qc_data_list':
          id = value.id 
          newPage = true
          break
        case 'recordwarnings':
          id = value.record_warning_id
          break
        case 'diarys':
          id = value.diary_id
          newPage = true
          break
        case 'maintaintask':
          id = value.task_id
          newPage = true
          break
        case 'repairtask':
          id = value.task_id
          newPage = true
          break
        case 'installtask':
          id = value.task_id
          newPage = true
          break
        case 'articleapplis':
          id = value.apply_id
          break
        case 'samplechecks':
          id = value.sample_check_id
          break
        default:
          break
      }
      // 产品-产品类型-试剂-跳转的页面
      if (this.mode == 'products' && value.inventory_type == '平台') {
        this.$router.push({
          path: 'apparatus-detail',
          query: { id },
        })
      } else if(newPage) {
        const{ href } = this.$router.resolve({ path: this.mode + '-detail',query:{id}});
        window.open(href,'_blank');
      } else {
        this.$router.push({
          path: this.mode + '-detail',
          query: { id },
        })
      }
      
    },
    // 文件打包压缩
    upload_packageFun(value) { 
      let pack_name = value.reagent_name + value.batch,
          file_list = value.report_img;
      
      let param = {
        file_list,
        pack_name,
      }
      apiDetail.upload_package(param).then(res => {
      })
    },
    // 链接下载
    choseDownload(url) {
 
      //首先点击下载事件时创建一个a标签
      let link = document.createElement("a");
      //将a标签隐藏
      link.style.display = "none";
      //给a标签添加下载链接  "域名+接口"
      //safe是一个动态的域名(safe是ip地址，需要自己定义)
      //后面拼接的是接口，替换成你自己的下载接口和需要传递的参数
      link.href = url;
      // 此处给a标签添加一个download属性，属性值就是文件名称否则下载出来的文件是没有属性的
      //下面代码，name会有斜杠，不影响使用
      link.setAttribute("download", name);
      link.target = "_blank";
      //将上面创建的a标签加入到body的尾部
      document.body.appendChild(link);
      //最后执行a标签
      link.click();
      document.body.removeChild(link);
    },

    // 下载-质检单-图片
    downImgBtn(value) {
   
      if( value.is_pdf){
        value.report_img[0]
        this.choseDownload(value.report_img[0])
        return
      }

      let loadingInstance = Loading.service();
      // 首先传至后端进行图片压缩
      let param = {
        pack_name: value.reagent_name + value.batch,
        file_list: value.report_img
      }
      apiDetail.upload_package(param).then(res => {
        if(res.code == 200){
            window.open(res.data)
        }else{
          this.$message.success('下载失败！')
        }
        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close();
        });
      }).catch(err => {
        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
        loadingInstance.close();
        });
      })
    },
    // 下载
    downloadCodeImg(ImgURL) {
      var image = new Image();
      image.setAttribute("crossOrigin", "Anonymous");
      var _this = this;
      // image.crossOrigin = "Anonymous"; //允许跨域
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = _this.projectName || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = ImgURL;
    }
  },
}
</script>
<style lang="scss" scoped>
.tableList {
  min-height: px(649);
  background: #ffffff;
  border-radius: px(5);
  .btn{
    margin-top: px(20);
    display: flex;
    justify-content: end;
  }
  .el-table {
    padding: px(15);
   // min-height: px(750);
    min-height: px(380);
    .box {
      img {
        width: px(36);
        height: px(36);
        cursor: pointer;
      }
    }
    .caozuo {
      display: flex;
      justify-content: center;
      align-items: center;
      .el-icon-download {
        font-size: px(22);
      }
    }
  }
}
.dialog {
  .title {
    font-size: px(25);
    font-weight: bold;
    color: #000000;
  }
  .tips {
    color: red;
    font-size: px(18);
  }
}

</style>
<style>
.custom-tooltip-border{
  border: 1px solid #8f9297!important;
}
.tooltip_instrument{
  //border-color: #BD3124!important;
  border-color: transparent!important;
  //box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.16);
  box-shadow: 0 0.1041666667vw 0.4166666667vw 0 rgba(0, 0, 0, 0.15);
  color:#BD3124!important;
  .popper__arrow {
    border-top-color: transparent!important;
    //border-top-color: #BD3124!important;
  }
}
.question{
  vertical-align: 3px;
  width: 13px;
  height: 13px;
  display: inline-block;

  background: url('../../../assets/img/quest.png');
  background-size: cover;
}
</style>
