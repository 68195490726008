<template>
  <div>
     <div>
       <el-tabs v-model="activeName" >
         <el-tab-pane label="按客户定价" name="1"> <span class="tab_title" slot="label"><i class="el-icon-s-grid"></i> 按客户定价</span>
           </el-tab-pane>
         <el-tab-pane label="按商品定价" name="2"><span  class="tab_title" slot="label"><i class="el-icon-s-grid"></i> 按商品定价</span></el-tab-pane>

         </el-tabs>
       <span style ="position: absolute;top: 13px;right: 20px;" @click="close" ><i class="el-icon-close"></i></span>
       <div class="drawer_content_all" :style="'height:' + blockHeight + 'px'" @scroll="handleScroll" ref="fixPrice">
          <custom-fix-price v-if="activeName == '1'" ref="customFixPrice" :selectData="tableData" @close="close"></custom-fix-price>
          <trade-fix-price v-if="activeName == '2'" ref="tradeFixPrice" :selectData="tableData" @close="close"></trade-fix-price>
       </div>
     </div>
  </div>
</template>
<script>
import customFixPrice from './customFixPrice.vue'
import tradeFixPrice from './tradeFixPrice.vue'
export default {
  components: {customFixPrice, tradeFixPrice},
  data() {
    return {
      activeName: '1',
      isDrawerShow: false,
      tableData: [],
      blockHeight: 0,
    }
  },
  props: {
    selectData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  created() {
    this.getTableHeight()
    if( this.selectData){
      this.tableData = JSON.parse(JSON.stringify(this.selectData))
    }
  },
  watch:{
    selectData(newVal) {
      // this.tableData = []
      // this.tableData.push(...JSON.parse(JSON.stringify(this.selectData)))
      this.selectData = newVal
    }
  },
  computed: {
    totalAmount(){
      let total = 0
      this.tableData.forEach(item => {
        total += Number(item.total)
      })
      return total
    }
  },
  mounted() {
    this.$refs.fixPrice.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    this.$refs.orderDtl.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll(){
      if(this.activeName == '1'){
        this.$refs.customFixPrice.handleScroll()
      }else{
        this.$refs.tradeFixPrice.handleScroll()
      }
    },
    getTableHeight(){
      var viewportHeight = window.innerHeight;
      this.blockHeight = viewportHeight - 130
    },

    handleCountChange(row){
      row.total= Number( row.price) * row.count
    },
    handleFullNameChange(row){
      row.easy_name= 'M-Cardiac 3in1',
          row.type= '20人份',
          row.price= '2000.00'
    },
    handleSave(){},
    handleDelete(index){
      this.tableData.splice(index, 1)
    },
    close(){
      this.$emit('close')
    }
  }
}
</script>
<style scoped lang="scss">

.drawer_content_all{
  height: 800px;
  overflow-y: auto;
}
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  ::v-deep .el-tabs__active-bar.is-top{
  width: 114px!important;
  left:5px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 14px;
  }
  ::v-deep .el-drawer__header{
    color: #000;
    font-size: 16px;
  }
  .orderInfo .block p, .orderInfo .block div{
    font-size: 14px;
  }
  .block_title{
    font-size: 16px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 14px;
  }
  .table_bottom p{
    font-size: 14px;
  }
  .tab_title{
    font-size: 16px;
  }
}
@media screen and (min-width: 1551px) , print {
  ::v-deep .el-tabs__active-bar.is-top{
    width: 126px!important;
    left:5px;
  }
  .tab_title{
    font-size: 18px;
  }
  ::v-deep .el-table th.el-table__cell>.cell{
    font-size: 16px;
  }
  ::v-deep .el-table td.el-table__cell div{
    font-size: 16px;
  }
  ::v-deep .el-drawer__header{
    color: #000;
    font-size: 18px;
  }
  .orderInfo .block p, .orderInfo .block div{
    font-size: 16px;
  }
  .block_title{
    font-size: 18px;
  }
  .table_bottom p{
    font-size: 16px;
  }
}

  .block_title{
    font-size: 16px;
    font-weight: bold;
    margin: 16px 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
  }

::v-deep .el-tabs__item{
  padding: 0 10px;
}
::v-deep .el-tabs--top .el-tabs__item.is-top:nth-child(2){
  margin-left:10px;
}
</style>