<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="60%"
      custom-class="dialog_right"
      :close-on-click-modal="false"
      :show-close="false">
      <div class="monthly-box">
        <div class="top"><!-- 头部 -->
          <span>质控月度报告</span>
          <div class="calendarDate-box">
            <el-date-picker
              v-model="month"
              type="month"
              format="yyyy/MM"
              value-format="yyyy-MM"
              :size="size"
              placeholder="选择月"
              class="calendarDate"
              :picker-options="{}"
              :clearable="false"
              @change="chanageMonth"
            ></el-date-picker>
          </div>
         
        </div>
        <div class="body" ref="body" >
          
          <div  ref="printBox" class="printBox">
            <detailMenu :dataList="dataList1"></detailMenu>
            <!-- 循环分割线头 -->
            <div class="line"></div>
            <div v-if="showNoData" class="noData-box"> 
              您所选择的月份无数据
            </div>

            <div v-for="(item,index) in qcp_data_list" :key="index" class="qcp-data-box">
              <detailMenu :dataList="item.dataList"></detailMenu>
              <div class="echart-box">
                <p class="title">L-J图</p>
                <table border="1" class="monthly-table" >
                  <tbody class="monthly-tbody">
                    <tr>
                      <td class="left no-left-border">
                        <span>C1</span>
                        <ul>
                          <li v-if="item.dataList[3].value=='设定'"><span>(设定)</span></li>
                          <li><p><span class="xbar">X</span>:</p><p>{{textTarget(item.qc_data.target_c1)}}mg/L</p></li>
                          <li><p>SD:</p><p>{{textTarget(item.qc_data.sd_c1)}}mg/L</p></li>
                          <li><p>CV:</p><p>{{ textCV(item.qc_data.cv_c1)}}%</p></li>
                        </ul>
                      </td>
                      <td class="no-right-border">
                        <line_LJ v-if="echartsShow" :legendShow="false" :id="'monthly'+item.c1_data.id" :ehartData="item.c1_data" :calibration_data_list="calibration_data_list"></line_LJ>
                      </td>
                    </tr>
                    <tr>
                      <td class="left no-left-border">
                        <span>C2</span>
                        <ul>
                          <li v-if="item.dataList[3].value=='设定'"><span>(设定)</span></li>
                          <li><p><span class="xbar">X</span>:</p><p>{{textTarget(item.qc_data.target_c2)}}mg/L</p></li>
                          <li><p>SD:</p><p>{{textTarget(item.qc_data.sd_c2)}}mg/L</p></li>
                          <li><p>CV:</p><p>{{ textCV(item.qc_data.cv_c2)}}%</p></li>
                        </ul>
                      </td>
                      <td class="no-right-border">
                        <line_LJ v-if="echartsShow" :legendShow="false" :id="'monthly'+item.c2_data.id" :ehartData="item.c2_data" :calibration_data_list="calibration_data_list"></line_LJ>
                      </td> 
                    </tr>
                  </tbody>
                </table>
                <p class="title">
                  Z分数图
                </p>
                <div class="Z-box" v-if="echartsShow">
                  <line_Z :id="'monthlyEchartLine'+index"  :ehartData="item.zData" :calibration_data_list="calibration_data_list"></line_Z>
                </div>
              </div>
            </div>
            <div class="line"></div>
            <!-- 循环分割线  尾 -->
            <div class="statistics-box" v-if="statistics_data!={}">
              <p class="title">统计图</p>
              <table border="1" class="monthly-table" >
                <tbody class="monthly-tbody">
                  <tr>
                    <td rowspan='5' class="no-left-border tip-c-td">
                      C1
                    </td>
                    <td rowspan='4' class="no-left-border">
                      质控品数据
                    </td>
                    <td></td>
                    <td class="no-right-border">
                      <ul class="target">
                        <li><span class="xbar">X</span>(mg/L)</li>
                        <li>SD(mg/L)</li>
                        <li>CV(%)</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>上月</td>
                    <td class="no-right-border">
                      <ul class="target">
                        <li>{{ textTarget(statistics_data.last_month_target_c1) }}</li>
                        <li>{{ textTarget(statistics_data.last_month_sd_c1) }}</li>
                        <li>{{ textCV(statistics_data.last_month_cv_c1) }}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>本月</td>
                    <td class="no-right-border">
                      <ul class="target">
                        <li>{{ textTarget(statistics_data.now_month_target_c1) }}</li>
                        <li>{{ textTarget(statistics_data.now_month_sd_c1) }}</li>
                        <li>{{ textCV(statistics_data.now_month_cv_c1) }}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>累积</td>
                    <td class="no-right-border">
                      <ul class="target">
                        <li>{{ textTarget(statistics_data.year_stats_target_c1) }}</li>
                        <li>{{ textTarget(statistics_data.year_stats_sd_c1)  }}</li>
                        <li>{{ textCV(statistics_data.year_stats_cv_c1)  }}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="no-right-border">
                      <monthlyS v-if="echartsShow" id="echartLineS1" :ehartData="statistics_data.ehartData_C1"></monthlyS>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan='5' class="no-left-border tip-c-td">
                      C2
                    </td>
                    <td rowspan='4' class="no-left-border">
                      质控品数据
                    </td>
                    <td></td>
                    <td class="no-right-border"> 
                      <ul class="target">
                        <li><span class="xbar">X</span>(mg/L)</li>
                        <li>SD(mg/L)</li>
                        <li>CV(%)</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>上月</td>
                    <td class="no-right-border">
                      <ul class="target">
                        <li>{{ textTarget(statistics_data.last_month_target_c2) }}</li>
                        <li>{{ textTarget(statistics_data.last_month_sd_c2) }}</li>
                        <li>{{ textCV(statistics_data.last_month_cv_c2) }}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>本月</td>
                    <td class="no-right-border">
                      <ul class="target">
                        <li>{{ textTarget(statistics_data.now_month_target_c2) }}</li>
                        <li>{{ textTarget(statistics_data.now_month_sd_c2)  }}</li>
                        <li>{{ textCV(statistics_data.now_month_cv_c2)  }}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>累积</td>
                    <td class="no-right-border">
                      <ul class="target">
                        <li>{{ textTarget(statistics_data.year_stats_target_c2)}}</li>
                        <li>{{ textTarget(statistics_data.year_stats_sd_c2)  }}</li>
                        <li>{{ textCV(statistics_data.year_stats_cv_c2 ) }}</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" class="no-right-border">
                      <monthlyS v-if="echartsShow" id="echartLineS2" :ehartData="statistics_data.ehartData_C2"> </monthlyS>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="analysis-box">
              <p>综合分析</p>
              <div class="analysis-text-box">
                <p>根据本月质控结果观察:</p>
                <p class="indent">C1质控品统计次数 <u>{{'\u00a0'}}{{analysis_data.c1_cnt}}{{'\u00a0'}}</u> 次，C2质控品统计次数 <u>{{'\u00a0'}}{{analysis_data.c2_cnt}}{{'\u00a0'}}</u> 次</p>
                <p class="indent">失控统计 <u>{{'\u00a0'}}{{analysis_data.lc_cnt}}{{'\u00a0'}}</u> 次:</p>
                <p class="indent2">
                  <span v-for="(item,index) in analysis_data.stats_lc_report" :key="index">
                    {{item.analysis_type_name}}: <u>{{'\u00a0'}}{{ item.count}}{{'\u00a0'}}</u> 次
                  </span>
                </p>
              </div>
<!--              <div class="print-info-box">-->
<!--                <p><span>分析人:</span>{{user_name}}</p>-->
<!--                <p><span>打印人:</span>{{user_name}}</p>-->
<!--                <p><span>打印时间:</span>{{nowDate}}</p>-->
<!--                <p><span>质量负责人:</span></p>-->
<!--              </div>-->
            </div>
            <div class="analysis-box" style="margin-top: 10px;">
              <p>结论</p>
              <div class="analysis-text-box conclusion">
                <!--情况1，新旧试剂批号切换-->
<!--                <p v-if="month_summary.change_batch"  class="conclusion_item">本月切换试剂批号，定靶过程中，测试的均值和SD与设定的靶值和SD接近，符合预期，处于受控状态。</p>-->
                <!--情况2，没有发生失控的月度分析小结-->
                <p v-if="month_summary.not_lc" class="conclusion_item">本月<span v-if="month_summary.change_batch">切换试剂批号，共</span>做了{{month_summary.qc_cnt}}次质控，未发生失控情况，系统状态稳定，没有明显误差，质控曲线形态
                  <el-select size="mini" v-model="status_val" @change="changeSelect" style="width: 100px" class="conclusion_select">
                    <el-option
                        v-for="item in statusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                  </el-option></el-select>，
                  <el-select size="mini" v-model="trend_val" @change="changeSelect" style="width: 100px" class="conclusion_select">
                    <el-option
                        v-for="item in trendOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                  </el-select>趋势线变化，SD、CV值与上个月做比较：
                  <el-select size="mini" v-model="compare_val" @change="changeSelect" style="width: 130px" class="conclusion_select">
                    <el-option
                        v-for="item in compareOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option>
                  </el-select>，本月处于：<el-select size="mini" v-model="control_val" @change="changeSelect" style="width: 100px" class="conclusion_select">
                    <el-option
                        v-for="item in controlOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    >
                    </el-option></el-select>状态。</p>
                <!--情况3，发生失控的月度小结-->
                <p v-if="!month_summary.not_lc" >本月<span v-if="month_summary.change_batch">切换试剂批号，共</span>做了{{month_summary.qc_cnt}}次质控，失控数据如下:</p>
                <div v-if="!month_summary.not_lc">
                  <div v-for="item in month_summary.lc_info_list" class="conclusion_item">
                    <span style="vertical-align: top;font-weight: 600">{{item.data_time}},{{item.level}} 失控：</span>
                    <p style="display: inline-block">
                      <p>失控原因：{{item.reason}}</p>
                      <p>纠正措施：{{item.correction_name}}</p>
                    </p>
                  </div>

                  <p class="conclusion_item_last">纠正后数据SD、CV值与上个月做比较：
                    <el-select size="mini" v-model="compare_val" @change="changeSelect" style="width: 130px" class="conclusion_select">
                      <el-option
                        v-for="item in compareOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>，本月处于：<el-select size="mini" v-model="control_val" @change="changeSelect" style="width: 100px" class="conclusion_select">
                      <el-option
                          v-for="item in controlOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                      >
                      </el-option>
                    </el-select>状态</p>
                </div>
              </div>
              <div class="print-info-box">
                <p><span>分析人:</span>{{user_name}}</p>
                <p><span>打印人:</span>{{user_name}}</p>
                <p><span>打印时间:</span>{{nowDate}}</p>
                <p><span>质量负责人:</span></p>
              </div>
            </div>
          </div>
          <div v-if="monthly_pdf_show">
          <!-- <div> -->
            <printMonthly id="pdfDom" :month="month" :user_name="user_name" :nowDate="nowDate" :analysis_data="analysis_data" :dataList1="dataList1" :qcp_data_list="qcp_data_list" :statistics_data="statistics_data" :calibration_data_list="calibration_data_list"></printMonthly>
          </div>
          
        </div>
        <div class="footed">
          <el-button type="primary" class="btn" :disabled="showNoData" :size="size" @click="download">下载报告</el-button>
          <el-button :size="size" class="btn" @click="close_dialog">关闭页面</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import detailMenu from '../detailMenu.vue'
import api from '@/api/quality'
import line_Z from '../echarts/line_Z.vue'; 
import line_LJ from '../echarts/line_LJ.vue'; 
import monthlyS from '../echarts/monthlyS.vue'
import indoorMixins from '../indoorMixins'
import printMonthly from './printMonthly.vue';

export default {
  props: {
    calibration_data_list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  mixins: [indoorMixins],
  components: {
    printMonthly,
    detailMenu,
    line_Z,
    line_LJ,
    monthlyS,
  },
  data() {
    return {
      size: 'medium', //组件样式
      dialogVisible: false, //弹窗是否显示
      month: '', //月份
      echartsShow: false, //图表显示
      title_show: false,
      monthly_pdf_show: false,
      dataList1: [
        {
          name: '医院',
          value: '',
          width: '100%',
          rightBorder: false,
        },
        {
          name: '仪器名称',
          value: '',
          width: '33%',
        },
        {
          name: '机号',
          value: '',
          width: '33%',
        },
        {
          name: '检测项目',
          value: '',
          width: '33%',
          rightBorder: false,
        },
        {
          name: '失控规则',
          value: '',
          width: '33%',
        },
        {
          name: '警告规则',
          value: '',
          width: '33%',
        },
        {
          name: '方法学',
          value: '',
          width: '33%',
          rightBorder: false,
        },
      ], //医院基本信息
      qcp_data_list: [
  
      ], //质控品列表
      statistics_data: {}, //统计数据
      analysis_data: {
        stats_lc_report: [],
      }, //综合分析
      optionsXm: [{testing_id:4,testing_unique:'hs-CRP'},{testing_id:11,testing_unique:'SAA'}],//项目列表
      monthly_params: {
        stats_month: '',
        instrument_id: '',
        testing_id: '',
      },
      user_name: JSON.parse(localStorage.getItem('userInfo')).user_name,//个人名称
      nowDate: '', //当前时间
      showNoData: false, // 当前无数据状态显示
      month_summary: {
        not_lc: false
      },
      compare_val: '',
      status_val: '',
      trend_val: '',
      control_val: '',
      compareOptions:[
        {value: '比较接近', label: '比较接近'},
        {value: '更好', label: '更好'},
        {value: '明显下降', label: '明显下降'},
      ],
      statusOptions:[
        {value: '正常', label: '正常'},
        {value: '异常', label: '异常'},
      ],
      trendOptions:[
        {value: '有', label: '有'},
        {value: '无', label: '无'},
      ],
      controlOptions:[
        {value: '受控', label: '受控'},
        {value: '失控', label: '失控'},
      ],
    }
  },
  methods: {
    /* 报告下载 */
    download() {
      if(this.month_summary.not_lc){
        if(!this.compare_val || !this.status_val || !this.trend_val || !this.control_val){
          return this.$message.warning('请先填写结论')
        }
      }else{
        if(!this.compare_val || !this.control_val){
          return this.$message.warning('请先填写结论')
        }
      }
      this.monthly_pdf_show = true;

      this.$refs.body.scrollTo(0,0); 
      // this.title_show = true

      let loading = this.$loading({
        lock: true,
        text: '月度报告生成中，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      this.$nextTick(()=> {
        let title = `${this.dataList1[0].value}-${this.dataList1[2].value}-${this.monthly_params.testing_id==4?'hs-CRP':'SAA'}-${this.nowDate.split('-').join('')}`
        setTimeout(() => {
          this.getPdf(title)
          // this.title_show = false;
          this.monthly_pdf_show = false;
        }, 2000);
        setTimeout(()=> {
          loading.close();
        }, 4000)
      })
    },
    /* 打开和关闭弹窗，其他页面也会调用 */
    open_dialog(parent_data) {
      this.dialogVisible = true
      // 滚动条置顶
      this.$nextTick(() => {
        this.$refs.body?.scrollTo({ top: 0,});
      });
      // this.$refs.body?.scrollTo({ top: 0, behavior: 'smooth' })
      // this.$refs.printBox.scrollTop = 0
      // 设置当前页面参数
      this.monthly_params.instrument_id = parent_data.instrument_id
      this.monthly_params.testing_id = parent_data.testing_id
      let nowDate_text_list =  parent_data.nowDate.split('-')
      if(nowDate_text_list.length == 1){ // 兼容火狐浏览器
        this.monthly_params.stats_month = this.month = `${nowDate_text_list[0].split('.')[2]}-${nowDate_text_list[0].split('.')[1]}`
      }else{
        this.monthly_params.stats_month = this.month = `${nowDate_text_list[0]}-${nowDate_text_list[1]}`
      }

      this.nowDate = parent_data.nowDate
      setTimeout(()=> {
        this.loadingShow();
        this.get_qc_month_report().then(res => {
          this.loading.close();
        })
      },200)
    },
    close_dialog() {
      this.dialogVisible = false
      this.compare_val = ''
      this.status_val = ''
      this.trend_val = ''
      this.control_val = ''
    },
    /* 切换月份 */ 
    chanageMonth() {
      this.loadingShow();
      this.monthly_params.stats_month = this.month
      this.compare_val = ''
      this.status_val = ''
      this.trend_val = ''
      this.control_val = ''
      this.get_qc_month_report().then(res => {
        this.loading.close();
      })
    },
    /* 获取月度数据 */
    get_qc_month_report() {
      
      return new Promise((resolve,reject)=> {

        // 数据清空
        this.showNoData = false
        this.echartsShow = false;
        this.dataList1.forEach(e => {
          e.value = '';
        })
        this.qcp_data_list = []
        this.statistics_data = {}
        this. analysis_data = {
          stats_lc_report: []
        }
        api.qc_month_report(this.monthly_params).then(res => {
          this.$refs.body.scrollTo(0,0); 
          let data = res.data
          this.month_summary = res.data.month_summary || this.month_summary
          // 设置第一个列表
          this.dataList1[0].value = data.hospital_name
          this.dataList1[1].value = data.platform_unique
          this.dataList1[2].value = data.instrument_id
          this.dataList1[3].value = this.monthly_params.testing_id==4?'hs-CRP':'SAA'
          this.dataList1[4].value = data.lose_control_rule
          this.dataList1[5].value = data.warning_rule
          // 判断数据有无并做出处理
          if(!data.s_serum_info){
            this.showNoData = true //所选月份无数据显示
            // this.$notify({
            //   title: '提示',
            //   duration: 0,
            //   type: 'warning',
            //   message: `您所选择的月份无数据`
            // });
            resolve()
            return
          }
          this.dataList1[6].value = data.s_serum_info[0].method
          // let stats_month_text_list = this.monthly_params.stats_month.split('-')
          // let report_time = `${this.monthly_params.stats_month}-01~${this.monthly_params.stats_month}-${[1,3,5,7,8,10,12].includes(Number(stats_month_text_list[1]))?'31':'30'}`

          // 设置质控品列表
          data.s_serum_info?.forEach(element => {
            let item= {};
            let start_date = element.qc_list[0].date_time.split(' ')[0],
              end_date =  element.qc_list[element.qc_list.length-1].date_time.split(' ')[0]

            item.dataList = [
              {
                name: '质控品批号',
                value: element.qc_batch,
                width: '33%',
                topBorder: true,
              },
              {
                name: '质控品来源',
                value: element.serum_source,
                width: '33%',
                topBorder: true,
              },
              {
                name: '质控品有效期',
                value: element.expiry_date,
                width: '33%',
                topBorder: true,
                rightBorder: false,
              },
              {
                name: '定靶方法',
                value: element.is_using_fixed?'设定':'浮动',
                width: '33%',
              },
              {
                name: '试剂来源',
                value: element.serum_source,
                width: '33%',
              },
              {
                name: '报告时间',
                value: `${start_date}~${end_date}`,
                width: '33%',
                rightBorder: false,
              },
            ]
            item.qc_data = element.qc_data
            
            // Z分数图
            let handle_dataToEcharts_data = this.handle_dataToEcharts([element.qc_data],element.qc_list,{[element.qc_serum_id]:element.qc_list})
            item.c1_data = handle_dataToEcharts_data.ljData[0].c1_data
            item.c2_data = handle_dataToEcharts_data.ljData[0].c2_data
            item.zData = handle_dataToEcharts_data.zData
            this.qcp_data_list.push(item)
          });
          // 统计数据
          let last_month = data.stats_info[data.stats_info.length-2]?data.stats_info[data.stats_info.length-2].total_stats:{}
          let now_month = data.stats_info[data.stats_info.length-1]?data.stats_info[data.stats_info.length-1].total_stats:{}
          let year_stats = data.year_stats
          
          let target_c1_list = [],
            target_c2_list = [],
            cv_c1_list = [],
            cv_c2_list = []
          data.stats_info.forEach(e=> {
            if(e.total_stats){
              let total_stats = e.total_stats
              target_c1_list.push(total_stats.target_c1?total_stats.target_c1.toFixed(2):'-')
              target_c2_list.push(total_stats.target_c2?total_stats.target_c2.toFixed(2):'-')
              cv_c1_list.push(total_stats.cv_c1?(total_stats.cv_c1*100).toFixed(2):'-')
              cv_c2_list.push(total_stats.cv_c2?(total_stats.cv_c2*100).toFixed(2):'-')
            }else {
              target_c1_list.push('-')
              target_c2_list.push('-')
              cv_c1_list.push('-')
              cv_c2_list.push('-')
            }
          })
          this.statistics_data = {
            last_month_target_c1: last_month?.target_c1,
            last_month_sd_c1: last_month?.sd_c1,
            last_month_cv_c1: last_month?.cv_c1,
            now_month_target_c1: now_month?.target_c1,
            now_month_sd_c1: now_month?.sd_c1,
            now_month_cv_c1: now_month?.cv_c1,
            year_stats_target_c1: year_stats?.target_c1,
            year_stats_sd_c1: year_stats?.sd_c1,
            year_stats_cv_c1: year_stats?.cv_c1,
            last_month_target_c2: last_month?.target_c2,
            last_month_sd_c2: last_month?.sd_c2,
            last_month_cv_c2: last_month?.cv_c2,
            now_month_target_c2: now_month?.target_c2,
            now_month_sd_c2: now_month?.sd_c2,
            now_month_cv_c2: now_month?.cv_c2,
            year_stats_target_c2: year_stats?.target_c2,
            year_stats_sd_c2: year_stats?.sd_c2,
            year_stats_cv_c2: year_stats?.cv_c2,
            ehartData_C1: {
              month_list: data.month_list,
              target_list: target_c1_list,
              cv_list: cv_c1_list
            },
            ehartData_C2: {
              month_list: data.month_list,
              target_list: target_c2_list,
              cv_list: cv_c2_list
            }
          }
          // 综合分析
          this.analysis_data = {
            ...data.stats_point_info,
            stats_lc_report: data.stats_lc_report,
            month_summary: this.month_summary,
            compare_val: this.compare_val || '',
            status_val: this.status_val || '',
            trend_val: this.trend_val || '',
            control_val: this.control_val || '',
          },
          this.echartsShow=true;
          resolve()
        }).finally(msg => {
          resolve();
        })
      })
      
    },
    changeSelect(){
      this.analysis_data.compare_val = this.compare_val
      this.analysis_data.status_val = this.status_val
      this.analysis_data.trend_val = this.trend_val
      this.analysis_data.control_val = this.control_val
      },
  }
}
</script>

<style lang="scss" scoped>

::v-deep .dialog_right {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  height: 100vh;
  .el-dialog__header {
    height: 0;
    padding: 1px;
  }
  .el-dialog__body {
    margin: $gap;
    padding: $gap;
    height: 95vh;
  }
}
.monthly-box {
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: $border-color solid 1px;
    padding-bottom: $gap;
    align-items: center;
    &>span {
      font-weight: bold;
      color: #111111;
      font-size: $dialog-title-size;
    }
    ::v-deep .calendarDate-box {
      margin-right: px(40);

      .el-input {
        width: px(210);
      }
    }
  }
  .body {
    height: 83vh;
    overflow: auto;
    .printBox {
      padding: 3*$gap;
    }
    .title-box {
      font-size: $title-size;
      font-weight: bold;
      text-align: center;
    }
    .title {
      text-align: center;
      font-weight: bold;
      font-size: $tip-size;
      color: #111111;
      margin: $gap 0 $gap/2;
    }
    .indent {
      text-indent: 2em;
    }
    .indent2 {
      text-indent: 3em;
    }
    .noData-box {
      padding: $gap;
      font-size: $dialog-text-size;;
    }
    .qcp-data-box {
      margin-top: 2*$gap;
    }
    .qcp-data-box:first-child {
      margin-top: 0;
    }
    .echart-box {
      margin: 0 $gap;
      .Z-box {
        border-bottom: $border-color solid 1px;
        border-top: $border-color solid 1px;
      }
    }
    .statistics-box {
      margin: 0 $gap;
      .tip-c-td {
        width: 10%;
      }
    }
    .analysis-box {
      margin: 0 $gap;
      font-size: $dialog-text-size;
      &>p {
        margin: $gap 0;
        font-size: $dialog-text-size;
        font-weight: bold;
        background-color: #409EFF;
        padding: $gap;
        color: #fff;
        border-radius: $gap/2;
        text-align: center;
        width: 10%;
        box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.4);
      }
      .analysis-text-box {
        &>p {
          margin-top: $gap;
        }
        .indent2 {
          display: flex;
          span {
            
          }
        }
        u {
          text-decoration-color: #409EFF;
        }
      }
      .print-info-box {
        display: flex;
        padding-top: $gap/2;
        margin-top: $gap;
        margin-bottom: 2*$gap;
        border-top: $border-color solid 1px;
        justify-content: space-between;
        p:last-child {
          width: 30%;
        } 
        p>span{
          margin-right: $gap;
        }
      }
    }
  }
  .footed {
    text-align: center;
    margin-top: $gap;
  }
}
.monthly-table{
  width:100%;
  height: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border-color:  $border-color;
  border-left: 0;
  border-right: 0;
  .monthly-tbody{
    td {
      font-size: $table-td-size;
      padding: $gap 0;
    }
    tr {
      width: 100%;
      text-align: center;
    }
    .left {
      width: 15%;
      &>span {
        font-weight: bold;
        font-size: $table-th-size;
      }
      &>ul{
        margin-top: 2*$gap;
        &>li {
          margin-top: $gap/2;
          display: flex;
          white-space: nowrap;
         
          p:first-child {
            flex: 1;
            text-align: right;
          }
          p:last-child {
            flex: 2;
            text-align: left;
          }
          span {
            flex: 1;
          }
        }
      }
    }
    .target {
      display: flex;
      &>li {
        flex: 1;
      }
    }
    .no-left-border {
      border-left: 0;
    }
    .no-right-border {
      border-right: 0;
    }
  }
  
}

.btn {
  //font-size: $btn-text-size;
}

.line {
  height: px(2);
  border-bottom: px(1) dashed #02A7F0;
  margin: $gap;
}
.xbar {
  text-decoration: overline;
}
.conclusion{
  margin-top: 10px;
  .conclusion_item{
    line-height: 2;
  }
  .conclusion_item_last{
    margin-top: 10px;
  }
  .conclusion_select{
    margin: 0 4px;
    ::v-deep .el-input--mini .el-input__inner{
      width: 100%;
    }
  }
}
</style>