<template>
  <div class="zone">
    <!-- <div class="summary">
      <el-button type="primary" @click="tosummary">前往质控汇总</el-button>
    </div> -->
    
    <TJDetailTitle :titleData="'基本信息'" />
    <TJDetailMenu :dataList="dataList" />

    <TJDetailTitle :titleData="'质控信息'" />
    <TJDetailMenu :dataList="dataList2" />
    
  </div>
</template>

<script>
import apiDetail from '@/api/searchDetail.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
export default {
  name: 'QcsDetail',
  components: { TJDetailTitle, TJDetailMenu },
  data() {
    return {
      dataList: [
        {
          name: '医院名称',
          value: '',
          width: '25%',
        },
        {
          name: '机号',
          value: '',
          width: '25%',
        },
        {
          name: '检测项目',
          value: '',
          width: '25%',
        },
        {
          name: '结果',
          value: '',
          width: '25%',
          rightBorder:false,
        },
        {
          name: '仪器位置',
          value: '',
          width: '25%',
        },
        {
          name: '平台',
          value: '',
          width: '25%',
        },
        {
          name: '质控批号',
          value: '',
          width: '25%',
        },
        {
          name: '试剂批号',
          value: '',
          width: '25%',
          rightBorder:false,
        },
        {
          name: '试剂开瓶时间',
          value: '',
          width: '25%',
        },
        {
          name: '试剂最新使用日期',
          value: '',
          width: '25%',
        },
        {
          name: '试剂累计使用时长',
          value: '',
          width: '25%',
        },
        {
          name: '质控唯一码',
          value: '',
          width: '25%',
        },
        {
          name: '试剂唯一码（UDI）',
          value: '',
          width: '75%',
          bottomBorder:false,
        },
      ],
      dataList2: [
        {
          name: '方法学',
          value: '',
          width: '25%',
        },
        {
          name: '主波长多点吸光度',
          value: '',
          width: '75%',
          rightBorder:false,
        },
        {
          name: 'SD',
          value: '',
          width: '25%',
        },
        {
          name: '均值',
          value: '',
          width: '75%',
          rightBorder:false,
        },
        {
          name: 'CV',
          value: '',
          width: '25%',
        },
        {
          name: '差值吸光度',
          value: '',
          width: '50%',
          bottomBorder:false,
        },
        {
          name: '质控条码号',
          value: '',
          width: '25%',
          rightBorder:false,
          bottomBorder:false,
        },
      ],
      detailData: {},
      qcDetail: {},
    }
  },
  created() {
    this.detailId = this.$route.query.id
    this.gitqcsDetail()
  },
  methods: {
    tosummary() {
      this.$router.push({ path: '/Panel.vue' })
    },
    gitqcsDetail() {
      let routeData = this.$route.query
      let params = {
        qc_id: this.detailId,
      }
      apiDetail
        .qcDetail(params)
        .then((res) => {
          this.detailData = res.data

          this.dataList[0].value = this.detailData.hospital_name
          this.dataList[1].value = this.detailData.instrument_id
          this.dataList[2].value = this.detailData.testing_unique
          this.dataList[3].value = this.detailData.qc_result
          this.dataList[4].value = this.detailData.hos_pos_name
          this.dataList[5].value = this.detailData.platform_unique
          this.dataList[6].value = this.detailData.qc_batch
          this.dataList[7].value = this.detailData.batch
          this.dataList[8].value = this.detailData.open_date
          this.dataList[9].value = this.detailData.last_used_date
          this.dataList[10].value = this.detailData.cumulative_time
          this.dataList[11].value = this.detailData.qc_unique
          this.dataList[12].value = this.detailData.kit_id

          this.dataList2[0].value = this.detailData.method
          this.dataList2[2].value = this.detailData.sd
          this.dataList2[3].value = this.detailData.target_value
          this.dataList2[4].value = this.detailData.cv
          this.dataList2[5].value = this.detailData.abs_diff
          this.dataList2[6].value = this.detailData.qc_bar

          if(this.detailData.platform_unique=='OTTOMAN-2020'){
            let temp = [
              {
                name: '主波长多点吸光度',
                value: this.detailData.qc_abs650.join(','),
                width: '75%',
                rightBorder:false,
              },
              {
                name: '第二主波长多点吸光度',
                value: this.detailData.qc_abs2.join(','),
                width: '100%',
              },{
                name: '副波长多点吸光度',
                value: this.detailData.qc_abs578.join(','),
                width: '100%',
                rightBorder:false,
              },
            ]
            this.dataList2.splice(1,1,...temp);
          }else {
            this.dataList2[1].value = this.detailData.qc_abs650.filter(item => item).join(',')
            this.detailData.qcabs_data &&
            this.detailData.qcabs_data.join(',')
          }

        })
        .catch((err) => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.summary {
  position: absolute;
  right: 50px;
  top: 50px;
}
</style>
