<template>
  <div class="zone">
    <!-- <TJDetailTitle :titleData="" /> -->
    <div class="titleText">
      {{ '室间一致性评价-' + hospital_name }}
    </div>
    <div class="screenBox">
      <div class="screem-input-box">
        <ul>
          <li>基本条件选择</li>
          <li v-if="userInfo.org_type != 2">
            <el-select
              v-model="hospital_id"
              class="hospital_inputBox"
              placeholder="请选择医院"
              size="small"
              clearable
              filterable
              remote
              :remote-method="remoteMethod"
              @change="changeYy"
            >
              <el-option
                v-for="item in hospitalOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li class="mini-input">
            <el-select
              v-model="valueJh"
              placeholder="请选择机号"
              size="small"
              filterable
              @change="set_optionsXm"
            >
              <el-option
                v-for="item in optionsJh"
                :key="item.instrument_id"
                :label="item.hos_pos_name"
                :value="item.instrument_id"
              ></el-option>
            </el-select>
          </li>
          <li class="mini-input">
            <el-select
              v-model="valueXm"
              placeholder="请选择检测项目"
              size="small"
              filterable
              @change="set_optionsXm"
            >
              <el-option
                v-for="item in optionsXm"
                :key="item.testing_id"
                :label="item.testing_unique"
                :value="item.testing_id"
              ></el-option>
            </el-select>
          </li>
          <li class="mini-input">
            <el-select
              v-model="qc_batch"
              placeholder="请选择质控批号"
              size="small"
              clearable
              filterable
            >
              <el-option
                v-for="item in optionsPh"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </li>
          <li>
            <timePickerCloud
              ref="timePickerCloud"
              :picker-options="pickerBeginDateBefore"

              :end_stats_dayOld="end_stats_day"
              :start_stats_dayOld="start_stats_day"
              @setTime="setTime"
            ></timePickerCloud>
          </li>
          <li class="mini-input">
            <el-select
              v-model="valueLY"
              placeholder="请选择质控来源"
              size="small"
              filterable
              @change="set_optionsXm"
            >
              <el-option
                v-for="item in optionsLY"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
        </ul>
        <el-divider class="divider"></el-divider>
        <ul>
          <li>对等组范围选择</li>
          <li>
            <el-select
              v-model="province_id"
              placeholder="请选择省份"
              size="small"
              clearable
              filterable
            >
              <el-option
                v-for="item in SfOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
          <li>
            <el-select
              v-model="DjValue"
              placeholder="请选择医院等级"
              size="small"
              clearable
              filterable
            >
              <el-option
                v-for="item in DjOption"
                :key="item.level_id"
                :label="item.level_name"
                :value="item.level_id"
              ></el-option>
            </el-select>
          </li>
          <li>
            <el-select
              v-model="YltValue"
              placeholder="请选择医联体"
              size="small"
              clearable
              filterable
            >
              <el-option
                v-for="item in YltOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </li>
        </ul>
      </div>
      <el-button type="primary" class="searchBtn" @click="searchBtn">
        搜 索
      </el-button>
    </div>

    <div class="sheet">
      <div class="title">
        质控结果
        <!-- <p>单位(mg/L)</p> -->
        <p >
          <el-button type="primary"  @click="openReportBetween" :disabled="disabled_report_between">室内质控室间化评价报告</el-button>

        </p>
      </div>
      <el-table
        class="table-box"
        :data="tableData"
        stripe
        style="width: 100% height:100%"
      >
        <el-table-column label="组内信息" align="center">
          <el-table-column
            prop="potency"
            label="浓度"
            align="center"
            :width="flexColumnWidth(90)"
          >
            <template slot="header">
              <div class="render-header">浓度</div>
            </template>
          </el-table-column>
          <el-table-column label="仪器结果|mg/L" align="center">
            <template slot="header">
              <div class="render-header">
                仪器结果
                <span>mg/L</span>
              </div>
            </template>
            <template slot-scope="scope">
              <strong>{{ scope.row.qc_result }}</strong>
            </template>
          </el-table-column>
          <el-table-column label="检测时间" align="center" min-width="100">
            <template slot-scope="scope">
              <div v-if="scope.row.time">
                <p><strong >{{ scope.row.time.split(' ')[0] }}</strong></p>
                <p><strong>{{ scope.row.time.split(' ')[1] }}</strong></p>
              </div>
              <div v-else>-</div>
<!--              <strong>{{ scope.row.time ? scope.row.time.split(' ')[0] : '-' }}</strong>-->
            </template>
          </el-table-column>
          <el-table-column label="组内数|个" align="center">
            <template slot="header">
              <div class="render-header">
                组内数
                <span>个</span>
              </div>
            </template>
            <template slot-scope="scope">
              <strong>{{ scope.row.count }}</strong>
            </template>
          </el-table-column>
          <el-table-column label="组均值|mg/L" align="center">
            <template slot="header">
              <div class="render-header">
                组均值
                <span>mg/L</span>
              </div>
            </template>
            <template slot-scope="scope">
              <strong>{{ scope.row.mean }}</strong>
            </template>
          </el-table-column>
          <el-table-column
            label="数据量(N)|个"
            align="center"
            :width="flexColumnWidth(170)"
          >
            <template slot="header">
              <div class="render-header">
                数据量(N)
                <el-popover
                  placement="top-start"
                  width="116"
                  trigger="hover"
                  content="组内机号数据总和"
                >
                  <span slot="reference" class="tips">?</span>
                </el-popover>
                <span>个</span>
              </div>
            </template>
            <template slot-scope="scope">
              <strong>{{ scope.row.sum }}</strong>
            </template>
          </el-table-column>
          <el-table-column label="SD|mg/L" align="center">
            <template slot="header">
              <div class="render-header">
                SD
                <span>mg/L</span>
              </div>
            </template>
            <template slot-scope="scope">
              <strong>{{ scope.row.sd }}</strong>
            </template>
          </el-table-column>

          <el-table-column label="SDI" align="center">
            <template slot="header">
              <div class="render-header">SDI</div>
            </template>
            <template slot-scope="scope">
              <strong>{{ scope.row.sdi }}</strong>
            </template>
          </el-table-column>

          <el-table-column label="CV|%" align="center">
            <template slot="header">
              <div class="render-header">
                CV
                <span>%</span>
              </div>
            </template>
            <template slot-scope="scope">
              <strong>{{ scope.row.cv }}</strong>
            </template>
          </el-table-column>

          <el-table-column label="CVI" align="center">
            <template slot="header">
              <div class="render-header">
                <span>CVI</span>
                <el-popover placement="top-start" width="280" trigger="hover">
                  <p>{{ 'CVI&lt;1.0，表示本室CV 低于对等组' }}</p>
                  <p>{{ 'CVI&gt;1.0，表示本室CV 高于对等组' }}</p>
                  <span slot="reference" class="tips">?</span>
                </el-popover>
                <span><!-- 为了统一样式添加该空标签 --></span>
              </div>
            </template>
            <template slot-scope="scope">
              <strong>{{ scope.row.cvi }}</strong>
            </template>
          </el-table-column>
          <el-table-column label="偏倚值" align="center">
            <template slot="header">
              <div class="render-header">
                偏倚值
                <span>%</span>
              </div>
            </template>
            <template slot-scope="scope">
            <strong>{{ scope.row.offset_value ? (scope.row.offset_value * 100).toFixed(2) : '-' }}</strong>
          </template>
          </el-table-column>
          <el-table-column
            label="操作"
            align="center"
            :width="flexColumnWidth(170)"
          >
            <template slot="header">
              <div class="render-header">操作</div>
            </template>

            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                @click="open_data_info_list(scope.$index)"
              >
                查看数据
              </el-button>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="评价标准" align="center">
<!--          <el-table-column label="靶值|mg/L" align="center">-->
<!--            <template slot="header">-->
<!--              <div class="render-header">-->
<!--                靶值-->
<!--                <span>mg/L</span>-->
<!--              </div>-->
<!--            </template>-->
<!--            <template slot-scope="scope">-->
<!--              <strong>{{ scope.row.target }}</strong>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column
            :key="componentKey"
            label="允许范围"
            align="center"
            :width="flexColumnWidth(140)"
          >
            <template slot="header">
              <div class="render-header">
                允许范围
                <el-popover
                  placement="top-start"
                  width="116"
                  trigger="hover"
                  :content="content_text"
                >
                  <span slot="reference" class="tips">?</span>
                </el-popover>
                <span>mg/L</span>
              </div>
            </template>
            <template slot-scope="scope">
              <strong>{{ scope.row.target_range }}</strong>
            </template>
          </el-table-column>
          <el-table-column label="评价" align="center">
            <template slot="header">
              <div class="render-header">评价</div>
            </template>
            <template slot-scope="scope">
              <strong>{{ scope.row.target_rusult }}</strong>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>

    <div class="echartBox">
      <div class="echartBox_left">
        <div class="title">
          <span class="span1">组内频数分布图</span>
          <span class="span2">
            平台：{{ platform_unique }}&nbsp;&nbsp;&nbsp;试剂：{{
              reagent_name
            }}
            &nbsp;&nbsp;&nbsp;方法学：{{ method }}
          </span>
        </div>
        <div class="echarBox">
          <div
            class="box"
            v-for="(item, index) of frequency_chart"
            :key="index"
          >
            <BetweenEchart :id="'BetweenEchart' + index" :ehartData="item" />
          </div>
          <div v-if="frequency_chart.length==0" class="no-data-box"> 
            暂无数据
          </div>
        </div>
      </div>
      <div class="echartBox_rigth">
        <div class="title">
          <span class="span1">Z分数图</span>
        </div>
        <BetweenLineEchart
          id="BetweenEchartLine"
          :ehartData="BetweenEchartLineData"
        />
      </div>
    </div>
    <!-- 数据详情列表 -->
    <el-dialog
      title=""
      :visible.sync="data_info_listStatus"
      :close-on-click-modal="true"
      :show-close="false"
      width="70%"
      custom-class="dialog_right"
    >
      <div class="data_info_listBox">
        <div class="top">
          <p>{{ dialog_title }}</p>
          <i class="el-icon-close" @click="data_info_listStatus = false"></i>
        </div>
        <div class="body">
          <data_info_list
            v-if="data_info_listStatus"
            :C_data="C_data"
          ></data_info_list>
        </div>
      </div>
    </el-dialog>
    <!-- 室间报告 -->
    <reportDialog ref="reportDialog" :key="report_key" :tableData="tableData" :report_time_text="report_time_text" :basicData="report_basic_info_list" :frequency_chart="frequency_chart" :BetweenEchartLineData="BetweenEchartLineData"></reportDialog>
  </div>
</template>

<script>
import TJDetailTitle from '@/components/TJDetailTitle'
import BetweenEchart from './components/BetweenEchart'
import BetweenLineEchart from './components/BetweenLineEchart'
import timePickerCloud from '@/components/timePickerCloud.vue'
import api from '@/api/quality'
import { search_qc_hospital } from '@/api/search.js'
import data_info_list from './components/data_info_list.vue'
import { flexColumnWidth } from '@/assets/js/adaption.js'
import reportDialog from './components/reportBetween.vue'

export default {
  components: {
    TJDetailTitle,
    BetweenEchart,
    BetweenLineEchart,
    timePickerCloud,
    data_info_list,
    reportDialog
  },
  name: 'Between',
  data() {
    return {
      userInfo: {},
      getLocalStorageId: true,
      data_info_listStatus: false,
      data_info_listText: '',
      report_key: 1,
      default_instrument: '',
      default_testing_id: '',
      hospital_name: '',
      default_qc_batch: '', //默认-批号

      content_text: '',
      componentKey: 0,

      optionsJh: [], //机号列表
      optionsXm: [], //检测项目列表
      optionsPh: [], //批号列表
      optionsLY: [
        {
          value: 1,
          label: '临检中心',
        },
        {
          value: 2,
          label: '上海奥普',
        },
        {
          value: 3,
          label: '上海昆涞',
        },
        {
          value: 4,
          label: '第三方',
        },
      ],
      valueJh: '',
      valueXm: '',
      valueLY: 2,
      qc_batch: '',
      pickerBeginDateBefore: {
        // 最大选择当前日
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },

      SfOption: [],
      province_id: '', //省份下拉框-值

      yyOptions: [
        {
          value: 1,
          label: '上海20家',
        },
        {
          value: 2,
          label: '全国三甲',
        },
        {
          value: 3,
          label: '北京20家',
        },
      ], //省份下拉框
      DjOption: [], // 医院等级
      YltOption: [], //医联体
      DjValue: '',
      YltValue: '',
      yyValue: '', //医院标准组
      date_time: '', //时间

      hospitalOptions: [], //医院列表
      hospital_id: '', //医院值
      C_data_obj: {
        c1_data: [],
        c2_data: [],
      },
      C_data: [],
      tableData: [],

      method: '', //方法学
      reagent_name: '', //试剂名

      frequency_chart: [], //组内频数分布图

      BetweenEchartLineData: {
        y: [],
      },
      // BetweenEchartLineData: {
      //   y: [[45.1,-1.2],[28,1],[88,-2]],
      // },
      start_stats_day: '',
      end_stats_day: '',

      qcInfoList: [],
      qcInfoList_Jh: [],
      qcInfoList_all: [],

      platform_unique: '', //平台名称
      dialog_title: '', //弹窗标题
      cal_method: 1, //临时添加参数
      disabled_report_between: true,
      // 报告相关数据
      report_time_text: '',
      report_basic_info_list: [{
          name: '医院名称',
          value: '',
          width: '75%',
        },
        {
          name: '仪器型号',
          value: '',
          width: '25%',
          rightBorder: false,
        },
        {
          name: '机号',
          value: '',
          width: '25%',
        },
        {
          name: '检测项目',
          value: '',
          width: '25%',
        },
        {
          name: '质控批号',
          value: '',
          width: '25%',
        },
        // {
        //   name: '试剂批号',
        //   value: '',
        //   width: '25%',
        // },
        {
          name: '方法学',
          value: '',
          width: '25%',
        },],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.cal_method = this.$route.query.cal_method
      ? this.$route.query.cal_method
      : 1

    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.hospital_name = this.userInfo.organization_name

    this.hospital_levelFun()
    this.medical_consortia_listFun()
    this.getAllProvince();
    // this.SfOption = JSON.parse(localStorage.getItem('province'))
    // this.SfOption.shift();
  },
  mounted() {
    // org_type 机构类型: 1,奥普,2, '医院',3, '经销商'
    this.hospital_id = this.userInfo.organization

    if (this.userInfo.org_type != 2) {
      this.getLocalStorageId = true
      this.hospitalOptions = [
        {
          value: this.userInfo.organization,
          label: this.userInfo.organization_name,
        },
      ]
    }
    this.room_detailsFun()
    this.qc_serum_infoFun() //获取质控品批号的列表
  },

  methods: {
    flexColumnWidth(width) {
      return flexColumnWidth(width)
    },
    // 获取全部省份
    getAllProvince() {
      api.city({province_is_all: true}).then(res => {
        let arr = []
        res.data.forEach(v => {
          arr.push({
              value: v.province_id,
              label: v.province_name,
          })
        })
        this.SfOption = arr
      })
    },
    // 室间一致性评价
    room_detailsFun(isChooseHospital = false) {
      let param = {
        hospital_id: this.hospital_id, //医院
        cal_method: this.cal_method,
      }
      let loading = this.$loading({
        lock: true,
        text: '数据加载中，请等待',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.disabled_report_between = true
      api
        .qc_consistency(param)
        .then((res) => {
          if (res.code == 200) {
            let data = res.data
            this.default_instrument = data.default_data.instrument
            this.default_testing_id = data.default_data.testing
            this.default_qc_batch = data.default_data.qc_batch
            this.setTime_$timePickerCloud({
              start_stats_day: data.start_date,
              end_stats_day: data.end_date,
            })
            this.report_time_text = `${data.start_date} ~ ${data.end_date}`
            // this.date_time = data.consistency_time
            // this.start_stats_day = data.consistency_time
            // this.end_stats_day = data.consistency_time

            this.hospital_name =  data.hospital_name
            if (isChooseHospital) {
              this.valueJh = this.default_instrument
              this.valueXm = this.default_testing_id
              this.qc_batch = this.default_qc_batch
            } else {
              this.valueJh == '' ? (this.valueJh = this.default_instrument) : ''
              this.valueXm == '' ? (this.valueXm = this.default_testing_id) : ''
              this.qc_batch == '' ? (this.qc_batch = this.default_qc_batch) : ''
            }
            this.optionsPh = data.qc_batch_list
            this.optionsJh = res.data.instrument_list.map((e) => {
              return {
                hos_pos_name: e.hos_pos_name
                  ? e.hos_pos_name + '(' + e.instrument + ')'
                  : e.instrument,
                instrument_id: e.instrument,
              }
            })
            this.content_text =
              this.valueXm == 4
                ? '根据《国家卫生健康委临床检验中心室间质量评价标准》hs-CRP可接受范围靶值±25%'
                : '根据《国家卫生健康委临床检验中心室间质量评价标准》SAA可接受范围靶值±30%'
            this.componentKey += 1
            this.optionsXm = res.data.testing_list
            this.datahandle(data)
            let qc_serum_id_text_list = data.qc_serum_id.split('-')
            console.log('data==',data)
            this.report_basic_info_list[0].value = this.hospital_name
            this.report_basic_info_list[1].value = data.qc_room_data.platform_unique
            this.report_basic_info_list[2].value = data.default_data.instrument
            this.report_basic_info_list[3].value = data.default_data.testing_unique
            this.report_basic_info_list[4].value = qc_serum_id_text_list[2]
            // this.report_basic_info_list[4].value = ''
            this.report_basic_info_list[5].value = data.qc_room_data.method
            this.disabled_report_between = false
          }
          loading.close()
        })
        .catch((err) => {
          loading.close()
        })
    },
    // 查看数据详情
    open_data_info_list(index) {
      this.dialog_title = `查看C${index + 1}数据`
      this.data_info_listText = `C${index + 1}数据详情列表`
      // C_data =
      this.C_data = this.C_data_obj[`c${index + 1}_data`]
      this.data_info_listStatus = true
    },
    // 监听医院变化
    changeYy() {
      if (this.hospital_id) {
        this.room_detailsFun(true) //改变医院
        this.qc_serum_infoFun() //获取质控品批号的列表
      }
    },
    // 设置时间
    setTime(val) {
      this.start_stats_day = val.start_stats_day
        ? val.start_stats_day.substring(0, 4) +
          '-' +
          val.start_stats_day.substring(4, 6) +
          '-' +
          val.start_stats_day.substring(6, 8)
        : ''
      this.end_stats_day = val.end_stats_day
        ? val.end_stats_day.substring(0, 4) +
          '-' +
          val.end_stats_day.substring(4, 6) +
          '-' +
          val.end_stats_day.substring(6, 8)
        : ''
      // this.set_time_and_phOption('time');
    },
    //给组件设置时间及给自己设置时间
    setTime_$timePickerCloud(val) {
      this.start_stats_day = val.start_stats_day
      this.end_stats_day = val.end_stats_day
      this.$refs.timePickerCloud.setTime(
        val.start_stats_day.split('-').join(''),
        val.end_stats_day.split('-').join(''),
      )
    },
    // 质控数据根据批号，时间区间过滤————新
    screen_qc_consistencyFun() {
      let param = {
        instrument_id: this.valueJh, //机号
        testing_id: this.valueXm, //检测项目标识
        qc_batch: this.qc_batch, //质控批号
        serum_source: this.valueLY, //质控品来源
        // date_time: this.date_time,
        start_date: this.start_stats_day,
        end_date: this.end_stats_day,
        province_id: this.province_id,
        level_id: this.DjValue,
        cal_method: this.cal_method,
        consortia_id: this.YltValue,
      }
      this.content_text =
        this.valueXm == 4
          ? '根据《国家卫生健康委临床检验中心室间质量评价标准》hs-CRP可接受范围靶值±25%'
          : '根据《国家卫生健康委临床检验中心室间质量评价标准》SAA可接受范围靶值±30%'
      this.componentKey += 1
      let loading = this.$loading({
        lock: true,
        text: '数据加载中，请等待',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
      this.disabled_report_between = true
      api
        .screen_qc_consistency(param)
        .then((res) => {
          if (res.code == 200) {
            this.report_key ++;
            loading.close()
            if (!res.data) {
              this.$message.warning('当前无数据，请选择其他日期')

              this.platform_unique = ''
              this.method = ''
              this.reagent_name = ''
              this.tableData = []
              this.frequency_chart = []
              this.BetweenEchartLineData.y = []
              return
            }
            this.report_time_text = `${param.start_date} ~ ${param.end_date}`
            this.datahandle(res.data)
            let qc_serum_id_text_list = res.data.qc_serum_id.split('-')
            this.report_basic_info_list[0].value = this.hospital_name
            this.report_basic_info_list[1].value = res.data.platform_unique
            this.report_basic_info_list[2].value = this.valueJh,
            this.report_basic_info_list[3].value = res.data.loc_ins_testing.split(' ')[1]
            this.report_basic_info_list[4].value = qc_serum_id_text_list[2]
            // this.report_basic_info_list[4].value = ''
            this.report_basic_info_list[5].value = res.data.qc_room_data.method
            // this.reportDataHandle(res.data)
            this.disabled_report_between = false

          }
        })
        .catch((err) => {
          loading.close()
        })
    },
    // 获取所有医院等级
    hospital_levelFun() {
      api.hospital_level().then((res) => {
        if (res.code == 200) {
          this.DjOption = res.data
        }
      })
    },
    // 数据处理
    datahandle(qc_info) {
      this.tableData = []
      this.frequency_chart = []
      this.BetweenEchartLineData.y = []
      qc_info.c1_data.sort((a,b)=> {
        let a_time = Date.parse(new Date(a.c1_time))
        let b_time = Date.parse(new Date(b.c1_time))
        if (a_time < b_time) {
            return 1;
        } else if (a_time > b_time) {
            return -1;
        } else {
            return 0;
        }
      })
      qc_info.c2_data.sort((a,b)=> {
        let a_time = Date.parse(new Date(a.c2_time))
        let b_time = Date.parse(new Date(b.c2_time))
        if (a_time < b_time) {
            return 1;
        } else if (a_time > b_time) {
            return -1;
        } else {
            return 0;
        }
      })

      this.C_data_obj = {
        c1_data: qc_info.c1_data,
        c2_data: qc_info.c2_data,
      }

      if (qc_info.qc_room_data == null) {
        this.$message.warning('当前无数据，请选择其他日期')
        this.platform_unique = ''
        this.method = ''
        this.reagent_name = ''
        this.frequency_chart = []
        return
      }

      this.platform_unique = qc_info.qc_room_data.platform_unique
      this.method = qc_info.qc_room_data.method //方法学
      this.reagent_name = qc_info.qc_room_data.reagent_name //试剂名

      let avg_data = qc_info.avg_data

      for (let key in qc_info.qc_room_data) {
        if (key == 'c1' || key == 'c2' || key == 'c3') {
          //只有是这个三项才去找
          let C = qc_info.qc_room_data[key]
          // C.value =C.value?C.value:0
          let target = C.target,
            target_range_temp = target * 0.3,
            // target_range_temp = qc_info.default_data.testing == 4?target*0.25:target*0.3,
            target_range_low = target - target_range_temp,
            target_range_high = target + target_range_temp,
            target_range = `${target_range_low.toFixed(
              2,
            )}~${target_range_high.toFixed(2)}`,
            target_rusult = ''
          if (C.value) {
            target_rusult =
              target_range_low <= C.value && target_range_high >= C.value
                ? '合格'
                : '不合格'
          } else {
            target_rusult = '-'
          }

          //表的显示
          let s = key[key.length - 1]

          let CData = {
            potency: 'C' + key.split('')[1],
            qc_result: C.value ? C.value.toFixed(2) : '-',
            count: C.cnt_total,
            mean: C.mean.toFixed(2),
            sum: C.sum,
            sd: C.sd.toFixed(2),
            cv: (C.cv * 100).toFixed(2),
            target: target.toFixed(2),
            target_range,
            target_rusult,
            sdi: avg_data['sdi' + s] ? avg_data['sdi' + s].toFixed(2) : '-',
            cvi: avg_data['cvi' + s] ? avg_data['cvi' + s].toFixed(2) : '-',
            offset_value:C.offset_value,
            time:C.time
          }

          this.tableData.push(CData)
          // Z分数图

          let y_item = []
          y_item.push(C.value)
          let y = ((C.value - C.target) / C.sd).toFixed(2)
          y = y > 3 ? 3 : y < -3 ? -3 : y
          y_item.push(y)
          this.BetweenEchartLineData.y.push(y_item)

          // 正态分布图
          let converted_data = []
          let mean = C.mean,
            Standard_deviation = C.sd,
            start = C.start - (C.end[1] - C.end[0]),
            end = C.end[C.end.length - 1] + 2 * (C.end[1] - C.end[0])
          let step = (end - start) / 50
          let x = start
          while (x < end) {
            let y =
              (1 / (Standard_deviation * Math.sqrt(2 * Math.PI))) *
              Math.exp(
                -Math.pow(x - mean, 2) / (2 * Math.pow(Standard_deviation, 2)),
              )
            x += step
            converted_data.push([x.toFixed(2), y.toFixed(10)])
          }
          // 图显示
          let arr = ['', C.start]
          let endTwo = arr.concat(C.end).concat(['', ''])
          let arrY = ['']
          let countTwo = arrY.concat(C.cnt).concat([''])
          let qc_result2 =
            C.value == C.start
              ? C.start + ''
              : C.end.concat([C.value, C.start]).sort((a, b) => {
                  return a - b
                })[
                  C.end
                    .concat([C.value, C.start])
                    .sort((a, b) => {
                      return a - b
                    })
                    .indexOf(C.value) - 1
                ] + ''
          let frequency_chartData = {
            potency: 'C' + key.split('')[1],
            qc_result: C.value + '',
            count: countTwo,
            qc_result2, //必须为X轴对应坐标点的值
            end: arr.concat(C.end).concat(['']),
            endTwo,
            converted_data,
          }
          this.frequency_chart.push(frequency_chartData)
        }
      }
    },

    // 获取医联体列表
    medical_consortia_listFun() {
      api.medical_consortia_list().then((res) => {
        if (res.code == 200) {
          this.YltOption = res.data.map((e) => {
            return {
              label: e.consortia_name,
              value: e.consortia_id,
            }
          })
        }
      })
    },

    // 获取质控品的所有信息
    qc_serum_infoFun() {
      api.qc_serum_info({ hospital_id: this.hospital_id }).then((res) => {
        if (res.code == 200) {
          this.qcInfoList = res.data // 项目下包含机号
          let qcInfoList_Jh = [], // 机号下包含项目
            qcInfoList_all = [] //所有质控品直接显示
          this.qcInfoList.forEach((temp) => {
            temp.qs_list.forEach((temp2) => {
              qcInfoList_all = qcInfoList_all.concat(temp2.qc_serum_info)
              let isNew = true
              qcInfoList_Jh.forEach((item) => {
                if (item.instrument_id == temp2.instrument_id) {
                  item.testingList.push({
                    testing_id: temp.testing_id,
                    testing_unique: temp.testing_unique,
                    // qc_serum_info: temp2.qc_serum_info
                  })
                  isNew = false
                }
              })
              if (isNew) {
                qcInfoList_Jh.push({
                  hos_pos_name: temp2.hos_pos_name,
                  instrument_id: temp2.instrument_id,
                  label: temp2.label,
                  testingList: [
                    {
                      testing_id: temp.testing_id,
                      testing_unique: temp.testing_unique,
                      // qc_serum_info: temp2.qc_serum_info
                    },
                  ],
                })
              }
            })
          })
          this.qcInfoList_all = qcInfoList_all
          this.qcInfoList_Jh = qcInfoList_Jh
        }
      })
    },

    // 质控品批号筛选框
    set_optionsXm() {
      let temp = this.qcInfoList.find((e) => e.testing_id === this.valueXm)
      if (!temp) {
        this.optionsPh = []
        return
      }
      let temp2 = temp.qs_list.find((e) => e.instrument_id === this.valueJh)
      if (!temp) {
        this.optionsPh = []
        return
      }
      this.optionsPh = temp2.qc_serum_info.map((e) => e.qc_batch)
      if (!this.optionsPh.find((e) => e === this.qc_batch)) {
        this.qc_batch = this.optionsPh[this.optionsPh.length - 1]
      }
      // this.optionsXm =
    },

    // 搜索按钮
    searchBtn() {
      this.screen_qc_consistencyFun()
    },

    // 医院远程搜索
    remoteMethod(e) {
      this.hospitalOptions = search_qc_hospital({ hospital_name: e })
    },

    // 打开室间报告
    openReportBetween() {
      // this.report_key ++;
      this.$refs.reportDialog.openDialog();
    }
  },
}
</script>

<style lang="scss" scoped>
.zone {
  padding: 0 $gap $gap;
}
.titleText {
  font-weight: bold;
  color: #111111;
  margin-left: $gap;
  margin-bottom: $gap;
  font-size: $title-size;
}
.title {
  font-size: px(20);
  font-weight: bold;
  color: #111111;
  padding-left: px(20);
  border-bottom: px(1) solid #ebf0f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .span2 {
    font-size: px(14);
    font-weight: 400;
    color: #666666;
    margin-right: px(21);
  }
}
.sheet {
  //height: px(346);
  margin-top: px(10);
  border-radius: px(5);
  background-color: #fff;
  margin-bottom: px(10);
  overflow-y: auto;
  .title {
    height: px(66);
    //line-height: px(66);
    position: relative;

    p {
      position: absolute;
      font-weight: 400;
      font-size: px(18);
      right: px(26);
      bottom: px(8);
    }
  }

  .table-box {
    .render-header {
      position: relative;
      height: px(45);
      font-size: px(18);

      & > span:last-child {
        font-size: px(16);
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }

    ::v-deep .cell {
      line-height: px(24);
    }
  }
}
.echartBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  div {
    background-color: #fff;
    margin-bottom: px(5);
    border-radius: px(5);

    .title {
      height: px(51);
      line-height: px(51);
    }
  }
  .echartBox_left {
    width: 70%;
    .echarBox {
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      flex-wrap: wrap;
      border-bottom: px(1) solid #e6eaf4;
      .box {
        border-right: px(1) solid #e6eaf4;
        box-sizing: border-box;
        width: 50%;
      }
      .box:nth-child(even) {
        border-right: none;
      }
    }
  }
  .echartBox_rigth {
    width: 29%;
  }
}

.screenBox {
  display: flex;
  background: #fff;
  align-items: center;
  border-radius: px(5);
  gap: 3 * $gap;
  .screem-input-box {
    flex: 22;
    .divider {
      margin: $gap;
      text-align: left;
    }
    ul {
      margin: $gap;
      display: flex;
      align-items: center;
      gap: $gap;
      li:first-child {
        width: px(150);
        text-align: right;
        margin-right: px(10);
      }
     
        ::v-deep .el-input__inner {
          width: px(180);
        }
      ::v-deep .calendarDate {
        width: px(180);
      }
      
    }
  }

  .searchBtn {
    flex: 1;
    margin: $gap;
  }
}

.tips {
  cursor: pointer;
  margin-left: px(5);
  display: inline-block;
  width: px(20);
  color: #00afe9;
  font-size: px(18);
  //background: #00afe9;
}

::v-deep .dialog_right {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  height: 100vh;
  .el-dialog__header {
    height: 0;
    padding: 1px;
  }
  .el-dialog__body {
    margin: $gap;
    padding: $gap;
    height: 95vh;
  }
}
.data_info_listBox {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 30px;
    border-bottom: $border-color solid 1px;
    p {
      font-weight: bold;
      color: #111111;
      font-size: $dialog-title-size;
      padding-bottom: $gap;
      width: 20%;
    }
  }
  .body {
    margin: $gap;
    height: 85vh;
    overflow: auto;
  }
}
.no-data-box {
  color: #909399;
  height: px(300);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  size: $text-size;
}
</style>
