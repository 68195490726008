<template>
  <div class="authen_box" v-loading="loading" :element-loading-text="loadingText">
    <div class="authen_header">
      <p class="authen_title">企业认证</p>
    </div>
    <p class="tip_text" style="color:#BD3124;">注：以下信息均为必填信息！</p>
    <div class="authen_form_box">

      <el-form  label-width="220px" :model="form" class="authen_form" ref="authen_form" :rules="rules">
        <el-form-item label="企业名称" prop="customer_name" >
         <el-input v-model="form.customer_name" clearable placeholder="请输入企业名称"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="registration_code" >
          <el-input v-model="form.registration_code" clearable placeholder="请输入18位统一社会信用代码"></el-input>
       </el-form-item>
        <el-form-item label="法定代表人姓名" prop="legal_person_name" >
          <el-input v-model="form.legal_person_name" clearable placeholder="请输入法定代表人姓名"></el-input>
        </el-form-item>
        <el-form-item label="多合一营业执照" class="upload_item">
          <el-upload
              action="action"
              list-type="picture-card"
              :headers="uploadHeaders"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :http-request="handleUpload"
              :on-success="handleUploadSuccess"
              :on-exceed="handleExceed"
              :show-file-list="true"
              :file-list="fileList"
              :limit="1"
              :multiple="false"
              accept=".jpeg,.jpg,.png,"
          >
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                  <i class="el-icon-zoom-in"></i>
                </span>
<!--                <span  class="el-upload-list__item-delete" @click="handleDownload(file)">-->
<!--                  <i class="el-icon-download"></i>-->
<!--                </span>-->
                <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                  <i class="el-icon-delete"></i>
                </span>
            </span>
            </div>
            <el-button size="small" >选择文件</el-button>
            <div slot="tip" class="el-upload__tip">请上传多合一营业执照原件图片或加盖公章复印件 支持 .jpg.jpeg.png 格式，大小不超过8M</div>
          </el-upload>
          <el-dialog class="picture" :visible.sync="fileDialogVisible"  :modal="false">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
        <div class="divider"></div>
        <el-form-item label="法定代表人手机号码" prop="legal_person_phone" >
          <el-input v-model="form.legal_person_phone" style="width: 565px" clearable placeholder="请输入法定代表人手机号码"></el-input>
          <el-button class="yzm"  type="primary" @click="getCode" :disabled="isButtonDisabled">
            {{ isButtonDisabled ? countdownText : '发送验证码' }}
          </el-button>
        </el-form-item>
        <el-form-item label="验证码" prop="input_code">
          <el-input v-model="form.input_code" clearable placeholder="请输入验证码"></el-input>
        </el-form-item>
        <div class="footer_btn">
          <el-button  @click="confirmForm">确认提交</el-button>
        </div>
      </el-form>

    </div>
    <el-dialog
        title="提示"
        :visible.sync="validDialogVisible"
        width="30%"
        :modal="false"
        :before-close="handleCancel">
      <p v-if="isNametip" style="margin-bottom: 10px">确定将企业名称改为：<span style="color:#BD3124;">{{this.form.customer_name}}</span></p>
      <p v-if="isCodetip && this.form.registration_code">确定将统一社会信用代码改为：<span style="color:#BD3124">{{this.form.registration_code}}</span></p>
      <span slot="footer" class="dialog-footer">
          <el-button style="margin-right: 14px" @click="validDialogVisible = false" size="mini">取 消</el-button>
          <el-button type="primary" @click="submitForm" size="mini" style="background: #4095E5" >确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import {certified, getCertifiedInfo, sendSms} from "@/api/orderGoods/custom";
  import axios from "axios";

  export default {
    data() {
      var checkPhone = (rule, value, callback) => {
        let reg = /^1\d{10}$/
        if (value === '') {
          callback(new Error('请输入电话'));
        } else {
          if (!reg.test(value)) {
            callback(new Error('请输入正确的电话'));
          }
          callback();
        }
      };
      var checkRegcode = (rule, value, callback) => {
        // let reg1 = /^[A-Z0-9]{18}$/
        let reg1 = /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/
        if (value === '') {
          callback(new Error('请输入税号'));
        } else {
          if (reg1.test(value) ) {
            callback();
          }else{
            callback(new Error('请输入正确的税号'));
          }
          callback();
        }
      };
      return {
        loading: false,
        loadingText:'正在提交',
        countdown: 60,
        countdownInterval: null,
        isButtonDisabled: false,
        action: `${process.env.VUE_APP_BASE_API}/api/upload_ins_file/`,
        token: localStorage.getItem('token') ||localStorage.getItem('vue_token'),
        uploadHeaders: {
          Authorization: `Bearer ${this.token}` // 假设你的后端需要这样的 Authorization 头部格式
        },
        dialogImageUrl:'',
        fileDialogVisible: false,
        customer_name_temp: '',
        registration_code_temp: '',
        fileList: [],
        validDialogVisible: false,
        isNametip: false,
        isCodetip:false,
        form: {
          customer_id:'',
          customer_name: '',
          registration_code: '',
          legal_person_name: '',
          legal_person_phone: '',
          business_license: '',
          input_code: '',
        },
        rules: {
          customer_name: [
            { required: true, message: '请输入企业名称', trigger: 'blur' },
          ],
          registration_code: [
            { required: true, message: '请输入18位统一社会信用代码', trigger: 'blur' },
            {validator: checkRegcode,trigger: 'blur', }
          ],
          legal_person_name: [
            { required: true, message: '请输入法定代表人姓名', trigger: 'blur' },
          ],
          legal_person_phone: [
            { required: true, message: '请输入法定代表人手机号码', trigger: 'blur' },
            {validator: checkPhone,trigger: 'blur',}

          ],
          input_code: [
            { required: true, message: '请输入验证码', trigger: 'blur' },
          ]
        }
      }
    },
    created() {
      this.getCertifiedInfo()
    },
    computed: {
      countdownText() {
        // 格式化倒计时文本
        return  `发送验证码${this.countdown}秒`;
      },
    },
    methods: {
      handleCancel(){
        this.validDialogVisible = false
      },
      confirmForm(){
        if(!this.form.business_license){
          this.$message.error('请上传多合一传营业执照')
          this.loading = false
          return
        }
        this.$refs['authen_form'].validate(valid => {
          if(valid){
        if(this.customer_name_temp && this.customer_name_temp != this.form.customer_name){
          this.validDialogVisible = true
          this.isNametip = true
          if(this.registration_code_temp && this.registration_code_temp != this.form.registration_code){
            this.isCodetip = true
          }else{
            this.isCodetip = false
          }
        }else{
          if(this.registration_code_temp && this.registration_code_temp != this.form.registration_code){
            this.validDialogVisible = true
            this.isCodetip = true
            this.isNametip = false
          }else{
            this.validDialogVisible = false
            this.isCodetip = false
            this.isNametip = false
            this.submitForm()
          }
        }
        }else{
          this.$message.error('请检查必填项!')
          this.loading = false
        }
        })

      },
      submitForm() {
        this.validDialogVisible = false
        this.loadingText = '正在提交'
        // if(!this.form.business_license){
        //   this.$message.error('请上传多合一传营业执照')
        //   this.loading = false
        //   return
        // }
        // this.$refs['authen_form'].validate(valid => {
        //   if(valid){
            this.loading = true
            certified(this.form).then(res => {
              if(res.code == 200){
                this.$message.success('提交成功')
                this.loading  = false
                this.$router.push({ path: '/authenResult',query: {type:1}})
                // this.$router.push('/SearchPage')
              }else{
              }
            }).catch(err=>{
              this.loading = false
              if(err.code == 201 && res.msg == '验证码错误'){
                this.$message.error(res.msg)
                this.loading = false
              }else{
                this.loading = false
                this.$router.push({ path: '/authenResult',query: {type:2}})
              }
            })
          // }else{
          //   this.$message.error('请检查必填项!')
          //   this.loading = false
          // }
        // })

      },
      handleExceed(files, fileList) {
        // 这个钩子函数在文件超出 limit 限制时被调用，但在这个例子中我们已经在 beforeUpload 中处理了限制
        // 你可以在这里添加额外的提示或处理逻辑
        this.$message.warning(`文件数量不能超过 1 个`);
      },
      handleUpload( file) {
        if(this.form.business_license){
          this.$message.error('只能上传一个营业执照')
          return
        }
        this.loading = true
        this.loadingText = '正在上传文件'
        const formData = new FormData();
        formData.append('files', file.file); // 注意：在某些 Element UI 版本中，可能需要使用 file.file 而不是 file.raw
        const config = {
          headers: {
            'Authorization': `Bearer ${this.token}` // 假设 token 已经在 data 或 computed 中定义
          }
        };

        let baseUrl= process.env.VUE_APP_BASE_API
        axios.post(`${baseUrl}/api/upload_business_license/`, formData, config)
            .then(data => {
              // 处理响应
              this.loading = false
              this.form.business_license =  data.data.data.urls[0]
              this.srcList.push({url: this.form.business_license, name: file.file.name, uid: this.form.business_license})
            })
            .catch(error => {
              // 处理错误
            });
      },
      handleUploadSuccess(){},
      handleRemove(file, fileList) {
        this.fileList = []
        this.form.business_license = null
      },
      handlePictureCardPreview(file) {
        if(file && file.url){
          this.dialogImageUrl = file.url
          this.fileDialogVisible = true
        }
      },
      getCertifiedInfo(){ //获取认证信息
        getCertifiedInfo().then(res=>{
          let resData = res.data
          this.customer_name_temp = resData.customer_title
          this.registration_code_temp = resData.registration_code
          this.form.customer_id = resData.customer_id
          this.form.customer_name = resData.customer_title
          this.form.registration_code = resData.registration_code
        })
      },
      getCode(){ //获取验证码
        if(!(/^1\d{10}$/.test(this.form.legal_person_phone))){
          this.$message.error('请输入正确手机号')
          this.isButtonDisabled = false
        }else{
          let params ={
            phone_number: this.form.legal_person_phone
          }
          sendSms(params).then(res=>{
          })
          if (this.isButtonDisabled) return;
          this.isButtonDisabled = true;
          this.startCountdown()
        }
      },
      startCountdown() {
        // 清除之前的倒计时（如果有的话）
        if (this.countdownInterval) clearInterval(this.countdownInterval);
        // 开始新的倒计时
        this.countdownInterval = setInterval(() => {
          if (this.countdown <= 0) {
            // 倒计时结束，重置状态
            clearInterval(this.countdownInterval);
            this.countdownInterval = null;
            this.isButtonDisabled = false;
            this.countdown = 60; // 重置倒计时时间
          } else {
            // 更新倒计时
            this.countdown--;
          }
        }, 1000);
      },
    },
    beforeDestroy() {
      // 在组件销毁前清除倒计时定时器，防止内存泄漏
      if (this.countdownInterval) clearInterval(this.countdownInterval);
    },
  }
</script>

<style scoped lang="scss">
.el-container{
  height: 100%!important;
  background: #fff;
  .context{
    height: 100%!important;
  }
}
.authen_box{
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 0 30px;
  box-sizing: border-box;
  overflow-y: hidden;
  .authen_header{
    border-bottom: 1px solid #CECECE;
    padding: 20px 0;
    .authen_title{
      font-size: 24px;
    }
  }
  .tip_text{
    margin: 30px auto 12px auto;
    width: 1263px;
    color:#BD3124;
    font-size: 20px;
  }
  .authen_form_box{
    height: calc(100% - 90px);
    overflow-y: auto;
    margin: 0 auto;
    width: 1263px;
    //margin-top: 40px;
    ::v-deep .el-upload-list__item.is-ready div{
      height: 100%;
    }
    ::v-deep .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before{
      display: none;
    }
    .authen_form{
      margin-top: 10px;
      border: 1px solid #CECECE;
      padding: 70px 60px 100px 60px;
      ::v-deep .el-input{
        width: 725px;
        .el-input__inner{
          width: 100%;
          font-size: 16px;
        }
      }
      .divider{
        width: 100%;
        border-bottom: 1px dotted #CECECE;
        height: 1px;
        margin-bottom: 30px;
      }
      ::v-deep.el-form-item{
        margin-bottom: 38px;
      }
      ::v-deep.el-form-item.upload_item{
        margin-bottom: 20px;
      }
      ::v-deep .el-form-item__label{
        font-size: 20px;
      }
      ::v-deep .el-upload--picture-card {
         background-color: transparent;
         border: none;
        border-radius: 0;
        box-sizing: border-box;
         width: 170px;
         height: 48px;
         line-height: 148px;
        vertical-align: top;
        .el-button--small{
          vertical-align: top;
          width: 100%;
          height: 100%;
          font-size: 16px;
          color: #4095E5;
          border-color: #4095E5;
        }
        i.el-icon-plus{
          display: none;
        }
      }
      ::v-deep .el-button.yzm{
        margin-left: 10px;
        width: 150px;
      }
      .el-upload-list__item.is-ready{
        width: 120px;
        height: 120px;
      }
      ::v-deep .el-upload__tip{
        font-size: 14px;
      }
      .footer_btn{
        text-align: center;
        margin-top: 100px;
        ::v-deep .el-button{
          width: 200px;
          height: 50px;
          font-size: 16px;
          color: #4095E5;
          border-color: #4095E5;
        }
      }
    }
  }
}
::v-deep .el-input__inner::placeholder {
  color: #888888;
  //font-weight: bold;
}
@media screen and (min-width: 768px) and (max-width: 1560px), print {
  ::v-deep .el-form-item__label{
    font-size: 18px;
  }
}
@media screen and (min-width: 1561px) , print {
  ::v-deep .el-form-item__label{
    font-size: 20px;
  }
}
</style>