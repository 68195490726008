<template>
  <div>
    <div class="dialog_content">
      <p v-for="item in newListTemp" style="color: #BD3124;">{{item.inventory_full_name}}/{{item.inventory_name}} / {{item.standard}}</p>
      <span style="display:inline-block; margin-top: 30px">以上<span style="color: #BD3124;">{{newListTemp.length}}项</span>  商品为首次订购，确定要提交订单吗？</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    newProductList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      dialogVisible: false,
      newListTemp : []
    }
  },
  created() {
    if(this.newProductList && this.newProductList.length > 0){
      this.newProductList.forEach(item => {
        if(item.is_first){
          this.newListTemp.push(item)
        }

      })
    }
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog__footer{
  text-align: center;
}
::v-deep .el-dialog__header{
  border-bottom: 1px dotted #BEBEBE;
}
.dialog_content{
  text-align: left;
  p{
    line-height: 2;
  }
  span{
    line-height: 2;
  }
}
</style>