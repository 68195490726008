<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="50%"
      custom-class="dialog_right"
      :show-close="false"
      :before-close="handleClose"
    >
      <div class="QC-product-box">
        <div class="top">
          <p>质控品列表</p>
          <i class="el-icon-close" @click="close_dialog"></i>
        </div>
        <div class="body">
          <div class="left">
            <el-tree
              ref="tree"
              :data="tree_data_qcp"
              @node-click="set_table_data"
              :highlight-current="true"
              node-key="key"
            ></el-tree>
          </div>
          <div class="right">
            <div class="right-btn-box">
              <el-button
                type="primary"
                class="btn"
                size="mini"
                @click="open_qcpRuleData"
              >
                设置规则
              </el-button>
              <el-button
                type="primary"
                class="btn"
                size="mini"
                @click="open_qcpNumData"
              >
                设置质控号
              </el-button>
            </div>
            <table border="1" class="QCProduct-table">
              <thead class="QCProduct-thead">
                <tr>
                  <th>质控品批号</th>
                  <th>启用时间</th>
                  <th>水平</th>
                  <th>
                    <span class="xbar">X</span>
                    (mg/L)
                  </th>
                  <th>SD(mg/L)</th>
                  <th>CV(%)</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody
                class="QCProduct-tbody"
                v-for="(item, index) in table_data_qcp"
                :key="index"
              >
                <tr>
                  <td rowspan="2">{{ item.qc_batch }}</td>
                  <td rowspan="2">{{ item.start_date }}</td>
                  <td>C1</td>
                  <td>
                    {{
                      item.fixed_target_c1 != null
                        ? item.fixed_target_c1.toFixed(2)
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      item.fixed_sd_c1 != null
                        ? item.fixed_sd_c1.toFixed(2)
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      item.is_using_fixed? '-' : textCV( item.fixed_cv_c1)
                    }}
                  </td>
                  <td rowspan="2" class="btn-box">
                    <div>
                      <el-button
                        size="mini"
                        class="btn"
                        @click="open_setTarget_dialog(item)"
                      >
                        设置均值
                      </el-button>
                      <el-popconfirm
                        confirm-button-text="确定"
                        cancel-button-text="取消"
                        icon="el-icon-info"
                        icon-color="red"
                        @confirm="del_qc_serum(item, index)"
                        title="确认删除该质控品吗？"
                      >
                        <el-button size="mini" class="btn" slot="reference">
                          删除
                        </el-button>
                      </el-popconfirm>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>C2</td>
                  <td>
                    {{
                      item.fixed_target_c2 != null
                        ? item.fixed_target_c2.toFixed(2)
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      item.fixed_sd_c2 != null
                        ? item.fixed_sd_c2.toFixed(2)
                        : '-'
                    }}
                  </td>
                  <td>
                    {{
                      item.is_using_fixed? '-' : textCV( item.fixed_cv_c2)
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 功能设置弹窗 -->
    <!-- 设置均值 -->
    <el-dialog
      :visible.sync="dialogVisible_target"
      :width="flexColumnWidth(800)"
      custom-class="dialog_center"
      :show-close="false"
    >
      <div class="dialog-center-box dialog-target-box">
        <div class="top">
          <p>设置均值</p>
          <i class="el-icon-close" @click="dialogVisible_target = false"></i>
        </div>
        <div class="body">
          <table border="1" class="QCProduct-table">
            <thead class="QCProduct-thead">
              <tr>
                <th>机号</th>
                <th>检测项目</th>
                <th>质控品批号</th>
                <th>水平</th>
                <th
                  :class="obj_setTarget_data.is_using_fixed && 'selected-th'"
                  style="cursor: pointer"
                  @click="change_is_using_fixed(true)"
                >
                  <i
                    v-show="obj_setTarget_data.is_using_fixed"
                    class="el-icon-success"
                    style="color: #fff"
                  ></i>
                  设定均值
                </th>
                <th
                  :class="!obj_setTarget_data.is_using_fixed && 'selected-th'"
                  style="cursor: pointer"
                  @click="change_is_using_fixed(false)"
                >
                  <i
                    v-show="!obj_setTarget_data.is_using_fixed"
                    class="el-icon-success"
                    style="color: #fff"
                  ></i>
                  浮动均值
                </th>
              </tr>
            </thead>
            <tbody class="QCProduct-tbody">
              <tr>
                <td rowspan="2">{{ obj_setTarget_data.instrument }}</td>
                <td rowspan="2">{{ obj_setTarget_data.testing }}</td>
                <td rowspan="2">{{ obj_setTarget_data.qc_batch }}</td>
                <td>C1</td>
                <td>
                  <ul>
                    <li>
                      <p>均值</p>
                      <p>
                        <el-input
                          type="number"
                          min="0"
                          :disabled="!obj_setTarget_data.is_using_fixed && [3,4].includes(obj_setTarget_data.is_finish)"
                          placeholder="请输入均值"
                          v-model="obj_setTarget_data.fixed_target_c1"
                          size="mini"
                          class="inpuStyle"
                        ></el-input>
                      </p>
                    </li>
                    <li>
                      <p>标准差</p>
                      <p>
                        <el-input
                          type="number"
                          min="0"
                          :disabled="!obj_setTarget_data.is_using_fixed &&  [3,4].includes(obj_setTarget_data.is_finish)"
                          placeholder="请输入标准差"
                          v-model="obj_setTarget_data.fixed_sd_c1"
                          size="mini"
                          class="inpuStyle"
                        ></el-input>
                      </p>
                    </li>
                  </ul>
                </td>
                <td rowspan="2">
                  <div class="setting_target_times_box">
                    <p>定靶次数</p>
                    <el-input
                      type="number"
                      min="2"
                      :disabled="obj_setTarget_data.is_using_fixed||[3,4].includes(obj_setTarget_data.is_finish) && obj_setTarget_data.is_using_fixed"
                      placeholder="请输入定靶次数"
                      v-model="obj_setTarget_data.setting_target_times"
                      size="mini"
                      class="target-num-input"
                    ></el-input>
                  </div>
                </td>
              </tr>
              <tr>
                <td>C2</td>
                <td>
                  <ul>
                    <li>
                      <p>均值</p>
                      <p>
                        <el-input
                          type="number"
                          min="0"
                          :disabled="!obj_setTarget_data.is_using_fixed && [3,4].includes(obj_setTarget_data.is_finish) "
                          placeholder="请输入均值"
                          v-model="obj_setTarget_data.fixed_target_c2"
                          size="mini"
                          class="inpuStyle"
                        ></el-input>
                      </p>
                    </li>
                    <li>
                      <p>标准差</p>
                      <p>
                        <el-input
                          type="number"
                          min="0"
                          :disabled="!obj_setTarget_data.is_using_fixed && [3,4].includes(obj_setTarget_data.is_finish)"
                          placeholder="请输入标准差"
                          v-model="obj_setTarget_data.fixed_sd_c2"
                          size="mini"
                          class="inpuStyle"
                        ></el-input>
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="footed">
          <el-button
            type="primary"
            class="btn"
            size="mini"
            @click="save_target"
          >
            保存
          </el-button>
          <el-button
            type="primary"
            :disabled="obj_setTarget_data.is_using_fixed"
            class="btn"
            size="mini"
            @click="open_targetData"
          >
            查看定靶数据
          </el-button>
          <el-button
            size="mini"
            class="btn"
            @click="dialogVisible_target = false"
          >
            关闭窗口
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 查看定靶数据 -->
    <qcpTargetDataDialog
      ref="qcpTargetDataDialog"
      :hospital_id="hospital_id"
      :nowDate="nowDate"
      @setTargetSuccess="refresh_parent"
    ></qcpTargetDataDialog>

    <!-- 设置规则 -->
    <el-dialog
      :visible.sync="dialogVisible_rule"
      :width="flexColumnWidth(800)"
      custom-class="dialog_center"
      :show-close="false"
    >
      <div class="dialog-center-box dialog-rule-box">
        <div class="top">
          <p>设置规则</p>
          <i class="el-icon-close" @click="dialogVisible_rule = false"></i>
        </div>
        <div class="body">
          <div class="info-box">
            <p>
              机号:
              <span>{{ obj_setRule_data.instrument_id }}</span>
            </p>
            <p>
              检测项目:
              <span>{{ textTesting_unique(obj_setRule_data.testing_id) }}</span>
            </p>
          </div>
          <table border="1" class="QCProduct-table rule-table">
            <thead class="QCProduct-thead">
              <tr>
                <th></th>
                <th class="GJ">告警</th>
                <th class="SK">失控</th>
              </tr>
            </thead>
            <tbody class="QCProduct-tbody">
              <tr v-for="(item, index) in list_setRule" :key="index">
                <td>
                  <div class="rule-box">
                    <p>{{ item.text }}</p>
                    <el-popover
                      placement="top-start"
                      width="116"
                      trigger="hover"
                      :content="item.rule_description"
                    >
                      <span slot="reference" class="tips buleBg">?</span>
                    </el-popover>
                  </div>
                </td>
                <td>
                  <el-checkbox
                    v-model="item.radioGJ"
                    @change="check_radio(item, 'radioGJ', 'radioSK')"
                  ></el-checkbox>
                </td>
                <td>
                  <el-checkbox
                    v-model="item.radioSK"
                    @change="check_radio(item, 'radioSK', 'radioGJ')"
                  ></el-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="footed">
          <el-button type="primary" class="btn" size="mini" @click="save_rule">
            保存
          </el-button>
          <el-button
            class="btn"
            size="mini"
            @click="dialogVisible_rule = false"
          >
            取消
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 设置质控号 -->
    <el-dialog
      :visible.sync="dialogVisible_num"
      :width="flexColumnWidth(800)"
      custom-class="dialog_center"
      :show-close="false"
    >
      <div class="dialog-center-box dialog-num-box">
        <div class="top">
          <p>设置质控号</p>
          <i class="el-icon-close" @click="dialogVisible_num = false"></i>
        </div>
        <div class="body">
          <div class="info-box">
            <p>
              机号:
              <span>{{ obj_setNum_data.instrument_id }}</span>
            </p>
            <p>
              检测项目:
              <span>{{ textTesting_unique(obj_setNum_data.testing_id) }}</span>
            </p>
          </div>
          <table border="1" class="QCProduct-table num-table">
            <thead class="QCProduct-thead">
              <tr>
                <th></th>
                <th>质控页面</th>
                <th>样本页面</th>
              </tr>
            </thead>
            <tbody class="QCProduct-tbody">
              <tr>
                <td class="C1">C1</td>
                <td>
                  <el-input
                    placeholder=""
                    v-model="obj_setNum_data.qc_number_of_c1"
                    size="mini"
                    class="inpuStyle"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    placeholder=""
                    v-model="obj_setNum_data.qc_sample_number_of_c1"
                    size="mini"
                    class="inpuStyle"
                  ></el-input>
                </td>
              </tr>
              <tr>
                <td class="C2">C2</td>
                <td>
                  <el-input
                    placeholder=""
                    v-model="obj_setNum_data.qc_number_of_c2"
                    size="mini"
                    class="inpuStyle"
                  ></el-input>
                </td>
                <td>
                  <el-input
                    placeholder=""
                    v-model="obj_setNum_data.qc_sample_number_of_c2"
                    size="mini"
                    class="inpuStyle"
                  ></el-input>
                </td>
              </tr>
            </tbody>
          </table>
          <p class="tip">
            <span>注</span>
            ：“质控页面”和“样本页面”至少需填写一组C1、C2。
          </p>
        </div>
        <div class="footed">
          <el-button type="primary" class="btn" size="mini" @click="save_num">
            保存
          </el-button>
          <el-button class="btn" size="mini" @click="dialogVisible_num = false">
            取消
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indoorMixins from '../indoorMixins'

import api from '@/api/quality'
import { flexColumnWidth } from '@/assets/js/adaption.js'
import qcpTargetDataDialog from './qcpTargetDataDialog.vue'
export default {
  components: {
    qcpTargetDataDialog,
  },
  mixins: [indoorMixins],
  props: ['nowDate', 'hospital_id'],
  data() {
    return {
      dialogVisible: false, //质控品右侧弹窗
      dialogVisible_target: false, //设置靶值弹窗
      tree_data_qcp: [], //左侧树状图数据
      key_tree_now: '', //当前选中的子节点
      table_data_qcp: [], //右侧列表数据
      obj_setTarget_data: {}, //设置靶值的相关参数
      obj_setRule_data: {}, //设置规则的相关参数
      obj_setNum_data: {
        instrument_id: '',
        testing_id: '',
        qc_number_of_c1: '',
        qc_number_of_c2: '',
        qc_sample_number_of_c1: '',
        qc_sample_number_of_c2: '',
        hospital_id: '',
      },
      list_setRule: [], //靶值列表
      optionsXm: [
        { testing_id: 4, testing_unique: 'hs-CRP' },
        { testing_id: 11, testing_unique: 'SAA' },
      ], //项目列表
      childShow: false,
      is_targetdata_show: false,
      dialogVisible_rule: false, //质控规则弹窗
      dialogVisible_num: false, //质控号弹窗
      disabled_target: false, // 均值设置页面能否填写

      parent_data: {
        testing_id: '',
        instrument_id: '',
      },
    }
  },
 
  methods: {
    // 表格列宽
    flexColumnWidth(width, minWidth) {
      return flexColumnWidth(width, minWidth)
    },
    /* 打开和关闭弹窗，其他页面也会调用 */
    open_dialog(parent_data) {
      this.parent_data = parent_data
      this.dialogVisible = true
      setTimeout(() => {
        //设置默认
        this.$refs.tree.setCurrentKey(
          `${this.parent_data.instrument_id}-${this.parent_data.testing_id}`,
        )
        this.$refs.tree.getNode(this.parent_data.testing_id).expanded = true
        this.set_table_data(this.$refs.tree.getCurrentNode())
      }, 2)
    },
    close_dialog() {
      this.$emit('close_success')
      this.dialogVisible = false
    },
    handleClose(done) {
      this.$emit('close_success')
      done()
    },
    /* 接口过来的数据处理以用于界面展示 */
    set_tree_data_qcp(list_qcp_info_crude) {
      this.tree_data_qcp = []
      list_qcp_info_crude.forEach((element) => {
        let obj = {
          label: element.testing_unique,
          level: 1,
          children: [],
          disabled: true,
          key: element.testing_id,
        }
        element.qs_list.forEach((e) => {
          obj.children.push({
            level: 2,
            label: e.instrument_id,
            qc_serum_info: e.qc_serum_info,
            key: `${e.instrument_id}-${element.testing_id}`,
          })
        })
        this.tree_data_qcp.push(obj)
      })
    },
    /* 设置右侧展示数据 */
    set_table_data(data) {
      if (data.level != 1) {
        this.key_tree_now = data.key
        this.table_data_qcp = data.qc_serum_info
      }
    },
    /* 设置均值相关接口 */
    // 打开设置均值弹窗
    open_setTarget_dialog(item) {
      // 设置弹窗中的数据
      let qc_serum_id_text = item.qc_serum_id.split('-')
      this.obj_setTarget_data = {
        qc_serum_id: item.qc_serum_id,
        instrument: qc_serum_id_text[0],
        testing: this.optionsXm.find(
          (e) => e.testing_id == Number(qc_serum_id_text[1]),
        ).testing_unique,
        qc_batch: qc_serum_id_text[2],
        fixed_target_c1: item.fixed_target_c1,
        fixed_target_c2: item.fixed_target_c2,
        fixed_sd_c1: item.fixed_sd_c1,
        fixed_sd_c2: item.fixed_sd_c2,
        is_using_fixed: item.is_using_fixed,
        setting_target_times: item.setting_target_times,
        is_finish: item.is_finish,
      }
      this.disabled_target = false
      this.dialogVisible_target = true
    },
    // 删除质控品
    del_qc_serum(item, index) {
      let qc_serum_id_text_list = item.qc_serum_id.split('-')
      let params = {
        instrument_id: qc_serum_id_text_list[0],
        testing_id: qc_serum_id_text_list[1],
        qc_batch: qc_serum_id_text_list[2],
        hospital_id: this.hospital_id,
      }
      this.loadingShow('删除中，请稍后')
      api.delete_qc_serum(params).then((res) => {
        if (res.code == 200) {
          this.loading.close()
          this.$emit('delSucess', params)
          // this.$refs.tree.setCurrentKey(`${params.instrument_id}-${params.testing_id}`)
          // this.$refs.tree.getNode(params.testing_id).expanded = true
          // this.set_table_data(this.$refs.tree.getCurrentNode())
          // this.loading.close();
          // this.$message.error('删除成功')
          // delete this.table_data_qcp.splice(index,1);
          // this.$message.error('删除成功')
        }
      })
    },
    // 保存质控数据
    save_target() {
      let param = {
        hospital_id: this.hospital_id,
        is_using_fixed: this.obj_setTarget_data.is_using_fixed,
        qc_serum_id: this.obj_setTarget_data.qc_serum_id,
      }
      if (param.is_using_fixed) {
        if (
          !this.obj_setTarget_data.fixed_target_c1 ||
          !this.obj_setTarget_data.fixed_target_c2 ||
          !this.obj_setTarget_data.fixed_sd_c1 ||
          !this.obj_setTarget_data.fixed_sd_c2
        ) {
          this.$message.warning('请填写固定均值数据')
          return
        }
        if (
          Math.sign(this.obj_setTarget_data.fixed_target_c1) == 1 &&
          Math.sign(this.obj_setTarget_data.fixed_target_c2) == 1 &&
          Math.sign(this.obj_setTarget_data.fixed_sd_c1) == 1 &&
          Math.sign(this.obj_setTarget_data.fixed_sd_c2) == 1
        ) {
          param.fixed_info = {
            fixed_target_c1: this.obj_setTarget_data.fixed_target_c1,
            fixed_target_c2: this.obj_setTarget_data.fixed_target_c2,
            fixed_target_c3: this.obj_setTarget_data.fixed_target_c3,
            fixed_sd_c1: this.obj_setTarget_data.fixed_sd_c1,
            fixed_sd_c2: this.obj_setTarget_data.fixed_sd_c2,
            fixed_sd_c3: this.obj_setTarget_data.fixed_sd_c3,
          }
        } else {
          this.$message.warning('均值标准差为正数')
          return
        }
      } else {
        if (!this.obj_setTarget_data.setting_target_times) {
          this.$message.warning('请填写定靶次数')
          return
        }
        if (
          Number(this.obj_setTarget_data.setting_target_times) >= 2 &&
          Number.isInteger(Number(this.obj_setTarget_data.setting_target_times))
        ) {
          param.setting_target_times =
          this.obj_setTarget_data.setting_target_times
        } else {
          this.$message.warning('定靶次数为大于1的正整数')
          return
        }
      }
      this.loadingShow('保存中,请稍后')
      api.set_qc_target(param).then((res) => {
        this.dialogVisible_target = false
        let qc_serum_id_text_list = param.qc_serum_id.split('-')
        let parentData = {
          testing_id: qc_serum_id_text_list[1],
          instrument_id: qc_serum_id_text_list[0],
        }
        this.$emit('setTargetSuccess', parentData)
      })
    },
    // 定靶数据详情里面修改后返回数据
    refresh_parent(value) {
      this.$refs.qcpTargetDataDialog.close_dialog()
      this.$emit('setTargetSuccess', value)
    },
    // 打开设置均值显示
    open_targetData() {
      this.dialogVisible_target = false
      this.$refs.qcpTargetDataDialog.open_dialog(
        this.obj_setTarget_data.qc_serum_id,
      )
    },
    // 修改均值设置
    change_is_using_fixed(type) {
      // this.obj_setTarget_data.is_using_fixed = type
      // if([1,2].includes(this.obj_setTarget_data.is_finish)){
        this.obj_setTarget_data.is_using_fixed = type;
      // }
    },
    // 关闭均值显示
    close_targetData() {},

    /* 设置质控规则相关 */
    // 打开质控规则弹窗
    open_qcpRuleData() {
      this.dialogVisible_rule = true
      setTimeout(() => {
        this.loadingShow()
        let key_text_list = this.key_tree_now.split('-')
        this.obj_setRule_data = {
          instrument_id: key_text_list[0],
          testing_id: key_text_list[1],
          qc_serum_id: this.table_data_qcp[0].qc_serum_id,
        }
        this.list_setRule = JSON.parse(
          JSON.stringify({ list_qcp_rule: this.list_qcp_rule }),
        ).list_qcp_rule
        api
          .qc_rule_list({
            // 质控规则
            instrument: key_text_list[0],
            testing: key_text_list[1],
          })
          .then((res) => {
            let nowRuleData = res.data[0]
            for (let key in nowRuleData) {
              if (nowRuleData[key] === true) {
                this.list_setRule.find((e) => e.field === key).radioSK = true
              } else if (nowRuleData[key] === false) {
                this.list_setRule.find((e) => e.field === key).radioGJ = true
              }
            }
          })
          .finally((msg) => {
            this.loading.close()
          })
      }, 50)
    },
    // 质控规则选择
    check_radio(item, type1, type2) {
      if (item[type1]) {
        item[type2] = false
      }
    },
    // 保存质控规则
    save_rule() {
      let qc_rule_data = {}
      this.list_setRule.forEach((e) => {
        qc_rule_data[e.field] = e.radioSK ? true : e.radioGJ ? false : null
      })
      let param = {
        hospital_id: this.hospital_id,
        is_target: 0,
        qc_serum_id: this.obj_setRule_data.qc_serum_id,
        qc_rule_data,
      }
      this.loadingShow('保存中,请稍后')
      api
        .set_qc_rule(param)
        .then((res) => {
          if (res.code == 200) {
            this.dialogVisible_rule = false
            this.$message.success('操作成功')
          } else {
            this.$message.error('操作失败')
          }
        })
        .finally((msg) => {
          this.loading.close()
        })
    },
    /* 设置质控号相关 */
    // 打开质控号弹窗
    open_qcpNumData() {
      this.dialogVisible_num = true
      // let key_text_list = this.$refs.tree.getCurrentKey().split('-')
      let key_text_list = this.key_tree_now.split('-')
      let temp_qc_number = this.table_data_qcp[0].qc_number
      this.obj_setNum_data = {
        instrument_id: key_text_list[0],
        testing_id: key_text_list[1],
        qc_number_of_c1: temp_qc_number.qc_number_of_c1,
        qc_number_of_c2: temp_qc_number.qc_number_of_c2,
        qc_sample_number_of_c1: temp_qc_number.qc_sample_number_of_c1,
        qc_sample_number_of_c2: temp_qc_number.qc_sample_number_of_c2,
      }
    },
    // 保存质控号
    save_num() {
      if (
        (this.obj_setNum_data.qc_number_of_c1 == '') ^
          (this.obj_setNum_data.qc_number_of_c2 == '') ||
        (this.obj_setNum_data.qc_sample_number_of_c1 == '') ^
          (this.obj_setNum_data.qc_sample_number_of_c2 == '')
      ) {
        this.$message.warning('每组质控号请填写完整或不填写')
        return
      }

      let param = {
        ...this.obj_setNum_data,
        hospital_id: this.hospital_id,
      }
      this.loadingShow('保存中,请稍后')
      api.qc_number_set_up(param).then((res) => {
        // if (res.code == 200) {
        //   this.dialogVisible_num = false;
        //   this.$message.success('操作成功')
        // } else {
        //   this.$message.error('操作失败')
        // }
        this.dialogVisible_num = false
        let parentData = {
          instrument_id: this.obj_setNum_data.instrument_id,
          testing_id: this.obj_setNum_data.testing_id,
        }
        this.$emit('setNumSuccess', parentData)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.GJ {
  color: $alarm-color;
}
.SK {
  color: $incontrollable-color;
}
.rule-box {
  display: flex;
  justify-content: center;

  & > p {
    width: 50%;
  }
  .tips {
    cursor: pointer;
    width: px(14);
    height: px(14);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 50%;
    font-size: px(13);
  }
  .buleBg {
    background: #00afe9;
  }
}
.C1 {
  color: $c1-color;
}
.C2 {
  color: $c2-color;
}
::v-deep .dialog_right {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  height: 100vh;
  .el-dialog__header {
    height: 0;
    padding: 1px;
  }
  .el-dialog__body {
    margin: $gap;
    padding: $gap;
    height: 95vh;
  }
}
.QC-product-box {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $dialog-title-size;
    border-bottom: $border-color solid 1px;
    p {
      font-weight: bold;
      color: #111111;
      font-size: $dialog-title-size;
      padding-bottom: $gap;
      width: 20%;
    }
  }
  .body {
    margin: 3 * $gap 0;
    height: 85vh;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    .left {
      flex: 1;
      ::v-deep .el-tree {
        .el-tree-node__label {
          font-size: $dialog-title-size;
        }
        .el-tree-node.is-current > .el-tree-node__content {
          background-color: transparent;
          color: #70b603;
        }
        .el-tree-node__expand-icon.expanded {
          // 动画取消
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        .el-icon-caret-right:before {
          // 收起
          content: '\e6d9';
          font-size: $dialog-title-size;
        }
        .el-tree-node__expand-icon.expanded.el-icon-caret-right:before {
          // 展开
          content: '\e6d8';
          font-size: $dialog-title-size;
        }
      }
    }
    .right {
      flex: 3;
      height: 80vh;
      overflow: auto;
      .right-btn-box {
        margin: $gap 0;
      }
      .QCProduct-table {
        td {
          padding: 3/2 * $gap 0;
        }
        .btn-box {
          width: 15%;
          .btn {
            width: 85%;
            padding: $gap/2 0;
            text-align: center;
          }
          .btn:last-child {
            margin-top: $gap;
            margin-left: 0;
          }
        }
      }
    }
  }
}
.QCProduct-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border-color: $border-color;
  tr {
    width: 100%;
    text-align: center;
  }
  th {
    font-size: $table-th-size;
    padding-top: px(5);
  }
  td {
    font-size: $table-td-size;
  }
  .QCProduct-thead {
  }
}
//内部弹窗
::v-deep .dialog_center {
  height: px(600);
  .el-dialog__header {
    height: 0;
    padding: 1px;
  }
  .el-dialog__body {
    margin: $gap;
    padding: $gap;
  }
}
.dialog-center-box {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $dialog-title-size;
    border-bottom: $border-color solid 1px;
    p {
      font-weight: bold;
      color: #111111;
      font-size: $dialog-title-size;
      padding-bottom: $gap;
      width: 20%;
    }
  }
  .body {
    margin: $gap;
    font-size: $dialog-text-size;
  }
}
.dialog-target-box {
  .body {
    height: px(450);
    .selected-th {
      background-color: #409eff;
    }
    ul {
      li {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: $gap;
        gap: $gap;
        p:first-child {
          flex: 3;
          text-align: right;
        }
        p:last-child {
          flex: 7;
          text-align: left;
          ::v-deep .el-input__inner {
            width: px(120);
          }
        }
      }
    }
    .setting_target_times_box {
      width: 90%;
      margin-left: 5%;
      & > p {
        text-align: left;
      }
      ::v-deep .el-input__inner {
        width: px(150);
      }
    }
  }
  .footed {
    display: flex;
    justify-content: center;
  }
}
.dialog-rule-box {
  .body {
    height: px(450);
    .info-box {
      display: flex;
      gap: 2 * $gap;
      font-size: $tip-size;
      font-weight: bold;
      margin-bottom: $gap;
    }

    .rule-table {
      td {
        padding: $gap/2;
      }
    }
  }
  .footed {
    display: flex;
    justify-content: center;
  }
}
.dialog-num-box {
  .body {
    height: px(450);

    .info-box {
      display: flex;
      gap: 2 * $gap;
      font-size: $tip-size;
      font-weight: bold;
      margin-bottom: $gap;
    }
    .num-table {
      td {
        padding: $gap;
      }
    }
    .tip {
      margin-top: $gap;
      font-size: $btn-text-size;
      & > span {
        color: red;
      }
    }
  }
  .footed {
    display: flex;
    justify-content: center;
  }
}
.btn {
  font-size: $btn-text-size;
}
.xbar {
  text-decoration: overline;
}
</style>