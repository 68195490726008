<template>
  <div class="content-box">
    <div class="row_block">
      <div class="row_item">
        <p class="item_top">
          <i class="all_hos"></i>
          <span>全部医院</span>
        </p>
        <p class="item_bottom">
          <span class="big_num">{{staticsData.hospital_total}}</span>
          <span class="unit">家</span>
        </p>
      </div>
      <div class="row_item">
        <p class="item_top">
          <i class="all_instrument"></i>
          <span>全部仪器</span>
        </p>
        <p class="item_bottom">
          <span class="big_num">{{staticsData.instrument_total}}</span>
          <span class="unit">台</span>
        </p>
      </div>
      <div class="row_item">
        <p class="item_top">
          <i class="targeting"></i>
          <span>定靶中</span>
        </p>
        <p class="item_bottom">
          <span class="big_num">{{staticsData.on_target_total}}</span>
          <span class="unit">台</span>
        </p>
      </div>
      <div class="row_item">
        <p class="item_top">
          <i class="no_control"></i>
          <span><span v-if="is_today">今日</span>未做质控</span>
        </p>
        <p class="item_bottom">
          <span class="big_num">{{staticsData.no_qc_total}}</span>
          <span class="unit">台</span>
        </p>
      </div>
      <div class="row_item">
        <p class="item_top">
          <i class="no_data"></i>
          <span>不传数据仪器</span>
        </p>
        <p class="item_bottom">
          <span class="big_num">{{staticsData.no_instrument_data_total}}</span>
          <span class="unit">台</span>
        </p>
      </div>
    </div>
    <div class="row_block">
      <div class="row_item">
        <p class="item_top">
          <i class="all_data"></i>
          <span>数据总数</span>
        </p>
        <p class="item_bottom">
          <span class="big_num">{{staticsData.qc_data_total}}</span>
          <span class="unit">条</span>
        </p>
      </div>
      <div class="row_item">
        <p class="item_top">
          <i class="in_control"></i>
          <span>在控数量</span>
        </p>
        <p class="item_bottom">
          <span class="big_num">{{staticsData.qc_in_total}}</span>
          <span class="unit">条</span>
        </p>
      </div>
      <div class="row_item">
        <p class="item_top">
          <i class="not_control"></i>
          <span>失控数量</span>
        </p>
        <p class="item_bottom">
          <span class="big_num">{{staticsData.qc_error_total}}</span>
          <span class="unit">条</span>
        </p>
      </div>
      <div class="row_item">
        <p class="item_top">
          <i class="in_control_rate"></i>
          <span>在控率</span>
        </p>
        <p class="item_bottom">
          <span class="big_num">{{staticsData.qc_in_rate}}</span>
          <span class="unit">%</span>
        </p>
      </div>
      <div class="row_item">
        <p class="item_top">
          <i class="not_control_rate"></i>
          <span>失控率</span>
        </p>
        <p class="item_bottom">
          <span class="big_num">{{staticsData.qc_error_rate}}</span>
          <span class="unit">%</span>
        </p>
      </div>
    </div>
    <div class="chart_box">
    <div class="chart_block chart-left ">
      <div class="top_title">
        <p>仪器数据分析</p>
      </div>
      <div :id="id1" class="echarts"></div>
    </div>
    <div class="chart_block chart-right">
      <div class="top_title">
        <p>质控数据分析</p>
      </div>
      <div :id="id2" class="echarts2"></div>
      <div :id="id3" class="echarts3"></div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    staticsData: Object,
    dateRange: Array,
  },
  watch:{
    dateRange:{
      handler(newVal,oldVal){
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // 月份从0开始，所以要加1
        const day = currentDate.getDate();
        const today = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
        if(this.dateRange[0] == today && this.dateRange[1] == today){
          this.is_today = true
        }else{
          this.is_today = false
        }
      },
      deep:true
    },
    staticsData:{
      handler(newVal,oldVal){
        this.data[0].value = newVal.on_target_total // 定靶中
        this.data[1].value = newVal.no_instrument_data_total // 不传数据
        this.data[2].value = newVal.no_qc_total // 未做质控
        this.data[3].value = newVal.target_finish_total // 完成定靶
        this.initEcharts()
        this.initEchart2()
        this.initEchart3()
      },
      deep:true
    }
  },
  data() {
    return {
      id1: 'id1',
      id2: 'id2',
      id3: 'id3',
      is_today: true,
      data: [
        { value: 0, name: '定靶中' },
        { value: 0, name: '不传数据' },
        { value: 0, name: '未做质控' },
        { value: 0, name: '完成定靶' },
      ],
    }
  },
  mounted() {
    this.myChart = this.$echarts.init(document.getElementById('id1'))
    this.myChart2 = this.$echarts.init(document.getElementById('id2'))
    this.myChart3 = this.$echarts.init(document.getElementById('id3'))
    this.initEcharts()
    this.initEchart2()
    this.initEchart3()

  },
  created() {
    this.data[0].value = this.staticsData.on_target_total // 定靶中
    this.data[1].value = this.staticsData.no_instrument_data_total // 不传数据
    this.data[2].value = this.staticsData.no_qc_total // 未做质控
    this.data[3].value = this.staticsData.target_finish_total // 完成定靶
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // 月份从0开始，所以要加1
    const day = currentDate.getDate();
    const today = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
    if(this.dateRange[0] == today && this.dateRange[1] == today){
      this.is_today = true
    }else{
      this.is_today = false
    }

  },
  methods:{
    initEcharts() {
      var that = this
      var option = {
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b} : {c} ({d}%)'
          formatter: '{b} : {c} ({d}%)',
        },
        avoidLabelOverlap: true,
        label: {
          normal: {
            show: true,
            position: 'outside',
            formatter: '{b}: {c} ({d}%)',
            minMargin: 1 // 调整这个值看是否能够显示标签
          }
        },
        labelLine: { // 设置指示线的长度
          normal: {
            length: 10,
            length2: 0
          }
        },
        labelLayout: {
          hideOverlap: false
        },

        // color:['#81B337','#E99D42','#CBA43F','#BD3124'],
        color:['#E99D42','#BD3124','#CBA43F','#81B337'],
        series: [
          {
            label: {
              fontSize:'14',
              show: true, // 显示数值
              position: 'outside', // 数值显示在扇区外侧
              // formatter: '{b}: {c} ' // 自定义标签的回调函数
              formatter: function (params) {
                // 假设我们按照每10个字符进行换行（仅为示例，实际应根据需要调整）
                var text = params.name;
                var lines = [];
                for (var i = 0; i < text.length; i += 10) {
                  lines.push(text.substring(i, i + 10));
                }
                return lines.join('\n') +  '\n'+ `${params.value}台`;
              },
            },
            type: 'pie',
            radius: '70%',
            center: ['50%', '50%'],
            selectedMode: 'single',
            avoidLabelOverlap: false,  // 是否启用防止标签重叠策略
            data: this.data,
            minAngle: 0, //最小角度
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      this.myChart.setOption(option)
    },
    initEchart2() {
      var that = this
      var option = {
        color:['#377F7F'], //#5087EC
        tooltip: {
          trigger: 'axis',
          formatter: '{c} 条',
          axisPointer: {
            type: 'shadow'
          }
        },
        label: {
          show: true,
          position: 'right',
          formatter: '{c} 条',
          color: '#377F7F',
        },
        legend: {},
        grid: {
          left: '3%',
          right: '8%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 1],
          axisLabel: {
            formatter: '{value} 条' // 在这里添加单位
          },
          max: function(value) {
            return Math.ceil(value.max /10 ) * 10 || 10; // 比如增加10%
          }
        },
        yAxis: {
          type: 'category',
          data: ['失控数量', '在控数量', ]
        },
        series: [
          {
            type: 'bar',
            data: [this.staticsData.qc_error_total,this.staticsData.qc_in_total]
          },
        ]
      };
      this.myChart2.setOption(option)
    },
    initEchart3() {
      var that = this
      var option = {
        color:['#5087EC'], //#5087EC
        tooltip: {
          trigger: 'axis',
          formatter: '{c} %',
          axisPointer: {
            type: 'shadow',


          }
        },
        label: {
          show: true,
          position: 'right',
          formatter: '{c} %',
          color:'#5087EC'
        },
        legend: {

        },
        grid: {
          left: '3%',
          right: '10%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 1],
          max: 100,
          axisLabel: {
            // formatter: '{value} %' // 在这里添加单位
            formatter: function (value) {
              var num = Number(value); // 转换为数值（这里仅为示例，实际可能不需要）
              return `${num.toFixed(2)}%`; // 保留两位小数
            }
          }
        },
        yAxis: {
          type: 'category',
          data: ['   失控率  ', '   在控率  ', ]
        },
        series: [
          {
            type: 'bar',
            data: [this.staticsData.qc_error_rate, this.staticsData.qc_in_rate]
          },
        ]
      };
      this.myChart3.setOption(option)
    },
  }
}
</script>
<style scoped lang="scss">
.content-box {
  width: 100%;
  .row_block {
    display: flex;
    width: 100%;
    .row_item {
      width: 20%;
      height: 120px;
      background: #fff;
      border-radius: 6px;
      margin-right: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      padding: 10px 20px 0 20px;
      box-sizing: border-box;
      .item_top {
        display: flex;
        align-items: start;

        span {
          font-size: 16px;
          color:#4F4F4F;
        }
        i {
          width: 24px;
          height: 24px;
          background: #fff;
          background-size:cover;
          margin-right: 8px;
          vertical-align: -2px;
        }
        .all_hos{
          background-image: url("../../../../assets/img/pas_all_hos.png");
        }
        .all_instrument{
          background-image: url("../../../../assets/img/pas_all_instrument.png");
        }
        .targeting{
          background-image: url("../../../../assets/img/pas_targeting.png");
        }
        .no_control{
          background-image: url("../../../../assets/img/pas_no_control.png");
        }
        .no_data{
          background-image: url("../../../../assets/img/pas_no_data.png");
        }
        .all_data{
          background-image: url("../../../../assets/img/pas_all_data.png");
        }
        .in_control{
          background-image: url("../../../../assets/img/pas_in_control_num.png");
        }
        .not_control{
          background-image: url("../../../../assets/img/pas_not_control_num.png");
        }
        .in_control_rate{
          background-image: url("../../../../assets/img/pas_in_control_rate.png");
        }
        .not_control_rate{
          background-image: url("../../../../assets/img/pas_not_control_rate.png");
        }
      }
      .item_bottom {
        display: flex;
        align-items: flex-end;
        margin-top: 10px;
        span {
          font-size: 16px;
          color:#4F4F4F;
        }
        span.unit{
          margin: 0 0 6px 10px;
        }
        span.big_num {
          font-size: 48px;
        }
      }
    }
    .row_item:last-child {
      margin-right: 0;
    }
  }
}
.chart_box{
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.chart_block{
  position: relative;
  display: flex;
  background: #fff;
  width: 49%;
  padding: 18px;
  box-sizing: border-box;
  div{
    text-align: left;
  }
  .top_title {
    position: absolute;
    display: inline-block;
    width: calc(100% - 36px);
    height: 40px;
    p{
      font-size: 18px;
      color: #4F4F4F;
      font-weight: bold;
    }
  }

}
.chart-left{
  margin-right: 20px;
}
.chart-right{
  //margin-left: 1%;
}
.echarts {
  width: 100%;
  height: 380px;
}
.echarts2 {
  position: absolute;
  width: calc(100% - 36px);
  height: 160px;
}
.echarts3 {
  position: absolute;
  width: calc(100% - 36px);
   height: 160px;
  top: 200px;
 }


</style>