<template>
  <div>
    <div class="dialog_content">
      <div class="body_block" >
        <div class="right_top">
          <el-table ref="goodsTable" :data="formData.inventory_data" border style="width: 100%">
            <el-table-column prop="inventory_id" label="存货编码" width="120"  align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.inventory_id ? row.inventory_id : '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="inventory_full_name" label="商品全称" min-width="150" align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span :style="{color: row.is_first ? '#BD3124' : '' }">{{row.inventory_full_name ? row.inventory_full_name : '/'}}</span>
                <el-tag v-if="row.is_first" type="danger" size="mini" style="background: #BD3124;color:#fff;line-height: 20px" class="new_pro_tag"> 新购</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="inventory_name" label="商品简称" min-width="150" align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.inventory_name ? row.inventory_name : '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="standard" label="规格/型号" min-width="120"  align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.standard ? row.standard : '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="commodity" label="批准文号" min-width="130"  align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.commodity ? row.commodity : '/'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="tax_price" label="单价" min-width="100"  align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.tax_price ? row.tax_price : '0'}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="quantity" label="数量" width="100"  :min="1" :max="999999999" align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.quantity ? row.quantity : '0'}}&nbsp;{{row.sale_unit}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="total_price" label="总价" min-width="110"  align="center" header-align="center">
              <template v-slot="{ row, $index }">
                <span>{{row.total_price ? row.total_price : '0'}}</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: right">
            <p style="margin: 10px;font-weight: bold">总金额：<span  style="color:#BD3124;font-weight: bold">{{formData.total}}</span>&nbsp;元</p>
          </div>
        </div>
        <div class="right_block">
          <div class="right_bottom">
            <div class="page_title">
              <p style="color:#215476;font-weight: bold"><i style="font-size: 18px;color:#BEBEBE;margin-right: 4px" class="el-icon-tickets" ></i>开票信息</p>
            </div>
            <div class="bt_content">
              <el-form :model="formData" ref="form" label-width="100px">
                <el-col :span="12">
                  <el-form-item label="开票抬头：" class="invoice_label">
<!--                    <el-tooltip :manual="true" v-model="!formData.isInvoiceHeaderValid" class="" popper-class="custom_tooltip" :disabled="formData.isInvoiceHeaderValid" placement="top-start" effect="light" content="请您确认开票抬头是否正确" >-->
                      <span :class="formData.isInvoiceHeaderValid ? '' :'is_valid'">{{formData.invoice_data.invoice_header}}
                      <span class="valid_tip" v-if="!formData.isInvoiceHeaderValid">请您确认开票抬头是否正确</span>
                        <i id="triangle"></i>
                      </span>
<!--                    </el-tooltip>-->
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="公司税号：" class="invoice_label">
                    <span>{{formData.invoice_data.registration_code}}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="收票人：" class="invoice_label">
                    <span>{{formData.invoice_data.contact_person}}</span>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="接收邮箱：" class="invoice_label">
                    <span>{{formData.invoice_data.contact_email}}</span>
                  </el-form-item>
                </el-col>
              </el-form>
            </div>
          </div>
          <div class="right_bottom">
            <div class="page_title">
              <p style="color:#215476;font-weight: bold">
                <i style="font-size: 18px;color:#BEBEBE;margin-right: 4px"
                   class="el-icon-location-outline"></i>{{formData.delivery_type ==4001 ? '提货信息' : '收货信息'}}</p>
            </div>
            <div class="bt_content">
              <el-form :model="form" ref="form" label-width="100px" size="mini">
                <el-col :span="24">
                  <el-form-item label="是否自提：" class="address_label">
                   <span>{{formData.delivery_type == 4002 ? '否' : '是'}}</span>
                  </el-form-item>
                </el-col>
                <div>
                  <el-col :span="7">
                    <el-form-item :label="formData.delivery_type == 4002 ? '收货人：' : '提货人：'" class="address_label">
                      <span>{{formData.address_data.customer_contact}}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item label="电话：" class="address_label">
                      <span>{{formData.address_data.customer_phone}}</span>
                    </el-form-item>
                  </el-col>
                  <el-col :span="10">
                    <el-form-item label="地址：" class="address_label">
                      <span>{{formData.address_data.customer_address}}</span>
                    </el-form-item>
                  </el-col>
                </div>
              </el-form>
            </div>
          </div>
          <div class="right_bottom">
            <div class="page_title">
              <p style="color:#215476;font-weight: bold">
                <i style="font-size: 18px;color:#BEBEBE;margin-right: 4px" class="el-icon-document"></i>备注说明</p>
            </div>
            <div class="bt_content">
              <el-form :model="form" ref="form" label-width="0" size="mini">
                <el-form-item  class="address_label">
                  <span v-if="formData.order_remark" style="display: inline-block; padding: 10px 50px; color:#6C6C6C; ">{{formData.order_remark}}</span>
                  <span class="font_resize" v-else style="text-align: center;display: inline-block;width: 100%;margin-top: 10px;color: #8888;">暂无数据</span>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import common from "@/views/OrderManagement/mixin/common";
export default {
  mixins: [common],
  props: {
    formData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialogVisible: false,
      tabelHeight: 0,
      blockHeight: 0,
      form:{
        total: 0,
        invoice_title: '',
        invoice_no: '',
        invoice_person: '',
        invoice_mail: '',
        isSelf: 0,
        receive_person: '',
        receive_phone: '',
        receive_adress: '',
      }
    }
  },
  created() {
    this.$nextTick(() => {
      // 获取表格的 DOM 元素
      const tableWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper');
      window.requestAnimationFrame(() => {
        tableWrapper.scrollTop = 0;
      });
    });
  },
  methods: {

  }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/order";
::v-deep .el-table thead th.el-table__cell:last-child div.cell{
  border-right: none!important;
}
::v-deep .el-dialog__body .el-table thead th.el-table__cell:nth-child(8) {
  border-right: none!important;
}
::v-deep .el-table th.el-table__cell>.cell{
  color:#000;
}
::v-deep .el-table thead th.el-table__cell{
  background: #E8E8E8;
  color: #000;
}
::v-deep .el-table--border{
  border: 1px solid #BBBBBB;
}
::v-deep .el-table--border .el-table__cell,{
  border-right: 1px solid #BBBBBB;
}
::v-deep .el-table td.el-table__cell{
  border-bottom: 1px solid #BBBBBB;
}
.dialog_content{
  text-align: left;
  p{
    line-height: 2;
  }
  span{
    line-height: 2;
  }
}
.right_block{
  bottom: 20px;
  .right_top{
    display: inline-block;
    margin-top:20px;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }
  .right_bottom{
    display: block;
    .bt_content{
      padding:  20px 0;
    }
  }
}
.is_valid{
  color: #BD3124;
}
::v-deep .custom_tooltip.el-tooltip__popper .popper__arrow{
  border-color:#FFE58F!important;
}
::v-deep .invoice_label .el-form-item__label{
  color: #000;
  //font-weight: bold;
}
::v-deep .address_label,::v-deep .address_label .el-form-item__label {
  color: #000;
}
::v-deep .el-form-item__label{
  padding-right: 0!important;
}
.el-dialog__body .right_block .right_bottom .page_title{
  padding:4px 20px;
}
//表格多一列star
::v-deep .el-table__body{
  width: 100%!important;
}
::v-deep .el-table colgroup col[name='gutter']{
  display: none;
  width: 0;
}
::v-deep .el-table .gutter{
  display: none!important;
  width: 0!important;
}
//表格多一列end
@media screen and (min-width: 768px) and (max-width: 1550px), print {
  .dialog_content{
    height:390px;
    overflow: scroll;
  }
  .el-dialog__body .right_block .right_bottom .page_title p{
    font-size: 18px!important;
  }
}
@media screen and (min-width: 1551px) , print {
  .el-dialog__body .right_block .right_bottom .page_title p{
    font-size: 20px!important;
  }
  .dialog_content{
    height: 500px;
    overflow: scroll;
  }
}
</style>
<style>
::v-deep .el-tooltip__popper[placement="top-start"] .popper__arrow {
  border-color:#FFFCED!important;
}

.custom_tooltip{
  border: 1px solid #FFE58F!important;
  color:#BD3124!important;
  background: #FFFCED!important;
  .popper__arrow {
    border-top-color:  #FFE58F!important; /* 替换为你想要的颜色 */
  }
}
.is_valid{
  position: relative;
}
.valid_tip{
  font-size: 14px;
  display: inline-block;
  border: 1px solid #FFE58F!important;
  color:#BD3124!important;
  background: #FFFCED!important;
  padding: 3px 10px;
  height: 32px;
  //line-height: 32px!important;
  box-sizing: border-box;
  border-radius: 4px;
  position: absolute;
  top:  -46px;
  left:18px;
  box-shadow: 0px 4px 6px 0px rgba(0,0,0,0.16);
  width: 200px;
}
#triangle{
  top: -14px;
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid;
  //border-color: red transparent transparent transparent;
  border-color: #FFFBE6 transparent transparent transparent;
  left:50px;
}
</style>