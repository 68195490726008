<template>
  <div>
    <el-select
        @change="chooseGoods"
        class="check_select"
        v-model="inventory"
        placeholder="请输入关键字"
        loading-text="正在从服务端获取数据"
        size="small"
        filterable
        clearable
        remote
        :remote-method="getGoodsList"
    >
      <el-option
          v-for="item in goodsList"
          :key="item.value"
          :label="item.label"
          :disabled="item.disabled"
          :value="item">
      </el-option>
    </el-select>
  </div>
</template>

<script>
  import {getInventoryList} from "@/api/orderGoods/custom";

  export default {
    props: {
      clo_index: {
        type: Number,
        default: null
      },
      existIdList:{ // 页面已选的商品的id
        type: Array,
        default: () => []
      },
      goodsListTemp:{ // 页面已选的商品的option
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
        goodsList: [],
        inventory: {},
      }
    },
    methods: {
      getGoodsList(e) {
        getInventoryList({name: e}).then(res => {
          let originalArray = res.data.data
          const idArray = this.existIdList
          let updatedArray = originalArray.map((item,index) => {
            if (idArray.includes(item.inventory_id)) {
              return  { ...item, disabled: true };
            }
            return item;
          });
          this.goodsList = updatedArray.map(item => {
            return {
              label: item.inventory_full_name,
              value: item.inventory_id,
              inventory_name: item.inventory_name,
              standard: item.standard,
              sale_unit: item.sale_unit,
              disabled: item.disabled
            }
          })
          let newIds = this.goodsList.map(item => item.value)
          if(this.goodsListTemp && this.goodsListTemp.length > 0){
            if(newIds && !newIds.includes(this.goodsListTemp[0].value)){
              this.goodsList = this.goodsList.concat(this.goodsListTemp)
            }
          }
        })
      },
      chooseGoods(){
        this.$emit('chooseGoods',{rowData:this.inventory,clo_index: this.clo_index,isEdit: false})
      }
    }
  }
</script>
<style scoped lang="scss">
::v-deep .el-input .el-input__inner{

  width: 100%;
}
</style>