<template>
  <div>
    <el-select
        @change="chooseGoods"
        class="check_select"
        @focus="selectFocus(clo_index)"
        v-model="inventory"
        placeholder="请输入关键字"
        loading-text="正在从服务端获取数据"
        size="small"
        filterable
        clearable
        remote
        :remote-method="getGoodsList"
        @visible-change="handleVisibleChange"
    >
      <el-option
          v-if="isOptionShow"
          v-for="item in goodsList"
          :key="item.value"
          :label="item.label"
          :disabled="item.disabled"
          :value="item">
      </el-option>
    </el-select>
    <template >

    </template>
  </div>
</template>

<script>
import {getInventoryList, getInventoryPrice} from "@/api/orderGoods/custom";

export default {
  props: {
    clo_index: {
      type: Number,
      default: null
    },
    existIdList:{ // 页面已选的商品的id
      type: Array,
      default: () => []
    },
    goodsListTemp:{ // 页面已选的商品的option
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      goodsList: [],
      inventory: {},
      selectDialogVisible: false,
      isOptionShow: false,
      loading: false,
      searchText: '',
    }
  },
  methods: {
    selectFocus(index){
      this.currentIndex = index
      this.$emit('selectFocus')
      // this.$nextTick(() => {
      //   // 获取表格的 DOM 元素
      //   const tableWrapper = this.$refs.goodsTable.$el.querySelector('.el-table__body-wrapper');
      //   window.requestAnimationFrame(() => {
      //     tableWrapper.scrollLeft = 0;
      //   });
      // });
    },
    handleVisibleChange(visible) {
      // 如果下拉框关闭且没有选项，则不显示下拉框
      if ( this.goodsList.length === 0 || this.goodsList.length > 1) {
        this.inventory = {}
      }
    },
    getGoodsList(e) {
      getInventoryList({name: e}).then(res => {
        let originalArray = res.data.data
        const idArray = this.existIdList
        let updatedArray = originalArray.map((item,index) => {
          if (idArray.includes(item.inventory_id)) {
            return  { ...item, disabled: true };
          }
          return item;
        });
        this.goodsList = updatedArray.map(item => {
          return {
            label: item.inventory_full_name,
            value: item.inventory_id,
            inventory_name: item.inventory_name,
            inventory_full_name: item.inventory_full_name,
            standard: item.standard,
            sale_unit: item.sale_unit,
            disabled: item.disabled
          }
        })
        this.goodsListTemp = JSON.parse(JSON.stringify(this.goodsList))
        if(this.goodsList.length > 0){
          if(this.goodsList.length === 1){
            this.isOptionShow = true
          }else{
            this.$emit('selectDialogShow',true)
            this.selectDialogVisible = true
            this.isOptionShow = false
          }
        }
        // let newIds = this.goodsList.map(item => item.value)
        // if(this.goodsListTemp && this.goodsListTemp.length > 0){
        //   if(newIds && !newIds.includes(this.goodsListTemp[0].value)){
        //     this.goodsList = this.goodsList.concat(this.goodsListTemp)
        //   }
        // }
      })
    },
    chooseGoods(){
      this.$emit('chooseGoods',{rowData:[this.inventory],clo_index: this.clo_index,isEdit: false})
    },
    handleSelectionChange(val){
      if(val.length > 0){
        this.selectedList = val
        this.selectedList.forEach(item => {
          item.inventory_ids = item.value
          item.inventory_id = item.value
          item.isAdd = true
          if(item.price){
            item.unit_price = Number(item.price).toFixed(2)
          }else{
            item.unit_price = 0.00
          }
          if(item.tax_price){
            item.tax_price = Number(item.tax_price).toFixed(2)
          }else{
            item.tax_price = 0.00
          }
        })
      }
    },
    handleClose(){
      this.selectDialogVisible = false
    },
    handleSelected(){ // 弹窗选商品
      if(this.selectedList && this.selectedList.length > 0){
        this.$emit('chooseGoods',{rowData:this.selectedList,clo_index: this.clo_index,isEdit: false,type: 1})
        this.selectDialogVisible = false
      }else{
        this.selectDialogVisible = false
      }
    },
  }
}
</script>
<style  lang="scss">
::v-deep .el-input .el-input__inner{
  width: 100%;
}

</style>