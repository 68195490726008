export default {
    data() {
        return {
            goodsTypeList: [
                {value: 0, label: '试剂'},
                {value: 1, label: '仪器'},
                {value: 2, label: '耗材'}
            ],
            orderStatusList:[
                {value: 9001, label: '待审核'},
                {value: 9002, label: '已审核'},
                {value: 9003, label: '已撤销'},
                {value: 9004, label: '已发货'},
                {value: 9005, label: '已收货'},
                {value: 9006, label: '已驳回'}
            ],
            methodology_options:[
                {value: '1', label: '胶体金法'},
                {value: '2', label: '免疫比浊法(透射)'},
                {value: '3', label: '免疫比浊法(散射)'},
                {value: '4', label: '免疫比浊法(散射+透射)'},
                {value: '5', label: '免疫荧光层析法'},
                {value: '6', label: '化学发光法'},
            ],
            reagent_options:[
                {value: '1', label: 'Qpad'},
                {value: '2', label: '出口'},
                {value: '3', label: 'OTTOMAN-1000'},
            ],
        }
    }
}